import React from "react";
import { Portal } from "@mui/material";
import { useSelector } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import SnackbarWrapper from "modules/common/components/snackbars/snackbarWrapper";
import { UseSetSnackbarMessages } from "modules/common/hooks/useSetSnackbarMessages";

interface ISnackbarsProps {}

const Snackbars: React.FC<ISnackbarsProps> = ({}) => {
    const { successMessage, errorMessage, infoMessage } = useSelector((state: GlobalApplicationState) => state.snackbars);
    const {
        setSuccessMessage,
        setErrorMessage,
        setInfoMessage
    } = UseSetSnackbarMessages();

    return <Portal>
        <SnackbarWrapper open={!!successMessage} message={successMessage} severity="success" onClose={() => setSuccessMessage("")}/>
        <SnackbarWrapper open={!!errorMessage} message={errorMessage} severity="error" onClose={() => setErrorMessage("")}/>
        <SnackbarWrapper open={!!infoMessage} message={infoMessage} severity="info" onClose={() => setInfoMessage("")}/>
    </Portal>;
};

export default Snackbars;
