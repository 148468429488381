import { TranslatedNewsletterContent } from "modules/common/components/authoring/models";
import { SortStyle } from "utils/managementUtils";

export type EmailActivityEvent = "Bounced" | "Clicked" | "Deferred" | "Delivered" | "Dropped" | "Opened" | "Processed" | "SpamReported" | "Unsubscribed";
export const emailActivityEvents: EmailActivityEvent[] = ["Bounced", "Clicked", "Deferred", "Delivered", "Dropped", "Opened", "Processed", "SpamReported", "Unsubscribed"]
export const toEmailActivityEventName = (event: EmailActivityEvent): string => (event === 'SpamReported') ? "Spam reported" : event;
export type ConfigChannel = "AdminPortal" | "SharePoint" | "Teams";
export type NewsletterStatus = "Disabled" | "Draft" | "Enabled";
export type NewsletterIssueStatus = "Future" | "Sent" | "Skip" | "Skipped";
export type NewsletterIssueType = "Scheduled" | "Adhoc";
export type NewsletterRecurrence = "Adhoc" | "Daily" | "Biweekly" | "Monthly";
export type DayOfWeek = "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday"| "Friday" | "Saturday";
export const daysOfWeek : DayOfWeek[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday" , "Friday", "Saturday"];
export type NewsletterMonthlyRecurrenceWeek = "First" | "Second" | "Third" | "Last"
export const recurrenceWeeks : NewsletterMonthlyRecurrenceWeek[] = ["First", "Second", "Third", "Last"]
export type NewsletterTopicOption = "Employee" | "All" | "Custom"
export type NewsletterDynamicContentWindow = "SinceLastIssue" | "DaysBeforeCurrentIssue"
export type NewsletterImageType = "Header" | "Footer";
export type NewsletterTemplateImageType = NewsletterImageType | "Preview";

export const HEADER: NewsletterImageType = "Header";
export const FOOTER: NewsletterImageType = "Footer";

export interface NewsletterConfig {
    fromEmailAddress: string;
    fromEmailName: string;
    linkPreference: ConfigChannel;
    addresses: Address[];
}

export interface SaveNewsletterConfigModelStateErrors {
    FromEmailAddress?: string[];
    FromEmailName?: string[];
    LinkPreference?: string[];
}

export interface NewsletterConfigDigest extends NewsletterConfig {
    availableLinkPreferences: ConfigChannel[];
}

export interface NewsletterFilterValues {
    textToSearch?: string;
    status?: NewsletterStatus;
    sortType?: SortStyle;
    tags?: string[];
    lastSentFrom?: string;
    lastSentTo?: string;
    nextQueuedFrom?: string;
    nextQueuedTo?: string;
    modifiedFrom?: string;
    modifiedTo?: string;
}

export interface NewsletterListingPage {
    currentPage: number;
    results: NewsletterDigest[];
    totalItems: number;
    totalPages: number;
}

export interface SimpleTag {
    id: string;
    name: string;
}

export interface NewsletterTags {
    draft: SimpleTag[];
    enabled: SimpleTag[];
    disabled: SimpleTag[];
    count: number;
}

export interface Address {
    fromEmailAddress: string;
    fromEmailName: string;
    currentlyInUse: boolean;
}

export interface Newsletter {
    id: string;
    tenantId: string;
    status: NewsletterStatus;
    editedTime: string;
    title: string;
    description: string;
    recurrenceType: NewsletterRecurrence;
    dailyRecurrenceOn: DayOfWeek[];
    monthlyRecurrenceWeek: NewsletterMonthlyRecurrenceWeek | null;
    monthlyRecurrenceDay: DayOfWeek | null;
    firstIssueDateTime: string;
    emailSubject: string;
    emailPreheader: string;
    emailTemplateIsCustom: boolean;
    emailTemplateId: string;
    emailTemplateFooterImageIsCustomized: boolean;
    emailTemplateFooterText: string;
    emailTemplateHeaderImageIsCustomized: boolean;
    emailTemplateHeaderText: string;
    bodyText: string;
    audiences: SimpleTag[];
    allowUnsubscribe: boolean;
    topicOption: NewsletterTopicOption;
    customTopics: SimpleTag[];
    maxEventsToInclude: number;
    includeUpcomingEvents: boolean;
    eventsToInclude: NewsletterDynamicContentWindow;
    eventsToIncludeDaysWindow: number;
    maxPostsToInclude: number;
    postsToInclude: NewsletterDynamicContentWindow;
    postsToIncludeDaysWindow: number;
    fromEmailName: string
    fromEmailAddress: string
    sortPostsAscending: boolean;
    numberSubscribed: number;
    numberUnsubscribed: number;
    translatedContent: TranslatedNewsletterContent;
}

export interface NewsletterDigest extends Newsletter {
    lastIssueSentOn: string | null;
    lastSentIssueDateLocal: string | null;
    nextIssueSendOn: string | null;
}

export interface NewsletterDetails extends NewsletterDigest {
    futureIssues: NewsletterFutureIssueDigestModel[];
    emailTemplate?: NewsletterTemplate;
    sentIssueTags: SimpleTag[];
    
    /* colors (subject to change) */
    emailBackground: string;
    primaryBackground: string;
    primaryText: string;
    secondaryBackground: string;
    secondaryText: string;
    linkTexts: string;

    newsletterTheme: NewsletterThemeSimple;
}

export interface SaveNewsletterModelStateErrors {
    Title?: string[];
    Status?: string[];

    EmailTemplateId?: string[];
    EmailSubject?: string[];
    EmailPreheader?: string[];
    EmailTemplateHeaderText?: string[];

    FirstIssueDateTime?: string[];
    DailyRecurrenceOn?: string[];
    MonthlyRecurrenceDay?: string[];
    MonthlyRecurrenceWeek?: string[];

    CustomTopics?: string[];
    MaxEventsToInclude?: string[];
    EventsToIncludeDaysWindow?: string[];
    MaxPostsToInclude?: string[];
    PostsToIncludeDaysWindow?: string[];
}

export interface SaveNewsletterModel {
    /* do not modify - pass back what was fetched */
    id?: string;
    tenantId?: string;

    /* updateable properties */
    status: NewsletterStatus;
    title: string;
    description: string;
    recurrenceType: NewsletterRecurrence;
    dailyRecurrenceOn: DayOfWeek[];
    monthlyRecurrenceWeek: NewsletterMonthlyRecurrenceWeek | null;
    monthlyRecurrenceDay: DayOfWeek | null;
    firstIssueDateTime?: string;
    emailSubject: string;
    emailPreheader: string;
    emailTemplateId: string;
    emailTemplateFooterText: string;
    emailTemplateHeaderText: string;
    bodyText: string;
    audiences?: SimpleTag[];
    allowUnsubscribe: boolean;
    topicOption: NewsletterTopicOption;
    customTopics: SimpleTag[];
    includeUpcomingEvents: boolean
    maxEventsToInclude: number;
    eventsToInclude: NewsletterDynamicContentWindow;
    eventsToIncludeDaysWindow: number;
    maxPostsToInclude: number;
    postsToInclude: NewsletterDynamicContentWindow;
    postsToIncludeDaysWindow: number;
    sortPostsAscending: boolean;
    fromEmailName: string
    fromEmailAddress: string
    newsletterTheme: NewsletterThemeSimple;
    translatedContent: TranslatedNewsletterContent;
}

export interface NewsletterThemeSimple {
    themeId: string;
    emailBackground: string;
    primaryBackground: string;
    primaryText: string;
    secondaryBackground: string;
    secondaryText: string;
    linkText: string;
}

export interface NewsletterTemplate {
    id: string;
    name: string;
    isCustom: boolean;
    hasPreviewImage: boolean;
    headerImageHeight: number;
    headerImageWidth: number;
    footerImageHeight: number;
    footerImageWidth: number;
    thumbnailImageBase64: string;
}

export interface NewsletterTheme {
    id: string;
    name: string;
    isDefault: boolean;
    isCustom: boolean;
    emailBackground: string;
    primaryBackground: string;
    primaryText: string;
    secondaryBackground: string;
    secondaryText: string;
    linkText: string;
    createdBy: string;
    createdByDisplayName: string;
    createdOnUTC: Date;
    lastModifiedBy: string;
    lastModifiedByDisplayName: string;
    lastModifiedOnUTC: Date;
    headerImage: string;
    footerImage: string;
    themeHeaderImageIsCustomized: boolean;
    themeFooterImageIsCustomized: boolean;
}
    
export interface UploadNewsletterImageModel {
    headerImage?: string;
    footerImage?: string;
    newsletterId: string;
}

export interface UploadIssueImageModel extends UploadNewsletterImageModel {
    issueDate: string;
}

//=================================================//

export interface Activity {
    activity: EmailActivityEvent;
    eventTime: string;
    userEmail: string;
    userFullName: string;
    newsletterTitle: string;
    issueDate: string;
}

export interface ActivityLogFilterValues {
    eventNames: EmailActivityEvent[];
    newsletterIds: string[];
    issueIds: string[];
    eventDateFrom: string | null;
    eventDateTo:string | null;
}

export interface ActivityLogPage {
    currentPage: number;
    results: Activity[];
    totalItems: number;
    totalPages: number;
}

export interface FeaturedContent {
    contentType: "Event" | "Post";
    id: string;
    name: string;
}

export interface NewsletterIssue extends NewsletterIssueInput {
    issueType: NewsletterIssueType;
    editedTime?: string;
    emailTemplateFooterImageIsCustomized: boolean;
    emailTemplateHeaderImageIsCustomized: boolean;
}

export interface SaveNewsletterIssueModelStateErrors {
    OverriddenIssueDateTime?: string[];
    EmailSubject?: string[];
    EmailPreheader?: string[];
}

export interface NewsletterIssueInput {
    /* do not modify - pass back what was fetched */
    id?: string;
    issueDateLocal: string;
    newsletterId: string;
    tenantId?: string;

    /* updateable properties */
    emailPreheader: string;
    emailSubject: string;
    emailTemplateHeaderText: string;
    bodyText: string;
    emailTemplateFooterText: string;
    featuredContent: FeaturedContent[];
    overriddenIssueDateTime: string;
    status: NewsletterIssueStatus;
    translatedContent: TranslatedNewsletterContent;
}

export interface NewsletterFutureIssueDigestModel extends NewsletterIssue {
    isCustomized: boolean;
    sendDateTime: string;
}

export interface NewsletterFutureIssueDetailModel extends NewsletterFutureIssueDigestModel {
    earliestOverrideDateTime?: string;
    latestOverrideDateTime?: string;
}

export interface NewsletterPastIssueDigestModel extends NewsletterIssue {
    issueProcessedOnDateTime?: string;
    stats: EmailEventStats;
    sentReceipt: NewsletterIssueSentReceipt | null;
}

export interface NewsletterIssueSentReceipt {
    sentAtUtc: string;
    countOfCopiesSent: number;
    countOfInvalidEmailAddresses: number;
    countOfUnsubscribers: number;
    countOfSkippedWithNoContent: number;
}
export interface NewsletterPastIssuesPage {
    currentPage: number;
    results: NewsletterPastIssueDigestModel[];
    totalItems: number;
    totalPages: number;
}

export interface NewsletterSummary {
    id: string;
    name: string;
    scheduleDisplay: string;
    lastIssue: Date;
    nextIssue: Date;
}

export interface EmailEventStats {
    Bounced: number;
    Clicked: number;
    Deferred: number;
    Delivered: number;
    Dropped: number;
    Opened: number;
    Processed: number;
    SpamReported: number;
    UniqueBounced: number;
    UniqueClicked: number;
    UniqueDeferred: number;
    UniqueDelivered: number;
    UniqueDropped: number;
    UniqueOpened: number;
    UniqueProcessed: number;
    UniqueSpamReported: number;
    UniqueUnsubscribed: number;
    Unsubscribed: number;
}

export const getEmptyEmailEventStats = () => { 
    return {
        Bounced: 0,
        Clicked: 0,
        Deferred: 0,
        Delivered: 0,
        Dropped: 0,
        Opened: 0,
        Processed: 0,
        Unsubscribed: 0,
        SpamReported: 0,
        UniqueBounced: 0,
        UniqueClicked: 0,
        UniqueDeferred: 0,
        UniqueDelivered: 0,
        UniqueDropped: 0,
        UniqueOpened: 0,
        UniqueProcessed: 0,
        UniqueSpamReported: 0,
        UniqueUnsubscribed: 0} as EmailEventStats
    };

export interface DailyEmailEventStats extends EmailEventStats {
    Day: string;
}

export interface WeeklyEmailEventStats extends EmailEventStats {
    WeekStarting: string;
}