import { GlobalApplicationState } from "globalApplicationState";
import * as Actions from "./actions";
import {
    Answers, 
    AttendanceFilterValues,
    AttendanceListingPage,
    AttendanceType,
    Event,
    EventFeedFilters,
    EventFeedItem,
    EventFilterValues,
    EventListItem,
    EventView,
    RSVPType,
    TranslatableEventContent
} from "modules/events/models";
import API from "api";
import XhrUpload from "utils/xhrUpload";
import MsalAuthModule from "authentication/msalAuthModule";
import { ImageScale } from "modules/posts/models";
import { AttachedFile, Image as ImageV1 } from "modules/gallery/models";
import { ContentAnalysis } from "modules/common/components/authoring/models";
import { ContentState } from "utils/managementUtils";

export const changeRSVPStatus = (id: string, attendeeIds: string[], attendanceType: AttendanceType) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
    dispatch(Actions.ChangeRSVPStatus({}));
    return dispatch(API.events.ChangeRSVPStatus(id, attendeeIds, attendanceType))
        .then(response => {
            dispatch(Actions.ChangeRSVPStatusComplete({ succeeded: response.status === 200 }));
            return true;
        })
        .catch(err => {
            dispatch(Actions.ChangeRSVPStatusComplete({ succeeded: false, err }));
            return false;
        });
}


export const cloneEvent = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<Event> => {
    dispatch(Actions.CloneEvent({}));
    return dispatch(API.events.CloneEvent(id))
        .then(response => {
            dispatch(Actions.CloneEventComplete({ succeeded: true }));
            return response.json();
        })
        .catch(err => {
            dispatch(Actions.CloneEventComplete({ succeeded: false, err }));
            return;
        });
}


export const deleteEvents = (events: EventListItem[]) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
    dispatch(Actions.DeleteEvents({}));
    return Promise.all(events.map(e => dispatch(e.status === "draft" ? API.events.DeleteEventDraft(e.draftId) : API.events.DeleteEvent(e.draftId))))
        .then(results => {
            dispatch(Actions.DeleteEventsComplete({ succeeded: true, deletedEvents: events.map(e => e.draftId) }));
            return true;
        })
        .catch(err => {
            dispatch(Actions.DeleteEventsComplete({ succeeded: false, err }));
            return false;
        });
}

export const unpublishEvent = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<Event> => {
    dispatch(Actions.UnpublishEvent({}));
    return dispatch(API.events.UnpublishEvent(id))
        .then(response => {
            dispatch(Actions.UnpublishEventComplete({ succeeded: true }));
            return true;
        })
        .catch(err => {
            dispatch(Actions.UnpublishEventComplete({ succeeded: false, err }));
            return false;
        });
}

export const editResponse = (id: string, attendeeId: string, attendanceType: AttendanceType, answers: Answers) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
    dispatch(Actions.EditResponse({}));
    return dispatch(API.events.EditResponse(id, attendeeId, attendanceType, answers))
        .then(response => {
            dispatch(Actions.EditResponseComplete({ succeeded: response.status === 200 }));
            return true;
        })
        .catch(err => {
            dispatch(Actions.EditResponseComplete({ succeeded: false, err }));
            return false;
        });
}


export const getAttendance = (id: string, attendanceTypes: AttendanceType[], pageNumber: number, filters: Partial<AttendanceFilterValues>) => (dispatch, getState: () => GlobalApplicationState): Promise<AttendanceListingPage> => {
    dispatch(Actions.GetAttendanceListing({}));
    return dispatch(API.events.GetAttendance(id, attendanceTypes, pageNumber, filters))
        .then(response => {
            dispatch(Actions.GetAttendanceListingComplete({ succeeded: true }));
            return response.json();
        })
        .catch(err => {
            dispatch(Actions.GetAttendanceListingComplete({ succeeded: false, err }));
            return;
        });
}


export const getAttendanceCSV = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<string> => {
    dispatch(Actions.GetAttendanceListingCSV({}));
    return dispatch(API.events.GetAttendanceCSV(id))
        .then(response => {
            dispatch(Actions.GetAttendanceListingCSVComplete({ succeeded: true }));
            return response.json();
        })
        .catch(err => {
            dispatch(Actions.GetAttendanceListingCSVComplete({ succeeded: false }));
            return "";
        });
}


export const getContentAnalysis = (id: string, text: string) => (dispatch, getState: () => GlobalApplicationState): Promise<ContentAnalysis> => {
    dispatch(Actions.GetContentAnalysis({}));
    return dispatch(API.events.GetContentAnalysis(id, text))
        .then(response => {
            dispatch(Actions.GetContentAnalysisComplete({ succeeded: true }));
            return response.json();
        })
        .catch(err => {
            dispatch(Actions.GetContentAnalysisComplete({ succeeded: false, err }));
            return;
        });
}


export const getDocument = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<AttachedFile> => {
    dispatch(Actions.GetDocument({}));
    return dispatch(API.mediaLibrary.FetchDocumentForId((id)))
        .then(response => response.json())
        .then(file => {
            dispatch(Actions.GetDocumentComplete({ succeeded: true }));
            const image: AttachedFile = {
                blobId: file.id,
                fileExtension: "",
                fileName: file.name,
                fileType: "application",
                fileUrl: file.url,
                ospreyId: file.id
            };
            return image;
        })
        .catch(err => {
            dispatch(Actions.GetDocumentComplete({ succeeded: false }));
            return;
        });
}


export const getDocumentGallery = () => (dispatch, getState: () => GlobalApplicationState): Promise<void> => {
    dispatch(Actions.GetDocumentGallery({}));
    return dispatch(API.mediaLibrary.FetchDocuments())
        .then(response => response.json())
        .then(documentGalleryPage => dispatch(Actions.GetDocumentGalleryComplete({ ...documentGalleryPage, succeeded: true })))
        .catch(err => dispatch(Actions.GetDocumentGalleryComplete({ currentPage: 1, documents: [], totalDocuments: 0, totalPages: 0, succeeded: false, err })));
}


export const getDraft = (draftId: string, imageScale: ImageScale = ImageScale.Unprocessed) => (dispatch, getState: () => GlobalApplicationState): Promise<Event> => {
    dispatch(Actions.GetDraft({}));
    return dispatch(API.events.GetDraft(draftId, imageScale))
        .then(response => {
            dispatch(Actions.GetDraftComplete({ succeeded: response.status === 200 }));
            return response.status === 200 ? response.json() : null;
        })
        .catch(err => {
            dispatch(Actions.GetDraftComplete({ succeeded: false, err }));
            return;
        });
}

export const getSubmissions = (pageNumber: number, filters: Partial<EventFilterValues>, pageAmount?: number) => (dispatch, _: () => GlobalApplicationState): Promise<void> => {
    dispatch(Actions.GetSubmissions({}));
    return dispatch(API.events.getEventDrafts(pageNumber, {...filters, eventStates: [ContentState.Submission]}, pageAmount))
        .then(response => response.json())
        .then(eventPage => {
            dispatch(Actions.GetSubmissionsComplete({ ...eventPage, succeeded: true }))
        })
        .catch(err => dispatch(Actions.GetSubmissionsComplete({ currentPage: 1, events: [], totalEvents: 0, totalPages: 0, succeeded: false, err })));
}

export const getDrafts = (pageNumber: number, filters: Partial<EventFilterValues>, pageAmount?: number) => (dispatch, _: () => GlobalApplicationState): Promise<void> => {
    dispatch(Actions.GetDrafts({}));
    return dispatch(API.events.getEventDrafts(pageNumber, {...filters, eventStates: [ContentState.Draft]}, pageAmount))
        .then(response => response.json())
        .then(eventPage => dispatch(Actions.GetDraftsComplete({ ...eventPage, succeeded: true })))
        .catch(err => dispatch(Actions.GetDraftsComplete({ currentPage: 1, events: [], totalEvents: 0, totalPages: 0, succeeded: false, err })));
}


export const getAllEvents = (pageNumber: number, filters: Partial<EventFilterValues>, pageAmount?: number) => (dispatch, getState: () => GlobalApplicationState): Promise<void> => {
    dispatch(Actions.GetAllEvents({}));
    return dispatch(API.events.GetAllEvents(pageNumber, filters, pageAmount))
        .then(response => response.json())
        .then(eventPage => dispatch(Actions.GetAllEventsComplete({ ...eventPage, succeeded: true })))
        .catch(err => dispatch(Actions.GetAllEventsComplete({ currentPage: 1, events: [], totalEvents: 0, totalPages: 0, succeeded: false, err })));
}


export const getDraftTranslation = (id: string, content: TranslatableEventContent, outputLCID: string) => (dispatch, getState: () => GlobalApplicationState): Promise<TranslatableEventContent> => {
    dispatch(Actions.GetDraftTranslation({}));
    return dispatch(API.events.GetDraftTranslation(id, content, outputLCID))
        .then(response => {
            dispatch(Actions.GetDraftTranslationComplete({ succeeded: true }));
            return response.json();
        })
        .catch(err => {
            dispatch(Actions.GetDraftTranslationComplete({ succeeded: false, err }));
            return;
        });
}


export const getEvent = (id: string, imageScale: ImageScale = ImageScale.Unprocessed) => (dispatch, getState: () => GlobalApplicationState): Promise<EventView> => {
    dispatch(Actions.GetEvent({}));
    return dispatch(API.events.GetEvent(id, imageScale))
        .then(response => {
            dispatch(Actions.GetEventComplete({ succeeded: response.status === 200 }));
            return response.status === 200 ? response.json() : null;
        })
        .catch(err => {
            dispatch(Actions.GetEventComplete({ succeeded: false, err }));
            return;
        });
}

/*
 * Fetches the specified section of the event feed. Overwrites the event feed state in redux.
 */
export const getEventFeed = (filters: Partial<EventFeedFilters>, filtersApplied: boolean, sortAscending: boolean, pageNumber: number) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.GetEventFeed({}));

    dispatch(API.events.GetEventFeed(filters, sortAscending, pageNumber))
        .then(response => response.json())
        .then((eventsFeed) => dispatch(Actions.GetEventFeedComplete({ succeeded: true, filtersApplied, eventsFeed })))
        .catch(error => dispatch(Actions.GetEventFeedComplete({ succeeded: false, filtersApplied, eventsFeed: [] })));
}

/*
 * Fetches the specified section of the event feed, WITHOUT overwriting the event feed state in redux.
 */
export const fetchEventFeedLocal = (filters: Partial<EventFeedFilters>, pageNumber: number, pageAmount: number) => (dispatch, _: () => GlobalApplicationState) : Promise<EventFeedItem[]> => {
    return dispatch(API.events.GetEventFeed(filters, true, pageNumber, pageAmount))
        .then(res => res.json())
        .then((eventsFeed: EventFeedItem[]) => eventsFeed)
        .catch(_ => []);
}

const getFirstGifFrame = (gif: File): Promise<File> => {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const img = new Image();
        img.onload = function () {
            canvas.width = img.width;
            canvas.height = img.height;
            let context = canvas.getContext("2d")!;
            context.drawImage(img, 0, 0, img.width, img.height);
            canvas.toBlob((newBlob: any) => {
                newBlob.lastModifiedDate = new Date();
                newBlob.name = gif.name;
                return resolve(newBlob as File);
            });
        }
        img.src = URL.createObjectURL(gif);
    });
}


export const getImage = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<AttachedFile> => {
    dispatch(Actions.GetImage({}));
    return dispatch(API.mediaLibrary.FetchImageForId(id))
        .then(response => response.json())
        .then(file => {
            dispatch(Actions.GetImageComplete({ succeeded: true }));
            const image: AttachedFile = {
                blobId: file.id,
                fileExtension: "",
                fileName: file.name,
                fileType: !!file.videoId ? "video" : "image",
                fileUrl: file.url,
                ospreyId: file.id
            };
            return image;
        })
        .catch(err => {
            dispatch(Actions.GetImageComplete({ succeeded: false }));
            return;
        });
}


export const getImageGallery = (pageNumber: number = 1, includeVideos: boolean = false) => (dispatch, getState: () => GlobalApplicationState): Promise<void> => {
    dispatch(Actions.GetImageGallery({}));
    return dispatch(API.mediaLibrary.GetImageGallery(pageNumber, includeVideos))
        .then(response => response.json())
        .then(imageGalleryPage => dispatch(Actions.GetImageGalleryComplete({ ...imageGalleryPage, succeeded: true })))
        .catch(err => dispatch(Actions.GetImageGalleryComplete({ currentPage: 1, images: [], totalImages: 0, totalPages: 0, succeeded: false, err })));
}


export const getPublished = (pageNumber: number, filters: Partial<EventFilterValues>, pageAmount?: number) => (dispatch, getState: () => GlobalApplicationState): Promise<void> => {
    dispatch(Actions.GetPublished({}));
    return dispatch(API.events.GetPublished(pageNumber, filters, pageAmount))
        .then(response => response.json())
        .then(eventPage => dispatch(Actions.GetPublishedComplete({ ...eventPage, succeeded: true })))
        .catch(err => dispatch(Actions.GetPublishedComplete({ currentPage: 1, events: [], totalEvents: 0, totalPages: 0, succeeded: false, err })));
}


export const getScheduled = (pageNumber: number, filters: Partial<EventFilterValues>, pageAmount?: number) => (dispatch, getState: () => GlobalApplicationState): Promise<void> => {
    dispatch(Actions.GetScheduled({}));
    return dispatch(API.events.GetScheduled(pageNumber, filters, pageAmount))
        .then(response => response.json())
        .then(eventPage => dispatch(Actions.GetScheduledComplete({ ...eventPage, succeeded: true })))
        .catch(err => dispatch(Actions.GetScheduledComplete({ currentPage: 1, events: [], totalEvents: 0, totalPages: 0, succeeded: false, err })));
}


export const getVideo = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<string> => {
    dispatch(Actions.GetVideo({}));
    return dispatch(API.mediaLibrary.FetchVideoForId(id))
        .then(response => response.json())
        .then(url => {
            dispatch(Actions.GetVideoComplete({ succeeded: true }));
            return url;
        })
        .catch(err => {
            dispatch(Actions.GetVideoComplete({ succeeded: false }));
            return;
        });
}

export const getVideosComplete = () => (dispatch, getState: () => GlobalApplicationState): void => {
    dispatch(Actions.GetVideosComplete({}));
}

export const publishEvent = (eventId: string, sendNotification: boolean) => (dispatch, _: () => GlobalApplicationState): Promise<boolean> => {
    dispatch(Actions.PublishEvent({}));
    return dispatch(API.events.PublishEvent(eventId, sendNotification))
        .then(response => {
            if (response.status === 200) {
                dispatch(Actions.ClearEventFeed({}));
                dispatch(Actions.ClearEventFeedFilters({}));
            }
            return response;
        })
        .then(response => response.json())
        .then (res => {
            dispatch(Actions.PublishEventComplete({ succeeded: true, publishedEvents: res }));
            return true;
        })
        .catch(err => {
            dispatch(Actions.PublishEventComplete({ succeeded: false, err }));
            return false;
        });
}

export const rsvp = (id: string, attendanceType: AttendanceType, answers: Answers, rsvpType: RSVPType) => (dispatch, getState: () => GlobalApplicationState): Promise<EventView> => {
    dispatch(Actions.RSVPToEvent({}));
    return dispatch(API.events.RSVP(id, attendanceType, answers))
        .then(response => {
            dispatch(Actions.RSVPToEventComplete({ succeeded: response.status === 200, attendanceType, rsvpType }));
            return response.json();
        })
        .catch(err => {
            dispatch(Actions.RSVPToEventComplete({ succeeded: false, err, attendanceType, rsvpType }));
            return;
        });
}

export const submitEvent = (eventId: string) => (dispatch, _: () => GlobalApplicationState) : boolean => {
    dispatch(Actions.SubmitEvent({}));
    return dispatch(API.events.submitEvent(eventId))
        .then(_ => {
            dispatch(Actions.SubmitEventComplete({ succeeded: true }));
            return true
        })
        .catch(err => {
            dispatch(Actions.SubmitEventComplete({ succeeded: false, err }));
            return false;
        });
}

export const saveEvent = (event: Partial<Event>) => (dispatch, getState: () => GlobalApplicationState): Promise<Event> => {
    dispatch(Actions.SaveEvent({}));
    return dispatch(API.events.SaveEvent(event))
        .then(response => {
            dispatch(Actions.SaveEventComplete({ succeeded: true }));
            return response.json();
        })
        .catch(err => {
            dispatch(Actions.SaveEventComplete({ succeeded: false, err }));
            return;
        });
}


export const setEventFeedFilters = (filters: Partial<EventFeedFilters>) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.SetEventFeedFilters({ filters }));
}

export const setEventFeedView = (selectedView: string) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.SetEventFeedView({ selectedView }));
}

export const updateEventFeed = (eventsFeed: EventFeedItem[]) => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.UpdateEventFeed({ eventsFeed }));
}


export const updateUrl = () => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.UpdateUrl({}));
}

export const updateUrlComplete = () => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.UpdateUrlComplete({}));
}


export const uploadDocument = (file: File, onProgress: (percentCompleted: number) => void, onLoad?: (e) => void, onError?: (e) => void, onAbort?: (e) => void) => async (dispatch, getState) => {
    dispatch(Actions.UploadDocument({}));
    return MsalAuthModule.getInstance().getAccessToken().then(accessToken => {
        const requestDetails = API.mediaLibrary.UploadDocument(file);
        const url = getState().config.SparrowClientApiUrl + requestDetails.url
            .replace("{tenant}", getState().tenant.id);

        const handlers = { onLoad, onAbort, onError, onProgress };

        return new XhrUpload(url, requestDetails, handlers)
            .authenticateWith(accessToken)
            .upload();
    })
        .then((response: string) => {
            let document = JSON.parse(response) as { docId: string, fileName: string };
            dispatch(Actions.UploadDocumentComplete({ succeeded: true }));
            return { id: document.docId, fileName: document.fileName };
        });
}


/*
 * In the upload image case we cant use the default fetch implementation,
 * we have to resort to xhr; hence we need to call the authmanager ourselves.
 * This however lets us attach an onProgress event listener.
 * This xhr request could have been abstracted in the middleware, but we would lose
 * the onProgress event when we dispatch the network request.
 */
export const uploadImage = (image: File, onProgress: (percentCompleted: number) => void, onLoad?: (e) => void, onError?: (e) => void, onAbort?: (e) => void) => async (dispatch, getState: () => GlobalApplicationState): Promise<string> => {
    if (!(/^image\/(jpg|jpeg|png|bmp)/).test(image.type)) {
        Actions.UploadImageComplete({ succeeded: false });
        return Promise.resolve("");
    }

    dispatch(Actions.UploadImage({}));

    const fileToUpload = image.type === "image/gif" ? await getFirstGifFrame(image) : image;

    return MsalAuthModule.getInstance().getAccessToken().then(accessToken => {

        const requestDetails = API.mediaLibrary.UploadImage(fileToUpload);
        const url = getState().config.SparrowClientApiUrl + requestDetails.url
            .replace("{tenant}", getState().tenant.id);

        const handlers = { onLoad, onAbort, onError, onProgress };

        return new XhrUpload(url, requestDetails, handlers)
            .authenticateWith(accessToken)
            .upload();

    }).then((response: string) => {
        let image = JSON.parse(response) as { imageId: string, fileName: string };
        dispatch(Actions.UploadImageComplete({ succeeded: true }));
        return image.imageId;
    });
}

export const uploadThumbImage = (blob: Blob, videoId: string, onProgress: (percentCompleted: number) => void, onLoad?: (e) => void, onError?: (e) => void, onAbort?: (e) => void) => (dispatch, getState: () => GlobalApplicationState) => {
    return MsalAuthModule.getInstance().getAccessToken()
        .then((accessToken) => {
            let requestDetails = API.mediaLibrary.VideoUploads.UploadImageThumbnail(blob, videoId);
            let url = getState().config.SparrowClientApiUrl + requestDetails.url
                .replace('{tenant}', getState().tenant.id);

            let handlers = {
                onProgress,
                onLoad,
                onError,
                onAbort
            };
            return new XhrUpload(url, requestDetails, handlers)
                .authenticateWith(accessToken)
                .upload();
        })
        .then((response: string) => {
            let resultingThumb = JSON.parse(response) as { id: string, url: string, thumbnail: ImageV1 }
            return resultingThumb;
        });
}

export const hasChangedSinceSaved = () => (dispatch, getState: () => GlobalApplicationState): void => {
    dispatch(Actions.ChangedSinceSaved({}));
}

export const clearChangedSinceSaved = () => (dispatch, getState: () => GlobalApplicationState): void => {
    dispatch(Actions.ClearChangedSinceSaved({}));
}

export const clearErrorMessage = () => (dispatch, getState: () => GlobalApplicationState): void => {
    dispatch(Actions.ClearErrorMessage({}));
}

export const clearEventFeed = () => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.ClearEventFeed({}));
}

export const clearEventFeedFilters = () => (dispatch, getState: () => GlobalApplicationState) => {
    dispatch(Actions.ClearEventFeedFilters({}));
}

export const clearEventList = () => (dispatch, getState: () => GlobalApplicationState): void => {
    dispatch(Actions.ClearEventList({}));
}

export const clearShouldFetch = () => (dispatch, getState: () => GlobalApplicationState): void => {
    dispatch(Actions.ClearShouldFetch({}));
}

export const clearShowPublishSuccess = () => (dispatch, getState: () => GlobalApplicationState): void => {
    dispatch(Actions.ClearShowPublishSuccess({}));
}

export const clearSuccessMessage = () => (dispatch, getState: () => GlobalApplicationState): void => {
    dispatch(Actions.ClearSuccessMessage({}));
}

export const setEventsFeedToDefault = () => (dispatch, getState: () => GlobalApplicationState): void => {
    dispatch(Actions.SetEventsFeedToDefault({}));
}
