import * as React from "react";
import { Route, Switch } from "react-router";
import AdminAppLayout from "./_layout/adminApp/adminAppLayout";
import UserAppLayout from "./_layout/userApp/userAppLayout";
import InsufficientPermissions from "modules/common/components/insufficientPermissions";
import _ from 'lodash';

import ClientRouteWrapper from "modules/tenant/components/clientRouteWrapper";
import TenantRouteWrapper from "modules/tenant/components/tenantRouteWrapper";
import Dashboard from "modules/dashboard/components/dashboard";
import UserManagementPage from "modules/users/components/userManagementPage";
import SurveyManagementPage from "modules/surveys/components/surveyManagementPage"
import SurveyEdit from "modules/surveys/components/surveyEdit";
import SurveyDetails from "modules/surveys/components/surveyDetails";
import { SettingsPage } from "modules/settings/components/settingsPage";
import AudienceListing from "modules/audiences/components/audience-listing/audienceListing";
import EveryoneAudienceManager from "modules/audiences/components/everyoneAudienceManager";
import { UserRoleStrings } from "modules/authorization/models";

import NewsletterManagementPage from "modules/newsletters/management/newsletterManagementPage"
import EditNewsletterPage from "modules/newsletters/edit/editNewsletterPage";
import EditThemePage from "modules/newsletters/edit/editThemePage";
import NewsletterDashboard from "modules/newsletters/dashboard/newsletterDashboardPage";

import PostManagementPage from "modules/posts/components/postsManagementPage"
import PostCreation from "modules/posts/components/post-creation/postCreation";

import EventManagementPage from "modules/events/components/eventManagementPage";
import EventCreation from "modules/events/components/event-creation/eventCreation";
import EventAttendance from "modules/events/components/attendance-listing/attendanceListing";

import DocumentManagementPage from "modules/documents/containers/documentManagementPage";
import DocumentCreation from "modules/documents/components/document-creation/documentCreation";

import InsightsDashboard from "modules/insights/components/dashboard";
import InsightsTagList from "modules/insights/components/tagList";
import InsightsTagDetail from "modules/insights/components/tagDetail";

import CategoryTagsManagementPage from "modules/categorytags/containers/categoryTagsManagementPage";

import PostInsights from "modules/posts/components/post-insights/postInsightsv2";
import ImageGallery from 'modules/gallery/components/imageGallery';
import Logout from "./_layout/common/components/Logout";
import RedirectToDashboard from "./RedirectToDashboard";
import RedirectToHome from "./RedirectToHome";
import RedirectToWelcome from "./RedirectToWelcome";
import RequireLogin from "authentication/RequireLogin";
import IFrameDetector from "authentication/IFrameDetector";
import ActivityManagementPage from "modules/activity/components/activityManagementPage";
import { GlobalApplicationState } from "globalApplicationState";
import { connect, ConnectedProps } from "react-redux";

import UserProfilePage from "modules/users/components/user-profile/userProfilePage";

import MessagingCenterScreen from "modules/messaging/components/messagingCenterScreen";

import ExternalUsersLobbyView from "modules/users/components/externalUsersLobbyView";

import NavigationItemsManagementPage from "modules/portalPages/containers/navigationItemsManagementPage";
import PortalPagesManagementPage from "modules/portalPages/containers/portalPagesManagementPage";
import PortalPagesCreation from "modules/portalPages/components/portal-pages-creation/portalPagesCreation";

import DocumentsPage from "pages/documents/documentsPage";
import EventsPage from "pages/events/eventsPage";
import HomePage from "pages/home/homePage";
import PortalPagesPage from "pages/portalPages/portalPagesPage";
import PostsPage from "pages/posts/postsPage";
import PublicPostsPage from "pages/posts/publicPostsPage";
import PublicNewsletterUnsubscribedPage from "pages/newsletters/publicNewsletterUnsubscribedPage";
import UserPage from "pages/user/userPage";
import WelcomePage from "pages/welcome/welcomePage";

import Invitation from "authentication/Invitation";
import AuthRedirectHandler from "authentication/AuthRedirectHandler";
import PageForbidden from "pages/pageForbidden/pageForbidden";
import AdminPageForbidden from "pages/pageForbidden/adminPageForbidden";
import DownloadReportPage from "modules/reports/downloadReportPage";
import AdminPageNotFound from "pages/pageNotFound/adminPageNotFound";
import TenantManagementPage from "modules/tenantManagement/components/tenantManagementPage";
import { getHighestRole } from "utils/userRoleUtils";
import AuthRoute from "./authRoute";
import { ConnectedManageHomeScreenSettings } from "modules/settings/components/manageHomeScreenSettingsPage";
import { ConnectedContentBandsEditorPage } from "modules/contentBands/components/editor/contentBandsEditorPage";
import ContentGuidelinesPage from "modules/settings/components/content/guidelines/contentGuidelinesPage";
import EmailsManagementPage from "modules/emails/components/emailsManagementPage";
import EmailEditorPage from "modules/emails/components/editor/emailEditorPage";


const Routes: React.FC<PropsWithRedux> = (props: PropsWithRedux) => {
    const userId = props.currentUser.userId;
    const userIsOwner = !!props.userRoles && _.some(_.intersection([UserRoleStrings.OWNER], props.userRoles));
    const userIsAuthor = !!props.userRoles && (userIsOwner || _.some(_.intersection([UserRoleStrings.AUTHOR], props.userRoles)));
    const isContributor = getHighestRole(props.currentUser) === UserRoleStrings.CONTRIBUTOR;
    const userIsInternalOnly = !!props.userRoles && !userIsOwner && !userIsAuthor && _.some(_.intersection([UserRoleStrings.INTERNAL], props.userRoles));

    return (
        <Route>
            <Switch>
                <Route path="/" exact={true}>
                    <RequireLogin>
                        <RedirectToHome />
                    </RequireLogin>
                </Route>

                <Route path="/invitation" exact component={Invitation} />

                <Route path="/auth">
                    <IFrameDetector renderWhen="NotInFrame" >
                        <AuthRedirectHandler>
                            <RequireLogin>
                                <RedirectToHome />
                            </RequireLogin>
                        </AuthRedirectHandler>
                    </IFrameDetector>
                </Route>

                <Route path="/invite">
                    <RequireLogin>
                        <RedirectToWelcome />
                    </RequireLogin>
                </Route>

                <Route path="/logout" component={Logout} />

                <Route path="/notallowed" component={InsufficientPermissions} />

                <Route path="/:tenant">
                    <Switch>
                        <Route path="/:tenant/public">
                            <UserAppLayout publicAccess>
                                <Switch>
                                    <Route path="/:tenant/public/posts">
                                        <Switch>
                                            <Route path="/:tenant/public/posts" exact={true} component={PublicPostsPage} />
                                            <Route path="/:tenant/public/posts/:postId" component={PublicPostsPage} />
                                        </Switch>
                                    </Route>
                                    <Route path="/:tenant/public/unsubscribed" component={PublicNewsletterUnsubscribedPage} />
                                </Switch>
                            </UserAppLayout>
                        </Route>
                        <Route path="/:tenant/admin">
                            <RequireLogin>
                                <TenantRouteWrapper>
                                    <AdminAppLayout userIsInternalOnly={userIsInternalOnly}>
                                        <Switch>
                                            <Route path="/:tenant/admin/" exact={true}>
                                                <RedirectToDashboard />
                                            </Route>

                                            <AuthRoute path="/:tenant/admin/dashboard" component={Dashboard} roleName={UserRoleStrings.AUTHOR}/>

                                            <AuthRoute path="/:tenant/admin/users" roleName={UserRoleStrings.OWNER}>
                                                <Switch>
                                                    <Route path="/:tenant/admin/users" exact={true} component={UserManagementPage} />
                                                    <Route path="/:tenant/admin/users/profile/:userId" component={UserProfilePage} />
                                                    {props.tenantSettings.lobbyEnabled &&
                                                        <Route path="/:tenant/admin/users/externallobby" component={ExternalUsersLobbyView} />
                                                    }
                                                </Switch>
                                            </AuthRoute>

                                            <AuthRoute path="/:tenant/admin/posts" roleName={UserRoleStrings.CONTRIBUTOR}>
                                                <Switch>
                                                    <Route
                                                        path="/:tenant/admin/posts/"
                                                        exact={true}
                                                        render={() => <PostManagementPage isContributor={isContributor}/>}
                                                    />
                                                    <Route
                                                        path="/:tenant/admin/posts?allPage=:allPageNum&pubPage=:pubPageNum&schPage=:schPageNum&dftPage=:dftPageNum&sbmPage=:sbmPageNum&tab=:tabNum"
                                                        component={PostManagementPage}
                                                    />
                                                    <Route path="/:tenant/admin/posts/edit/:draftId" component={PostCreation} />
                                                    <AuthRoute path="/:tenant/admin/posts/:postId" component={PostInsights} roleName={UserRoleStrings.AUTHOR}/>
                                                </Switch>
                                            </AuthRoute>

                                            {
                                                props.tenantSettings.mobileEventsEnabled &&
                                                <AuthRoute path="/:tenant/admin/events" roleName={UserRoleStrings.CONTRIBUTOR}>
                                                    <Switch>
                                                        <Route path="/:tenant/admin/events/" exact={true} component={EventManagementPage} />
                                                        <Route path="/:tenant/admin/events/attendance/:eventId" component={EventAttendance} />
                                                        <Route path="/:tenant/admin/events/edit/:draftId" component={EventCreation} />
                                                    </Switch>
                                                </AuthRoute>
                                            }

                                            {
                                                props.tenantSettings.showFeatures.documentsEnabled && props.tenantSettings.documentsEnabled &&
                                                <AuthRoute path="/:tenant/admin/documents" roleName={UserRoleStrings.AUTHOR}>
                                                    <Switch>
                                                        <Route path="/:tenant/admin/documents" exact={true} component={DocumentManagementPage} />
                                                        <Route path="/:tenant/admin/documents/upload" component={DocumentCreation} />
                                                    </Switch>
                                                </AuthRoute>
                                            }

                                            <AuthRoute path="/:tenant/admin/portalPages" roleName={UserRoleStrings.OWNER}>
                                                <Switch>
                                                    <Route path="/:tenant/admin/portalPages" exact={true} component={PortalPagesManagementPage} />
                                                    <Route path="/:tenant/admin/portalPages/edit/:draftId" component={PortalPagesCreation} />
                                                    <Route path="/:tenant/admin/portalPages/navigation" component={NavigationItemsManagementPage} />
                                                </Switch>
                                            </AuthRoute>

                                            <AuthRoute path="/:tenant/admin/gallery" component={ImageGallery} roleName={UserRoleStrings.AUTHOR}/>

                                            <AuthRoute path="/:tenant/admin/insights" component={InsightsDashboard} roleName={UserRoleStrings.OWNER}/>

                                            <AuthRoute path="/:tenant/admin/topics" roleName={UserRoleStrings.OWNER}>
                                                <Switch>
                                                    <Route path="/:tenant/admin/topics/" exact={true} component={InsightsTagList} />
                                                    <Route path="/:tenant/admin/topics/:tagGroupId/:tagId" component={InsightsTagDetail} />
                                                </Switch>
                                            </AuthRoute>

                                            {
                                                props.tenantSettings.showFeatures.categoryTagsEnabled &&
                                                <AuthRoute path="/:tenant/admin/categoryTags" roleName={UserRoleStrings.OWNER}>
                                                    <Switch>
                                                        <Route path="/:tenant/admin/categoryTags" exact={true} component={CategoryTagsManagementPage} />
                                                    </Switch>
                                                </AuthRoute>
                                            }

                                            <AuthRoute path="/:tenant/admin/surveys" roleName={UserRoleStrings.AUTHOR}>
                                                <Switch>
                                                    <Route path="/:tenant/admin/surveys/" exact={true} component={SurveyManagementPage} />
                                                    <Route path="/:tenant/admin/surveys/edit/:surveyId" component={SurveyEdit} />
                                                    <Route path="/:tenant/admin/surveys/edit" component={SurveyEdit} />
                                                    <Route path="/:tenant/admin/surveys/:surveyId" component={SurveyDetails} />
                                                </Switch>
                                            </AuthRoute>

                                            <AuthRoute path="/:tenant/admin/activity" component={ActivityManagementPage} roleName={UserRoleStrings.AUTHOR}/>

                                            <AuthRoute path="/:tenant/admin/audiences" roleName={UserRoleStrings.OWNER}>
                                                <Switch>
                                                    <Route path="/:tenant/admin/audiences/" exact={true} component={AudienceListing} />
                                                    <Route path="/:tenant/admin/audiences/everyone" exact={true} component={EveryoneAudienceManager} />
                                                </Switch>
                                            </AuthRoute>

                                            <AuthRoute path="/:tenant/admin/settings" exact component={SettingsPage} roleName={UserRoleStrings.OWNER}/>

                                            <AuthRoute path="/:tenant/admin/homeScreens" exact component={ConnectedManageHomeScreenSettings} roleName={UserRoleStrings.OWNER} />

                                            <AuthRoute path="/:tenant/admin/contentBands/editor" exact component={ConnectedContentBandsEditorPage} roleName={UserRoleStrings.OWNER} />

                                            <AuthRoute path="/:tenant/admin/guidelines" component={ContentGuidelinesPage} roleName={UserRoleStrings.CONTRIBUTOR}/>

                                            <AuthRoute path="/:tenant/admin/messaging" roleName={UserRoleStrings.OWNER}>
                                                <Switch>
                                                    <Route path="/:tenant/admin/messaging/" exact={true} component={MessagingCenterScreen} />
                                                </Switch>
                                            </AuthRoute>

                                            <AuthRoute path="/:tenant/admin/newsletters" roleName={UserRoleStrings.OWNER}>
                                                <Switch>
                                                    <Route path="/:tenant/admin/newsletters/" exact={true} component={NewsletterManagementPage} />
                                                    <Route path="/:tenant/admin/newsletters/new/" exact={true} component={EditNewsletterPage} />
                                                    <Route path="/:tenant/admin/newsletters/:newsletterId/" exact={true} component={EditNewsletterPage} />
                                                    <Route path="/:tenant/admin/newsletters/theme/new" exact={true} component={EditThemePage} />
                                                    <Route path="/:tenant/admin/newsletters/theme/:themeId/" exact={true} component={EditThemePage} />
                                                    <Route path="/:tenant/admin/newsletters/:newsletterId/dashboard/" exact={true} component={NewsletterDashboard} />
                                                    <Route path="/:tenant/admin/newsletters/:newsletterId/dashboard/:tab/" component={NewsletterDashboard} />
                                                </Switch>
                                            </AuthRoute>

                                            <AuthRoute path="/:tenant/admin/emails" roleName={UserRoleStrings.OWNER}>
                                                <Switch>
                                                    <Route path="/:tenant/admin/emails" exact component={EmailsManagementPage} />
                                                    <Route path="/:tenant/admin/emails/edit/new/" exact component={EmailEditorPage} />
                                                    <Route path="/:tenant/admin/emails/edit/:emailId/" exact component={EmailEditorPage} />
                                                </Switch>
                                            </AuthRoute>

                                            <AuthRoute path="/:tenant/admin/download" roleName={UserRoleStrings.AUTHOR}>
                                                <Switch>
                                                    <Route path="/:tenant/admin/download/:reportId" exact={true} component={DownloadReportPage} />
                                                    <Route path="/:tenant/admin/download"  component={AdminPageNotFound} />
                                                </Switch>
                                            </AuthRoute>

                                            <AuthRoute path="/:tenant/admin/tenantManagement" roleName={UserRoleStrings.SPARROW_SUPER_ADMIN}>
                                                <Switch>
                                                    <Route path="/:tenant/admin/tenantManagement" exact={true} component={TenantManagementPage} />
                                                </Switch>
                                            </AuthRoute>

                                            {!!userId &&
                                                <Route component={isContributor ? PageForbidden : AdminPageForbidden} />
                                            }
                                        </Switch>
                                    </AdminAppLayout>
                                </TenantRouteWrapper>
                            </RequireLogin>
                        </Route>
                        <Route>
                            <RequireLogin>
                                <ClientRouteWrapper>
                                    <UserAppLayout>
                                        <Switch>
                                            <Route path={["/:tenant/home", "/:tenant/dashboard"]} component={HomePage} />
                                            <Route path="/:tenant/me" component={UserPage} />
                                            {props.clientSettings.portalPagesEnabled &&
                                                <Route path="/:tenant/page">
                                                    <Switch>
                                                        <Route path="/:tenant/page/" exact={true} component={PortalPagesPage} />
                                                        <Route path="/:tenant/page/:portalPageUrl" component={PortalPagesPage} />
                                                    </Switch>
                                                </Route>
                                            }
                                            <Route path="/:tenant/posts">
                                                <Switch>
                                                    <Route path="/:tenant/posts/" exact={true} component={PostsPage} />
                                                    <Route path="/:tenant/posts/:postId" component={PostsPage} />
                                                </Switch>
                                            </Route>
                                            {props.clientSettings.mobileEventsEnabled &&
                                                <Route path="/:tenant/events">
                                                    <Switch>
                                                        <Route path="/:tenant/events/" exact={true} component={EventsPage} />
                                                        <Route path="/:tenant/events/:eventId" component={EventsPage} />
                                                    </Switch>
                                                </Route>
                                            }
                                            {props.clientSettings.documentsEnabled &&
                                                <Route path="/:tenant/documents">
                                                    <Switch>
                                                        <Route path="/:tenant/documents/" exact={true} component={DocumentsPage} />
                                                        <Route path="/:tenant/documents/:documentId" component={DocumentsPage} />
                                                    </Switch>
                                                </Route>
                                            }
                                            <Route path="/:tenant/welcome" component={WelcomePage} />
                                            {!!props.clientSettings.id &&
                                            <Route component={PageForbidden} />
                                            }
                                        </Switch>
                                    </UserAppLayout>
                                </ClientRouteWrapper>
                            </RequireLogin>
                        </Route>
                    </Switch>
                </Route>
            </Switch>
        </Route>
    );
};

interface ComponentProps { }

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        userRoles: state.settings.currentUser?.roles,
        currentUser: state.settings.currentUser,
        clientSettings: state.settings.clientSettings,
        navigation: state.portalPages.navigation,
        tenantSettings: state.settings.tenantSettings
    }),
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(Routes);
