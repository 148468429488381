import React from "react";

import Loading from "modules/common/components/loading";
import { Button } from "@mui/material";

export const LoadMoreButton: React.FunctionComponent<ILoadMoreButton> = (props: ILoadMoreButton) => {
    const [pageNumber, setPageNumber] = React.useState<number>(1);

    const onFetchMore = () => {
        setPageNumber(pageNumber + 1);
        props.onFetchMore(pageNumber + 1);
    }

    if (!props.canLoadMore) return <React.Fragment/>;

    if (props.isFetching) return <Loading/>;

    return <Button variant="outlined" color="primary" onClick={onFetchMore} className="feed-buttons">
        Load more
    </Button>;
};

export interface ILoadMoreButton {
    isFetching: boolean;
    canLoadMore: boolean;
    onFetchMore: (pageNumber: number) => void;
}