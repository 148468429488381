import { UserRole } from "modules/users";

export enum UserRoleStrings {
    SPARROW_SUPER_ADMIN = "role_sparrowsuperadmin",
    OWNER = "role_owner",
    AUTHOR = "role_author",
    INTERNAL = "role_internal",
    CONTRIBUTOR = "role_contributor"
}

export const USER_ROLE_DESCRIPTIONS : Record<UserRole, string> = {
    [UserRole.DEFAULT]: "",
    [UserRole.AUTHOR]: "Authors can publish and manage content. You can also customize the access of authors to optimize the tools they can use within the portal.",
    [UserRole.OWNER]: "Owners have access to all features and tools in your portal.",
    [UserRole.USER]: "Users can access the portal and view content targeted to them or content they are subscribed to. You can also allow users to submit and contribute content.",
    [UserRole.CONTRIBUTOR]: "Contributors are allowed to submit draft content for review using topics they have access to.\n\nThey also have limited access to certain settings when creating or publishing content."
}
