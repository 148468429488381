import { AxiosPromise } from "axios";
import { SparrowApi } from "./network/adapters/SparrowApi";
import { ApiRequest } from "./network/ApiRequest";
import { IEmail, IEmailFilters, IEmailInput, IEmailListing } from "modules/emails/models";

export class EmailsApi {
    constructor(private _sparrowApi: SparrowApi) {}

    getEmailList(pageAmount: number, pageNumber: number, filters: IEmailFilters): AxiosPromise<IEmailListing> {
        return new ApiRequest<IEmailListing>(
            this._sparrowApi.TenantRequest()
                .post(
                    `/{tenant}/adminapi/v2/notifications/emails/paged?pageNumber=${pageNumber}&pageAmount=${pageAmount}`,
                    filters
                )
        ).catch((err) => {
            throw err;
        });
    }

    getEmail(emailId: string): AxiosPromise<IEmail> {
        return new ApiRequest<IEmail>(
            this._sparrowApi.TenantRequest().get(`/{tenant}/adminapi/v2/notifications/emails/${emailId}`)
        ).catch((err) => {
            throw err;
        });
    }

    deleteEmail(emailId: string): AxiosPromise<void> {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().delete(`/{tenant}/adminapi/v2/notifications/emails/${emailId}`)
        ).catch((err) => {
            throw err;
        });
    }

    cloneEmail(emailId: string): AxiosPromise<string> {
        return new ApiRequest<string>(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v2/notifications/emails/${emailId}/clone`)
        ).catch((err) => {
            throw err;
        });
    }

    cancelSend(emailId: string): AxiosPromise<void> {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v2/notifications/emails/${emailId}/cancelSend`)
        ).catch((err) => {
            throw err;
        });
    }
    
    createNewEmail(email: IEmail): AxiosPromise<IEmail> {
        const body: IEmailInput = {
            ...email,
            audiences: email.audiences.map(a => a.id),
            sentTime: email.sentTime && new Date(email.sentTime) >= new Date() ? email.sentTime : undefined
        };

        return new ApiRequest<IEmail>(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v2/notifications/emails/new`, body)
        ).catch((err) => {
            throw err;
        });
    }

    saveExistingEmail(email: IEmail): AxiosPromise<IEmail> {
        const body: IEmailInput = {
            ...email,
            audiences: email.audiences.map(a => a.id),
            sentTime: email.sentTime && new Date(email.sentTime) >= new Date() ? email.sentTime : undefined
        };

        return new ApiRequest<IEmail>(
            this._sparrowApi.TenantRequest().put(`/{tenant}/adminapi/v2/notifications/emails/${email.id}`, body)
        ).catch((err) => {
            throw err;
        });
    }

    getRecipientsReport(emailId: string) : AxiosPromise<string> {
        return new ApiRequest<IEmail>(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v2/notifications/emails/${emailId}/recipientReport`)
        ).catch((err) => {
            throw err;
        });
    }

    testEmail(emailId: string, emails: string[]) : AxiosPromise<void> {

        return new ApiRequest<IEmail>(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v2/notifications/emails/${emailId}/testSend`, emails)
        ).catch((err) => {
            throw err;
        });
    }

    sendEmail(emailId: string) : AxiosPromise<void> {
        return new ApiRequest<IEmail>(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v2/notifications/emails/${emailId}/send`)
        ).catch((err) => {
            throw err;
        });
    }
}