import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { PostViewStats } from "modules/posts/models";

import HoverText from "modules/documents/components/action-buttons/hoverText";
import { useTranslatableContent } from "modules/common/hooks/useTranslatableContent";

import ContentTypeIcons, { IBanner } from "modules/common/components/banners/contentTypeIcons";

import "../../../../../../common/components/banners/contentTypeIcons.sass";

const icoComplied: string = "/images/icons/complied_ico.svg";
const icoMandatory: string = "/images/icons/mandatory_ico.svg";
const icoNotComplied: string = "/images/icons/not_complied_ico.svg";
const icoPublic: string = "/images/icons/public_ico.svg";

type PostBannerTypes = "complied" | "mandatory" | "notComplied" | "public";

type PostBanners = { [bannerType in PostBannerTypes]: IBanner; };

interface ComponentProps {
    lcid: string;
    postType: string;
    stats?: PostViewStats;
    isRestricted?: boolean;
    currentUserHasComplied?: boolean
}

const PostTypeIcons: React.FunctionComponent<PropsWithRedux> = props => {  
  const { bannerColors } = props;
  const { getTranslation } = useTranslatableContent();
  
  if (!bannerColors) return (null);

  const banners: PostBanners = {
    complied: {
      color: (bannerColors && bannerColors.complianceChecked) || "#05b34b",
      icon: icoComplied,
      text: getTranslation("compliedPost") || "Complied",
      description: getTranslation("compliedBannerDescription") || "You have already read and complied to this post."
    },
    mandatory: {
      color: (bannerColors && bannerColors.mandatory) || "#004e79",
      icon: icoMandatory,
      text: getTranslation("mandatoryPost") || "Mandatory",
      description: getTranslation("mandatoryBannerDescription") || "You are required to read this post."
    },
    notComplied: {
      color: (bannerColors && bannerColors.compliance) || "#faaf18",
      icon: icoNotComplied,
      text: getTranslation("compliancePost") || "Compliance",
      description: getTranslation("complianceBannerDescription") || "If you agree with the content of this post, comply using the checkbox down below."
    },
    public: {
      color: (bannerColors && bannerColors.public) || "#ffffff",
      icon: icoPublic,
      text: getTranslation("publicPost") || "Public",
      description: getTranslation("publicBannerDescription") || "This is a public post.",
      urlDescription: getTranslation("publicBannerDescriptionShareTitle") || "Share it through here"
    }
  };

  const hasBanner = props.postType !== "standard" || (props.isRestricted && props.restrictedBannerEnabled);
  const hasTwoIcons = props.postType !== "standard" && props.isRestricted && props.restrictedBannerEnabled;
  const isComplied = props.stats ? (!!props.stats && !!props.stats.complianceTime) : props.currentUserHasComplied;

  return (
    <div style={{paddingRight: hasBanner ? "6px" : "0px", display: "flex", flexDirection: "row"}}>
        {props.postType === "compliance" &&
            <HoverText
                label={
                    <div className="contentIcon" style={{backgroundColor: isComplied ? banners.complied.color : banners.notComplied.color, marginRight: hasTwoIcons ? "6px" : "0px"}}>
                    {isComplied ?
                        <img src={icoComplied} alt="Complied icon" className="content-icon"></img>
                        : <img src={icoNotComplied} alt="Not complied icon" className="content-icon"></img>}
                    </div>
                }
            >
                {isComplied ? banners.complied.description : banners.notComplied.description}
            </HoverText>
        }

        {props.postType === "mandatory" && 
            <HoverText
                label={
                    <div className="contentIcon" style={{backgroundColor: banners.mandatory.color, marginRight: hasTwoIcons ? "6px" : "0px"}}>
                        <img src={icoMandatory} alt="Mandatory post icon" className="iconImage"></img>
                    </div>
                }
            >
                {banners.mandatory.description}
            </HoverText>
        }

        {props.postType === "public" && 
            <HoverText
                label={
                    <div className="contentIcon" style={{backgroundColor: banners.public.color, marginRight: hasTwoIcons ? "6px" : "0px"}}>
                        <img src={icoPublic} alt="Public post icon" className="iconImage"></img>
                    </div>
                }
            >
                {banners.public.description}
            </HoverText>
        }
        <ContentTypeIcons isRestricted={props.isRestricted}/>
    </div>
  );
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
      ...ownProps,
      bannerColors: state.settings.clientSettings?.specialtyPostColors,
      restrictedBannerEnabled: state.settings.tenantSettings.bannerVisibility.showRestrictedBanner,
    }),
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(PostTypeIcons);