import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Alert from '@mui/material/Alert';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloseIcon from "@mui/icons-material/Close";

import LoadingOverlay from "modules/common/components/loadingOverlay";
import DocumentMoreInfo from "./components/documentViewMoreInfo";
import { DocumentFeedItem } from "../../models";

import "../../styles/documentViews.sass";
import DocumentShareButton from "../document-feed/components/documentShareButton";

export const DocumentView: React.FunctionComponent<PropsWithRedux> = (props) => {
    const onClose = () => props.onClose();

    const handlePreview = () => {
        if (props.view) {
            props.onViewDocument(props.view);
            onClose();
        }
    };

    const handleDownload = () => {
        if (props.view) {
            props.onDownloadDocument(props.view);
            onClose();
        }
    }

    const canPreview = (): boolean => props.view?.fileType === "jpeg"
        || props.view?.fileType === "jpg"
        || props.view?.fileType === "pdf"
        || props.view?.fileType === "png"
        || props.view?.fileType === "txt";

    const getPreviewButton = (): JSX.Element => {
        if (!canPreview()) return <></>;

        return <Button
            onClick={handlePreview}
            variant="outlined"
            startIcon={<VisibilityOutlinedIcon />}
            style={{ marginRight: "10px" }}
        >
            PREVIEW
        </Button>;
    };

    const getDialogFooter = (): JSX.Element => <DialogActions className="action-section doc-footer">
        <DocumentShareButton documentId={props.view?.id ?? ""}>
            COPY LINK
        </DocumentShareButton>

        <div>
            {getPreviewButton()}
            <Button onClick={handleDownload} variant="contained" startIcon={<FileDownloadOutlinedIcon />}>
                DOWNLOAD
            </Button>
        </div>
    </DialogActions>;

    const getDialogHeader = (): JSX.Element => <DialogTitle>
        <div className="action-section">
            <Typography variant="h2" marginY="auto"> More Information </Typography>
            <IconButton onClick={onClose} size="large">
                <CloseIcon />
            </IconButton>
        </div>
    </DialogTitle>;

    if (!props.view) return <React.Fragment />;
    return (
        <>
            <Dialog
                open={props.show}
                onClose={onClose}
                classes={{ paper: "document-view-paper" }}
                className="document-view"
            >
                {getDialogHeader()}

                <DialogContent>
                    <DocumentMoreInfo view={props.view} />
                </DialogContent>

                {getDialogFooter()}
                <LoadingOverlay show={props.isSaving || props.isUploading} styles={{ paddingTop: 150 }} />
            </Dialog>
            <Snackbar open={!!props.errorMessage} anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={6000} onClose={props.clearErrorMessage}>
                <Alert variant="filled" severity="error" elevation={6} onClose={props.clearErrorMessage}>{props.errorMessage}</Alert>
            </Snackbar>
        </>
    );
};

interface ComponentProps {
  show: boolean;
  view: DocumentFeedItem | undefined;
  onDownloadDocument: (document: DocumentFeedItem) => Promise<void>;
  onViewDocument: (document: DocumentFeedItem) => Promise<void>;
  onClose: () => void;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        tenantId: state.tenant.id,
        errorMessage: state.documents.errorMessage,
        isSaving: state.documents.isSaving,
        isUploading: state.documents.isUploading
    }),
    {
        clearErrorMessage: actions.clearErrorMessage
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(DocumentView);