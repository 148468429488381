export type ArticleType = "post" | "event" | "document";

export class ShareUtil {
    private tenantId: string;
    private location: string;
    private isPublic?: boolean;
    constructor(tenantId: string, isPublic?: boolean) {
        this.tenantId = tenantId;
        this.location = window.location.href;
        this.isPublic = isPublic;
    }

    public getPortalUrl = (baseUrl: string, articleType: ArticleType, articleId: string): string => {
        let segments: string[] = [];

        if (!this.tenantId) {
            segments = this.location.split("/public/");

            return `${segments[0]}/public/${articleType}s/${articleId}`;
        } else {
            segments = this.location.split(this.tenantId);

            if (!baseUrl || this.isPublic) {
                return `${segments[0]}${this.tenantId}/${this.isPublic ? "public/" : ""}${articleType}s/${articleId}`;
            }
            else {
                return `${baseUrl}${articleId}`;
            }
        }
    };

}