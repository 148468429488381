import { EventFeedItem } from "modules/events";
import { IColorOption } from "modules/homeScreens/models";
import { PostFeedItem } from "modules/posts/models";
import { SortStyle } from "utils/managementUtils";

export enum MobileAppPages {
    Home,
    Account,
    Chat
}

export interface IContentBandsState {
    isLoadingContentBands: boolean;
    isLoadingPublishedContentBands: boolean;
    isLoadingContentBandConfig: boolean;
    isLoadingContent: boolean;
    isPublishingContentBands: boolean;
    isPublishingContentBandConfig: boolean;
    isSavingContentBandConfig: boolean;
    isSavingContentBands: boolean;
    contentBands: IContentBand[];
    pristineContentBands: IContentBand[];
    publishedContentBands: IContentBand[] | null;
    successMessage?: string;
    fetched: boolean;
    publishCount: number;
    fetchIdx?: number | null; // idx of the band we are currently fetching for
    addFinished: boolean;
    addIdx?: number;
    reordered: boolean;
    config?: IContentBandConfig;
    pristineConfig?: IContentBandConfig;
}

export interface IContentBandSettings {
    layoutType?: ContentBandLayoutType;
    contentType?: ContentBandContentType;
    seeMoreEnabled?: boolean;
    displayFullTitles?: boolean;
    headers?: Record<string, IHeaderTranslation>;
    contentCount?: number;
    sortBy?: SortStyle.publishAsc | SortStyle.publishDesc | SortStyle.startAsc | SortStyle.startDesc;
    pinnedIds?: string[];
    lockToReadStatus?: ReadStatus;
    lockToTypes?: string[];
    lockToTopicIds?: string[];
    ignoreSubscriptions: boolean;
    autoPlayCarousel?: boolean;
    status: ContentBandStatus;
    order: number;
    createdById: string;
    createdDateTimeUtc: Date | null;
}

export interface IContentBand extends IContentBandSettings {
    id: string;
    parentId: string | null;
    publishedDateTimeUtc: Date | null;
    posts: PostFeedItem[];
    events: EventFeedItem[];
}

export interface IContentBandConfig {
    id: string;
    parentId: string | null;
    contentBandsGradientColor: IColorOption;
    contentBandsHeaderTextColor: IColorOption;
    status: ContentBandStatus;
}

export interface IContentBandInfo {
    contentBands: IContentBand[];
    publishCount: number;
}

export enum ReadStatus {
    Both = "both",
    Read = "read",
    Unread = "unread"
}

export enum ContentBandContentType {
    Post = "post",
    Event = "event"
}

export enum ContentBandStatus {
    Draft = "draft",
    Published = "published"
}

export interface IHeaderTranslation {
    header: string;
}

export enum ContentBandLayoutType {
    Card = "card",
    Window = "window",
    Carousel = "carousel"
}

export const CONTENT_BAND_LAYOUT_TYPE_MAP: Record<ContentBandLayoutType, string> = {
    [ContentBandLayoutType.Card]: "Card",
    [ContentBandLayoutType.Window]: "Window",
    [ContentBandLayoutType.Carousel]: "Carousel"
};

export const READ_STATUS_MAP: Record<ReadStatus, string> = {
    [ReadStatus.Both]: "Read and unread",
    [ReadStatus.Read]: "Read",
    [ReadStatus.Unread]: "Unread"
};

export const SORT_STYLE_MAP: Partial<Record<SortStyle, string>> = {
    [SortStyle.publishDesc]: "newest first",
    [SortStyle.publishAsc]: "oldest first",
    [SortStyle.startDesc]: "start date - nearest first",
    [SortStyle.startAsc]: "start date - farthest first"
};
