import React from "react";
import DownloadRecipientsButton from "./downloadRecipientsButton";
import LoadingCircle from "modules/common/components/loadingCircle";
import { useDispatch, useSelector } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { emailsSlice } from "modules/emails/reducer";
import { useDownloadRecipients } from "modules/common/hooks/useDownloadRecipients";
import { useAudienceCount } from "modules/common/hooks/useAudienceCount";

interface IRecipientCountDownloadProps {
    onSaveEmail: () => void;
    onSuccessMessage: (message: string) => void;
    onErrorMessage: (message: string) => void;
    defaultAudienceCount?: number;
    isFetchingDefaultAudienceCount?: boolean;
}

const RecipientCountDownload: React.FC<IRecipientCountDownloadProps> = ({
    onSaveEmail,
    onSuccessMessage,
    onErrorMessage,
    defaultAudienceCount,
    isFetchingDefaultAudienceCount
}) => {
    const { email, changedSinceLastSaved } = useSelector((state: GlobalApplicationState) => state.emails.editor);
    const { isDownloadingRecipients, downloadRecipients } = useDownloadRecipients({onSuccessMessage, onErrorMessage, emailId: email.id});
    const dispatch = useDispatch();
    const setShouldDownloadRecipientsList = (shouldDownload: boolean) => 
        dispatch({ type: emailsSlice.actions.SET_SHOULD_DOWNLOAD_RECIPIENTS_LIST, payload: { shouldDownload }});

    const onClickDownloadRecipientsReport = async () => {
        // if a user clicks on downloading the recipients report and the editor isn't
        // saved (either it's a new email with email.id == "" or there have been changes)
        // then we save the email first and then perform the action.
        // in the case of no email id, we will save, redirect, and then on the first render we 
        // will start the download
        if (!email.id || changedSinceLastSaved) {
            onSaveEmail();
            setShouldDownloadRecipientsList(true);
        }
        else
            await downloadRecipients();
    }

    const { audienceCount, isFetchingAudienceCount } = useAudienceCount({ 
        constantAudienceCount: defaultAudienceCount, 
        audiences: email.audiences, 
        sendToAllUsers: email.sendToAllUsers 
    });

    const innerAudienceCount = defaultAudienceCount ?? audienceCount;
    const innerIsFetching = isFetchingDefaultAudienceCount ?? isFetchingAudienceCount;

    return <div className="recipient-button-container">
        <span className="recipient-count">{innerIsFetching ? <LoadingCircle size="16px"/> : innerAudienceCount} employee{innerAudienceCount === 1 ? "" : "s"}</span>
        <DownloadRecipientsButton
            isDownloadingRecipients={isDownloadingRecipients} 
            audienceCount={innerAudienceCount} 
            onClick={onClickDownloadRecipientsReport}
        />
    </div>;
};

export default RecipientCountDownload;
