import moment from "moment";

import { Event, ValidationChecks } from "../../models";
import isValidEmail from "utils/isValidEmail";


class Validation {
  public isValid = (validationChecks: ValidationChecks): boolean => {
    const invalidChecks = validationChecks.checks.filter((check) => {
      if (check.skip)
        return false;
      return !check.isValid;
    });
    return !invalidChecks.length;
  };

  public getValidationChecks = (event: Partial<Event>): ValidationChecks => {
    const validation: ValidationChecks = {
      checks: [
        { key: "title", description: "Create a title for each selected language", isValid: this.isTitleValid(event), link: "#title" },
        { key: "body", description: "Create body content for each selected language", isValid: this.isBodyValid(event), link: "#body" },
        { key: "author", description: "Set an organizer name", isValid: this.isAuthorValid(event), link: "#author", skip: !!event.author },
        { key: "email", description: "Set a proper email for the organizer", isValid: isValidEmail(event.authorEmail || ""), link: "#email", skip: isValidEmail(event.authorEmail || "")},
        { key: "cutoffTime", description: "The RSVP deadline must be set after the publish date and before the expiry date", isValid: this.isCutoffTimeValid(event), link: "#cutoff-time", skip: !event.cutoffTime || event.eventType === "informational" },
        { key: "tags", description: "Select topics to categorize event", isValid: this.isTagsValid(event), link: "#tags" },
        { key: "expiryTime", description: "The expiry date must be set after the publish date", isValid: this.isExpiryTimeValid(event), link: "#expiry-date", skip: !event.expiryTime },
        { key: "time", description: "Event date(s) must be valid", isValid: this.isDateTimeValid(event), skip: !event.eventTimes}
      ]
    };

    return validation;
  }

  private isAuthorValid = (event: Partial<Event>): boolean => {
    return !!event.author;
  }

  private isBodyValid = (event: Partial<Event>): boolean => {
    if (!event.translatedContent) {
      return false;
    }

    const invalid = !!Object.keys(event.translatedContent).filter((lcid) => {
      const body = event.translatedContent![lcid].body;
      return !body || !body.trim().length;
    }).length;
    return !invalid;
  }

  private isCutoffTimeValid = (event: Partial<Event>): boolean => {
    const { cutoffTime, expiryTime, publishTime } = event;

    if (!cutoffTime)
      return true;
    
    if (!publishTime && moment(cutoffTime) < moment())
      return false;
    if (!!publishTime && moment(cutoffTime) < moment(publishTime))
      return false;
    if (!!expiryTime && moment(cutoffTime) > moment(expiryTime))
      return false;
    
    return true;
  }

  private isStartAfterEnd = (startDate: string, endDate: string) => 
    moment(endDate).format('HH:mm') < moment(startDate).format('HH:mm')

  private isDateTimeValid = (event: Partial<Event>): boolean => {
    const { eventTimes } = event

    if (!eventTimes) return true

    const hasInvalidDate = ([startTime, endTime]) => endTime? this.isStartAfterEnd(startTime, endTime) : false
    return !Object.entries(eventTimes).some(hasInvalidDate)
  }

  private isExpiryTimeValid = (event: Partial<Event>): boolean => {
    const { expiryTime, publishTime } = event;

    if (!expiryTime)
      return true;
    
    if (!publishTime && moment(expiryTime) < moment())
      return false;
    if (!!publishTime && moment(expiryTime) < moment(publishTime))
      return false;
    
    return true;
  }

  private isTagsValid = (event: Partial<Event>): boolean => {
    return !!event.tags && !!event.tags.length;
  }

  private isTitleValid = (event: Partial<Event>): boolean => {
    if (!event.translatedContent) {
      return false;
    }

    const invalid = !!Object.keys(event.translatedContent).filter((lcid) => {
      const title = event.translatedContent![lcid].title;
      return !title || !title.trim().length;
    }).length;
    return !invalid;
  }
}

export default Validation;