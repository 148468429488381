import { AttachedFile } from "modules/gallery";
import { Action } from "redux";
import { CompleteAction, ActionCreator } from "typedActions";
import { AttendanceType, EventFeedFilters, EventFeedItem, EventListItem, RSVPType } from "./models";
import { Image } from "modules/gallery/models";


export const GET_ATTENDANCE_LISTING = "GET_ATTENDANCE_LISTING";
export interface GetAttendanceListing extends Action {}
export const GetAttendanceListing = ActionCreator<GetAttendanceListing>(GET_ATTENDANCE_LISTING);

export const GET_ATTENDANCE_LISTING_COMPLETE = "GET_ATTENDANCE_LISTING_COMPLETE";
export interface GetAttendanceListingComplete extends CompleteAction {}
export const GetAttendanceListingComplete = ActionCreator<GetAttendanceListingComplete>(GET_ATTENDANCE_LISTING_COMPLETE);


export const GET_ATTENDANCE_LISTING_CSV = "GET_ATTENDANCE_LISTING_CSV";
export interface GetAttendanceListingCSV extends Action {}
export const GetAttendanceListingCSV = ActionCreator<GetAttendanceListingCSV>(GET_ATTENDANCE_LISTING_CSV);

export const GET_ATTENDANCE_LISTING_CSV_COMPLETE = "GET_ATTENDANCE_LISTING_CSV_COMPLETE";
export interface GetAttendanceListingCSVComplete extends CompleteAction {}
export const GetAttendanceListingCSVComplete = ActionCreator<GetAttendanceListingCSVComplete>(GET_ATTENDANCE_LISTING_CSV_COMPLETE);


export const GET_CONTENT_ANALYSIS = "GET_CONTENT_ANALYSIS";
export interface GetContentAnalysis extends Action {}
export const GetContentAnalysis = ActionCreator<GetContentAnalysis>(GET_CONTENT_ANALYSIS);

export const GET_CONTENT_ANALYSIS_COMPLETE = "GET_CONTENT_ANALYSIS_COMPLETE";
export interface GetContentAnalysisComplete extends CompleteAction {}
export const GetContentAnalysisComplete = ActionCreator<GetContentAnalysisComplete>(GET_CONTENT_ANALYSIS_COMPLETE);


export const GET_DOCUMENT = "GET_DOCUMENT";
export interface GetDocument extends Action {}
export const GetDocument = ActionCreator<GetDocument>(GET_DOCUMENT);

export const GET_DOCUMENT_COMPLETE = "GET_DOCUMENT_COMPLETE";
export interface GetDocumentComplete extends Action {}
export const GetDocumentComplete = ActionCreator<GetDocumentComplete>(GET_DOCUMENT_COMPLETE);


export const GET_DOCUMENT_GALLERY = "GET_DOCUMENT_GALLERY";
export interface GetDocumentGallery extends Action {}
export const GetDocumentGallery = ActionCreator<GetDocumentGallery>(GET_DOCUMENT_GALLERY);

export const GET_DOCUMENT_GALLERY_COMPLETE = "GET_DOCUMENT_GALLERY_COMPLETE";
export interface GetDocumentGalleryComplete extends CompleteAction {
  currentPage: number;
  documents: AttachedFile[];
  totalDocuments: number;
  totalPages: number;
}
export const GetDocumentGalleryComplete = ActionCreator<GetDocumentGalleryComplete>(GET_DOCUMENT_GALLERY_COMPLETE);


export const GET_DRAFT = "GET_DRAFT";
export interface GetDraft extends Action {}
export const GetDraft = ActionCreator<GetDraft>(GET_DRAFT);

export const GET_DRAFT_COMPLETE = "GET_DRAFT_COMPLETE";
export interface GetDraftComplete extends CompleteAction {}
export const GetDraftComplete = ActionCreator<GetDraftComplete>(GET_DRAFT_COMPLETE);


export const GET_DRAFT_TRANSLATION = "GET_DRAFT_TRANSLATION";
export interface GetDraftTranslation extends Action {}
export const GetDraftTranslation = ActionCreator<GetDraftTranslation>(GET_DRAFT_TRANSLATION);

export const GET_DRAFT_TRANSLATION_COMPLETE = "GET_DRAFT_TRANSLATION_COMPLETE";
export interface GetDraftTranslationComplete extends CompleteAction {}
export const GetDraftTranslationComplete = ActionCreator<GetDraftTranslationComplete>(GET_DRAFT_TRANSLATION_COMPLETE);


export const GET_DRAFTS = "GET_DRAFTS";
export interface GetDrafts extends Action { }
export const GetDrafts = ActionCreator<GetDrafts>(GET_DRAFTS);

export const GET_DRAFTS_COMPLETE = "GET_DRAFTS_COMPLETE";
export interface GetDraftsComplete extends CompleteAction {
  currentPage: number;
  events?: EventListItem[];
  totalEvents: number;
  totalPages: number;
  succeeded: boolean;
}
export const GetDraftsComplete = ActionCreator<GetDraftsComplete>(GET_DRAFTS_COMPLETE);

export const GET_SUBMISSIONS = "GET_SUBMISSIONS";
export interface GetSubmissions extends Action { }
export const GetSubmissions = ActionCreator<GetSubmissions>(GET_SUBMISSIONS);

export const GET_SUBMISSIONS_COMPLETE = "GET_SUBMISSIONS_COMPLETE";
export interface GetSubmissionsComplete extends CompleteAction {
  currentPage: number;
  events?: EventListItem[];
  totalEvents: number;
  totalPages: number;
  succeeded: boolean;
}
export const GetSubmissionsComplete = ActionCreator<GetSubmissionsComplete>(GET_SUBMISSIONS_COMPLETE);

export const GET_ALL_EVENTS = "GET_ALL_EVENTS";
export interface GetAllEvents extends Action { }
export const GetAllEvents = ActionCreator<GetAllEvents>(GET_ALL_EVENTS);

export const GET_ALL_EVENTS_COMPLETE = "GET_ALL_EVENTS_COMPLETE";
export interface GetAllEventsComplete extends CompleteAction {
  currentPage: number;
  events?: EventListItem[];
  totalEvents: number;
  totalPages: number;
  succeeded: boolean;
}
export const GetAllEventsComplete = ActionCreator<GetAllEventsComplete>(GET_ALL_EVENTS_COMPLETE);

export const GET_EVENT = "GET_EVENT";
export interface GetEvent extends Action {}
export const GetEvent = ActionCreator<GetEvent>(GET_EVENT);

export const GET_EVENT_COMPLETE = "GET_EVENT_COMPLETE";
export interface GetEventComplete extends CompleteAction {}
export const GetEventComplete = ActionCreator<GetEventComplete>(GET_EVENT_COMPLETE);



export const GET_EVENT_FEED = "GET_EVENT_FEED";
export interface GetEventFeed extends Action {};
export const GetEventFeed = ActionCreator<GetEventFeed>(GET_EVENT_FEED);

export const GET_EVENT_FEED_COMPLETE = "GET_EVENT_FEED_COMPLETE";
export interface GetEventFeedComplete extends CompleteAction { filtersApplied: boolean, eventsFeed: EventFeedItem[] };
export const GetEventFeedComplete = ActionCreator<GetEventFeedComplete>(GET_EVENT_FEED_COMPLETE);


export const GET_IMAGE = "GET_IMAGE";
export interface GetImage extends Action {}
export const GetImage = ActionCreator<GetImage>(GET_IMAGE);

export const GET_IMAGE_COMPLETE = "GET_IMAGE_COMPLETE";
export interface GetImageComplete extends CompleteAction {}
export const GetImageComplete = ActionCreator<GetImageComplete>(GET_IMAGE_COMPLETE);


export const GET_IMAGE_GALLERY = "GET_IMAGE_GALLERY";
export interface GetImageGallery extends Action {}
export const GetImageGallery = ActionCreator<GetImageGallery>(GET_IMAGE_GALLERY);

export const GET_IMAGE_GALLERY_COMPLETE = "GET_IMAGE_GALLERY_COMPLETE";
export interface GetImageGalleryComplete extends CompleteAction {
  currentPage: number;
  images: Image[];
  totalImages: number;
  totalPages: number;
}
export const GetImageGalleryComplete = ActionCreator<GetImageGalleryComplete>(GET_IMAGE_GALLERY_COMPLETE);


export const GET_PUBLISHED = "GET_PUBLISHED";
export interface GetPublished extends Action { }
export const GetPublished = ActionCreator<GetPublished>(GET_PUBLISHED);

export const GET_PUBLISHED_COMPLETE = "GET_PUBLISHED_COMPLETE";
export interface GetPublishedComplete extends CompleteAction {
  currentPage: number;
  events?: EventListItem[];
  totalEvents: number;
  totalPages: number;
}
export const GetPublishedComplete = ActionCreator<GetPublishedComplete>(GET_PUBLISHED_COMPLETE);



export const GET_SCHEDULED = "GET_SCHEDULED";
export interface GetScheduled extends Action { }
export const GetScheduled = ActionCreator<GetScheduled>(GET_SCHEDULED);

export const GET_SCHEDULED_COMPLETE = "GET_SCHEDULED_COMPLETE";
export interface GetScheduledComplete extends CompleteAction {
  currentPage: number;
  events?: EventListItem[];
  totalEvents: number;
  totalPages: number;
}
export const GetScheduledComplete = ActionCreator<GetScheduledComplete>(GET_SCHEDULED_COMPLETE);


export const SET_EVENTS_FEED_TO_DEFAULT = "SET_EVENTS_FEED_TO_DEFAULT";
export interface SetEventsFeedToDefault extends Action {}
export const  SetEventsFeedToDefault = ActionCreator<SetEventsFeedToDefault>(SET_EVENTS_FEED_TO_DEFAULT);

export const GET_VIDEO = "GET_VIDEO";
export interface GetVideo extends Action {}
export const GetVideo = ActionCreator<GetVideo>(GET_VIDEO);

export const GET_VIDEO_COMPLETE = "GET_VIDEO_COMPLETE";
export interface GetVideoComplete extends CompleteAction {}
export const GetVideoComplete = ActionCreator<GetVideoComplete>(GET_VIDEO_COMPLETE);

export const GET_VIDEOS_COMPLETE = "GET_VIDEOS_COMPLETE";
export interface GetVideosComplete extends Action {}
export const GetVideosComplete = ActionCreator<GetVideosComplete>(GET_VIDEOS_COMPLETE);


export const CHANGE_RSVP_STATUS = "CHANGE_RSVP_STATUS";
export interface ChangeRSVPStatus extends Action {}
export const ChangeRSVPStatus = ActionCreator<ChangeRSVPStatus>(CHANGE_RSVP_STATUS);

export const CHANGE_RSVP_STATUS_COMPLETE = "CHANGE_RSVP_STATUS_COMPLETE";
export interface ChangeRSVPStatusComplete extends CompleteAction {}
export const ChangeRSVPStatusComplete = ActionCreator<ChangeRSVPStatusComplete>(CHANGE_RSVP_STATUS_COMPLETE);


export const CLONE_EVENT = "CLONE_EVENT";
export interface CloneEvent extends Action {}
export const CloneEvent = ActionCreator<CloneEvent>(CLONE_EVENT);

export const CLONE_EVENT_COMPLETE = "CLONE_EVENT_COMPLETE";
export interface CloneEventComplete extends CompleteAction {}
export const CloneEventComplete = ActionCreator<CloneEventComplete>(CLONE_EVENT_COMPLETE);

export const UNPUBLISH_EVENT = "UNPUBLISH_EVENT";
export interface UnpublishEvent extends Action {}
export const UnpublishEvent = ActionCreator<UnpublishEvent>(UNPUBLISH_EVENT);

export const UNPUBLISH_EVENT_COMPLETE = "UNPUBLISH_EVENT_COMPLETE";
export interface UnpublishEventComplete extends CompleteAction {}
export const UnpublishEventComplete = ActionCreator<UnpublishEventComplete>(UNPUBLISH_EVENT_COMPLETE);

export const DELETE_EVENTS = "DELETE_EVENTS";
export interface DeleteEvents extends Action {}
export const DeleteEvents = ActionCreator<DeleteEvents>(DELETE_EVENTS);

export const DELETE_EVENTS_COMPLETE = "DELETE_EVENTS_COMPLETE"
export interface DeleteEventsComplete extends CompleteAction {
  deletedEvents?: string[];
}
export const DeleteEventsComplete = ActionCreator<DeleteEventsComplete>(DELETE_EVENTS_COMPLETE);


export const EDIT_RESPONSE = "EDIT_RESPONSE";
export interface EditResponse extends Action {}
export const EditResponse = ActionCreator<EditResponse>(EDIT_RESPONSE);

export const EDIT_RESPONSE_COMPLETE = "EDIT_RESPONSE_COMPLETE";
export interface EditResponseComplete extends CompleteAction {}
export const EditResponseComplete = ActionCreator<EditResponseComplete>(EDIT_RESPONSE_COMPLETE);

export const SUBMIT_EVENT = 'SUBMIT_EVENT';
export interface SubmitEvent extends Action {};
export const SubmitEvent = ActionCreator<SubmitEvent>(SUBMIT_EVENT);

export const SUBMIT_EVENT_COMPLETE = 'SUBMIT_EVENT_COMPLETE';
export interface SubmitEventComplete extends CompleteAction {};
export const SubmitEventComplete = ActionCreator<SubmitEventComplete>(SUBMIT_EVENT_COMPLETE);

export const PUBLISH_EVENT = "PUBLISH_EVENT";
export interface PublishEvent extends Action {}
export const PublishEvent = ActionCreator<PublishEvent>(PUBLISH_EVENT);

export const PUBLISH_EVENT_COMPLETE = "PUBLISH_EVENT_COMPLETE";
export interface PublishEventComplete extends CompleteAction {
  publishedEvents?: EventListItem;
}
export const PublishEventComplete = ActionCreator<PublishEventComplete>(PUBLISH_EVENT_COMPLETE);


export const RSVP_TO_EVENT = "RSVP_TO_EVENT";
export interface RSVPToEvent extends Action {}
export const RSVPToEvent = ActionCreator<RSVPToEvent>(RSVP_TO_EVENT);

export const RSVP_TO_EVENT_COMPLETE = "RSVP_TO_EVENT_COMPLETE";
export interface RSVPToEventComplete extends CompleteAction { attendanceType: AttendanceType, rsvpType: RSVPType }
export const RSVPToEventComplete = ActionCreator<RSVPToEventComplete>(RSVP_TO_EVENT_COMPLETE);


export const SAVE_EVENT = "SAVE_EVENT";
export interface SaveEvent extends Action {}
export const SaveEvent = ActionCreator<SaveEvent>(SAVE_EVENT);

export const SAVE_EVENT_COMPLETE = "SAVE_EVENT_COMPLETE";
export interface SaveEventComplete extends CompleteAction {}
export const SaveEventComplete = ActionCreator<SaveEventComplete>(SAVE_EVENT_COMPLETE);


export const SET_EVENT_FEED_FILTERS = "SET_EVENT_FEED_FILTERS";
export interface SetEventFeedFilters extends Action { filters: Partial<EventFeedFilters> }
export const SetEventFeedFilters = ActionCreator<SetEventFeedFilters>(SET_EVENT_FEED_FILTERS);


export const SET_EVENT_FEED_VIEW = "SET_EVENT_FEED_VIEW";
export interface SetEventFeedView extends Action { selectedView: string }
export const SetEventFeedView = ActionCreator<SetEventFeedView>(SET_EVENT_FEED_VIEW);


export const UPDATE_EVENT_FEED = "UPDATE_EVENT_FEED";
export interface UpdateEventFeed extends Action { eventsFeed: EventFeedItem[] }
export const UpdateEventFeed = ActionCreator<UpdateEventFeed>(UPDATE_EVENT_FEED);


export const UPDATE_URL = "UPDATE_URL";
export interface UpdateUrl extends Action {}
export const UpdateUrl = ActionCreator<UpdateUrl>(UPDATE_URL);

export const UPDATE_URL_COMPLETE = "UPDATE_URL_COMPLETE";
export interface UpdateUrlComplete extends Action {}
export const UpdateUrlComplete = ActionCreator<UpdateUrlComplete>(UPDATE_URL_COMPLETE);


export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export interface UploadDocument extends Action {}
export const UploadDocument = ActionCreator<UploadDocument>(UPLOAD_DOCUMENT);

export const UPLOAD_DOCUMENT_COMPLETE = "UPLOAD_DOCUMENT_COMPLETE";
export interface UploadDocumentComplete extends CompleteAction {}
export const UploadDocumentComplete = ActionCreator<UploadDocumentComplete>(UPLOAD_DOCUMENT_COMPLETE);


export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export interface UploadImage extends Action {}
export const UploadImage = ActionCreator<UploadImage>(UPLOAD_IMAGE);

export const UPLOAD_IMAGE_COMPLETE = "UPLOAD_IMAGE_COMPLETE";
export interface UploadImageComplete extends CompleteAction {}
export const UploadImageComplete = ActionCreator<UploadImageComplete>(UPLOAD_IMAGE_COMPLETE);


export const CHANGED_SINCE_SAVED = "CHANGED_SINCE_SAVED";
export interface ChangedSinceSaved extends Action {}
export const ChangedSinceSaved = ActionCreator<ChangedSinceSaved>(CHANGED_SINCE_SAVED);

export const CLEAR_CHANGED_SINCE_SAVED = "CLEAR_CHANGED_SINCE_SAVED";
export interface ClearChangedSinceSaved extends Action {}
export const ClearChangedSinceSaved = ActionCreator<ClearChangedSinceSaved>(CLEAR_CHANGED_SINCE_SAVED);

export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";
export interface ClearErrorMessage extends Action {}
export const ClearErrorMessage = ActionCreator<ClearErrorMessage>(CLEAR_ERROR_MESSAGE);

export const CLEAR_EVENT_FEED = "CLEAR_EVENT_FEED";
export interface ClearEventFeed extends Action {}
export const ClearEventFeed = ActionCreator<ClearEventFeed>(CLEAR_EVENT_FEED);

export const CLEAR_EVENT_FEED_FILTERS = "CLEAR_EVENT_FEED_FILTERS";
export interface ClearEventFeedFilters extends Action {}
export const ClearEventFeedFilters = ActionCreator<ClearEventFeedFilters>(CLEAR_EVENT_FEED_FILTERS);

export const CLEAR_EVENT_LIST = "CLEAR_EVENT_LIST";
export interface ClearEventList extends Action {}
export const ClearEventList = ActionCreator<ClearEventList>(CLEAR_EVENT_LIST);

export const CLEAR_SHOULD_FETCH = "CLEAR_SHOULD_FETCH";
export interface ClearShouldFetch extends Action {}
export const ClearShouldFetch = ActionCreator<ClearShouldFetch>(CLEAR_SHOULD_FETCH);

export const CLEAR_SHOW_PUBLISH_SUCCESS = "CLEAR_SHOW_PUBLISH_SUCCESS";
export interface ClearShowPublishSuccess extends Action {}
export const ClearShowPublishSuccess = ActionCreator<ClearShowPublishSuccess>(CLEAR_SHOW_PUBLISH_SUCCESS);

export const CLEAR_SUCCESS_MESSAGE = "CLEAR_SUCCESS_MESSAGE";
export interface ClearSuccessMessage extends Action {}
export const ClearSuccessMessage = ActionCreator<ClearSuccessMessage>(CLEAR_SUCCESS_MESSAGE);
