import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { push } from "react-router-redux";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from "modules/posts/actionCreator";

import { defaultPostFeedFilter, PostFeedFilters, PostFeedItem, Tag } from "../../models";
import Card from "./components/layouts/card/Card";
import Column from "./components/layouts/column/Column";
import Window from "./components/layouts/window/Window";
import List from "./components/layouts/list/List";
import Loading from "modules/common/components/loading";
import { DialogPostView } from "../post-view/dialogPostView";
import { FeedLayout } from "modules/portalPages/tinacms/models/common";
import { EmptyFeed } from "modules/common/components/feed/emptyFeed";
import { FeedButtons } from "../../../common/components/feed/feedButtons";

import "./styles/NewsFeed.sass";

class PostFeed extends React.Component<PropsWithRedux, ComponentState> {
    _isMounted = false;

    constructor(props: PropsWithRedux) {
        super(props);

        this.state = {
            isFetching: true,
            canLoadMore: true,
            postsFeed: [],
            selectedPostId: ""
        };
    }

    public componentDidMount() {
        this._isMounted = true;
        this.fetchFeed();
    }

    public componentWillUnmount() {
        this._isMounted = false;
    }

    public componentDidUpdate(prevProps: PropsWithRedux) {
        if (this.props.ignoreSubscriptions !== prevProps.ignoreSubscriptions || this.props.lockSubscriptions !== prevProps.lockSubscriptions || this.props.lockedToTopics !== prevProps.lockedToTopics || this.props.maxResults !== prevProps.maxResults || this.props.postTypes !== prevProps.postTypes)
            this.fetchFeed();
    }

    public render() {
        const { postsFeed } = this.state;

        const loadMore = this.props.hideLoadMore
            ? undefined
            : { canLoadMore: (this.state.canLoadMore), isFetching: this.state.isFetching, onFetchMore: this.fetchFeed }

        if (postsFeed.length === 0 && this.state.isFetching) return <Loading/>;

        return <div id="news-feed" className={this.props.layout === FeedLayout.HorizontalBand ? "horizontal-scrollable" : ""}>
            {
                postsFeed.length === 0 && !this.state.isFetching
                ? <EmptyFeed />
                : this.getLayout({ ...this.props, ...this.state }, this.props.currentUser.preferredLCID)
            }
            <DialogPostView
                isPublic
                onClose={this.unselectPost}
                postId={this.state.selectedPostId}
                open={!!this.state.selectedPostId}
            />
            {
                this.props.layout !== FeedLayout.HorizontalBand &&
                <FeedButtons
                    loadMore={loadMore}
                    viewAll={this.props.hideViewAll
                        ? undefined
                        : {action: this.onViewAll}
                    }
                />
            }
        </div>
    }

    private onViewAll = () => {
        const topicIds = this.props.lockedToTopics.map(t => t.id)
        this.props.setPostFeedFilters({...defaultPostFeedFilter, tags: topicIds, postTypes: this.props.postTypes});
        this.props.redirectTo(`/${this.props.tenantId}/posts`);
    }

    private fetchFeed = async (pageNumber: number = 1) => {
        this.setState({ isFetching: true });

        const filters: Partial<PostFeedFilters> = {
            ignoreSubscriptions: this.props.ignoreSubscriptions,
            lockSubscriptions: !!this.props.lockedToTopics.length,
            postTypes: !!this.props.postTypes.length ? this.props.postTypes : undefined,
            lockedTags: !!this.props.lockedToTopics.length ? this.props.lockedToTopics.map(topic => topic.id) : undefined
        };

        const postsToFetch = this.props.maxResults;

        try {
            const postsFeed = await this.props.fetchPostsFeed(filters, postsToFetch, pageNumber);
            
            if (!this._isMounted) return;

            this.setState({
                isFetching: false,
                canLoadMore: postsFeed.length === +postsToFetch,
                postsFeed: pageNumber === 1 ? [...postsFeed] : [...this.state.postsFeed, ...postsFeed]
            });
        }
        catch {
            this.setState({
                isFetching: false,
                canLoadMore: false,
                postsFeed: []
            });  
        }
    }

    private getLayout(props: ComponentProps & ComponentState, preferredLCID: string): JSX.Element {
        switch (props.layout) {
            case FeedLayout.HorizontalBand:
                return <Card {...props}
                    cardCount={0}
                    preferredLCID={preferredLCID}
                    onPostSelected={this.selectPost}
                    viewAll={this.props.hideViewAll ? undefined : this.onViewAll}
                    loadMore={{
                        canLoadMore: this.state.canLoadMore,
                        isFetching: this.state.isFetching,
                        onFetchMore: this.fetchFeed
                    }}
                    />;
            case FeedLayout.Column:
                return <Column {...props} preferredLCID={preferredLCID} onPostSelected={this.selectPost} />;
            case FeedLayout.Window:
                return <Window {...props} cardCount={props.cardCount || 3} preferredLCID={preferredLCID} onPostSelected={this.selectPost} />;
            case FeedLayout.List:
                return <List {...props} cardCount={props.cardCount || 10} preferredLCID={preferredLCID} onPostSelected={this.selectPost} />;
            case FeedLayout.StackedCard:
                return <Card {...props} cardCount={props.cardCount || 3} preferredLCID={preferredLCID} onPostSelected={this.selectPost} />;
            default:
                return <Card {...props} cardCount={0} preferredLCID={preferredLCID} onPostSelected={this.selectPost} />;
        }
    }

    private selectPost = (post: PostFeedItem) => {
        this.setState({ selectedPostId: post.id });
        if (!post.lastReadTime)
            this.setState({
                postsFeed: this.state.postsFeed.map(article => article.id === post.id && !post.lastReadTime
                    ? { ...article, lastReadTime: new Date().toISOString() }
                    : article
                )
            });
    }

    private unselectPost = () => {
        this.setState({ selectedPostId: "" });
    }
}

interface ComponentProps {
    cardCount?: number;
    hidePublishTime?: boolean;
    hideStats?: boolean;
    hideSummary?: boolean;
    hideTopics?: boolean;
    hideLoadMore?: boolean;
    hideViewAll?: boolean;
    ignoreSubscriptions?: boolean;
    lockSubscriptions?: boolean;
    layout: FeedLayout;
    lockedToTopics: Tag[];
    maxResults: number;
    postTypes: string[];
}

interface ComponentState {
    isFetching: boolean;
    canLoadMore: boolean;
    postsFeed: PostFeedItem[];
    selectedPostId: string;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        currentUser: state.settings.currentUser,
        tenantId: state.tenant.id
    }),
    {
        fetchPostsFeed: actions.fetchPostsFeedLocal,
        setPostFeedFilters: actions.setPostFeedFilters,
        redirectTo: push
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(PostFeed);
