import React from "react";

import { IDocumentsFeedConfig } from "../../models/feed";
import { contentHeaderTemplate, lockedToCategoryTagsTemplate, optionalDataTemplate, stylingTemplate } from "./templates";
import DocumentFeedSection from "modules/documents/components/document-feed/components/layouts/feed/documentFeedSection";

export const DocumentsFeedBlock: React.FunctionComponent<{ data: IDocumentsFeedConfig }> = props => {
    const [refresh, setRefresh] = React.useState<boolean>(false);
    const {
        max_results,
        locked_to_category_tags
    } = props.data;

    React.useEffect(() => setRefresh(true), [max_results, locked_to_category_tags])

    return (
        <DocumentFeedSection
            maxResults={Number.parseInt(props.data.max_results)}
            lockedToCategoryTags={props.data.locked_to_category_tags}
            optionalContent={{
                hideLoadMore: props.data.hide_load_more,
                hideViewAll: props.data.hide_view_all,
                hideActionIcons: props.data.hide_action_icons,
                hideCategoryTags: props.data.hide_category_tags,
                hideFileIcon: props.data.hide_file_icon
            }}
            refresh={refresh}
            resetRefresh={() => setRefresh(false)}
        />
    );
};

const styling = stylingTemplate("document");

export const DOCUMENT_FEED_TEMPLATE = {
    key: "documents-feed-block",
    label: "Documents Feed",
    defaultItem: {
        max_results: 6,
        locked_to_category_tags: [],
        hide_load_more: false,
        hide_view_all: false,
    },
    fields: [
        contentHeaderTemplate,
        lockedToCategoryTagsTemplate,
        optionalDataTemplate.header,
        ...optionalDataTemplate.docsData,
        ...optionalDataTemplate.buttons,
        styling.header,
        styling.tiles
    ]
};