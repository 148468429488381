export interface ContentAnalysis {
  fogScore: string;
  fogScoreText: string;
  ieltsScore: string;
  ieltsScoreText: string;
  lixScore: number;
  longestSentenceWords: string;
  longestWord: string;
  lettersPerWord: string;
  readabilityScore: string;
  readabilityText: string;
  readingTime: string;
  sentencesPerParagraph: string;
  sentimentScore: number;
  speakingTime: string;
  syllablesPerWord: string;
  tone: string;
  toneNumber: number;
  uiSentimentScore: number;
  wordCount: number;
  wordsPerParagraph: string;
  wordsPerSentence: string;
  sentenceCount: number;
  readabilityIndicator: IIndicatorDetail;
  toneIndicator: IIndicatorDetail;
  sentimentIndicator: IIndicatorDetail;
  personalismIndicator: IIndicatorDetail;
  highlightAnalysis?: IHighlightAnalysis;
}

export interface IHighlightAnalysis {
    highlightDetails: IHighlightDetails;
    veryLongSentenceCount: number;
    spellingErrorCount: number;
    grammarErrorCount: number;
    longSentenceCount: number;
    passiveVoiceCount: number;
    adverbCount: number;
    clicheCount: number;
    hedgeCount: number;
    transitionCount: number;
    profanityCount: number;
    buzzwordsCount: number;
    namesCount: number;
    lazyCount: number;
    stopwordsCount: number;
    longWordCount: number;
    highSyllableWordCount: number;
    highlightedText: string;
    processedHighlightText: string;
}

export interface IHighlightDetails {
    higlightWarn: string;
    highlightBad: string;
    highlightLongWord: string;
    highlightHardWord: string;
    highlightAdverb: string;
    highlightCliche: string;
    highlightPassive: string;
    highlightHedge: string;
    highlightTransition: string;
    highlightProfanity: string;
    highlightBuzzwords: string;
    highlightStopwords: string;
    highlightLazy: string;
    highlightNames: string;
}

export interface IIndicatorDetail {
    minScore: number;
    maxScore: number;
    minLabel: string;
    maxLabel: string;
    score: number;
    scoreLabel: string;
    recommendedScore: number;
}

export interface CroppableImage {
  id: string;
  transforms: { points: number[], zoom?: number };
  url: string;
}

export type NotificationRange = "fallback" | "subscribers" | "none";

export interface Notifications {
  emailOnPublish: NotificationRange;
  mobileOnPublish: NotificationRange;
  smsOnPublish: NotificationRange;
  teamsOnPublish: NotificationRange;
  reminders: Reminder[];
}

export interface Reminder {
  channel: ReminderChannel;
  delay: number;
  range: NotificationRange;
}

export type ReminderChannel = "inherit" | "email" | "mobile" | "sms" | "teams" | "none";

export interface Tag {
  id: string;
  name: string;
  mandatory?: boolean;
  restricted?: boolean;
}

export interface TranslatedContent {
  [languageCode: string]: TranslatableContent;
}

export interface TranslatedNewsletterContent { 
  [languageCode: string]: TranslatableNewsletterContent;
}

export interface TranslatableContent {
  title: string;
  body: string;
  description: string;
}

export interface TranslatableNewsletterContent {
  subject: string;
  body: string;
  preheader: string;
  header: string;
  footer: string;
}

export interface ValidationChecks {
  checks: ValidationCheck[];
}

export interface ValidationCheck {
  key: string;
  description: string;
  isValid: boolean;
  link?: string;
  skip?: boolean;
}

export const DEFAULT_TRANSLATABLE_CONTENT: TranslatableContent = {
    title: "",
    body: "",
    description: "",
}

export const DEFAULT_TRANSLATABLE_NEWSLETTER_CONTENT: TranslatableNewsletterContent = {
  subject: "",
  body: "",
  preheader: "",
  header: "",
  footer: ""
}


export type DelayType = "hours" | "days";

export interface ReminderInfo {
    delay: number;
    delayType: DelayType;
    emailRange: NotificationRange;
    mobileRange: NotificationRange;
    smsRange: NotificationRange;
    teamsRange: NotificationRange;
}

export interface DraftActivity {
    id: string;
    action : DraftAction;
    draftId: string;
    userFullName: string;
    time: Date;
    comment?: string;
}

export enum DraftAction {
    Submit = "SUBMIT",
    Reject = "REJECT", 
    Publish = "PUBLISH",
    Unpublish = "UNPUBLISH"
}