import * as React from "react";

import { EventFeedItem } from "modules/events";
import { EventInfo } from "../eventInfo";
import EventTypeBanner from "modules/common/components/banners/eventTypeBanner";

export const Tile: React.FunctionComponent<ITileProps> = ({
    event,
    onClick,
    cardCount,
    hideSummary
}) => {
    if (!event) return (null);

    const selectPost = () => onClick(event.id);

    return (
        <div className={`tile-cell tile-cell-${cardCount}`} onClick={selectPost}>
            <div className="tile">
                <div
                    style={{
                        backgroundColor: event.imageUrl ? "#DDE1E5" : event.bannerColor,
                        backgroundImage: event.imageUrl ? `url("${event.imageUrl}")` : "",
                        backgroundSize: "contain"
                    }}
                    className="image"
                >
                    <EventTypeBanner
                        eventType={event.eventType}
                        isRestricted={event.isRestricted}
                        lcid={event.preferredLCID}
                        eventTime={{
                            startTime: event.eventStartTime,
                            endTime: event.eventEndTime
                        }}
                    />
                    {!event.lastViewTime && <div className="read-date unread unread-event"> Unread </div>}
                    <EventInfo
                        event={event}
                        hideSummary={hideSummary}
                        hideTopics
                        hideUnreadStatus
                        hideRestrictedIcon
                        hideStartDate
                    />
                </div>

                <div className="gradient-overlay"></div>
            </div>
        </div>
    );
};

interface ITileProps {
    event: EventFeedItem;
    onClick: (eventId: string) => void;
    cardCount: number;
    hideSummary: boolean;
}
