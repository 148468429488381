import { useEffect, useState } from "react";
import useIsMounted from "./useIsMounted";
import { audiencesApi } from "api/instances";
import { IdNamePair } from "../models";

type UseAudienceCountReturn = {
    audienceCount: number;
    isFetchingAudienceCount: boolean;
};

interface IUseAudienceCountProps {
    // set this for a default, constant number of audience counts (for ex. if previously calculated from somewhere else so we don't
    // continuously call the count audiences endpoint unnecessarily)
    constantAudienceCount?: number;
    audiences: IdNamePair<string, string>[];
    sendToAllUsers?: boolean;
};

export const useAudienceCount = ({ 
    constantAudienceCount,
    audiences,
    sendToAllUsers = false
}: IUseAudienceCountProps): UseAudienceCountReturn => {
    const [audienceCount, setAudienceCount] = useState(constantAudienceCount ?? 0);
    const [isFetchingAudienceCount, setIsFetchingAudienceCount] = useState(false);

    const isMounted = useIsMounted();

    useEffect(() => {
        const getAndSetAudienceCount = async () => {
            setIsFetchingAudienceCount(true);
            try {
                const { data } = await audiencesApi.getUniqueUsers(audiences.map(a => a.id), sendToAllUsers);
                if (isMounted()) setAudienceCount(data);
            }
            catch {
                if (isMounted()) setAudienceCount(0);
            }
            finally {
                if (isMounted()) setIsFetchingAudienceCount(false);
            }
        }

        if (isMounted() && constantAudienceCount === undefined) {
            if (audiences.length > 0 || sendToAllUsers) {
                getAndSetAudienceCount();
            }
            else if (audiences.length === 0 && audienceCount !== 0) {
                setAudienceCount(0);
            }
        }
        else if (isMounted() && constantAudienceCount !== undefined) {
            setAudienceCount(constantAudienceCount);
        }

    }, [audiences, sendToAllUsers, isMounted, constantAudienceCount]);

    return {
        audienceCount,
        isFetchingAudienceCount
    };
}