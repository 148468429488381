import { GlobalApplicationState } from "globalApplicationState";
import { TranslatableField } from "modules/settings";
import { useSelector } from "react-redux";
import { getTranslatedContentField } from "utils/getTranslatedContent";

export type TranslatableContentReturnType = {
    getTranslation: (field: TranslatableField) => string
}

const DEFAULT_LCID = "en-us";

/*
 * Hook for accessing translatable content.
 * Fetches translation according to the user's preferred language.
 */
export const useTranslatableContent = (): TranslatableContentReturnType  => {
    const {
        translatedContent
    } = useSelector((state: GlobalApplicationState) => state.settings.clientSettings);
    const { preferredLCID: lcid } = useSelector((state: GlobalApplicationState) => state.settings.currentUser);

    const getTranslation = (field: TranslatableField) : string => {
        if (!translatedContent
            || (!translatedContent[lcid] && !translatedContent[DEFAULT_LCID]))
            return "";

        const translation = getTranslatedContentField(field, translatedContent, lcid)
        return translation
    };

    return { getTranslation };
}
