import React from "react";
import { MultiSelectTopic, TenantSettingsTagGroup } from "modules/settings";
import { UserRole } from "modules/users/models";
import MultiSelectAndChipsWithSearch from "modules/common/components/forms/inputs/multiSelectAndChipsWithSearch";

import "./permissionDrawer.sass";

interface ComponentProps {
    topics: TenantSettingsTagGroup[];
    selectedTopics: MultiSelectTopic[];
    setSelectedTopics: (topics: MultiSelectTopic[]) => void;
    role: UserRole;
    defaultChipLabel?: string;
}

const SECTION_HEADER = {
    [UserRole.CONTRIBUTOR]: "Content Submission",
    [UserRole.AUTHOR]: "Content Creation"
};

const SECTION_DESCRIPTION = {
    [UserRole.CONTRIBUTOR]: <p>Allow to submit content in these topics<span className="red">*</span></p>,
    [UserRole.AUTHOR]: <p>Assign specific topics when authoring</p>
};

const SECTION_CLAUSE = {
    [UserRole.CONTRIBUTOR]: "Contributor cannot submit content to any topics.",
    [UserRole.AUTHOR]: "Author can publish content using any topic."
};

const TopicPermissionSection: React.FunctionComponent<ComponentProps> = (props: ComponentProps) => {
    return (
        <div className="topic-permission-drawer">
            <div className="header">{SECTION_HEADER[props.role]}</div>
            <div className="interactive-description">
                <div className="description">
                    {SECTION_DESCRIPTION[props.role]}
                    <span className="subtitle"> {`If none are selected, ${SECTION_CLAUSE[props.role]}`}</span>
                </div>
                <MultiSelectAndChipsWithSearch
                    defaultOptions={props.topics.map(opt => ({id: opt.id, name: opt.name, children: opt.tags}))}
                    selectedOptions={props.selectedTopics}
                    onChange={(topics) => props.setSelectedTopics(topics)}
                    buttonText="Add/Edit Topics"
                    truncateAtIndex={5}
                    nestingAllowed
                    showEmptyChip
                />
            </div>
        </div>
    );
};

export default TopicPermissionSection;