import * as React from "react";
import Loading from "./loading";

export interface LoadingProps{
  show: boolean
  absolute?: boolean
  styles?: React.CSSProperties;
}

export default class LoadingOverlay extends React.PureComponent<LoadingProps, {}> {
    public render() {
        return !this.props.show
          ? null
          : <div style={this.props.styles || {}} className={ `loading-overlay ${this.props.absolute ? "absolute" : ""}`}>
              { 
                <Loading />
              }
            </div>;
    }
}
