import React from "react";

import Attendance from "modules/events/components/attendance/attendance";
import Topics from "modules/posts/components/post-feed/components/topics/Topics";
import FullStartDate from "../dates/FullStartDate";
import { EventFeedItem } from "modules/events/models";
import ContentTypeIcons from "modules/common/components/banners/contentTypeIcons";
import { FeedLayout } from "modules/portalPages/tinacms/models/common";

interface IEventInfo {
    event: EventFeedItem;
    layout?: FeedLayout;
    hideStartDate?: boolean;
    hideUnreadStatus?: boolean;
    hideRestrictedIcon?: boolean;
    hideTopics?: boolean;
    hideSummary?: boolean;
}

const DOT_LENGTH = 3;
const LIST_EXCERPT_LENGTH = 150 - DOT_LENGTH;
const MAX_EXCERPT_LENGTH = 300 - DOT_LENGTH

export const EventInfo: React.FunctionComponent<IEventInfo> = ({
    event,
    layout,
    hideSummary = false,
    hideRestrictedIcon = false,
    hideStartDate = false,
    hideUnreadStatus = false,
    hideTopics = false
}) => {
    const excerptLength = layout === FeedLayout.List ? LIST_EXCERPT_LENGTH : MAX_EXCERPT_LENGTH;
    const excerptToDisplay = event.excerpt.length > excerptLength
        ? event.excerpt.substring(0, excerptLength) + "..."
        : event.excerpt;

    const getTitle = (): JSX.Element => {
        return (
            <div className="title">
                <ContentTypeIcons isRestricted={!hideRestrictedIcon && event.isRestricted} />
                <div>
                    {event.title}
                </div>
            </div>);
    };

    return (
        <div className="info">
            <div className="details">
                {
                    hideUnreadStatus
                        ? getTitle()
                        : <div className="first-line">
                            {getTitle()}
                            {!event.lastViewTime && <div className="read-date unread"> Unread </div>}
                        </div>
                }

                {
                    !hideStartDate &&
                    <FullStartDate eventStartTime={event.eventStartTime} eventEndTime={event.eventEndTime} />
                }
                {!hideSummary && <div className="summary truncated-text-1">{excerptToDisplay}</div>}
                {!hideTopics && <Topics topics={event.tags} />}
            </div>
            <Attendance {...event} />
        </div>
    );
};