import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import AuthoringSearch from "modules/common/components/filters/authoringSearch";
import FilterContainer from "modules/common/components/filters/filterContainer";
import FilterDropdown from "modules/common/components/filters/filterDropdown";

import { CategoryTag, DocumentFeedFilters as IDocumentFeedFilters } from "../../models";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";


import moment from "moment";


interface Option {
  id: string;
  text: string;
}

class DocumentFeedFilters extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      canSearch: !!props.filters.tags!.length || !!props.filters.lcids!.length || !!props.filters.textToSearch,
      textToSearch: props.filters.textToSearch || ""
    };
  }

  public componentDidMount() {
    moment.locale("en");
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.filters.textToSearch !== prevProps.filters.textToSearch)
      this.setState({ textToSearch: this.props.filters.textToSearch || "" });
  }

  public render() {
    const { filters } = this.props;

    const availableLanguages: Option[] = this.getAvailableLanguages();

    return (
      <FilterContainer
        filters={
          <React.Fragment>
            <TextField
              variant="outlined"
              size="small"
              value={this.state.textToSearch}
              placeholder="Search title, key words or author"
              InputProps={{
                startAdornment: <SearchIcon className="search-icon" />
              }}
              onChange={this.onUpdateTextToSearch}
              onKeyUp={this.onKeyUp}
              className="text-to-search"
            />
            <div className="filter-options-group">
              {availableLanguages.length > 1 &&
                <FilterDropdown text="Language">
                  <List disablePadding>
                  {availableLanguages.map((language) =>
                    <ListItem key={language.id} dense button onClick={() => this.onChangeLcids(language.id)}>
                      <ListItemIcon className="callout-checkbox">
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                          size="small"
                          color="primary"
                          checked={!!filters.lcids!.find((lcid) => lcid === language.id)}
                        />
                      </ListItemIcon>
                      <ListItemText primary={language.text} />
                    </ListItem>
                  )}
                </List>
                </FilterDropdown>
              }
            </div>
            {this.props.categoryTags.length > 0 &&
              <FilterDropdown
                text="Category tags"
                footer={
                  <div>
                    <Button variant="text" color="primary" onClick={this.onClearCategoryTags}>Clear all</Button>
                  </div>
                }
              >
                <List disablePadding className="category-tags">
                  {this.props.categoryTags.map((tag) =>
                    <ListItem key={tag.id} dense button onClick={() => this.onChangeCategoryTags(tag)}>
                      <ListItemIcon className="callout-checkbox">
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                          size="small"
                          color="primary"
                          checked={!!filters.tags!.find((selectedTag) => selectedTag === tag.id)}
                        />
                      </ListItemIcon>
                      <ListItemText primary={tag.name} />
                    </ListItem>
                  )}
                </List>
              </FilterDropdown>
            }
          </React.Fragment>
        }
        filterCommands={
          <AuthoringSearch
            canSearch={this.state.canSearch}
            onApplyFilters={this.onChangeTextToSearch}
            onClearFilters={this.onClearFilters}
          />
        }
        filterSelection={this.getFilterSelection()}
      />
    );
  }


  private getAvailableLanguages = (): Option[] => {
    if (!this.props.clientSettings || !this.props.lcidMappings)
      return [];

    let availableLanguages: Option[] = [];
    Object.keys(this.props.clientSettings.translatedContent).map((lcid) => {
      if (!!this.props.lcidMappings[lcid])
        availableLanguages.push({ id: lcid, text: this.props.lcidMappings[lcid].language });
      return lcid;
    });
    return availableLanguages;
  }

  private getFilterSelection = (): JSX.Element | undefined => {
    const { categoryTags, filters, lcidMappings } = this.props;

    if (!filters.textToSearch && !filters.lcids!.length && !filters.availabilities!.length && !filters.tags!.length)
      return undefined;

    return (
      <React.Fragment>
        {!!filters.textToSearch &&
          <Chip
            key="search-text"
            label={`"${filters.textToSearch}"`}
            onDelete={this.onClearTextToSearch}
            deleteIcon={<CloseIcon />}
          />
        }
        {filters.lcids!.map((selectedLcid) =>
          <Chip
            key={selectedLcid}
            label={lcidMappings[selectedLcid].language}
            onDelete={() => this.onClearLcid(selectedLcid)}
            deleteIcon={<CloseIcon />}
          />
        )}
        {categoryTags.length && filters.tags?.map((selectedTag) =>
          <Chip
            key={selectedTag}
            label={categoryTags.find((tag) => tag.id === selectedTag)?.name}
            onDelete={() => this.onClearCategoryTag(selectedTag)}
            deleteIcon={<CloseIcon />}
          />
        )}
      </React.Fragment>
    );
  }


  private onChangeCategoryTags = (tag: CategoryTag) => {
    const hasTag: boolean = !!this.props.filters.tags!.find((selectedTag) => selectedTag === tag.id);
    if (hasTag)
      this.onChangeFilters({ tags: this.props.filters.tags!.filter((selectedTag) => selectedTag !== tag.id) });
    else
      this.onChangeFilters({ tags: this.props.filters.tags!.concat([tag.id]) });
  }

  private onChangeFilters = (value: Partial<IDocumentFeedFilters>) => {
    this.props.onChangeFilters({ ...this.props.filters, textToSearch: this.state.textToSearch, ...value });
    
    let currentFilters = {...this.props.filters, ...value};
    if ( currentFilters.availabilities?.length === 0 && currentFilters.lcids?.length === 0 && currentFilters.tags?.length === 0 && this.state.textToSearch === "") {
      this.clearCanSearch();
    }
    else {
      this.setCanSearch();
    }
  }

  private onChangeLcids = (lcid: string) => {
    const hasSelectedLcid: boolean = !!this.props.filters.lcids!.find((selectedLcid) => selectedLcid === lcid);
    if (hasSelectedLcid)
      this.onChangeFilters({ lcids: this.props.filters.lcids!.filter((selectedLcid) => selectedLcid !== lcid) });
    else
      this.onChangeFilters({ lcids: this.props.filters.lcids!.concat([lcid]) });
  }
  
  private onChangeTextToSearch = () => {
    this.onChangeFilters({ textToSearch: this.state.textToSearch });
  }

  private onClearCategoryTag = (tag: string) => {
    this.onChangeFilters({ tags: this.props.filters.tags!.filter((selectedTag) => selectedTag !== tag) });
  }

  private onClearCategoryTags = () => {
    this.onChangeFilters({ tags: [] });
  }

  private onClearFilters = () => {
    this.clearCanSearch();
    this.props.onClearFilters();
  }

  private onClearLcid = (lcid: string) => {
    this.onChangeFilters({ lcids: this.props.filters.lcids!.filter((selectedLcid) => selectedLcid !== lcid) });
  }

  private onClearTextToSearch = () => {
    this.onChangeFilters({ textToSearch: "" });

    if(this.hasNoFilters()) {
      this.clearCanSearch();
    }
  }
  
  private onKeyUp = (key) => {
    if (key.keyCode === 13)
      this.onChangeTextToSearch();
  }

  private onUpdateTextToSearch = (event) => {
    this.setState({ textToSearch: event.target.value });

    if(event.target.value !== "" && !this.state.canSearch) {
      this.setState({canSearch: true});
    }
    else if(event.target.value === "" && this.hasNoFilters()) {
      this.setState({canSearch: false});
    }
  }

  private hasNoFilters = () => {
    return (
      this.props.filters.availabilities?.length === 0 
      && this.props.filters.lcids?.length === 0 
      && this.props.filters.tags?.length === 0);
  }

  private clearCanSearch = () => {
    this.setState({ canSearch: false });
  }

  private setCanSearch = () => {
    this.setState({ canSearch: true });
  }
}
  

interface ComponentProps {
  filters: Partial<IDocumentFeedFilters>;
  onChangeFilters: (filters: Partial<IDocumentFeedFilters>) => void;
  onClearFilters: () => void;
}

interface ComponentState {
  canSearch: boolean;
  textToSearch: string;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    categoryTags: state.categoryTags.userCategoryTags,
    clientSettings: state.settings.clientSettings,
    lcidMappings: state.resources.lcidMappings,
    showFilters: state.documents.documentFeed.showFilters
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(DocumentFeedFilters);