import AuthoringFilter from "modules/common/components/filters/authoringFilter";
import AuthoringSearch from "modules/common/components/filters/authoringSearch";
import FilterContainer from "modules/common/components/filters/filterContainer";
import React, { useState } from "react";
import { IEmailFilters } from "../models";
import CloseIcon from "@mui/icons-material/Close";
import { Chip } from "@mui/material";

interface IEmailFiltersProps {
    filters: IEmailFilters;
    onChangeFilters: (filters: IEmailFilters) => void;
    onClearFilters: () => void;
}

const EmailFilters: React.FC<IEmailFiltersProps> = ({
    filters,
    onChangeFilters,
    onClearFilters
}) => {
    const [textToSearch, setTextToSearch] = useState("");

    const onApplyFilters = () => {
        onChangeFilters({...filters, textToSearch});
    }

    const onInnerClearFilters = () => {
        setTextToSearch("");
        onClearFilters();
    }

    const getFilterSelection = (): JSX.Element | undefined => {    
        if (!filters.textToSearch) return undefined;
    
        return <React.Fragment>
            {
                !!filters.textToSearch &&
                <Chip
                    key="emails-search-text"
                    label={`"${filters.textToSearch}"`}
                    onDelete={onInnerClearFilters}
                    deleteIcon={<CloseIcon />}
                />
            }
        </React.Fragment>;
    }

    return <FilterContainer
        filters={
            <AuthoringFilter
                textToSearch={textToSearch}
                searchTextPlaceholder="Search subject, publisher or sender"
                onChangeTextToSearch={onApplyFilters}
                onUpdateTextToSearch={e => setTextToSearch(e.target.value)}
            />
        }
        filterCommands={
            <AuthoringSearch
                canSearch={!!textToSearch || !!filters.textToSearch}
                onApplyFilters={onApplyFilters}
                onClearFilters={onInnerClearFilters}
            />
        }
        filterSelection={getFilterSelection()}
    />;
};

export default EmailFilters;
