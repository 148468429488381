import React from "react";

interface IInputCounterProps {
    count: number;
    maxCount: number;
    isFocused: boolean;
    errorClass?: string;
    errorHelpText?: string;
}
export const InputCounter: React.FunctionComponent<IInputCounterProps> = ({ count, maxCount, isFocused, errorHelpText, errorClass }) => {
    // only add error class when focused
    const getErrorClass = (): string => {
        return isFocused && maxCount && count === maxCount ? "error" : "";
    };

    return (
        <div className="filled-input-helper-container">
            <span className="filled-input-helper-text">{errorHelpText}</span>
            <span className={`filled-input-char-count ${errorClass ?? getErrorClass()}`}>
                {count}/{maxCount}
            </span>
        </div>
    );
};
