import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import ShareLink from "../shareLink";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";
import ShareIcon from "@mui/icons-material/Share";
import WarningIcon from "@mui/icons-material/Warning";


import "./shareDialog.sass";
import { ArticleType, ShareUtil } from "../utils/shareUtils";

class ShareDialog extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      showLinks: false
    };
  }

  public render() {
    const {
        clientSettings,
        articleType,
        articleId,
        articleTitle,
        linkText,
        preview = false,
    } = this.props;

    let spUrl = "";
    let teamsUrl = "";
    let portalUrl = "";
    if (articleType === "post") {
      spUrl = !!clientSettings && !!clientSettings.teams ? clientSettings.teams.spUrl : "";
      teamsUrl = !!clientSettings && !!clientSettings.teams ? clientSettings.teams.teamsUrl : "";
      portalUrl = !!clientSettings && !!clientSettings.teams ? clientSettings.teams.companyPortalUrl : "";
    } else if (articleType === "event") {
      spUrl = !!clientSettings && !!clientSettings.teams ? clientSettings.teams.spEventsUrl : "";
      teamsUrl = !!clientSettings && !!clientSettings.teams ? clientSettings.teams.teamsEventsUrl : "";
      portalUrl = !!clientSettings && !!clientSettings.teams ? clientSettings.teams.companyPortalEventsUrl : "";
    }
    
    return (
      <div className={`share-button ${linkText ? "link" : "share"}`}>
        {linkText
          ? <div title="Share" onClick={this.onShow} className="link-text">
              {linkText}
            </div>
          : <IconButton disabled={preview} title="Share" size={this.props.size === "small" ? "small" : "medium"} onClick={this.onShow}>
              <ShareIcon fontSize={this.props.size === "small" ? "small" : "medium"} />
            </IconButton>
        }
        <Dialog open={this.state.showLinks} maxWidth={false} onClose={this.onClose} className="share-button-dialog">
          <DialogTitle className="share-button-dialog-header">
            <div className="share-button-dialog-header-title">
              <div>
                <Typography variant="h2">Share '{articleTitle}'</Typography>
              </div>
              <IconButton disabled={preview} onClick={this.onClose} size="large">
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent className="share-button-content">
            {spUrl && this.props.clientSettings.shareUrlsConfig.toggleSpUrl 
            && <ShareLink label= { this.props.clientSettings.shareUrlsConfig.spUrlShareLinkName || "Intranet Link" } url={this.openInSP(spUrl, articleType, articleId)} />}
            {teamsUrl && this.props.clientSettings.shareUrlsConfig.toggleTeamsUrl 
            && <ShareLink label={ this.props.clientSettings.shareUrlsConfig.teamsUrlShareLinkName || "MS Teams Link"} url={this.openInTeams(teamsUrl, articleType, articleId)} />}
            {this.props.clientSettings.shareUrlsConfig.toggleCompanyPortalUrl 
            && <ShareLink preview={preview} label={ this.props.clientSettings.shareUrlsConfig.companyPortalUrlShareLinkName || "Portal Link" } url={this.getPortalUrl(portalUrl, articleType, articleId)} />
            }
            {this.props.warningMessage &&
              <div className="warning-message">
                <WarningIcon className="warning-icon" />
                <div>{this.props.warningMessage}</div>
              </div>
            }
          </DialogContent>
        </Dialog>
      </div>
    );
  }

  private onClose = () => {
    this.setState({ showLinks: false });
  }

  private onShow = () => {
    this.setState({ showLinks: true });
  }

  private openInSP(baseUrl: string, articleType: ArticleType, articleId: string): string {
    return `${baseUrl}?${articleType}Id=${articleId}`;
  }

  private openInTeams(baseUrl: string, articleType: ArticleType, articleId: string): string {
    const subEntityId: string = `%22${articleType}%7C${articleId}%7C%22`;
    return baseUrl.replace("null", subEntityId);
  }

  private getPortalUrl = (portalUrl: string, articleType: ArticleType, articleId: string): string => {
    const shareUtil = new ShareUtil(this.props.tenantId, this.props.isPublic);
    return shareUtil.getPortalUrl(portalUrl, articleType, articleId);
  }
}


interface ComponentProps {
  preview?: boolean;
  articleId: string;
  articleTitle: string;
  articleType: ArticleType;
  size?: "default" | "small";
  isPublic?: boolean;
  linkText?: string;
  warningMessage?: string;
}

interface ComponentState {
  showLinks: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    clientSettings: state.settings.clientSettings,
    tenantId: state.tenant.id
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(ShareDialog);
