import React from "react";


const Text: React.FunctionComponent<{ data: any }> = props => {
  return (
    <p dangerouslySetInnerHTML={{ __html: props.data.text }}></p>
  );
};

export const TextBlock: React.FunctionComponent<{ data: any }> = props => {
  return (
    <Text data={props.data} />
  );
};

export const TEXT_TEMPLATE = {
  key: "text-block",
  label: "Text",
  defaultItem: {
    text: ""
  },
  fields: [
    {
      name: "text",
      label: "Rich text",
      component: "tinymce"
    }
  ]
};