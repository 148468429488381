import TranslateContentConfirmation from 'modules/common/components/authoring/dialogs/translateContentConfirmation';
import { DEFAULT_TRANSLATABLE_NEWSLETTER_CONTENT, TranslatableNewsletterContent, TranslatedNewsletterContent } from 'modules/common/components/authoring/models';
import ConfirmDialog from 'modules/common/components/dialogs/confirmDialog';
import useTranslatedContent from 'modules/common/hooks/useTranslatedContent';
import LanguageBar from 'modules/localization/components/languageBar';
import { ILanguage } from 'modules/localization/models';
import { lcidMappings } from 'modules/resources';
import * as React from 'react';
import confirm from "utils/notyPopups";

const CONFIRM_REPLACE_TRANSLATION = {
    text: <div style={{ width: 400 }}>Are you sure you want to replace the existing content?</div>,
    title: "Replace content",
};

const NewsletterLanguageBar: React.FunctionComponent<ComponentProps> = props => {
    const [checkIfAnyTranslatableContent, setCheckIfAnyTranslatableContent] = React.useState<boolean>(false);
    const [translatableLanguagesWithText, setTranslatableLanguagesWithText] = React.useState<ILanguage[]>([]);
    const translations = props.newsletterContent;

    const translationHasContent = (lcid: string) =>
        translations &&
        translations[lcid] &&
        (
            Boolean(translations[lcid].subject) || 
            Boolean(translations[lcid].body) || 
            Boolean(translations[lcid].footer) || 
            Boolean(translations[lcid].header) || 
            Boolean(translations[lcid].preheader)
        );

    /**
     * Handle translate click
     * - show dialog if translate will replace content
     */
    const onTranslate = async (inputLanguage: string) => {
        if (!translations || !inputLanguage) return;

        let translate = true;
        const outputLanguage = props.availableLanguages?.find((language: ILanguage) => language.lcid === props.activeLcid);

        // show confirm dialog
        if (translationHasContent(props.activeLcid)) translate = await confirm.show(CONFIRM_REPLACE_TRANSLATION);

        // do the translation and update state
        if (translate && outputLanguage) {
            const translation = await props.getNewsletterTranslation( 
                translations[inputLanguage],
                outputLanguage.lcid,
                inputLanguage,
                props.newsletterId ?? "00000000-0000-0000-0000-000000000000"
            );

            let newTranslations = { ...translations };
            newTranslations[props.activeLcid] = {
                ...translation,

                header: translation.header.length > 0 ? `<p><em>[${props.lcidMappings[props.activeLcid].autoTranslateText}]</em></p>${translation.header}` : "",
                body: translation.body.length > 0 ? `<p><em>[${props.lcidMappings[props.activeLcid].autoTranslateText}]</em></p>${translation.body}` : "",
                footer: translation.footer.length > 0 ? `<p><em>[${props.lcidMappings[props.activeLcid].autoTranslateText}]</em></p>${translation.footer}` : "",
            };

            props.onChangeTranslatedContent(newTranslations);
        }
    };

    const {
        showTranslationDialog,
        removeLanguage,
        onRemoveTranslation,
        setRemoveLanguage,
        onSelectTranslation,
        onShowTranslationDialog,
        onCloseTranslationDialog,
        onConfirmDialog,
        onConfirmRemoveLanguage
    } = useTranslatedContent({
        translations: translations, 
        defaultContent: DEFAULT_TRANSLATABLE_NEWSLETTER_CONTENT,
        onChangeTranslatedContent: props.onChangeTranslatedContent,
        translationHasContent: translationHasContent,
        onTranslate: onTranslate,
        onSelectLanguage: props.onSelectLanguage,
        onRemoveLanguage: props.onRemoveLanguage
    });

    React.useEffect(() => {
        function setLanguageTranslationAvailability() {
            let currentAvailableTranslations = { ...translations };
            let languagesWithText: ILanguage[] = [];
            let allAddedLanguages: ILanguage[] = [];

            Object.keys(currentAvailableTranslations).forEach((nextLanguage) => {
                //Only subject is mandatory, so we only check that one.
                if (props.availableLanguages && translations && translations[nextLanguage].subject) {
                    let matchingLang = props.availableLanguages.find((l) => l.lcid === nextLanguage);

                    if (matchingLang) {
                        languagesWithText.push(matchingLang);
                    }
                }

                if (props.availableLanguages && translations) {
                    let matchingLang = props.availableLanguages.find((l) => l.lcid === nextLanguage);

                    if (matchingLang) {
                        allAddedLanguages.push(matchingLang);
                    }
                }
            });

            if (languagesWithText.length >= 1 && allAddedLanguages.length > 1) {
                setCheckIfAnyTranslatableContent(false);
            } else {
                setCheckIfAnyTranslatableContent(true);
            }

            setTranslatableLanguagesWithText(languagesWithText);
        }

        setLanguageTranslationAvailability();
    }, [translations, props.availableLanguages]);

    return (
        <div style={{width: "100%"}}>
            <LanguageBar
                languages={props.availableLanguages?.map((lang) => ({ key: lang.lcid, value: lang.language })) || []}
                activeLcid={props.activeLcid}
                defaultLcid={props.defaultLcid}
                selectedLanguages={props.selectedLanguages || []}
                onAddLanguage={onSelectTranslation}
                onRemoveLanguage={onRemoveTranslation}
                onSelectLanguage={onSelectTranslation}
                onTranslate={onShowTranslationDialog}
                anyAvailableTranslationsCheck={checkIfAnyTranslatableContent}
                hide={props.availableLanguages?.length === 1}
            />
            {showTranslationDialog && (
                <TranslateContentConfirmation
                    show={showTranslationDialog}
                    onConfirm={onConfirmDialog}
                    onCancel={onCloseTranslationDialog}
                    currentLanguagesWithText={translatableLanguagesWithText}
                />
            )}
            {removeLanguage && (
            <ConfirmDialog
                title="You'll lose your translated content"
                confirmLabel="KEEP"
                denyLabel="REMOVE"
                open={Boolean(removeLanguage)}
                onClose={() => setRemoveLanguage(undefined)}
                onConfirm={() => setRemoveLanguage(undefined)} // KEEP
                onDeny={() => onConfirmRemoveLanguage()}
            >
                <div style={{ width: 400 }}>
                    You have content on this language. Removing the language will clear the translated work you've done.
                    <br />
                    <br />
                    Do you want to continue?
                </div>
            </ConfirmDialog>
        )}
        </div>
    )
}

interface ComponentProps {
    newsletterContent: TranslatedNewsletterContent;
    onChangeTranslatedContent: (newValue: TranslatedNewsletterContent) => void;
    getNewsletterTranslation: (toTranslate: TranslatableNewsletterContent, targetLcid: string, inputLcid: string, newsletterId: string) => Promise<TranslatableNewsletterContent>;

    availableLanguages: ILanguage[] | undefined;
    selectedLanguages: ILanguage[] | undefined;
    activeLcid: string;
    defaultLcid: string;
    lcidMappings: lcidMappings;
    newsletterId?: string;
    onRemoveLanguage: ( lcid: string ) => void;
    onSelectLanguage: ( lcid: string ) => void; 
}

export default NewsletterLanguageBar;