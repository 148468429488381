import * as React from "react";

export enum FeedLayout {
    HorizontalBand = "card",
    Column = "column",
    Window = "window",
    List = "list",
    StackedCard = "stackedCard" 
}

export interface ITinaProps<T> {
    input: ITinaInput<T>;
    field: ITinaField;
}

interface ITinaInput<T> {
    value: T;
    name: string;
    onChange: (event: React.SyntheticEvent<any, Event> | any) => void,
    onFocus: (event?: React.SyntheticEvent<any, Event>) => void,
    onBlur: (event?: React.SyntheticEvent<any, Event>) => void,
}

interface ITinaField {
    component: string;
    description: string;
    label: string;
    name: string;

    buttonLabel?: string;
    options?: ISelectableOption[];
}

interface ISelectableOption {
    value: string;
    label: string;
}