// BUTTON ACTIONS

// Saving Changes
export const DRAFT_SAVE_BTN_TEXT = "Save As Draft";
export const DRAFT_MOVE_BTN_TEXT = "Move To Drafts";
export const DRAFT_CHANGED_BTN_TEXT = "Save Changes";
export const DISCARD_BTN_TEXT = "Discard";

// Editing
export const RETURN_TO_EDITOR = "Keep Editing";

// Publishing
export const DFLT_PUBLISH_TEXT = "Publish";
export const SUBMISSION_PUBLISH_TEXT = "Approve & Publish";
export const CONTRIBUTOR_PUBLISH_TEXT = "Submit";
export const CONTRIBUTOR_REPUBLISH_TEXT = "Resubmit";

export const PREVIEW_HOVER_TEXT = "Preview (Desktop only)";

export const REJECT_BTN_TEXT = "Reject Submission";

export const BACK_POST_TEXT = "Back to Manage Posts";
export const BACK_EVENT_TEXT = "Back to Manage Events";
export const BACK_EMAIL_TEXT = "Back to Manage Emails";
