import * as React from "react";

import Tile from "./Tile";
import { PostFeedItem } from "../../../../../models";
import { ILoadMoreButton } from "../../../../../../common/components/feed/loadMoreButton";
import { FeedButtons } from "modules/common/components/feed/feedButtons";

import "../../../styles/Card.sass";


const Card: React.FunctionComponent<ComponentProps> = props => {
  return (
    <div className="card">
      {props.postsFeed.map(post => <Tile key={post.id} {...props} post={post} />)}

      {
        (props.viewAll || props.loadMore) &&
        <div className={"tile load-tile"}>
            <FeedButtons
                inCard
                viewAll={props.viewAll ? {action: props.viewAll} : undefined}
                loadMore={props.loadMore}
            />
        </div>
      }
    </div>
  );
}

interface ComponentProps {
  cardCount: number;
  hidePublishTime?: boolean;
  hideReadDate?: boolean;
  hideStats?: boolean;
  hideSummary?: boolean;
  hideTopics?: boolean;
  hideLoadMore?: boolean;
  postsFeed: PostFeedItem[];
  preferredLCID: string;
  loadMore?: ILoadMoreButton;
  viewAll?: () => void;
  onPostSelected: (post: PostFeedItem) => void;
}

export default Card;