import React, { useRef, useState } from "react";
import { Box, Button, Checkbox, DrawerProps, FormControlLabel, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { Tab as ITab } from "pages/common/tabs";
import { ContentAnalysis, CroppableImage, ValidationChecks } from "modules/common/components/authoring/models";
import { ProgressBar } from "modules/common/components/authoring/validator";
import AuthoringDrawer from "../../../../common/components/drawers/authoringDrawer";
import { AttachedFile } from "modules/gallery";
import { CommentingTypes, Post, Tag, Notifications as INotifications, PostAverages, Notifications } from "modules/posts/models";
import OptionsEditor from "modules/common/components/authoring/optionsEditor";
import { NotificationEvents, TenantNotificationSettings } from "modules/messaging";
import ContentAnalyses from "modules/insights/components/contentAnalyses";
import { CurrentUserSettings } from "modules/settings";
import Callout from "modules/common/components/callout";
import DetailsEditor from "modules/common/components/authoring/detailsEditor";

import "../styles/postSettingsPanel.sass";
import { lcidMappings } from "modules/resources";
import { LANGUAGE_CODES } from "modules/localization/models";
import { getDefaultNotificationSetting } from "utils/notificationsHelper";

interface IContentAnalysesCalloutProps {
    anchorEl: HTMLElement | null;
    checked: boolean;
    onChange: (checked: boolean) => void;
    onClose: (element: HTMLElement | null) => void;
}

const ContentAnalysesCallout: React.FunctionComponent<IContentAnalysesCalloutProps> = ({
    anchorEl,
    checked,
    onChange,
    onClose,
}) => {
    const [calloutOpen, setCalloutOpen] = React.useState(false);

    return (
        <Callout
            autoClose
            autoCloseDuration={8000}
            open={calloutOpen}
            setOpen={(toSet) => {
                setCalloutOpen(toSet);
            }}
            contentClassName="content-analyses-callout"
            anchorEl={anchorEl}
            arrowPosition="right"
            popoverProps={{
                anchorOrigin: {
                    vertical: -44,
                    horizontal: -163
                }
            }}
        >
            <p>We have some suggestions to improve the readership and engagement for your post!</p>
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                <FormControlLabel
                    style={{ fontSize: 14 }}
                    classes={{
                        label: "font-14"
                    }}
                    label="Don't show me again"
                    control={<Checkbox onChange={(_, checked) => onChange(checked)} checked={checked} size="small" color="primary" />}
                />
                <Button
                    disableRipple
                    color="primary"
                    style={{
                        textDecoration: "underline",
                        fontSize: 14
                    }}
                    variant="text"
                    onClick={() => onClose(null)}
                >
                    Close
                </Button>
            </div>
        </Callout>
    );
}

interface IPostSettingsPanelProps {
    drawerProps: DrawerProps;
    validationChecks: ValidationChecks;
    post: Partial<Post>;
    hasPublished: boolean;
    notificationsEnabled: boolean;
    reminderNotificationsEnabled: boolean;
    reactionsEnabled: boolean;
    commentsEnabled: boolean;
    tenantNotificationSettings: TenantNotificationSettings;
    tenantAverages: PostAverages | undefined;
    globalAverages: PostAverages | undefined;
    isUsingTenantAverages: boolean;
    setIsUsingTenantAverages: (toSet: boolean) => void;
    analysisIndicator?: boolean;
    activeLcid: string;
    currentUser: CurrentUserSettings;
    selectedTab: number;
    contentAnalyses?: ContentAnalysis;
    fetchingAnalyses?: boolean;
    highlightsOn: boolean;
    lcidMappings: lcidMappings;
    toggleHighlights: () => void;
    analyze: () => void;
    onChangeTab: (event: React.ChangeEvent, newTab: number) => void;
    saveUserSettings: (newSettings: Partial<CurrentUserSettings>) => Promise<boolean>;
    onChangePost: (value: Partial<Post>) => void;
    onBookmarkClick: () => void;
    isContributor: boolean;
    canContributorsToggleReactions: boolean;
    canContributorsToggleComments: boolean;
}

const POST_DETAILS = 0;
const POST_OPTIONS = 1;
const CONTENT_ANALYSES = 2;

const TABS: ITab[] = [
    {
        label: "Post details",
        value: POST_DETAILS,
    },
    {
        label: "Post options",
        value: POST_OPTIONS,
    },
    {
        label: "Content analysis",
        value: CONTENT_ANALYSES,
    }
];

/**
 * The post settings editor drawer
 * - this is an authoring v2 component
 */
const PostSettingsPanel: React.FunctionComponent<IPostSettingsPanelProps> = ({
    onBookmarkClick,
    onChangePost,
    analyze,
    saveUserSettings,
    onChangeTab,
    toggleHighlights,
    currentUser,
    lcidMappings,
    highlightsOn,
    contentAnalyses,
    hasPublished,
    selectedTab,
    reminderNotificationsEnabled,
    notificationsEnabled,
    drawerProps,
    post,
    validationChecks,
    commentsEnabled,
    reactionsEnabled,
    tenantAverages,
    globalAverages,
    isUsingTenantAverages,
    setIsUsingTenantAverages,
    analysisIndicator = false,
    tenantNotificationSettings,
    activeLcid,
    fetchingAnalyses = false,
    isContributor,
    canContributorsToggleComments,
    canContributorsToggleReactions,
}) => {
    const theme = useTheme();
    const isSmallAndSmaller = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

    const [analysesTab, setAnalysesTab] = useState<HTMLDivElement | null>(null);
    const [calloutOpen, setCalloutOpen] = useState<boolean>(currentUser.showContentAnalysesCallout);
    const [showContentAnalysesCalloutChecked, setShowContentAnalysesCalloutChecked] = useState<boolean>(!currentUser.showContentAnalysesCallout);

    const originalPostNotifications = useRef<Notifications | undefined>(post.notifications);

    const onChangeAttachedContent = (attachedContent: AttachedFile[]) => {
        onChangePost({ attachedContent });
    }

    const onChangeImage = (image: CroppableImage | undefined) => {
        onChangePost({ image });
    }

    const onChangePostType = (postType: string) => {
        onChangePost({ postType });
    }

    const onChangeTopics = (topics: Tag[]) => {
        onChangePost({ tags: topics });
    }

    const onChangeAuthor = (author: string) => {
        onChangePost({ author });
    }

    const onChangeAuthorEmail = (authorEmail: string) => {
        onChangePost({ authorEmail });
    }

    const onChangeFileAttachments = (fileAttachments: AttachedFile[]) => {
        onChangePost({ fileAttachments });
    }

    const onChangeBreakingTime = (breakingTime: string | null) => {
        onChangePost({ breakingTime: !!breakingTime ? new Date(breakingTime) : null });
    }

    const onChangeCommentingEnabled = (commentingEnabled: boolean) => {
        onChangePost({ commentingEnabled });
    }

    const onChangeCommentingType = (commentingType: CommentingTypes) => {
        onChangePost({ commentingType });
    }

    const onChangeFeaturedTime = (featuredTime: string | null) => {
        onChangePost({ featuredTime: !!featuredTime ? new Date(featuredTime) : null });
    }

    const onChangeExpiryTime = (expiryTime: string | undefined | null) => {
        let newExpiryTime = expiryTime ? new Date(expiryTime) : undefined;

        if (post.publishTime && !post.expiryTime && newExpiryTime && newExpiryTime < post.publishTime) {
            newExpiryTime.setDate(post.publishTime.getDate() + 1);
            newExpiryTime.setHours(post.publishTime.getHours())
        }
        else if (!post.expiryTime && newExpiryTime) newExpiryTime.setHours(8);

        onChangePost({ expiryTime: newExpiryTime });
    }

    const onChangePublishTime = (publishTime: string | undefined | null) => {
        let newDateTime = publishTime ? new Date(publishTime) : undefined;
        if (!post.publishTime) newDateTime?.setHours(8);
        onChangePost({ publishTime: newDateTime });
    }

    const onChangeReactingEnabled = (reactingEnabled: boolean) => {
        onChangePost({ reactingEnabled });
    }

    const onChangeNotifications = (notifications: INotifications) => {
        onChangePost({ notifications });
    }

    const onSetReminders = (checked: boolean) => {
        let currNotifications = post.notifications || {} as INotifications;

        if (!checked) {
            onChangePost({
                notifications: {
                    ...currNotifications,
                    reminders: []
                }
            });
        } else {
            const remindersDefined = Boolean(tenantNotificationSettings.defaultPostSettings?.reminders?.length);

            onChangePost({
                notifications: {
                    ...currNotifications,
                    reminders: remindersDefined && tenantNotificationSettings.defaultPostSettings?.reminders?.length! > 0
                        ? tenantNotificationSettings.defaultPostSettings!.reminders
                        : [{
                            channel: "inherit",
                            delay: tenantNotificationSettings.defaultPostReminder || 0,
                            range: "subscribers"
                        }]
                }
            });
        }
    }

    /*
    * Sets the notifications of the post when toggled.
    */
    const onSetNotifications = (checked: boolean, currToggleVal: boolean) => {
        let currNotifications = post.notifications || ({} as INotifications);

        if (checked) {
            setPreviousPostNotifications(currToggleVal);
        } else {
            onChangePost({
                notifications: {
                    ...currNotifications,
                    emailOnPublish: "none",
                    mobileOnPublish: "none",
                    smsOnPublish: "none",
                    teamsOnPublish: "none",
                },
            });
        }
    };

    /*
    * Try to set the previous notifications of a post, if not they will be none or reverted to default.
    */
    const setPreviousPostNotifications = (currToggleVal: boolean) => {
        let currNotifications = post.notifications || ({} as INotifications);

        //If true, sets toggles to the previous published post details or else none. If not true, set's the default from the tenantConfig
        if ((post.isPreviouslyPublished && post.publishTime && new Date(post.publishTime).toISOString() <= new Date().toISOString()) ||
            originalPostNotifications.current) {
            if (currToggleVal) {
                onChangePost({
                    notifications: {
                        ...currNotifications,
                        emailOnPublish: post.notifications?.emailOnPublish || "none",
                        mobileOnPublish: post.notifications?.mobileOnPublish || "none",
                        smsOnPublish: post.notifications?.smsOnPublish || "none",
                        teamsOnPublish: post.notifications?.teamsOnPublish || "none"
                    },
                });
            } 
            else {
                onChangePost({
                    notifications: {
                        ...currNotifications,
                        emailOnPublish: getDefaultNotificationSetting(NotificationEvents.PostPublishedEmail, tenantNotificationSettings),
                        mobileOnPublish: getDefaultNotificationSetting(NotificationEvents.PostPublishedMobile, tenantNotificationSettings),
                        smsOnPublish: getDefaultNotificationSetting(NotificationEvents.PostPublishedSMS, tenantNotificationSettings),
                        teamsOnPublish: getDefaultNotificationSetting(NotificationEvents.PostPublishedTeams, tenantNotificationSettings)
                    }
                });
            }
        }
    };

    /**
     * Get the english content that was analyzed
     */
    const getAnalysisContent = (): string | undefined => {
        let content: string | undefined = undefined;

        if (post.translatedContent && lcidMappings[activeLcid].translateAs === LANGUAGE_CODES.en)
            content = post.translatedContent[activeLcid].body;

        return content;
    }

    const getTabLabel = (isAnalysis: boolean, label: string): JSX.Element => (<span>
        {isAnalysis && analysisIndicator ? <FiberManualRecordIcon style={{ width: 8, height: 8, marginRight: 1 }} htmlColor="#b72026" /> : undefined}
        {label}
    </span>)

    const getTab = (isAnalysis: boolean, tab: ITab): JSX.Element => (
        <Tab
            ref={(element) => {
                if (isAnalysis) setAnalysesTab(element);
            }}
            classes={{
                iconWrapper: isAnalysis ? "tab-label-icon" : "",
                labelIcon: isAnalysis ? "tab-label-icon-container" : ""
            }}
            className={`tab-label ${tab.value === selectedTab ? "selected" : ""}`}
            key={`${tab.label}-${tab.value}`}
            label={getTabLabel(isAnalysis, tab.label)}
            wrapped
        />
    );

    const onChangeCalloutChecked = (checked: boolean) => {
        setShowContentAnalysesCalloutChecked(checked);
    }

    /**
     * Close call out and save show content analyses setting
     */
    const onCloseCallout = async () => {
        setCalloutOpen(false);

        // check if api call is neccessary
        // note: the checkbox label language is negative ("Don't show me again") but we save in the positive ("settings.showCallout")
        // so this check should be not equal
        if (currentUser.showContentAnalysesCallout !== showContentAnalysesCalloutChecked)
            return;

        let newSettings = { ...currentUser, showContentAnalysesCallout: !showContentAnalysesCalloutChecked };

        await saveUserSettings(newSettings);
    }

    /**
     * Logic to show or not show content analyses tab
     * - dont show on small screens because by default on small screens settings pannel is closed
     * - dont show if user has previously checked "Dont show again"
     * @returns true to show callout false otherwise
     */
    const showCallout = (): boolean => calloutOpen && currentUser.showContentAnalysesCallout && !isSmallAndSmaller;

    return (
        <AuthoringDrawer
            onBookmarkClick={onBookmarkClick}
            {...drawerProps}
        >
            {/* progress bar and checklist */}
            <div className="settings-padding">
                <div className="progress-section">
                    <ProgressBar validationChecks={validationChecks.checks} />
                    <div className="static-progress-list">
                        {validationChecks.checks.map(({ skip, key, isValid, description }) => {
                            let listItem: JSX.Element;
                            if (skip)
                                listItem = <React.Fragment key={key}></React.Fragment>;
                            else
                                listItem = (
                                    <div key={key} className={`progress-list-item ${isValid ? "item-valid" : ""}`}>
                                        <div className="item-valid-indicator">
                                            <div className="item-valid-indicator-icon"></div>
                                        </div>
                                        <div className="item-description">{description}</div>
                                    </div>
                                );

                            return listItem;
                        })}
                    </div>
                </div>
            </div>
            {/* tabs */}
            <Box className="tabs-container settings-padding" style={{ paddingTop: 0 }}>
                <Tabs
                    scrollButtons={isSmallAndSmaller ? "auto" : false}
                    className="tabs"
                    value={selectedTab}
                    onChange={onChangeTab}
                    indicatorColor="primary"
                    variant="fullWidth"
                >
                    {TABS.map((tab: ITab) => getTab(tab.value === CONTENT_ANALYSES, tab))}
                </Tabs>
            </Box>
            <div className="tab-panel" hidden={selectedTab !== POST_DETAILS} style={{ height: "100%" }}>
                <DetailsEditor
                    post={post}
                    hasPublished={hasPublished}
                    onChangeAttachedContent={onChangeAttachedContent}
                    onChangeImage={onChangeImage}
                    onChangePostType={onChangePostType}
                    onChangeTopics={onChangeTopics}
                    onChangeAuthor={onChangeAuthor}
                    onChangeAuthorEmail={onChangeAuthorEmail}
                    onChangeFileAttachements={onChangeFileAttachments}
                    isContributor={isContributor}
                />
            </div>
            <div className="tab-panel" hidden={selectedTab !== POST_OPTIONS} style={{ height: "100%" }}>
                <OptionsEditor
                    post={post}
                    reminderNotificationsEnabled={reminderNotificationsEnabled}
                    notificationsEnabled={notificationsEnabled}
                    previousNotifications={originalPostNotifications.current}
                    reactionsEnabled={reactionsEnabled}
                    commentsEnabled={commentsEnabled}
                    canContributorsToggleReactions={canContributorsToggleReactions}
                    canContributorsToggleComments={canContributorsToggleComments}
                    onChangeNotifications={onChangeNotifications}
                    onChangeFeaturedTime={onChangeFeaturedTime}
                    onChangePublishTime={onChangePublishTime}
                    onChangeBreakingTime={onChangeBreakingTime}
                    onChangeExpiryTime={onChangeExpiryTime}
                    onChangeReactionsEnabled={onChangeReactingEnabled}
                    onChangeCommentingEnabled={onChangeCommentingEnabled}
                    onChangeCommentingType={onChangeCommentingType}
                    onToggleReminderSettings={onSetReminders}
                    onToggleNotifications={onSetNotifications}
                    isContributor={isContributor}
                />
            </div>
            <div className="tab-panel" hidden={selectedTab !== CONTENT_ANALYSES} style={{ height: "100%" }}>
                {/* Loads near instantly but won't work with a ! */}
                {tenantAverages && globalAverages &&
                    <ContentAnalyses
                        analyze={analyze}
                        highlightsOn={highlightsOn}
                        fetching={fetchingAnalyses}
                        content={getAnalysisContent()}
                        contentAnalyses={contentAnalyses}
                        toggleHighlights={toggleHighlights}
                        tenantAverages={tenantAverages}
                        globalAverages={globalAverages}
                        isUsingTenantAverages={isUsingTenantAverages}
                        setIsUsingTenantAverages={setIsUsingTenantAverages}
                    />
                }
            </div>
            {showCallout() &&
                <ContentAnalysesCallout
                    checked={showContentAnalysesCalloutChecked}
                    onChange={onChangeCalloutChecked}
                    anchorEl={calloutOpen ? analysesTab : null}
                    onClose={onCloseCallout}
                />}
        </AuthoringDrawer>
    );
}

export default PostSettingsPanel;
