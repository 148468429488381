import React, { useEffect, useState } from "react";
import Tabs from "pages/common/tabs";
import EmailsList from "./emailsList";
import { DEFAULT_DRAFT_EMAIL_FILTERS, DEFAULT_SCHEDULED_EMAIL_FILTERS, DEFAULT_SENT_EMAIL_FILTERS, EmailStatus, IEmailFilters, IEmailListingPage } from "../models";
import { useDispatch, useSelector } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { getDraftEmailList, getScheduledEmailList, getSentEmailList } from "../reducer";
import cookie, { ROWS_PER_PAGE_COOKIE_NAMES } from "utils/cookie";
import CreatedByFilter, { CreatedBySelectValues } from "modules/common/components/authoring/createdByFilter";
import { ContentType } from "modules/common/models";

import "../../common/components/authoring/styles/authoringListing.sass";

interface IEmailsListingProps {
    onDownloadEmailActivity: (emailId: string) => void;
    activeLcid: string;
}

const EmailsListing: React.FC<IEmailsListingProps> = ({
    onDownloadEmailActivity,
    activeLcid
}) => {
    const [selectedTab, setSelectedTab] = useState(EmailStatus.Draft);
    const [draftEmailListFilters, setDraftEmailListFilters] = useState(DEFAULT_DRAFT_EMAIL_FILTERS);
    const [scheduledEmailListFilters, setScheduledEmailListFilters] = useState(DEFAULT_SCHEDULED_EMAIL_FILTERS);
    const [sentEmailListFilters, setSentEmailListFilters] = useState(DEFAULT_SENT_EMAIL_FILTERS);
    const currentFilters = selectedTab === EmailStatus.Draft 
        ? draftEmailListFilters 
        : selectedTab === EmailStatus.Scheduled 
            ? scheduledEmailListFilters 
            : sentEmailListFilters;

    const { draftEmailsList, scheduledEmailsList, sentEmailsList } = useSelector((state: GlobalApplicationState) => state.emails);
    const dispatch = useDispatch();

    const getTabLabel = (label: string, page: IEmailListingPage): string => {
        if (!!page.currentPage)
            return `${label} (${page.totalEmails})`;
        return label;
    }

    const fetchDraftEmailList = (
        filters: IEmailFilters = DEFAULT_DRAFT_EMAIL_FILTERS,
        pageNumber: number = 1, 
        pageAmount: number = cookie.getRowsPerPage(ROWS_PER_PAGE_COOKIE_NAMES.EMAILS, 25)
    ) => 
        dispatch(getDraftEmailList({ pageNumber, pageAmount, filters }));

    const fetchScheduledEmailList = (
        filters: IEmailFilters = DEFAULT_SCHEDULED_EMAIL_FILTERS,
        pageNumber: number = 1, 
        pageAmount: number = cookie.getRowsPerPage(ROWS_PER_PAGE_COOKIE_NAMES.EMAILS, 25)
    ) => 
        dispatch(getScheduledEmailList({ pageNumber, pageAmount, filters }));

    const fetchSentEmailList = (
        filters: IEmailFilters = DEFAULT_SENT_EMAIL_FILTERS,
        pageNumber: number = 1, 
        pageAmount: number = cookie.getRowsPerPage(ROWS_PER_PAGE_COOKIE_NAMES.EMAILS, 25)
    ) => 
        dispatch(getSentEmailList({ pageNumber, pageAmount, filters }));


    const fetchAllPages = () => {
        fetchDraftEmailList(draftEmailListFilters)
        fetchScheduledEmailList(scheduledEmailListFilters);
        fetchSentEmailList(sentEmailListFilters);
    }

    useEffect(() => {
        fetchAllPages();
    }, []);

    const onSelectPostCreatedBy = (selection: CreatedBySelectValues) => {
        if (selectedTab === EmailStatus.Draft) {
            const newFilters = { ...draftEmailListFilters, createdByMeOnly: selection === CreatedBySelectValues.VIEW_MY };
            setDraftEmailListFilters(newFilters);
            fetchDraftEmailList(newFilters);
        }
        else if (selectedTab === EmailStatus.Scheduled) {
            const newFilters = { ...scheduledEmailListFilters, createdByMeOnly: selection === CreatedBySelectValues.VIEW_MY };
            setScheduledEmailListFilters(newFilters);
            fetchScheduledEmailList(newFilters);
        }
        else if (selectedTab === EmailStatus.Sent) {
            const newFilters = { ...sentEmailListFilters, createdByMeOnly: selection === CreatedBySelectValues.VIEW_MY };
            setSentEmailListFilters(newFilters);
            fetchSentEmailList(newFilters);
        }
    }

    return <div className="emails-listing">
        <div className="authoring-tabs-container">
            <Tabs
                tabs={[
                    { label: getTabLabel("Drafts", draftEmailsList), dotColor: "grey", value: EmailStatus.Draft },
                    { label: getTabLabel("Scheduled", scheduledEmailsList), dotColor: "yellow", value: EmailStatus.Scheduled },
                    { label: getTabLabel("Sent", sentEmailsList), dotColor: "green", value: EmailStatus.Sent }
                ]}
                selectedTab={selectedTab}
                onSelectTab={newTab => setSelectedTab(newTab)}
            />
            <CreatedByFilter
                selectedFilter={currentFilters.createdByMeOnly ? CreatedBySelectValues.VIEW_MY : CreatedBySelectValues.VIEW_ALL}
                content={ContentType.Email}
                onSelect={onSelectPostCreatedBy}
            />
        </div>
        <EmailsList 
            show={selectedTab === EmailStatus.Draft} 
            page={draftEmailsList}
            fetchPage={fetchDraftEmailList}
            fetchAllPages={fetchAllPages}
            filters={draftEmailListFilters}
            onUpdateFilters={setDraftEmailListFilters}
            defaultEmailFilterValues={DEFAULT_DRAFT_EMAIL_FILTERS}
            onDownloadEmailActivity={onDownloadEmailActivity}
            activeLcid={activeLcid}
        />
        <EmailsList 
            show={selectedTab === EmailStatus.Scheduled} 
            page={scheduledEmailsList}
            fetchPage={fetchScheduledEmailList}
            fetchAllPages={fetchAllPages}
            filters={scheduledEmailListFilters}
            onUpdateFilters={setScheduledEmailListFilters}
            defaultEmailFilterValues={DEFAULT_SCHEDULED_EMAIL_FILTERS}
            onDownloadEmailActivity={onDownloadEmailActivity}
            activeLcid={activeLcid}
        />
        <EmailsList 
            show={selectedTab === EmailStatus.Sent}
            page={sentEmailsList}
            fetchPage={fetchSentEmailList}
            fetchAllPages={fetchAllPages}
            filters={sentEmailListFilters}
            onUpdateFilters={setSentEmailListFilters}
            defaultEmailFilterValues={DEFAULT_SENT_EMAIL_FILTERS}
            onDownloadEmailActivity={onDownloadEmailActivity}
            activeLcid={activeLcid}
        />
    </div>;
};

export default EmailsListing;
