import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import EventTypeBanner from "modules/common/components/banners/eventTypeBanner";

import { EventFeedItem } from "../../../../../models";
import DateBlock from "../../../../date-block/dateBlock";
import { EventInfo } from "../eventInfo";

import "../../../../../../posts/components/post-feed/styles/ReadDate.sass";

const Event: React.FunctionComponent<PropsWithRedux> = props => {
    const { event, onEventSelected } = props;

    if (!event) return (null);

    const selectEvent = () => onEventSelected ? onEventSelected(event.id) : null;

    return (
        <div onClick={selectEvent} className={`event ${props.cardCount ? `event-${props.cardCount}` : ""}`}>
            <div className="image-container">
                <div
                    style={{
                        backgroundColor: event.imageUrl ? "#DDE1E5" : event.bannerColor,
                        backgroundImage: event.imageUrl ? 'url("' + event.imageUrl + '")' : "",
                        backgroundSize: "contain"
                    }}
                    className="image"
                >
                    <EventTypeBanner eventType={event.eventType} isRestricted={event.isRestricted} lcid={event.preferredLCID} />
                    {props.showReadingStatus && !event.lastViewTime && <div className="read-date unread unread-event"> Unread </div>}
                    <DateBlock eventStartTime={event.eventStartTime} eventEndTime={event.eventEndTime} isAllDayEvent={event.isAllDayEvent} />
                </div>
            </div>
            <EventInfo event={event} hideUnreadStatus hideRestrictedIcon hideSummary={props.hideSummary} hideTopics={props.hideTopics} />
        </div>
    );
};

interface ComponentProps {
    event: EventFeedItem;
    onEventSelected: (eventId: string) => void;
    showReadingStatus?: boolean;
    hideSummary?: boolean;
    hideTopics?: boolean;
    cardCount?: number;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        currentUser: state.settings.currentUser
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(Event);