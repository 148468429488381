import React from "react";

import { ICustomWidgetConfig } from "../models/widget";
import { debounce } from "lodash";

import "../styles/customWidget.sass";

const FETCH_WIDGET_TYPE = "SPARROW_FETCH_WIDGET_H";
const UPDATE_WIDGET_TYPE = "SPARROW_UPDATE_WIDGET_H";
const FETCH_REQUEST_WAIT_OFFSET = 1000;

const FETCH_HEIGHT_FTN = `async function (event) {
    if (event.data?.type !== "${FETCH_WIDGET_TYPE}") return;
    
    const delay = ms => new Promise(res => setTimeout(res, ms));

    for (let counter = 0, hprev = 0, h = 0; counter < 20 && (h === 0 || hprev !== h); counter++, hprev=h, h = window.document.body.clientHeight) {
        await delay(500);
    }

    event.source.postMessage(
        {height: window.document.body.clientHeight, type: "${UPDATE_WIDGET_TYPE}"},
        event.origin
    );
}`;

export const CustomWidget: React.FunctionComponent<{ data: ICustomWidgetConfig, index: number }> = (props) => {
    const [height, setHeight] = React.useState<number | undefined>(undefined);
    const iframeRef = React.useRef<HTMLIFrameElement>(null);

    const { htmlInput } = props.data;

    const fetchHeight = React.useRef(
        debounce(async () => {
            const iframe = iframeRef.current;

            if (iframe) {
                const iFrameContent = iframe.contentWindow;
                if (iFrameContent) {
                    iFrameContent.postMessage({ type: FETCH_WIDGET_TYPE }, "*");
                }
            }
        }, FETCH_REQUEST_WAIT_OFFSET)
    ).current;

    React.useEffect(() => {
        fetchHeight();
    }, [iframeRef, htmlInput, fetchHeight]);


    React.useEffect(() => {
        const requestHeightMeasurement = (event) => {
            if (event.data?.type === UPDATE_WIDGET_TYPE) setHeight(event.data?.height);
        };
        window.addEventListener("message", requestHeightMeasurement);

        return () => {
            window.removeEventListener("message", requestHeightMeasurement);
        };
    }, []);

    React.useEffect(() => {
        window.addEventListener("resize", fetchHeight);
        return () => {
            window.removeEventListener("resize", fetchHeight);
        };
    }, [fetchHeight]);

    if (!htmlInput) return <div style={{ width: "100%", height: "100px" }}></div>;

    return (
        <div className="custom-widget-container" style={{ height: height ? `${height}px` : "100px" }}>
            <iframe
                title={`iframe-${props.index}`}
                ref={iframeRef}
                className="widget-content"
                sandbox="allow-scripts allow-forms"
                srcDoc={`<head><meta http-equiv="Content-Security-Policy" content="child-src 'self' https:;"></meta></head><body style='margin: 0'>${htmlInput}</body><script> onmessage = ${FETCH_HEIGHT_FTN}</script>`}
            />
        </div>
    );
};

export const CUSTOM_WIDGET_TEMPLATE = {
    key: "custom-widget-block",
    label: "Custom Widget",
    defaultItem: {
        htmlInput: ""
    },
    fields: [
        {
            name: "htmlInput",
            component: "textarea",
            label: "Insert code",
        },
    ]
};