import { GlobalApplicationState } from "globalApplicationState";
import * as Actions from "./actions";
import API from "api";
import XhrUpload from "utils/xhrUpload";
import MsalAuthModule from "authentication/msalAuthModule";

import { AttachedArticles, Document, DocumentFeed, DocumentFeedFilters, DocumentFeedItem, DocumentFeedLayout, DocumentPreview, DocumentProperties, DocumentsFilterValues, DocumentsListingPage } from "./models";
import { defaultDocumentFeed, defaultListingPage } from "./reducer";
import { CategoryTagModel } from "modules/categorytags";


export const getAttachedArticles = (documentId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<AttachedArticles> => {
  dispatch(Actions.GetAttachedArticles({}));

  return dispatch(API.documents.GetAttachedArticles(documentId))
    .then(response => {
      dispatch(Actions.GetAttachedArticlesComplete({ succeeded: response.status === 200 }));
      return response.json();
    })
    .catch(err => {
      dispatch(Actions.GetAttachedArticlesComplete({ succeeded: false, err }));
      return "";
    });
}

export const getCategoryTagById = (id: string) => (dispatch, getState: () => GlobalApplicationState): Promise<CategoryTagModel> => {
  dispatch(Actions.GetCategoryTagById({}))
  return dispatch(API.categoryTags.GetCategoryTagsById(id))
  .then(response => {
      return response.json();
    })
    .catch(err => {
      dispatch(Actions.GetCategoryTagById({ succeeded: false, err }));
      return false;
  });
}


export const getDocumentDetails = (documentId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<DocumentFeedItem> => {
  dispatch(Actions.GetDocumentDetails({}));

  return dispatch(API.documents.GetDocumentDetails(documentId))
    .then(response => {
      dispatch(Actions.GetDocumentDetailsComplete({ succeeded: response.status === 200 }));
      return response.json();
    })
    .catch(err => {
      dispatch(Actions.GetDocumentDetailsComplete({ succeeded: false, err }));
      return;
    });
}

export const getDocumentFeed = (pageNumber: number, filters: Partial<DocumentFeedFilters>, hasFiltersApplied?: boolean) => (dispatch, getState: () => GlobalApplicationState): Promise<DocumentFeed> => {
  dispatch(Actions.GetDocumentFeed({ filters, hasFiltersApplied }));

  return dispatch(API.documents.GetDocumentFeed(pageNumber, filters))
    .then(response => response.json())
    .then(documentFeed => {
      dispatch(Actions.GetDocumentFeedComplete({ succeeded: true, documentFeed }));
      return documentFeed;
    })
    .catch(err => {
      dispatch(Actions.GetDocumentFeedComplete({ succeeded: false, err, documentFeed: defaultDocumentFeed }));
      return;
    });
}

export const getDocumentFeedLocal = (pageNumber: number, filters: Partial<DocumentFeedFilters>, maxResults: number) => (dispatch, _: () => GlobalApplicationState): Promise<DocumentFeedItem[]> => {
    return dispatch(API.documents.GetDocumentFeed(pageNumber, filters, maxResults))
    .then(response => response.json())
    .then(documentFeed => documentFeed.documents)
    .catch(err => { return; });
}

export const getDocumentProperties = (documentId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<Document> => {
  dispatch(Actions.GetDocumentProperties({}));

  return dispatch(API.documents.GetDocumentProperties(documentId))
    .then(response => {
      dispatch(Actions.GetDocumentPropertiesComplete({ succeeded: response.status === 200 }));
      return response.json();
    })
    .catch(err => {
      dispatch(Actions.GetDocumentPropertiesComplete({ succeeded: false, err }));
      return;
    });
}

export const getDocuments = (pageNumber: number, filters: Partial<DocumentsFilterValues>, pageAmount?: number) => (dispatch, getState: () => GlobalApplicationState): Promise<DocumentsListingPage> => {
  dispatch(Actions.GetDocuments({}));

  return dispatch(API.documents.GetDocuments(pageNumber, filters, pageAmount))
    .then(response => response.json())
    .then(page => {
      dispatch(Actions.GetDocumentsComplete({ succeeded: true, page }));
      return page;
    })
    .catch(err => {
      dispatch(Actions.GetDocumentsComplete({ succeeded: false, page: defaultListingPage, err }));
      return;
    });
}

export const getDisabledDocuments = (pageNumber: number, filters: Partial<DocumentsFilterValues>, pageAmount?: number) => (dispatch, getState: () => GlobalApplicationState): Promise<DocumentsListingPage> => {
  dispatch(Actions.GetDisabledDocuments({}));
  
  return dispatch(API.documents.GetDisabledDocuments(pageNumber, filters, pageAmount))
    .then(response => response.json())
    .then(page => {
      dispatch(Actions.GetDisabledDocumentsComplete({ succeeded: true, page }));
      return page;
    })
    .catch(err => {
      dispatch(Actions.GetDisabledDocumentsComplete({ succeeded: false, page: defaultListingPage, err }));
      return;
    });
}

export const getEnabledDocuments = (pageNumber: number, filters: Partial<DocumentsFilterValues>, pageAmount?: number) => (dispatch, getState: () => GlobalApplicationState): Promise<DocumentsListingPage> => {
  dispatch(Actions.GetEnabledDocuments({}));

  return dispatch(API.documents.GetEnabledDocuments(pageNumber, filters))
    .then(response => response.json())
    .then(page => {
      dispatch(Actions.GetEnabledDocumentsComplete({ succeeded: true, page }));
      return page;
    })
    .catch(err => {
      dispatch(Actions.GetEnabledDocumentsComplete({ succeeded: false, page: defaultListingPage, err }));
      return;
    });
}

export const getScheduledDocuments = (pageNumber: number, filters: Partial<DocumentsFilterValues>, pageAmount?: number) => (dispatch, getState: () => GlobalApplicationState): Promise<DocumentsListingPage> => {
  dispatch(Actions.GetScheduledDocuments({}));

  return dispatch(API.documents.GetScheduledDocuments(pageNumber, filters))
    .then(response => response.json())
    .then(page => {
      dispatch(Actions.GetScheduledDocumentsComplete({ succeeded: true, page }));
      return page;
    })
    .catch(err => {
      dispatch(Actions.GetScheduledDocumentsComplete({ succeeded: false, page: defaultListingPage, err }));
      return;
    });
}


export const hideDocumentFeedFilters = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.HideDocumentFeedFilters({}));
}

export const hideEditor = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.HideEditor({}));
}

export const hidePreview = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.HidePreview({}));
}

export const showDocumentFeedFilters = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ShowDocumentFeedFilters({}));
}

export const showEditor = (document: Document) => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ShowEditor({ document }));
}

export const showPreview = (document: DocumentPreview) => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ShowPreview({ document }));
}


export const setDocumentFeedFilters = (filters: Partial<DocumentFeedFilters>) => (dispatch, getState: () => GlobalApplicationState) => {
  dispatch(Actions.ClearDocumentFeed({}))
  dispatch(Actions.SetDocumentFeedFilters({ filters }));
}

export const setDocumentFeedLayout = (layout: DocumentFeedLayout) => (dispatch, getState: () => GlobalApplicationState) => {
  dispatch(Actions.SetDocumentFeedLayout({ layout }));
}


export const deleteDocument = (documentId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.DeleteDocument({}));

  return dispatch(API.documents.DeleteDocument(documentId))
    .then(response => {
        if (response.status === 200) {
          dispatch(Actions.ClearDocumentFeed({}));
          dispatch(Actions.ClearDocumentFeedFilters({}));
        }
        
      dispatch(Actions.DeleteDocumentComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.DeleteDocumentComplete({ succeeded: false, err }));
      return false;
    });
}

export const deleteDocuments = (documentIds: string[]) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.DeleteDocuments({}));

  return Promise.all(documentIds.map(documentId => dispatch(API.documents.DeleteDocument(documentId))))
    .then(() => {
      dispatch(Actions.DeleteDocumentsComplete({ succeeded: true }));
      return true;
    })
    .catch(err => {
      dispatch(Actions.DeleteDocumentsComplete({ succeeded: false, err }));
      return false;
    });
}

export const disableDocument = (documentId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.DisableDocument({}));

  return dispatch(API.documents.DisableDocument(documentId))
    .then(response => {
      if (response.status === 200) {
        dispatch(Actions.ClearDocumentFeed({}));
        dispatch(Actions.ClearDocumentFeedFilters({}));
      }

      dispatch(Actions.DisableDocumentComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.DisableDocumentComplete({ succeeded: false, err }));
      return false;
    });
}

export const enableDocument = (documentId: string) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.EnableDocument({}));

  return dispatch(API.documents.EnableDocument(documentId))
    .then(response => {
      if (response.status === 200) {
        dispatch(Actions.ClearDocumentFeed({}));
        dispatch(Actions.ClearDocumentFeedFilters({}));
      }
      
      dispatch(Actions.EnableDocumentComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.EnableDocumentComplete({ succeeded: false, err }));
      return false;
    });
}

export const replaceFile = (documentId: string, file: File, onProgress, onLoad, onError, onAbort) => (dispatch, getState: () => GlobalApplicationState): Promise<Document> => {
  dispatch(Actions.ReplaceFile({}));

  return MsalAuthModule.getInstance().getAccessToken().then(accessToken => {
    const requestDetails = API.documents.ReplaceFile(documentId, file);
    const url = getState().config.SparrowClientApiUrl + requestDetails.url
      .replace("{tenant}", getState().tenant.id);

    const handlers = { onLoad, onAbort, onError, onProgress };

    return new XhrUpload(url, requestDetails, handlers)
      .authenticateWith(accessToken)
      .upload();
  })
  .then((response: string) => {
    dispatch(Actions.ClearDocumentFeed({}));
    dispatch(Actions.ClearDocumentFeedFilters({}));

    const document = JSON.parse(response);
    dispatch(Actions.ReplaceFileComplete({ succeeded: true, document }));
    return document;
  })
  .catch(err => {
    dispatch(Actions.ReplaceFileComplete({ succeeded: false, err }));
    return false;
  });
}

export const updateDocumentFeed = (documentFeed: DocumentFeedItem[]) => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.UpdateDocumentFeed({ documentFeed }));
}

export const updateDocumentProperties = (documentId: string, properties: Partial<DocumentProperties>) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.UpdateDocumentProperties({}));

  return dispatch(API.documents.UpdateDocumentProperties(documentId, properties))
    .then(response => {
      if (response.status === 200) {
        dispatch(Actions.ClearDocumentFeed({}));
        dispatch(Actions.ClearDocumentFeedFilters({}));
      }
      
      dispatch(Actions.UpdateDocumentPropertiesComplete({ succeeded: response.status === 200 }));
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.UpdateDocumentPropertiesComplete({ succeeded: false, err }));
      return false;
    });
}

export const uploadFile = (file: File, onProgress, onLoad, onError, onAbort) => (dispatch, getState: () => GlobalApplicationState): Promise<Document> => {
  dispatch(Actions.UploadFile({}));

  return MsalAuthModule.getInstance().getAccessToken().then(accessToken => {
    const requestDetails = API.documents.UploadFile(file);
    const url = getState().config.SparrowClientApiUrl + requestDetails.url
      .replace("{tenant}", getState().tenant.id);

    const handlers = { onLoad, onAbort, onError, onProgress };

    return new XhrUpload(url, requestDetails, handlers)
      .authenticateWith(accessToken)
      .upload();
  })
  .then((response: string) => {
    dispatch(Actions.ClearDocumentFeed({}));
    dispatch(Actions.ClearDocumentFeedFilters({}));
    
    dispatch(Actions.UploadFileComplete({ succeeded: true }));
    return JSON.parse(response);
  })
  .catch(err => {
    dispatch(Actions.UploadFileComplete({ succeeded: false, err }));
    return false;
  });
}


export const hasChangedSinceSaved = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ChangedSinceSaved({}));
}

export const clearChangedSinceSaved = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ClearChangedSinceSaved({}));
}

export const clearDocumentFeed = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ClearDocumentFeed({}));
}

export const clearDocumentFeedFilters = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ClearDocumentFeedFilters({}));
}

export const clearErrorMessage = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ClearErrorMessage({}));
}

export const clearShouldFetch = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ClearShouldFetch({}));
}

export const clearShowPublishSuccess = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ClearShowPublishSuccess({}));
}

export const clearSuccessMessage = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ClearSuccessMessage({}));
}