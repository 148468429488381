import { Dispatch, SetStateAction, useState } from "react";
import { DEFAULT_TRANSLATABLE_CONTENT, TranslatableContent, TranslatableNewsletterContent, TranslatedNewsletterContent } from "../components/authoring/models";
import { TranslatedContent } from "modules/posts/models";

/**
 * Encapsulate accordion logic for analytics
 * @param {
 *   translations: any; //Intended to be the [lcid]: {content} objects, but may be different depending on what is calling the hook.
 *   defaultContent: any; //Same as above.
 *   onChangeTranslatedContent: (toSet: any) => void;
 *   translationHasContent: (lcid: string) => boolean;
 *   onTranslate: (any) => void;
 *   onSelectLanguage: (key: string) => void;
 *   onRemoveLanguage: (key: string) => void;
 * }
 * @returns {
 *   showTranslationDialog: boolean;
 *   removeLanguage: string | undefined;
 *   setRemoveLanguage: Dispatch<SetStateAction<string | undefined>>;
 *   onRemoveTranslation: (item: Record<string, string>) => void;
 *   onSelectTranslation: (item: Record<string, string>) => void;
 *   onShowTranslationDialog: () => void
 *   onCloseTranslationDialog: () => void;
 *   onConfirmDialog: (inputLanguage: string) => void;
 *   onConfirmRemoveLanguage: (lcidToRemove?: string) => void;
 * }
 */

export type TranslatedContentProps = {
    translations: TranslatedNewsletterContent | TranslatedContent; //Intended to be the [lcid]: {content} objects, but may be different depending on what is calling the hook.
    defaultContent: TranslatableNewsletterContent | TranslatableContent; //Same as above.
    onChangeTranslatedContent: (toSet: any) => void;
    translationHasContent: (lcid: string) => boolean | undefined;
    onTranslate: (any) => void;
    onSelectLanguage: (key: string) => void;
    onRemoveLanguage: (key: string) => void;
}

export type TranslatedContentStatus = {
    showTranslationDialog: boolean;
    removeLanguage: string | undefined;
    setRemoveLanguage: Dispatch<SetStateAction<string | undefined>>;
    onRemoveTranslation: (item: Record<string, string>) => void;
    onSelectTranslation: (item: Record<string, string>) => void;
    onShowTranslationDialog: () => void;
    onCloseTranslationDialog: () => void;
    onConfirmDialog: (inputLanguage: string) => void;
    onConfirmRemoveLanguage: (lcidToRemove?: string) => void;
}

const useTranslatedContent = (props: TranslatedContentProps): TranslatedContentStatus => {
    const [showTranslationDialog, setShowTranslationDialog] = useState(false);
    const [removeLanguage, setRemoveLanguage] = useState<string | undefined>();

    /**
     * Check if a translation has content
     * - if it does, show confirm dialog
     * - otherwise, continue remove
     */
    const onRemoveTranslation = (item: Record<string, string>) => {
        if (props.translationHasContent(item.key)) {
            setRemoveLanguage(item.key);
        } else {
            onConfirmRemoveLanguage(item.key);
        }
    };

    /**
     * Select a translation
     * - if translation does not exist, add it
     */
    const onSelectTranslation = (item: Record<string, string>) => {
        props.onSelectLanguage(item.key);

        // add to translations
        let newTranslations = { ...props.translations };

        if (!Object.keys(newTranslations).includes(item.key)) {
            newTranslations[item.key] = DEFAULT_TRANSLATABLE_CONTENT;
            props.onChangeTranslatedContent(newTranslations);
        }
    };

    const removeTranslation = (lcid: string) => {
        // remove from translations
        let newTranslations = { ...props.translations };
        if (Object.keys(newTranslations).includes(lcid)) {
            delete newTranslations[lcid];
            props.onChangeTranslatedContent(newTranslations);
        }
    };

    /**
     * Handle showing the translation dialog
     */
    const onShowTranslationDialog = (): void => {
        setShowTranslationDialog(true);
    };

    /**
     * Handle closing the translation dialog
     */
    const onCloseTranslationDialog = () => {
        setShowTranslationDialog(false);
    };

    /**
     * Handle dialog selection for the translation dialog
     */
    const onConfirmDialog = (inputLanguage: string) => {
        setShowTranslationDialog(false);
        props.onTranslate(inputLanguage);
    };

    /**
     * Handle confirm remove language or remove language with no content
     * @param lcidToRemove - used when no content (no confirm dialog)
     */
    const onConfirmRemoveLanguage = (lcidToRemove?: string) => {
        lcidToRemove = lcidToRemove || removeLanguage;

        if (lcidToRemove) {
            props.onRemoveLanguage(lcidToRemove); // prop function
            removeTranslation(lcidToRemove); // internal function
            setRemoveLanguage(undefined);
        }
    }

    return {
        showTranslationDialog,
        removeLanguage,
        setRemoveLanguage,
        onRemoveTranslation,
        onSelectTranslation,
        onShowTranslationDialog,
        onCloseTranslationDialog,
        onConfirmDialog,
        onConfirmRemoveLanguage
    }
}

export default useTranslatedContent;
