import React from "react";
import moment from "moment";

import { DocumentFeedItem } from "../../../../../models";
import { DocumentActionButtons } from "../../documentActionButtons";
import { useDefaultLocale } from "modules/common/hooks/useDefaultLocale";

const Document: React.FunctionComponent<ComponentProps> = props => {
  useDefaultLocale();

  const { document } = props;

  return (
    <div
      onClick={async () => await props.onDocumentSelected(document)}
      className="document"
    >
      <div className="details">
        {document.isNew
          ? <div className="new">New</div>
          : document.isUpdated &&
            <div className="updated">Updated</div>
        }
        <img src={`/images/icons/documents/${document.fileType}.png`} alt={document.fileType} className="file-type" />
        <div title={document.title || `${document.fileName}.${document.fileType}`} className="title">{document.title || `${document.fileName}.${document.fileType}`}</div>
        {!!document.tags.length &&
          <div>Category tags: {document.tags.slice(0, 3).map(tag => tag.name).join(", ")}{document.tags.length > 3 ? `, ... +${document.tags.length - 3}` : ""}</div>
        }
      </div>
      <div className="footer">
        {document.isUnread
          ? <div className="unread">Unread</div>
          : <div className="read">Read {moment(document.lastReadDate).fromNow()}</div>
        }
        <div onClick={(ev) => ev.stopPropagation()} className="footer-options">
          <DocumentActionButtons
            document={document}
            onDownloadDocument={props.onDownloadDocument}
            onInspectDocument={props.onInspectDocument}
          />
        </div>
      </div>
    </div>
  );
}

interface ComponentProps {
  document: DocumentFeedItem;
  onDocumentSelected: (document: DocumentFeedItem) => Promise<void>;
  onDownloadDocument: (document: DocumentFeedItem) => Promise<void>;
  onInspectDocument: (document: DocumentFeedItem) => void;
}

export default Document;