import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import LoadingOverlay from "modules/common/components/loadingOverlay";

import DocumentPreviewInfo from "./components/documentPreviewInfo";
import DocumentPreviewMoreInfo from "./components/documentPreviewMoreInfo";
import PreviewOptions from "../action-buttons/previewOptions";

import { getMimeType } from "../../utilities/getMimeType";

import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Snackbar from "@mui/material/Snackbar";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

import Alert from '@mui/material/Alert';


import { FileDownloader } from "utils/fileDownloader";
import { DialogContent, DialogTitle } from "@mui/material";

import "../../styles/documentViews.sass";

class DocumentPreview extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      showMoreInfo: false
    };
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.showPreview && !prevProps.showPreview)
      this.setState({ showMoreInfo: false });
  }

  public render() {
    if (!this.props.preview || !this.props.preview.fileUrl)
      return <React.Fragment></React.Fragment>;

    return (
      <Dialog
        open={this.props.showPreview}
        onClose={this.onClose}
        maxWidth="md"
        classes={{ paper: `document-preview-paper ${this.props.preview.fileType === "pdf" || this.props.preview.fileType === "txt" ? " document-preview-full" : ""}`}}
        className="document-preview"
        scroll="paper"
        fullWidth
      >
        <DialogTitle>
          <div className="action-section">
            <PreviewOptions preview={this.props.preview} onSuccess={this.props.onSuccess} />
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveAltIcon />}
              onClick={this.onDownload}
              style={{ marginLeft: "10px" }}
            > Download file</Button>
            
            <IconButton onClick={this.onClose} size="large"> <CloseIcon /> </IconButton>
          </div>
        </DialogTitle>

        {
          this.props.preview.fileType === "pdf" || this.props.preview.fileType === "txt"
          ? <object
            data={`${this.props.preview.fileUrl}#toolbar=0`}
            type={getMimeType(this.props.preview.fileType)}
            height="100%"
            width="100%"
          >
            <div>No online PDF viewer installed</div>
          </object>
          : <div className="preview-screen">
            <div>
              <img src={this.props.preview.fileUrl} alt="document preview" />
            </div>
          </div>
        }

        <DialogContent className="document-preview-content">
          <Collapse in={!this.state.showMoreInfo}>
            <DocumentPreviewInfo preview={this.props.preview} />
            <Link component="button" variant="body2" underline="always" onClick={this.onShowMoreInfo} className="more-info-link">More info</Link>
          </Collapse>

          <Collapse in={this.state.showMoreInfo} className="document-info">
            <div className="document-info-header">
              <IconButton onClick={this.onHideMoreInfo} size="large">
                <ArrowBackIcon />
              </IconButton>
              <div>More info</div>
            </div>
            <DocumentPreviewMoreInfo preview={this.props.preview} />
          </Collapse>
        </DialogContent>

        <Snackbar open={!!this.props.errorMessage} anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={6000} onClose={this.props.clearErrorMessage}>
          <Alert variant="filled" severity="error" elevation={6} onClose={this.props.clearErrorMessage}>{this.props.errorMessage}</Alert>
        </Snackbar>
        <LoadingOverlay show={this.props.isSaving || this.props.isUploading} styles={{ paddingTop: 150 }} />
      </Dialog>
    );
  }

  private onClose = () => {
    this.props.hidePreview();
  }

  private onDownload = () => {
    const { preview } = this.props;
    if (!preview) return;

    const file = {
      name: `${preview.fileName}.${preview.fileType}`
    };
    new FileDownloader(file).downloadProvided(preview.fileUrl);
  }

  private onHideMoreInfo = () => {
    this.setState({ showMoreInfo: false });
  }

  private onShowMoreInfo = () => {
    this.setState({ showMoreInfo: true });
  }
}


interface ComponentProps {
  onSuccess: () => void;
}

interface ComponentState {
  showMoreInfo: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    errorMessage: state.documents.errorMessage,
    isSaving: state.documents.isSaving,
    isUploading: state.documents.isUploading,
    preview: state.documents.preview,
    showPreview: state.documents.showPreview
  }),
  {
    clearErrorMessage: actions.clearErrorMessage,
    hidePreview: actions.hidePreview
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(DocumentPreview);