import { ITranslatedText } from "modules/common/models";
import { EMAIL_PERSONALIZATION_TOKENS, MERGE_TAG_PREFIX, MERGE_TAG_SUFFIX, PersonalizationTokenValue } from "modules/emails/models";
import { CurrentUserSettings } from "modules/settings";

// Maps the personalization token to the actual text we want
export const personalizationTokenMapper = (tokenValue: PersonalizationTokenValue, currentUser: CurrentUserSettings) => {
    let replaceText = "";

    switch (tokenValue) {
        case PersonalizationTokenValue.UserFirstName:
            replaceText = currentUser.firstName;
            break;
        case PersonalizationTokenValue.UserLastName:
            replaceText = currentUser.lastName;
            break;
    }

    return replaceText;
}

// replaces string text occurences of the token to what the value of the token is
export const replaceTextPersonalizationTokens = (subject: ITranslatedText[], activeLcid: string, currentUser: CurrentUserSettings): string => {
    let subjectText = subject.find(s => s.lcid === activeLcid)?.text;

    if (subjectText) {
        for (const token of EMAIL_PERSONALIZATION_TOKENS) {
            const personalizationTokenText = `${MERGE_TAG_PREFIX}${token.value}${MERGE_TAG_SUFFIX}`;
            const replaceValue = personalizationTokenMapper(token.value, currentUser);
            subjectText = subjectText.replaceAll(personalizationTokenText, replaceValue);
        }
    }

    return subjectText ?? "";
}
