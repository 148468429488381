import React from "react";
import PluginWrapper from "../../plugins/common/pluginWrapper";
import { FeedLayout } from "../../models/common";

export const contentHeaderTemplate = {
    name: "content_label",
    label: "Content",
    component: "section-label"
};

export const ignoreSubscriptionsTemplate = [
    {
        name: "ignore_subscriptions",
        label: "Ignore employee's topic subscriptions",
        component: "checkbox"
    },
    {
        name: "ignore_subscriptions_spacing",
        component: () => <PluginWrapper />
    },
];

export const lockedToTopicsTemplate = (content: string) => ({
    name: "locked_to_topics",
    label: "Lock to specific topics",
    description: `The component will only load ${content}s tagged with topics selected. If none are selected, all topics are loaded.`,
    component: "select-topics"
});

export const lockedToCategoryTagsTemplate = {
    name: "locked_to_category_tags",
    label: "Lock to specific category tags",
    description: `The component will only load documents tagged with category tags selected. If none are selected, all tags are loaded.`,
    component: "select-category-tags"
};

interface INumericalOption {
    value: number,
    label: string;
}

const numberOptions = (min: number, max: number) => {
    const options: INumericalOption[] = [];
    for (var i = min; i < max + 1; i++) {
        options.push({ value: i, label: i.toString() });
    }
    return options;
};

const tileNumberTemplate = (content: string) => ({
    name: "max_results",
    label: "Number of tiles",
    description: `Select the maximum number of ${content} tiles to display`,
    component: "select",
    options: numberOptions(1, 12)
});

const layoutTemplate = (content: string) => {
    const layoutOptions = [
        { value: FeedLayout.HorizontalBand, label: "Horizontal Band" },
        { value: FeedLayout.Window, label: "Window" },
        { value: FeedLayout.List, label: "List" },
        { value: FeedLayout.StackedCard, label: "Stacked Cards" }
    ];
    if (content.includes("news")) layoutOptions.push({ value: FeedLayout.Column, label: "Column" });

    return {
        name: "layout",
        label: "Layout",
        description: `Select the layout of each ${content} tile`,
        component: "select",
        options: layoutOptions
    };
};

export const stylingTemplate = (content: string) => ({
    header: {
        name: "styling_label",
        label: "Styling",
        component: "section-label"
    },
    layout: layoutTemplate(content),
    tiles: tileNumberTemplate(content),
});

const hideButtons = [
    {
        name: "hide_load_more",
        label: "Hide Load More button",
        component: "checkbox"
    },
    {
        name: "hide_view_all",
        label: "Hide View All button",
        component: "checkbox"
    },
];

const docsOptionalData = [
    {
        name: "hide_file_icon",
        label: "Hide file icon",
        component: "checkbox"
    },
    {
        name: "hide_category_tags",
        label: "Hide category tags",
        component: "checkbox"
    },
    {
        name: "hide_action_icons",
        label: "Hide action icons",
        component: "checkbox"
    },
];

const contentOptionalData = [
    {
        name: "hide_summary",
        label: "Hide summary",
        component: "checkbox"
    },
    {
        name: "hide_topics",
        label: "Hide topics",
        component: "checkbox"
    }
];

const postOptionalData = [
    {
        name: "hide_publish_time",
        label: "Hide publish date",
        component: "checkbox"
    },
    {
        name: "hide_stats",
        label: "Hide activity stats",
        component: "checkbox"
    },
    ...contentOptionalData
];

export const optionalDataTemplate = {
    header: {
        name: "optional_data_label",
        label: "Options",
        description: "Select data you want to hide.",
        component: "label"
    },
    postData: postOptionalData,
    docsData: docsOptionalData,
    eventsData: contentOptionalData,
    buttons: hideButtons
};
