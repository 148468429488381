import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Prompt, RouteComponentProps } from "react-router-dom";
import * as actions from "../actionCreator";
import * as audiencesActions from "modules/audiences/actionCreator";
import * as settingsActions from "modules/settings/actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import _ from "lodash";

import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import SuccessSnackbar from "modules/common/components/snackbars/successSnackbar";

import BasePage from "pages/common/basePage";
import Breadcrumb from "pages/common/breadcrumb";
import Columns from "pages/common/columns";
import MainContent from "pages/common/mainContent";
import MainContentWithFooter from "pages/common/mainContentWithFooter";

import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import TitleSection from "./components/title";
import { ProgressSection } from "./components/progress";
import TemplateSection from "./components/templateSection";
import SettingsSection from "./components/settingsSection";
import NewsletterPreview from "./components/newsletterPreview";
import "modules/common/components/authoring/authoring.sass";
import {
    Address,
    FOOTER,
    HEADER,
    NewsletterDetails,
    NewsletterImageType,
    NewsletterTemplate,
    NewsletterTemplateImageType,
    NewsletterTheme,
    NewsletterThemeSimple,
    SaveNewsletterModel,
    SaveNewsletterModelStateErrors,
    NewsletterDigest,
    UploadNewsletterImageModel,
} from "modules/newsletters";
import "../styles/newsletter.sass";
import "../styles/progress-bar.sass";
import Loading from "modules/common/components/loading";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Link,
    List,
    TextField,
    Typography,
} from "@mui/material";
import { Audience } from "modules/audiences/models";
import SendTestEmailDialog from "../common/dialogs/sendTestEmailDialog";
import Tabs from "pages/common/tabs";
import SubjectAndPreHeader from "./components/subjectAndPreHeader";
import { HeaderAndFooter } from "./components/headerAndFooter";
import MoreOptionsButton from "modules/common/components/buttons/moreOptionsButton";
import Disclaimer from "modules/common/components/disclaimer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseIcon from "@mui/icons-material/Close";
import { hexCodeFromText } from "utils/hexFunctions";
import MoreOptionsItem from "modules/common/components/moreOptionsItem";
import { getBase64, standardTemplateNames } from "utils/newsletterUtils";
import { FileDownloader } from "utils/fileDownloader";
import NewsletterLanguageBar from "./components/newsletterLanguageBar";
import { TranslatedNewsletterContent } from "modules/common/components/authoring/models";
import useLanguages from "modules/common/hooks/data/useLanguages";
import { useEffect, useState } from "react";
import { deepCopy } from "utils/deepCopy";
import { useDefaultLocale } from "modules/common/hooks/useDefaultLocale";

//TODO: Chop this component up into smaller parts, it's way too big.
const EditNewsletterPage: React.FunctionComponent<PropsWithRedux> = (props) => {
    const getNewNewsletter = (): SaveNewsletterModel => {
        const { tenantSettings } = props;

        const newsletter: SaveNewsletterModel = {
            status: "Draft",
            allowUnsubscribe: true,
            audiences: [],
            customTopics: [],
            dailyRecurrenceOn: ["Monday"],
            monthlyRecurrenceWeek: "First",
            monthlyRecurrenceDay: "Monday",
            description: "",
            emailPreheader: "",
            emailSubject: "",
            recurrenceType: "Adhoc",
            emailTemplateHeaderText: "",
            emailTemplateFooterText: "",
            bodyText: "",
            emailTemplateId: "",
            maxEventsToInclude: 0,
            eventsToInclude: "SinceLastIssue",
            eventsToIncludeDaysWindow: 0,
            includeUpcomingEvents: false,
            postsToInclude: "SinceLastIssue",
            maxPostsToInclude: 4,
            postsToIncludeDaysWindow: 7,
            sortPostsAscending: true,
            title: "",
            topicOption: "Employee",
            tenantId: tenantSettings.tenantId,
            fromEmailAddress: "",
            fromEmailName: "",
            translatedContent: { defaultLcid: { subject: "", preheader: "", header: "", body: "", footer: "" } },

            //Overwritten on page load.
            newsletterTheme: {
                themeId: "",
                emailBackground: "#F5F5F5",
                primaryBackground: "#FEFEFA",
                primaryText: "#333333",
                secondaryBackground: "#949494",
                secondaryText: "#FFFFFF",
                linkText: "#3B78AB",
            },
        };
        return newsletter;
    };

    const getCommands = (): JSX.Element => {
        const currentStatus = !!serverNewsletter ? serverNewsletter.status : "Draft";
        const currentlyCustomTemplate = templates?.find((t) => t.id === newsletter.emailTemplateId)?.isCustom;
        
        return (
            <React.Fragment>
                <Hidden mdDown>
                    <MoreOptionsButton>
                        <List disablePadding>
                            <MoreOptionsItem
                                text={currentStatus === "Draft" ? "Save as draft" : "Save"}
                                onClick={saveAsDraftClicked}
                                disabled={!newsletter.title || !changedSinceSaved}
                            />
                            <Divider />
                            <MoreOptionsItem
                                text="Save as theme"
                                onClick={() => {
                                    setSaveTemplateOpen(true);
                                    setTemplateXClicked(false);
                                }}
                                disabled={currentlyCustomTemplate}
                            />
                        </List>
                    </MoreOptionsButton>
                    {currentStatus === "Draft" && (
                        <Button variant="contained" disabled={!progressCheck.isComplete} color="primary" onClick={publishClicked}>
                            Publish
                        </Button>
                    )}
                </Hidden>
            </React.Fragment>
        );
    };

    const saveNewsletter = (newsletter: SaveNewsletterModel): Promise<boolean> => {
        setHighlightTitleFields(false);
        setIsSaving(true);
        setErrorMessage(null);
        return props.saveNewsletter(newsletter).then((response) => {
            if (!!response.success) {
                saveHeaderFooterImage(response.success);

                setChangedSinceSaved(false);
                setIsSaving(false);
                setServerNewsletter({
                    ...response.success,
                    futureIssues: [],
                    sentIssueTags: [],
                    emailBackground: "",
                    primaryBackground: "",
                    primaryText: "",
                    secondaryBackground: "",
                    secondaryText: "",
                    linkTexts: "",
                    newsletterTheme: {
                        themeId: "",
                        emailBackground: "",
                        primaryBackground: "",
                        primaryText: "",
                        secondaryBackground: "",
                        secondaryText: "",
                        linkText: "",
                    },
                });
                setModelErrors(null);

                if (newsletter.id === undefined) {
                    let newNewsletter = newsletter;
                    newNewsletter.id = response.success.id;
                    setNewsletter(newNewsletter);
                    props.redirectTo(`/${props.tenant}/admin/newsletters/${response.success.id}`);
                }

                return true;
            } else if (!!response.error) {
                const firstError = _.first(_.values(response.error.ModelState));
                setChangedSinceSaved(false);
                setIsSaving(false);
                setModelErrors(response.error.ModelState);
                setErrorMessage(firstError && _.first(firstError));
                if (isTemplateSectionInError()) {
                    setTemplateSection();
                } else {
                    setAudienceFrequencySection();
                }
            }
            return false;
        });
    };

    const checkAndHighlightTitleFields = () => {
        if (!newsletter.title) setHighlightTitleFields(true);
    };

    const onComposeClicked = () => {
        setSelectedTab(1);
        setHighlightSubjectAndHeader(true);
        setHighlightSenderDetails(false);
    };

    const onFrequencyClicked = () => {
        setSelectedTab(2);
        setHighlightSenderDetails(true);
        setHighlightSubjectAndHeader(false);
    };

    const setNewsletterToState = (
        newsletter: SaveNewsletterModel,
        selectedTemplate: NewsletterTemplate | undefined,
        selectedTheme: NewsletterTheme | undefined,
        isChanged: boolean
    ) => {
        const titleSectionIsComplete = isTitleSectionComplete(newsletter);
        const templateSectionIsComplete = isTemplateSectionComplete(newsletter);
        const audienceFrequencySectionIsComplete = isFrequencySectionComplete(newsletter);
        const composeSectionIsComplete = isComposeSectionComplete(newsletter);

        setNewsletter(newsletter);
        setHighlightTitleFields(false);
        setSelectedTemplate(selectedTemplate);
        setSelectedTheme(selectedTheme);
        setChangedSinceSaved(isChanged);

        setProgressCheck({
            isComplete:
                titleSectionIsComplete && templateSectionIsComplete && audienceFrequencySectionIsComplete && composeSectionIsComplete,
            titleSectionIsComplete: titleSectionIsComplete,
            audienceFrequencySectionIsComplete: audienceFrequencySectionIsComplete,
            templateSectionIsComplete: templateSectionIsComplete,
            composeSectionIsComplete: composeSectionIsComplete,
        });
    };

    const onChangeNewsletter = (value: Partial<SaveNewsletterModel>) => {
        const newsletterToSet = { ...newsletter, ...value };
        const selectedTemplate = _.first(_.filter(templates, (t) => t.id === newsletter.emailTemplateId));
        const selectedTheme = _.first(_.filter(themes, (t) => t.id === newsletter.newsletterTheme.themeId));
        setNewsletterToState(newsletterToSet, selectedTemplate, selectedTheme, true);
    };

    const renderFooter = (): React.ReactNode => {
        if (selectedTab === 0) {
            return returnTemplateOptions();
        } else if (selectedTab === 1) {
            return returnContentOptions();
        } else if (selectedTab === 2) {
            return returnSettingsOptions();
        } else {
            return <div></div>;
        }
    };

    const setImage = (imageType: NewsletterImageType, image?: File) => {
        let reader = new FileReader();
        if (imageType === FOOTER && !!image) {
            reader.readAsDataURL(image);
            reader.onload = (): void => {
                setChangedSinceSaved(true);
                setFooterImage(image);
                setHasFooterImageChanged(true);
                setHighlightTitleFields(false);
                setFooterImageBase64(reader.result?.toString());
            };
        } else if (imageType === HEADER && !!image) {
            reader.readAsDataURL(image);
            reader.onload = (): void => {
                setChangedSinceSaved(true);
                setHeaderImage(image);
                setHasHeaderImageChanged(true);
                setHighlightTitleFields(false);
                setHeaderImageBase64(reader.result?.toString());
            };
        }
    };

    const onDownloadTemplateHtml = (template: NewsletterTemplate) => {
        setIsDownloading(true);

        const file = {
            name: `${template.name}.html`,
        };
        props
            .getTemplateHtml(template.id)
            .then((data) => new FileDownloader(file).downloadProvided(data))
            .then((_) => setIsDownloading(false))
            .catch((_) => setIsDownloading(false));
    };

    const onDownloadTemplateImage = (template: NewsletterTemplate, imageType: NewsletterTemplateImageType) => {
        setIsDownloading(true);
        const file = {
            name: `${template.name} ${imageType}.png`,
        };
        props
            .getTemplateImage(template.id, imageType)
            .then((url) => new FileDownloader(file).downloadProvided(url))
            .then((_) => setIsDownloading(false))
            .catch((_) => setIsDownloading(false));
    };

    //Used when the theme is loaded, shouldn't mark things as edited.
    const setImageOnLoad = (type: NewsletterImageType, toSet: File) => {
        let reader = new FileReader();
        if (type === FOOTER && !!toSet) {
            reader.readAsDataURL(toSet);
            reader.onload = (): void => {
                setChangedSinceSaved(true);
                setFooterImage(toSet);
                setThemeHadFooterOnLoad(true);
                setFooterImageBase64(reader.result?.toString());
            };
        } else if (type === HEADER && !!toSet) {
            reader.readAsDataURL(toSet);
            reader.onload = (): void => {
                setChangedSinceSaved(true);
                setHeaderImage(toSet);
                setThemeHadHeaderOnLoad(true);
                setHeaderImageBase64(reader.result?.toString());
            };
        }
    };

    const updateSavedTheme = (toSet: NewsletterTheme) => {
        setSavedTheme(toSet);
    };

    const updateTranslatedContent = (toSet: TranslatedNewsletterContent) => {
        let newNewsletter = newsletter;
        newNewsletter.translatedContent = toSet;

        setNewsletterToState(newNewsletter, selectedTemplate, selectedTheme, true);
    };

    const clearImage = (type: NewsletterImageType) => {
        if (type === "Header") {
            setHeaderImage(null);
            setHeaderImageBase64(undefined);
            setHasHeaderImageChanged(true);
        } else if (type === "Footer") {
            setFooterImage(null);
            setFooterImageBase64(undefined);
            setHasFooterImageChanged(true);
        }
    };

    const onSearchSenders = (value: string) => {
        var newAddresses = originalAddresses.filter(
            (a) =>
                a.fromEmailAddress.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) >= 0 ||
                a.fromEmailName.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) >= 0
        );
        setAddresses(newAddresses);
    };

    const publishNewsletter = () => {
        const newsletterToPublish = deepCopy(newsletter);
        newsletterToPublish.status = "Enabled";
        saveNewsletter(newsletterToPublish).then((isSuccess) => {
            if (isSuccess) {
                props.redirectTo(`/${props.tenant}/admin/newsletters/${newsletterToPublish.id}/dashboard`);
            } else {
                setShowNewsletterPreview(false);
            }
        });
    };

    const handleThemeDialogClose = () => {
        setSaveTemplateOpen(false);
        setThemeName("");
    };

    const swapToImagesTab = () => {
        setSelectedTab(1);
        setSaveTemplateOpen(false);
    };

    const updateThemeAndMoveOn = () => {
        let result = updateTheme();
        if (result) {
            setSuccessMessage("Successfully updated theme.");
        } else {
            setErrorMessage("Could not update theme.");
        }
        saveNewsletter(newsletter);
        setPreSaveWarningOpen(false);
        setAlreadySeenWarning(true);
    };

    const handleUpdateOrSaveClose = () => {
        if (postWarningRedirect) {
            postWarningRedirect();
        }
        setPreSaveWarningOpen(false);
        setAlreadySeenWarning(true);
    };

    const [newsletter, setNewsletter] = useState(getNewNewsletter());
    const [modelErrors, setModelErrors] = useState<SaveNewsletterModelStateErrors | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | null | undefined>();
    const [showUnsavedChangesDialogPriorTo, setShowUnsavedChangesDialogPriorTo] = useState<"onShowSendTestEmail" | undefined | null>();

    const [highlightTitleFields, setHighlightTitleFields] = useState(false);
    const [highlightSubjectAndHeader, setHighlightSubjectAndHeader] = useState(false);
    const [highlightSenderDetails, setHighlightSenderDetails] = useState(false);

    const [showNewsletterPreview, setShowNewsletterPreview] = useState(false);
    const [showSendTestEmailDialog, setShowSendTestEmailDialog] = useState<boolean | undefined>();

    const [isSaving, setIsSaving] = useState(false);
    const [isDownloading, setIsDownloading] = useState<boolean | undefined>();
    const [isLoadingHeaderImage, setIsLoadingHeaderImage] = useState<boolean | undefined>();
    const [isLoadingFooterImage, setIsLoadingFooterImage] = useState<boolean | undefined>();
    const [themeHadHeaderOnLoad, setThemeHadHeaderOnLoad] = useState<boolean | undefined>();
    const [themeHadFooterOnLoad, setThemeHadFooterOnLoad] = useState<boolean | undefined>();
    const [headerImageBase64, setHeaderImageBase64] = useState<string | undefined>();
    const [footerImageBase64, setFooterImageBase64] = useState<string | undefined>();

    const [changedSinceSaved, setChangedSinceSaved] = useState(false);
    const [firstTime, setFirstTime] = useState(false);
    const [saveTemplateOpen, setSaveTemplateOpen] = useState(false);
    const [templateXClicked, setTemplateXClicked] = useState(false);
    const [preSaveWarningOpen, setPreSaveWarningOpen] = useState(false);
    const [alreadySeenWarning, setAlreadySeenWarning] = useState(false);
    const [hasHeaderImageChanged, setHasHeaderImageChanged] = useState(false);
    const [hasFooterImageChanged, setHasFooterImageChanged] = useState(false);

    const [selectedTab, setSelectedTab] = useState(0);
    const [themeName, setThemeName] = useState("");
    const [themeError, setThemeError] = useState("");
    const [addresses, setAddresses] = useState<Address[]>([]);
    const [originalAddresses, setOriginalAddresses] = useState<Address[]>([]);

    const [selectedTheme, setSelectedTheme] = useState<NewsletterTheme | undefined>();
    const [savedTheme, setSavedTheme] = useState<NewsletterTheme | undefined>();
    const [selectedTemplate, setSelectedTemplate] = useState<NewsletterTemplate | undefined>();
    const [serverNewsletter, setServerNewsletter] = useState<NewsletterDetails | undefined>();
    const [headerImage, setHeaderImage] = useState<File | null | undefined>();
    const [footerImage, setFooterImage] = useState<File | null | undefined>();

    const [audiences, setAudiences] = useState<Audience[] | undefined>();
    const [templates, setTemplates] = useState<NewsletterTemplate[] | undefined>();
    const [themes, setThemes] = useState<NewsletterTheme[] | undefined>();
    const [postWarningRedirect, setPostWarningRedirect] = useState<() => any | undefined>(); //Make sure this line is okay.

    useDefaultLocale();

    const [progressCheck, setProgressCheck] = useState({
        isComplete: false,
        titleSectionIsComplete: false,
        audienceFrequencySectionIsComplete: false,
        templateSectionIsComplete: false,
        composeSectionIsComplete: false,
    });

    useEffect(() => {
        props.getConfig().then((currentConfig) => {
            setAddresses(currentConfig.addresses);
            setOriginalAddresses(currentConfig.addresses);
        });

        const fetchNewsletterDetails = async () => {
            let templateToSet;
            let themeToSet;
            let newsletterToSet;

            templateToSet = await fetchAndSetTemplate();
            newsletterToSet = await fetchAndSetNewsletter();
            themeToSet = await fetchAndSetTheme();

            //We did not load a newsletter, so make sure everything lines up with a new one.
            if (!newsletterToSet) {
                newsletterToSet = getNewNewsletter();
                newsletterToSet.emailTemplateId = templateToSet.id;

                let themeToApply: NewsletterThemeSimple = {
                    themeId: themeToSet.id,
                    primaryText: themeToSet.primaryText,
                    primaryBackground: themeToSet.primaryBackground,
                    secondaryBackground: themeToSet.secondaryBackground,
                    secondaryText: themeToSet.secondaryText,
                    emailBackground: themeToSet.emailBackground,
                    linkText: themeToSet.linkText,
                };

                newsletterToSet.newsletterTheme = themeToApply;
            }
            setNewsletterToState(newsletterToSet, templateToSet, themeToSet, false);

            props.getAudiences().then((audiences) => {
                setAudiences(audiences);
            });
            props.getTagSettings();
        };

        fetchNewsletterDetails();
    }, []);

    const fetchAndSetNewsletter = async () => {
        if (!!props.match.params.newsletterId) {
            let fetchedNewsletter = await props.getNewsletterDetails(props.match.params.newsletterId);

            if (!fetchedNewsletter) {
                props.redirectTo(`/${props.tenant}/admin/newsletters`);
            } else {
                let idToCheck = fetchedNewsletter.emailTemplate?.isCustom
                    ? fetchedNewsletter.emailTemplate.id
                    : fetchedNewsletter.emailTemplateId;
                let selectedTemplate = _.first(_.filter(templates, (t) => t.id === idToCheck));
                if (!selectedTemplate) {
                    selectedTemplate = _.first(_.filter(templates, (t) => t.name.toLowerCase() === standardTemplateNames[0]));
                }

                let selectedTheme = _.first(_.filter(themes, (t) => t.id === fetchedNewsletter?.newsletterTheme?.themeId));
                if (!selectedTheme) {
                    selectedTheme = _.first(_.filter(themes, (t) => t.isDefault));
                }

                //No theme saved, custom!
                if (!fetchedNewsletter.newsletterTheme) {
                    let themeToSet: NewsletterThemeSimple = {
                        themeId: "toBeReplaced",
                        emailBackground: "#FFFFFF",
                        primaryBackground: "#FFFFFF",
                        primaryText: "#FFFFFF",
                        secondaryBackground: "#FFFFFF",
                        secondaryText: "#FFFFFF",
                        linkText: "#FFFFFF",
                    };
                    fetchedNewsletter.newsletterTheme = themeToSet;
                }

                fetchedNewsletter.newsletterTheme.themeId = selectedTheme?.id ?? fetchedNewsletter.newsletterTheme.themeId;
                setServerNewsletter(fetchedNewsletter);

                if (fetchedNewsletter.emailTemplateHeaderImageIsCustomized) {
                    setIsLoadingHeaderImage(true);
                    props.getNewsletterImage(fetchedNewsletter.id, HEADER).then((image) => {
                        setHeaderImage(image);
                        setIsLoadingHeaderImage(false);
                    });
                } else {
                    setHeaderImage(null);
                    setIsLoadingHeaderImage(false);
                }
                if (fetchedNewsletter.emailTemplateFooterImageIsCustomized) {
                    setIsLoadingFooterImage(true);
                    props.getNewsletterImage(fetchedNewsletter.id, FOOTER).then((image) => {
                        setFooterImage(image);
                        setIsLoadingFooterImage(false);
                    });
                } else {
                    setFooterImage(null);
                    setIsLoadingFooterImage(false);
                }

                return fetchedNewsletter;
            }
        }
    };

    const fetchAndSetTemplate = async () => {
        let templateResult = await props.getTemplates();

        if (!!templateResult) {
            setTemplates(templateResult);

            if (!!newsletter) {
                if (!newsletter.emailTemplateId) {
                    const firstTemplate = templateResult.find((t) => t.name.toLowerCase() === "1 column");

                    if (!!firstTemplate) {
                        let newsletterToSet = newsletter;
                        newsletter.emailTemplateId = firstTemplate.id;
                        setNewsletter(newsletterToSet);
                        setSelectedTemplate(firstTemplate);
                        return firstTemplate;
                    }
                } else {
                    const selectedTemplateToSet = _.first(_.filter(templateResult, (t) => t.id === newsletter.emailTemplateId));

                    setSelectedTemplate(selectedTemplateToSet);
                    return selectedTemplateToSet;
                }
            }
        }
    };

    const fetchAndSetTheme = async () => {
        let themes = await props.getThemes();

        if (!!themes) {
            let sortedThemes = themes.sort((a, b) => a.name.localeCompare(b.name));
            setThemes(sortedThemes);

            if (!!newsletter) {
                if ((!newsletter.newsletterTheme || !newsletter.newsletterTheme.themeId) && !props.match.params.newsletterId) {
                    //Check for default themes
                    let validDefaultState = themes.filter((t) => t.isDefault).length === 1;
                    let theme;

                    if (validDefaultState) theme = themes.find((t) => t.isDefault);
                    else {
                        theme = themes.find((t) => t.name.toLowerCase() === "sparrow connected light theme");
                    }
                    if (!!theme) {
                        let newsletterToUpdate = newsletter;

                        //This is a new newsletter, load the colors of the theme.
                        var colorsToSet: NewsletterThemeSimple = {
                            themeId: theme.id,
                            emailBackground: theme.emailBackground,
                            primaryBackground: theme.primaryBackground,
                            primaryText: theme.primaryText,
                            secondaryBackground: theme.secondaryBackground,
                            secondaryText: theme.secondaryText,
                            linkText: theme.linkText,
                        };

                        newsletterToUpdate.newsletterTheme = colorsToSet;
                        setNewsletter(newsletterToUpdate);
                        setFirstTime(true);
                        return theme;
                    }
                } else {
                    let selectedThemeToSet = _.first(_.filter(themes, (t) => t.id === newsletter.newsletterTheme.themeId));
                    let newsletterToUpdate = newsletter;

                    //Case where theme to be loaded has been deleted.
                    if (!selectedThemeToSet) {
                        selectedThemeToSet = themes.find((t) => t.isDefault);
                        if (selectedThemeToSet) {
                            newsletterToUpdate.newsletterTheme.themeId = selectedThemeToSet.id;
                        }
                    }

                    setSelectedTheme(selectedThemeToSet);
                    setNewsletter(newsletterToUpdate);
                    return selectedThemeToSet;
                }
            }
        }
    };

    const returnTemplateOptions = () => {
        return (
            <Grid container spacing={0} style={{ margin: "0", padding: "7px 28px" }}>
                <Grid item xs={6}></Grid>

                <Grid item xs={6} style={{ textAlign: "right" }}>
                    <Button variant="contained" color="primary" onClick={() => setSelectedTab(1)}>
                        Next
                    </Button>
                </Grid>
            </Grid>
        );
    };

    const returnContentOptions = () => {
        return (
            <Grid container spacing={0} style={{ margin: "0", padding: "7px 28px" }}>
                <Grid item xs={6} style={{ textAlign: "left" }}>
                    <Button variant="contained" color="primary" onClick={() => setSelectedTab(0)}>
                        Previous
                    </Button>
                </Grid>

                <Grid item xs={6} style={{ textAlign: "right" }}>
                    <Button variant="contained" color="primary" onClick={() => setSelectedTab(2)}>
                        Next
                    </Button>
                </Grid>
            </Grid>
        );
    };

    const returnSettingsOptions = () => {
        return (
            <Grid container spacing={0} style={{ margin: "0", padding: "7px 28px" }}>
                <Grid item xs={6} style={{ textAlign: "left" }}>
                    <Button variant="contained" color="primary" onClick={() => setSelectedTab(1)}>
                        Previous
                    </Button>
                </Grid>

                <Grid item xs={6} style={{ textAlign: "right" }}></Grid>
            </Grid>
        );
    };

    const publishClicked = () => {
        let colorsToCheck = newsletter.newsletterTheme;
        let backupTheme = savedTheme; //snapshot of theme to know if we edited or not.

        if (!backupTheme) {
            backupTheme = themes?.find((t) => t.id === newsletter.newsletterTheme.themeId);
        }

        //If user has changed the theme on the specific newsletter
        // compared to the one they loaded, show the warning a single time.
        if (backupTheme && !alreadySeenWarning) {
            if (
                colorsToCheck.emailBackground !== backupTheme.emailBackground ||
                colorsToCheck.primaryBackground !== backupTheme.primaryBackground ||
                colorsToCheck.primaryText !== backupTheme.primaryText ||
                colorsToCheck.secondaryBackground !== backupTheme.secondaryBackground ||
                colorsToCheck.secondaryText !== backupTheme.secondaryText ||
                colorsToCheck.linkText !== backupTheme.linkText ||
                hasHeaderImageChanged ||
                hasFooterImageChanged
            ) {
                setPreSaveWarningOpen(true);
                setPostWarningRedirect(() => () => setShowNewsletterPreview(true));
                return;
            }
        }

        setShowNewsletterPreview(true);
    };

    const saveAsDraftClicked = () => {
        var colorsToCheck = newsletter.newsletterTheme;
        var backupTheme = savedTheme;

        if (!backupTheme) {
            backupTheme = themes?.find((t) => t.id === newsletter.newsletterTheme.themeId);
        }

        if (backupTheme && !alreadySeenWarning) {
            if (
                colorsToCheck.emailBackground !== backupTheme.emailBackground ||
                colorsToCheck.primaryBackground !== backupTheme.primaryBackground ||
                colorsToCheck.primaryText !== backupTheme.primaryText ||
                colorsToCheck.secondaryBackground !== backupTheme.secondaryBackground ||
                colorsToCheck.secondaryText !== backupTheme.secondaryText ||
                colorsToCheck.linkText !== backupTheme.linkText ||
                hasHeaderImageChanged ||
                hasFooterImageChanged
            ) {
                setPreSaveWarningOpen(true);
                setPostWarningRedirect(() => () => saveNewsletter(newsletter));
                return;
            }
        }
        saveNewsletter(newsletter);
    };

    const isTitleSectionComplete = (newsletter: SaveNewsletterModel): boolean => {
        if (!newsletter || !newsletter.title) {
            return false;
        }
        return true;
    };

    const isTemplateSectionComplete = (newsletter: SaveNewsletterModel): boolean => {
        if (!newsletter || !newsletter.newsletterTheme) return false;
        return true;
    };

    const isTemplateSectionInError = (): boolean => {
        if (!modelErrors) return false;

        if (
            !!modelErrors.EmailPreheader ||
            !!modelErrors.EmailSubject ||
            !!modelErrors.EmailTemplateHeaderText ||
            !!modelErrors.EmailTemplateId
        )
            return true;

        return false;
    };

    const isFrequencySectionComplete = (newsletter: SaveNewsletterModel): boolean => {
        if (!newsletter || !newsletter.firstIssueDateTime) {
            return false;
        } else if (!newsletter.fromEmailAddress || !newsletter.fromEmailName) {
            return false;
        }
        return true;
    };

    const isComposeSectionComplete = (newsletter: SaveNewsletterModel): boolean => {
        if (!newsletter) {
            return false;
        }

        let allSubjectsValid = true;

        let newsletterLanguages = Object.keys(newsletter.translatedContent);
        newsletterLanguages.forEach((l) => {
            if (!newsletter.translatedContent[l].subject || newsletter.translatedContent[l].subject === "") {
                allSubjectsValid = false;
            }
        });

        return allSubjectsValid;
    };

    const onShowSendTestEmail = () => {
        if (changedSinceSaved || !serverNewsletter) setShowUnsavedChangesDialogPriorTo("onShowSendTestEmail");
        else setShowSendTestEmailDialog(true);
    };

    const setTemplateSection = () => {
        setHighlightTitleFields(false);
    };

    const setAudienceFrequencySection = () => {
        setHighlightTitleFields(false);
    };

    const checkValidNewTheme = () => {
        return validateThemeName() && checkValidThemeDetails();
    };

    const checkValidExistingTheme = () => {
        return checkValidThemeDetails();
    };

    const validateThemeName = () => {
        if (themes) {
            if (themeName.trim().length === 0) {
                setThemeError("Enter a name.");
                return false;
            }
            return true;
        }
        return false;
    };

    const checkValidThemeDetails = () => {
        if (themes) {
            if (themes.find((t) => t.name.trim().toLowerCase() === themeName.trim().toLowerCase())) {
                setThemeError("Theme already exists with current name.");
                return false;
            } else if (!checkColorsValid()) {
                setThemeError("Cannot save a theme with invalid colors.");
                return false;
            }
            return true;
        }
        return false;
    };

    const saveNewTheme = async () => {
        let currentState = newsletter.newsletterTheme;
        if (checkValidNewTheme()) {
            let toSave: NewsletterTheme = {
                id: "00000000-0000-0000-0000-000000000000",
                name: themeName,
                isDefault: false,
                isCustom: true,
                emailBackground: currentState.emailBackground,
                primaryBackground: currentState.primaryBackground,
                primaryText: currentState.primaryText,
                secondaryBackground: currentState.secondaryBackground,
                secondaryText: currentState.secondaryText,
                linkText: currentState.linkText,
                createdBy: "",
                createdByDisplayName: "",
                createdOnUTC: new Date(),
                lastModifiedBy: "",
                lastModifiedByDisplayName: "",
                lastModifiedOnUTC: new Date(),

                //Done elsewhere and updated independently.
                headerImage: "",
                footerImage: "",
                themeHeaderImageIsCustomized: false,
                themeFooterImageIsCustomized: false,
            };

            //Update images
            let updatedTheme = await props.saveTheme(toSave);
            let headerImageEncoded = "";
            let footerImageEncoded = "";

            if (!!headerImage) {
                headerImageEncoded = (await getBase64(headerImage)) ?? "";
            }

            if (!!footerImage) {
                footerImageEncoded = (await getBase64(footerImage)) ?? "";
            }

            if (headerImageEncoded !== "" || footerImageEncoded !== "") {
                await props.saveThemeImages(updatedTheme.id, headerImageEncoded, footerImageEncoded);
            }
            return true;
        }
        return false;
    };

    const updateTheme = () => {
        let currentState = newsletter.newsletterTheme;
        let themeId = newsletter.newsletterTheme.themeId;
        let themeToUpdate = themes?.find((t) => t.id === themeId) ?? null;

        if (themeToUpdate && checkValidExistingTheme()) {
            let toSave: NewsletterTheme = {
                id: newsletter.newsletterTheme.themeId,
                name: themeToUpdate.name,
                isDefault: themeToUpdate.isDefault,
                isCustom: true,
                emailBackground: currentState.emailBackground,
                primaryBackground: currentState.primaryBackground,
                primaryText: currentState.primaryText,
                secondaryBackground: currentState.secondaryBackground,
                secondaryText: currentState.secondaryText,
                linkText: currentState.linkText,
                createdBy: "",
                createdByDisplayName: "",
                createdOnUTC: new Date(),
                lastModifiedBy: "",
                lastModifiedByDisplayName: "",
                lastModifiedOnUTC: new Date(),

                //Done elsewhere and updated independently.
                headerImage: "",
                footerImage: "",
                themeHeaderImageIsCustomized: false,
                themeFooterImageIsCustomized: false,
            };

            props.saveTheme(toSave).then((theme) => {
                if (themes) {
                    let toReplaceIndex = themes.findIndex((t) => t.id === theme.id);
                    let themeCopy = [...themes];
                    themeCopy[toReplaceIndex] = theme;
                    setThemes(themeCopy);
                }
            });
            return true;
        }
        return false;
    };

    const checkColorsValid = () => {
        let toCheck = newsletter.newsletterTheme;

        let hexEmailBackground = hexCodeFromText(toCheck.emailBackground);
        let hexPrimaryBackground = hexCodeFromText(toCheck.primaryBackground);
        let hexPrimaryText = hexCodeFromText(toCheck.primaryText);
        let hexSecondaryBackground = hexCodeFromText(toCheck.secondaryBackground);
        let hexSecondaryText = hexCodeFromText(toCheck.secondaryText);
        let hexLinkText = hexCodeFromText(toCheck.linkText);

        if (
            !hexEmailBackground ||
            !hexPrimaryBackground ||
            !hexPrimaryText ||
            !hexSecondaryBackground ||
            !hexSecondaryText ||
            !hexLinkText
        ) {
            return false;
        }
        return true;
    };

    const saveHeaderFooterImage = (response: NewsletterDigest) => {
        if (hasFooterImageChanged || hasHeaderImageChanged || themeHadHeaderOnLoad || themeHadFooterOnLoad) {
            if (hasHeaderImageChanged) {
                if (!headerImage) props.deleteNewsletterImage(response.id, HEADER);
            }

            if (hasFooterImageChanged) {
                if (!footerImage) props.deleteNewsletterImage(response.id, FOOTER);
            }

            const uploadImageModel: UploadNewsletterImageModel = {
                newsletterId: response.id,
                headerImage: headerImageBase64,
                footerImage: footerImageBase64,
            };

            props.uploadNewsletterHeaderFooterImage(uploadImageModel);
        }
    };

    const tabs = [
        { label: "Template", value: 0 },
        { label: "Email details", value: 1 },
        { label: "Settings", value: 2 },
    ];

    const { availableLanguages, selectedLanguages, activeLcid, defaultLcid, lcidMappings, onRemoveLanguage, onSelectLanguage } =
        useLanguages(newsletter.translatedContent);

    if (!selectedTemplate) return <Loading />;

    if (!!props.match.params.newsletterId && !serverNewsletter) return <Loading />;

    if (!audiences || !props.tagSettings) return <Loading />;

    //Populate default language if loading existing newsletter
    if (!newsletter.translatedContent[defaultLcid]) {
        let defaultText: TranslatedNewsletterContent = {
            [defaultLcid]: {
                subject: newsletter.emailSubject,
                header: newsletter.emailTemplateHeaderText,
                footer: newsletter.emailTemplateFooterText,
                preheader: newsletter.emailPreheader,
                body: newsletter.bodyText,
            },
        };

        var newsletterCopy = newsletter;
        newsletterCopy.translatedContent = defaultText;
        setNewsletter(newsletterCopy);
    }

    const filledOutImages = !!headerImage && !!footerImage;
    const defaultThemeSelected = !selectedTheme?.isCustom;

    return (
        <BasePage fullWidth>
            <Prompt when={changedSinceSaved} message="You have unsaved changes, are you sure you want to leave this page?" />
            <Breadcrumb
                items={[{ title: "Newsletters", link: "~/admin/newsletters" }, { title: newsletter?.title || "New" }]}
                backItem={
                    !!serverNewsletter && serverNewsletter.status !== "Draft"
                        ? { title: "Back to dashboard", link: `~/admin/newsletters/${props.match.params.newsletterId}/dashboard` }
                        : { title: "Back to Manage Newsletters", link: "~/admin/newsletters" }
                }
                rightComponent={getCommands()}
            />
            <Columns
                leftComponent={
                    <div className="newsletter-page">
                        <MainContent addPadding10>
                            <ProgressSection
                                {...progressCheck}
                                onTitleSectionClicked={checkAndHighlightTitleFields}
                                onComposeClicked={onComposeClicked}
                                onTemplateSectionClicked={() => setSelectedTab(0)}
                                onAudienceFrequencySectionClicked={onFrequencyClicked}
                            ></ProgressSection>
                        </MainContent>
                        <MainContent addPadding10 addMarginTop>
                            <TitleSection
                                newsletter={newsletter}
                                highlightFields={highlightTitleFields}
                                modelErrors={modelErrors}
                                onNewsletterChange={onChangeNewsletter}
                            ></TitleSection>
                        </MainContent>
                    </div>
                }
                rightComponent={
                    <MainContentWithFooter
                        footerContent={renderFooter()}
                        addPadding
                        footerHeight={51}
                        headerContent={
                            <div>
                                <Tabs tabs={tabs} selectedTab={selectedTab} onSelectTab={(e) => setSelectedTab(e)} />
                            </div>
                        }
                    >
                        <div className="newsletter-page" style={{ overflowY: "clip", overflowX: "clip", paddingBottom: "20px" }}>
                            {selectedTab === 0 && (
                                <TemplateSection
                                    firstTime={firstTime}
                                    setFirstTime={setFirstTime}
                                    setImage={setImage}
                                    newsletter={newsletter}
                                    footerImage={footerImage}
                                    headerImage={headerImage}
                                    isLoadingFooterImage={isLoadingFooterImage}
                                    isLoadingHeaderImage={isLoadingHeaderImage}
                                    onNewsletterChange={onChangeNewsletter}
                                    templates={templates || []}
                                    modelErrors={modelErrors}
                                    onDownloadTemplateHtml={onDownloadTemplateHtml}
                                    onDownloadTemplateImage={onDownloadTemplateImage}
                                    getTemplateImage={props.getTemplateImage}
                                    onError={(message) => setErrorMessage(message)}
                                    setLoadImage={setImageOnLoad}
                                    updateSavedTheme={updateSavedTheme}
                                    themes={themes ?? []}
                                ></TemplateSection>
                            )}
                            {selectedTab === 1 && (
                                <div style={{ minHeight: "665px" }}>
                                    <NewsletterLanguageBar
                                        newsletterContent={newsletter.translatedContent}
                                        onChangeTranslatedContent={updateTranslatedContent}
                                        availableLanguages={availableLanguages}
                                        selectedLanguages={selectedLanguages}
                                        activeLcid={activeLcid}
                                        defaultLcid={defaultLcid}
                                        lcidMappings={lcidMappings}
                                        onRemoveLanguage={onRemoveLanguage}
                                        onSelectLanguage={onSelectLanguage}
                                        getNewsletterTranslation={props.translateNewsletter}
                                        newsletterId={newsletter.id}
                                    />

                                    <SubjectAndPreHeader
                                        newsletter={newsletter}
                                        modelErrors={modelErrors}
                                        onNewsletterChange={onChangeNewsletter}
                                        highlight={highlightSubjectAndHeader}
                                        currentLcid={activeLcid}
                                    />

                                    <HeaderAndFooter
                                        emailTemplate={selectedTemplate}
                                        setImage={setImage}
                                        clearImage={clearImage}
                                        newsletter={newsletter}
                                        modelErrors={modelErrors}
                                        footerImage={footerImage}
                                        headerImage={headerImage}
                                        isLoadingFooterImage={isLoadingFooterImage}
                                        isLoadingHeaderImage={isLoadingHeaderImage}
                                        onNewsletterChange={onChangeNewsletter}
                                        onError={(message) => setErrorMessage(message)}
                                        highlight={highlightSubjectAndHeader}
                                        currentLcid={activeLcid}
                                        changedSinceSaved={changedSinceSaved}
                                    />
                                </div>
                            )}
                            {selectedTab === 2 && (
                                <SettingsSection
                                    newsletter={newsletter}
                                    serverNewsletter={serverNewsletter}
                                    senders={addresses}
                                    onSearchSenders={onSearchSenders}
                                    audiences={audiences}
                                    modelErrors={modelErrors}
                                    onNewsletterChange={onChangeNewsletter}
                                    highlight={highlightSenderDetails}
                                ></SettingsSection>
                            )}
                        </div>
                    </MainContentWithFooter>
                }
            />
            {showNewsletterPreview && (
                <NewsletterPreview
                    audiences={audiences}
                    publishNewsletter={publishNewsletter}
                    newsletter={newsletter}
                    template={selectedTemplate}
                    headerImage={headerImage}
                    footerImage={footerImage}
                    onClose={() => setShowNewsletterPreview(false)}
                    getUniqueUsers={props.getUniqueUsers}
                    defaultLcid={defaultLcid}
                />
            )}
            <Dialog
                open={!!showUnsavedChangesDialogPriorTo}
                onClose={() => setShowUnsavedChangesDialogPriorTo(null)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Unsaved Changes</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">There are unsaved changes to the Newsletter.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowUnsavedChangesDialogPriorTo(null)} color="primary">
                        Go back to Editing
                    </Button>
                    <Button
                        onClick={() => {
                            const nextAction = showUnsavedChangesDialogPriorTo;
                            setShowUnsavedChangesDialogPriorTo(null);
                            saveNewsletter(newsletter).then((succeeded) => {
                                if (succeeded) {
                                    switch (nextAction) {
                                        case "onShowSendTestEmail":
                                            onShowSendTestEmail();
                                    }
                                }
                            });
                        }}
                        color="primary"
                        autoFocus
                    >
                        Save changes
                    </Button>
                </DialogActions>
            </Dialog>
            {!!serverNewsletter && (
                <SendTestEmailDialog
                    show={showSendTestEmailDialog || false}
                    newsletterId={serverNewsletter.id}
                    newsletterTitle={serverNewsletter!.title}
                    onError={(modelErrors) => {
                        setShowSendTestEmailDialog(false);
                        setModelErrors(modelErrors);
                        setErrorMessage(_.first(_.first(_.values(modelErrors))));
                    }}
                    onClose={() => setShowSendTestEmailDialog(false)}
                    onSuccess={(message) => setSuccessMessage(message)}
                />
            )}

            <Dialog open={saveTemplateOpen} onClose={handleThemeDialogClose} fullWidth maxWidth="sm">
                <DialogTitle id="alert-dialog-title" style={{ borderBottom: "1px solid #dde1e5", paddingBottom: "0px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h5">Save as Theme</Typography>
                        <IconButton onClick={handleThemeDialogClose} size="large">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {(filledOutImages || templateXClicked) && (
                        <div style={{ marginTop: "10px" }}>
                            <Disclaimer
                                icon={<InfoOutlinedIcon />}
                                text={
                                    <React.Fragment>
                                        <span className="successfully-scanned">
                                            A theme saves the color palette, header image, and footer image only.{" "}
                                            <Link
                                                onClick={() =>
                                                    window.open("https://support.sparrowconnected.com/en/what-are-newsletter-themes")
                                                }
                                                underline="always"
                                            >
                                                Learn more
                                            </Link>
                                            .
                                        </span>
                                    </React.Fragment>
                                }
                            ></Disclaimer>
                        </div>
                    )}
                    {!filledOutImages && !templateXClicked && (
                        <div style={{ marginTop: "10px", backgroundColor: "#FFE9AF", display: "flex", flexDirection: "row" }}>
                            <Disclaimer
                                icon={<InfoOutlinedIcon />}
                                backgroundColor="#FFE9AF"
                                text={
                                    <React.Fragment>
                                        <span className="successfully-scanned" style={{ backgroundColor: "#FFE9AF" }}>
                                            You are saving a theme with the default header/footer images.{" "}
                                            <Link onClick={swapToImagesTab} underline="always">
                                                Upload custom images
                                            </Link>
                                            .
                                        </span>
                                    </React.Fragment>
                                }
                            ></Disclaimer>
                            <Button onClick={() => setTemplateXClicked(true)}>
                                <CloseIcon style={{ color: "grey" }} />
                            </Button>
                        </div>
                    )}

                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        placeholder="Enter theme title"
                        style={{ marginTop: "12px", marginBottom: "6px" }}
                        value={themeName}
                        error={themeError !== ""}
                        helperText={themeError}
                        onChange={(e) => setThemeName(e.target.value)}
                        onClick={() => setThemeError("")}
                        onFocus={() => setThemeError("")}
                    />
                </DialogContent>
                <DialogActions style={{ marginLeft: "15px", marginRight: "15px" }}>
                    <Button
                        onClick={() => {
                            setSaveTemplateOpen(false);
                            window.open(`/${props.tenant}/admin/newsletters/managethemes`, "_blank", "noopener,noreferrer");
                        }}
                        color="primary"
                        endIcon={<OpenInNewIcon />}
                        style={{ marginRight: "auto" }}
                    >
                        Manage Themes
                    </Button>
                    <Button onClick={() => setSaveTemplateOpen(false)}>Cancel</Button>
                    <Button
                        onClick={async () => {
                            let result = await saveNewTheme();

                            if (result) {
                                setSaveTemplateOpen(false);
                                setSuccessMessage("Successfully saved as a theme.");

                                props.getThemes().then((themes) => {
                                    if (!!themes) {
                                        let sortedThemes = themes.sort((a, b) => a.name.localeCompare(b.name));
                                        setThemes(sortedThemes);
                                    }
                                });
                            }
                        }}
                        color="primary"
                        autoFocus
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={preSaveWarningOpen && !alreadySeenWarning} onClose={handleUpdateOrSaveClose} fullWidth maxWidth="sm">
                <DialogTitle id="alert-dialog-title" style={{ paddingBottom: "6px", paddingTop: "6px", paddingRight: "8px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h5">{defaultThemeSelected ? "Save Theme?" : "Update or Save Theme?"}</Typography>
                        <IconButton onClick={handleUpdateOrSaveClose} size="large">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent style={{ paddingRight: "24px" }}>
                    {defaultThemeSelected ? (
                        <span>
                            You've customized the colors and images of your newsletter. Would you like to save this as a new template theme?
                        </span>
                    ) : (
                        <span>
                            You've customized the colors and images of your newsletter. Would you like to update the '
                            <span style={{ fontWeight: "bold" }}>{selectedTheme?.name ?? ""}</span>' theme or save this as a new template
                            theme?
                        </span>
                    )}
                </DialogContent>
                <DialogActions style={{ marginLeft: "24px", marginRight: "18px", marginBottom: "4px" }}>
                    <Button
                        color="primary"
                        onClick={() => {
                            setPreSaveWarningOpen(false);
                            setSaveTemplateOpen(true);
                            setTemplateXClicked(false);
                            setAlreadySeenWarning(true);
                        }}
                    >
                        Save as new theme
                    </Button>
                    {!defaultThemeSelected && (
                        <Button color="primary" variant="contained" autoFocus onClick={updateThemeAndMoveOn}>
                            Update
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <LoadingOverlay absolute={true} show={isSaving || isDownloading || false} />
            <SuccessSnackbar successMessage={successMessage || ""} clearSuccessMessage={() => setSuccessMessage(null)} />
            <ErrorSnackbar errorMessage={errorMessage || ""} clearErrorMessage={() => setErrorMessage(null)} />
        </BasePage>
    );
};

interface RouteParams {
    newsletterId?: string;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: RouteComponentProps<RouteParams>) => ({
        ...ownProps,
        notificationSettings: state.settings.notificationSettings,
        tenant: state.tenant.id,
        tenantSettings: state.settings.tenantSettings,
        tagSettings: state.settings.tagSettings,
    }),
    {
        getNewsletterDetails: actions.getNewsletterDetails,
        getUniqueUsers: audiencesActions.fetchUniqueUserCount,
        getNewsletterImage: actions.getNewsletterImage,
        saveNewsletter: actions.saveNewsletter,
        getAudiences: audiencesActions.getAudiences,
        getTemplates: actions.getTemplates,
        getThemes: actions.getThemes,
        saveTheme: actions.saveTheme,
        getTemplateHtml: actions.getTemplateHtml,
        getTemplateImage: actions.getTemplateImage,
        deleteNewsletterImage: actions.deleteNewsletterImage,
        uploadNewsletterImage: actions.uploadNewsletterImage,
        uploadNewsletterHeaderFooterImage: actions.uploadNewsletterHeaderFooterImage,
        translateNewsletter: actions.translateNewsletter,
        saveThemeImages: actions.saveThemeImages,
        redirectTo: push,
        getTagSettings: settingsActions.getTagSettings,
        getConfig: actions.getConfig,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EditNewsletterPage);
