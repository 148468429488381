import { useState, useEffect, useRef } from "react";
import { AxiosResponse } from "axios";
import { debounce } from "lodash";
import useIsMounted from "./useIsMounted";

export type UseRetryableRequest<T> = {
    response?: T;
    isFailed: boolean;
};

interface RetryableRequest<T> {
    asyncRequest: () => Promise<AxiosResponse<T>>;
    maxRetry: number;
    requesting: boolean;
}

const FETCH_REQUEST_WAIT_MS = 500; // time between requests in ms

export function useRetryableRequest<T>(props: RetryableRequest<T>): UseRetryableRequest<T> {
    const [retryCount, setRetryCount] = useState<number>(0);
    const [res, setRes] = useState<T | undefined>(undefined);
    const isMounted = useIsMounted();

    const { requesting } = props;

    const makeRequest = useRef(
        debounce(async (retry : number) => {
            try {
                const { data } = await props.asyncRequest();

                data ? setRes(data) : handleFetchPublishedError(retry);
            }
            catch {
                handleFetchPublishedError(retry);
            }
        }, FETCH_REQUEST_WAIT_MS)
    ).current;

    const handleFetchPublishedError = (retry: number) => {
        if (retry < props.maxRetry) setRetryCount(retry + 1);
    };

    useEffect(() => {
        if (requesting && isMounted()) makeRequest(retryCount);
    }, [requesting, retryCount, makeRequest, isMounted]);

    return {
        response: res,
        isFailed: !res && (retryCount >= props.maxRetry)
    };
}
