import * as actions from "network/actions";
import { 
  ActivityLogFilterValues, 
  NewsletterConfig, 
  NewsletterFilterValues, 
  NewsletterIssueInput, 
  NewsletterIssueStatus, 
  NewsletterStatus, 
  NewsletterImageType, 
  NewsletterTemplateImageType, 
  SaveNewsletterModel, 
  NewsletterTheme,
  UploadNewsletterImageModel,
  UploadIssueImageModel
} from "modules/newsletters/models";
import cookie from "utils/cookie";
import { TranslatableNewsletterContent } from "modules/common/components/authoring/models";

export const GetNewsletterTags = () => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/adminapi/v1/newsletters/tags`
  });
}

export const GetNewslettersPaged = (pageNumber: number, filters: NewsletterFilterValues, pageAmount?: number) => {
  let rowsPerPage = pageAmount || cookie.getRowsPerPage();

  return actions.NetworkRequest({
    method: "POST",
    url: `/{tenant}/adminapi/v1/newsletters/paged`,
    queryParams: { "pageAmount": rowsPerPage, "pageNumber": pageNumber },
    body: JSON.stringify(filters)
  });
}

export const GetNewsletterDetails = (id: string) => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/adminapi/v1/newsletters/${id}/detail`
  });
}

export const SaveNewsletter = (newsletter: SaveNewsletterModel) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/adminapi/v1/newsletters/save`,
    body: JSON.stringify(newsletter)
  });
}

export const UpdateNewsletterStatus = (id: string, status: NewsletterStatus | "Deleted") => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "PATCH",
    url: `/{tenant}/adminapi/v1/newsletters/${id}/updateStatus`,
    body: JSON.stringify({
        status: status
    })
  });
}

export const CloneNewsletter = (id: string, title: string) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/adminapi/v1/newsletters/${id}/clone`,
    body: JSON.stringify({
        title: title
    })
  });
}

export const SendTestEmail = (newsletterId: string, issueDate: string, additionalAddresses: string[]) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/adminapi/v1/newsletters/${newsletterId}/sendTest`,
    body: JSON.stringify({
      additionalAddresses: additionalAddresses,
      issueDate: issueDate
    })
  });
}

export const GetConfig = () => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/adminapi/v1/newsletters/config`
  });
}

export const SaveConfig = (config: NewsletterConfig) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/adminapi/v1/newsletters/config`,
    body: JSON.stringify(config)
  });
}

export const GetPastIssues = (id: string, pageNumber: number) => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/adminapi/v1/newsletters/${id}/pastIssues?pageAmount=10&pageNumber=${pageNumber}`
  });
}

export const GetPastIssue = (newsletterId: string, issueDate: string) => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/adminapi/v1/newsletters/${newsletterId}/pastIssue/${issueDate}`
  });
}

export const GetFutureIssueDetails = (newsletterId: string, issueDate: string) => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/adminapi/v1/newsletters/${newsletterId}/futureIssue/${issueDate}`
  });
}

export const ResetFutureIssue = (newsletterId: string, issueDateAsString: string) => {
  return actions.NetworkRequest({
    method: "DELETE",
    url: `/{tenant}/adminapi/v1/newsletters/${newsletterId}/futureIssue/${issueDateAsString}`
  });
}

export const SaveFutureIssue = (issue: NewsletterIssueInput) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/adminapi/v1/newsletters/futureIssue`,
    body: JSON.stringify(issue)
  });
}

export const UpdateFutureIssueStatus = (newsletterId: string, issueDateAsString: string, status: NewsletterIssueStatus) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "PATCH",
    url: `/{tenant}/adminapi/v1/newsletters/${newsletterId}/futureIssue/${issueDateAsString}/updateStatus`,
    body: JSON.stringify({
      status: status
    })
  });
}

export const GetTemplates = () => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/adminapi/v1/newsletters/templates`
  });
}

export const GetTemplateHtml = (templateId: string) => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/adminapi/v1/newsletters/templates/${templateId}/html`
  });
}

export const GetTemplateImage = (templateId: string, imageType: NewsletterTemplateImageType) => {
  return actions.NetworkRequest({
    method:"GET",
    url: `/{tenant}/adminapi/v1/newsletters/templates/${templateId}/images/${imageType}`
  });
}

export const GetThemes = () => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/adminapi/v1/newsletters/themes`
  });
}

export const SetThemeDefault = (themeId: string, isCustom: boolean) => {
  let toSend = {themeId: themeId, isCustom: isCustom};
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "PUT",
    url: `/{tenant}/adminapi/v1/newsletters/themes/default`,
    body: JSON.stringify(toSend)
  });
}

export const DeleteTheme = (themeId: string, isCustom: boolean) => {
  let toSend = {themeId: themeId, isCustom: isCustom};
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "DELETE",
    url: `/{tenant}/adminapi/v1/newsletters/themes`,
    body: JSON.stringify(toSend)
  });
}

export const SaveTheme = (themeToSave: NewsletterTheme) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/adminapi/v1/newsletters/themes`,
    body: JSON.stringify(themeToSave)
  });
}

export const SaveThemeImages = (themeId: string, header: string, footer: string) => {
  let toSend = {themeId: themeId, headerImage: header, footerImage: footer};
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/adminapi/v1/newsletters/themes/images`,
    body: JSON.stringify(toSend)
  });
}

export const GetThemeImage = (imageType: NewsletterImageType, themeId: string) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "GET",
    url: `/{tenant}/adminapi/v1/newsletters/themes/images/${imageType}/${themeId}`,
  });
}

export const GetNewsletterPreview = (templateId: string) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "GET",
    url: `/{tenant}/adminapi/v1/newsletters/templates/${templateId}/image`
  });
}

export const DeleteThemeImage = (imageType: NewsletterImageType, themeId: string) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "DELETE",
    url: `/{tenant}/adminapi/v1/newsletters/themes/images/${imageType}/${themeId}`,
  });
}

export const GetNewsletterImage = (newsletterId: string, imageType: NewsletterImageType) => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/adminapi/v1/newsletters/images/${imageType}/${newsletterId}`
  });
}

export const GetIssueImage = (newsletterId: string, issueDate: string, imageType: NewsletterImageType) => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/adminapi/v1/newsletters/images/${imageType}/${newsletterId}/${issueDate}`
  });
}

export const UploadNewsletterImage = (id: string, imageType: NewsletterImageType, file: File) => {
  const body = new FormData();
  body.append("image", file);
  return actions.NetworkRequest({
    method: "POST",
    url: `/{tenant}/adminapi/v1/newsletters/images/${imageType}/${id}`,
    body,
  });
}

export const UploadNewsletterHeaderFooterImage = (payload: UploadNewsletterImageModel) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/adminapi/v1/newsletters/images`,
    body: JSON.stringify(payload)
  });
}

export const UploadIssueHeaderFooterImage = (payload: UploadIssueImageModel) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/adminapi/v1/newsletters/issue/images`,
    body: JSON.stringify(payload)
  });
}

export const UploadIssueImage = (newsletterId: string, issueDate: string, imageType: NewsletterImageType, image: File) => {
  const body = new FormData();
  body.append("image", image);
  return actions.NetworkRequest({
    method: "POST",
    url: `/{tenant}/adminapi/v1/newsletters/images/${imageType}/${newsletterId}/${issueDate}`,
    body: body
  });
}

export const DeleteNewsletterImage = (newsletterId: string, imageType: NewsletterImageType) => {
  return actions.NetworkRequest({
    method: "DELETE",
    url: `/{tenant}/adminapi/v1/newsletters/images/${imageType}/${newsletterId}`
  });
}

export const DeleteIssueImage = (newsletterId: string, issueDate: string, imageType: NewsletterImageType) => {
  return actions.NetworkRequest({
    method: "DELETE",
    url: `/{tenant}/adminapi/v1/newsletters/images/${imageType}/${newsletterId}/${issueDate}`
  });
}

export const GetTotalNewsletterInsights = (newsletterId: string) => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/adminapi/v1/newsletters/insights/total/${newsletterId}`
  });
}

export const GetDailyNewsletterInsights = (newsletterId: string) => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/adminapi/v1/newsletters/insights/daily/${newsletterId}?numberOfDays=${90}`
  });
}

export const GetWeeklyNewsletterInsights = (newsletterId: string) => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/adminapi/v1/newsletters/insights/weekly/${newsletterId}?numberOfWeeks=${52}`
  });
}

export const GetActivityLog = (pageNumber: number, filters: ActivityLogFilterValues) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/adminapi/v1/newsletters/insights/activity?pageAmount=10&pageNumber=${pageNumber}`,
    body: JSON.stringify(filters)
  });
}

export const GetActivityLogCSV = (filters: ActivityLogFilterValues) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/adminapi/v1/newsletters/insights/activityCsv`,
    body: JSON.stringify(filters)
  });
}

export const GetRecipientsCSV = (newsletterId: string) => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/adminapi/v1/newsletters/reports/${newsletterId}/recipientsCsv`
  });
}

//Fetches list of newsletters that use a specific alias.
//Input: fromName and fromAddress that make up the content of the alias. ie. "Sparrow Notifications", "do-not-reply@sparrowconnected.com"
//Output: List of newsletters that utilize this alias.
export const GetNewslettersUsingAlias = (fromName: string, fromAddress: string) => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/adminapi/v1/newsletters/address?fromEmail=${fromAddress}&fromName=${fromName}`
  })
}

export const GetNewsletterTranslation = (toTranslate: TranslatableNewsletterContent, targetLcid: string, inputLcid: string, newsletterId: string) => {
  return actions.NetworkRequest({
    method: "POST",
    url: `/{tenant}/api/v1/newsletters/translate`,
    body: JSON.stringify({toTranslate, targetLcid, inputLcid, newsletterId})
  })
}