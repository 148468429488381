import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { EventFilterValues } from "../../models";

import AuthoringFilter from "modules/common/components/filters/authoringFilter";
import AuthoringSearch from "modules/common/components/filters/authoringSearch";
import FilterContainer from "modules/common/components/filters/filterContainer";
import FilterDropdown from "modules/common/components/filters/filterDropdown";

import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import CloseIcon from "@mui/icons-material/Close";
import { TenantSettingsTagGroup } from "modules/settings";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PickerLocalization } from "modules/common/components/pickerLocalization";

import moment from "moment";
import { SelectOption } from "modules/common/models";
import TopicDropdown from "modules/common/components/topicDropdown";
import { availableContentStates, ContentState, contentStateLabels } from "utils/managementUtils";
import { ListItemButton } from "@mui/material";


const availableEventTypes: SelectOption[] = [
  { key: "standard", text: "Standard", type: "item" },
  { key: "mandatory", text: "Mandatory", type: "item" },
  { key: "informational", text: "Informational", type: "item" }
];

class EventFilters extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      canSearch: !this.hasNoCurrentFilters(),
      lcidToSearch: props.filters.lcidToSearch || "",
      textToSearch: props.filters.textToSearch || ""
    };
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.filters.lcidToSearch !== prevProps.filters.lcidToSearch)
      this.setState({ lcidToSearch: this.props.filters.lcidToSearch || "" });

    if (this.props.filters.textToSearch !== prevProps.filters.textToSearch)
      this.setState({ textToSearch: this.props.filters.textToSearch || "" });
  }

  private getFilterByState = (): JSX.Element => {
    return <FilterDropdown text="State">
      <List disablePadding>
        {availableContentStates.map((availableEventState) => (
          <ListItemButton key={availableEventState} dense onClick={() => this.onChangeEventStates(availableEventState)}>
            <ListItemIcon className="callout-checkbox">
              <Checkbox
                edge="start"
                tabIndex={-1}
                disableRipple
                size="small"
                color="primary"
                checked={this.props.filters.eventStates?.includes(availableEventState)}
              />
            </ListItemIcon>
            <ListItemText primary={contentStateLabels[availableEventState]} />
          </ListItemButton>
        )
        )}
      </List>
    </FilterDropdown>
  }

  private getFilterByDateTime = (): JSX.Element => {
    const { filters } = this.props;
    const label = this.props.pageId === "submissions"
      ? "Submitted between"
      : "Published between"

    return <FilterDropdown text={label}>
      <PickerLocalization>
        <div className="published-time">
          <div>
            <div>From</div>
            <DatePicker
              format="MMM dd yyyy"
              value={filters.newerThan ? Date.parse(filters.newerThan) : null}
              slotProps={{
                textField: { size: "small", placeholder: "", inputProps: { readOnly: true } },
                openPickerIcon: { color: "primary" }
              }}
              maxDate={this.props.onlyOlder ? new Date() : null}
              minDate={this.props.onlyNewer ? new Date() : null}
              onChange={this.onChangeFromDate}
              className="date-picker"
            />
          </div>
          <div>
            <div>To</div>
            <DatePicker
              format="MMM dd yyyy"
              value={filters.olderThan ? Date.parse(filters.olderThan) : null}
              slotProps={{
                textField: { size: "small", placeholder: "", inputProps: { readOnly: true } },
                openPickerIcon: { color: "primary" }
              }}
              maxDate={this.props.onlyOlder ? new Date() : null}
              minDate={this.props.onlyNewer ? new Date() : null}
              onChange={this.onChangeToDate}
              className="date-picker"
            />
          </div>
        </div>
      </PickerLocalization>
    </FilterDropdown>
  };

  private getFilterByType = (): JSX.Element => <FilterDropdown text="Types">
    <List disablePadding>
      {availableEventTypes.map((availableEventType) => (
        <ListItem key={availableEventType.key} dense button onClick={() => this.onChangeEventTypes(availableEventType.key)}>
          <ListItemIcon className="callout-checkbox">
            <Checkbox
              edge="start"
              tabIndex={-1}
              disableRipple
              size="small"
              color="primary"
              checked={!!this.props.filters.eventTypes!.find((selectedEventType) => selectedEventType === availableEventType.key)}
            />
          </ListItemIcon>
          <ListItemText primary={availableEventType.text} />
        </ListItem>
      )
      )}
    </List>
  </FilterDropdown>

  public render() {
    const { filters } = this.props;
    const tagSelected = (filters.tags?.length ?? [].length) > 0;

    return (
      <FilterContainer
        filters={
          <React.Fragment>
            <AuthoringFilter
              textToSearch={this.state.textToSearch}
              searchTextPlaceholder="Search event or organizer"
              additionalFilters={
                <React.Fragment>
                  {this.props.pageId === "all" && this.getFilterByState()}

                  {this.props.pageId !== "drafts" && this.getFilterByDateTime()}

                  <TopicDropdown
                    tagSelected={tagSelected}
                    availableTopics={this.props.availableTopics}
                    currentTags={filters.tags ?? []}
                    handleTagGroupClick={this.handleTagGroupClick}
                    checkGroupSelected={this.checkGroupSelected}
                    onChangeTags={this.onChangeTags}
                    onHandleAllTags={this.onHandleAllTags}
                  />

                  {
                    this.props.pageId !== "drafts"
                    && this.props.pageId !== "submissions"
                    && this.getFilterByType()
                  }
                </React.Fragment>
              }
              onChangeTextToSearch={this.onChangeTextToSearch}
              onUpdateTextToSearch={this.onUpdateTextToSearch}
            />
          </React.Fragment>
        }
        filterCommands={
          <AuthoringSearch
            canSearch={this.state.canSearch}
            onApplyFilters={this.onChangeTextToSearch}
            onClearFilters={this.onClearFilters}
          />
        }
        filterSelection={this.getFilterSelection()}
      />
    );
  }

  private onChangeFromDate = (date) => {
    let dateToUse = new Date(date);
    let timeNow = new Date();

    if (this.props.onlyNewer) {
      if (moment(date).dayOfYear() === moment().dayOfYear()) {
        dateToUse.setHours(timeNow.getHours(), timeNow.getMinutes(), timeNow.getSeconds());
      }
    }

    this.onChangeFilters({ newerThan: moment(date).toISOString() });
  }

  private onChangeToDate = (date) => {
    let dateToUse = new Date(date);
    let timeNow = new Date();

    if (this.props.onlyOlder) {
      if (moment(date).dayOfYear() === moment().dayOfYear()) {
        dateToUse.setHours(timeNow.getHours(), timeNow.getMinutes(), timeNow.getSeconds());
      }
    }

    this.onChangeFilters({ olderThan: moment(date).toISOString() });
  }

  private getFilterSelection = (): JSX.Element | undefined => {
    const { filters } = this.props;

    if (!filters.textToSearch && !filters.tags!.length && !filters.eventTypes!.length && !filters.newerThan && !filters.olderThan && !filters.eventStates!.length)
      return undefined;

    const availableTags: SelectOption[] = this.getAvailableTags();

    return (
      <React.Fragment>
        {!!filters.textToSearch &&
          <Chip
            key="search-text"
            label={`"${filters.textToSearch}"`}
            onDelete={this.onClearTextToSearch}
            deleteIcon={<CloseIcon />}
          />
        }
        {(!!filters.newerThan || !!filters.olderThan) &&
          <Chip
            key="published-time"
            label={`Released${!!filters.newerThan ? ` from ${moment(filters.newerThan).format("MMM D, YYYY")}` : ""}${!!filters.olderThan ? ` up to ${moment(filters.olderThan).format("MMM D, YYYY")}` : ""}`}
            onDelete={this.onClearPublishedTime}
            deleteIcon={<CloseIcon />}
          />
        }
        {filters.eventStates!.map((selectedEventState) =>
          <Chip
            key={selectedEventState}
            label={contentStateLabels[selectedEventState]}
            onDelete={() => this.onClearEventState(selectedEventState)}
            deleteIcon={<CloseIcon />}
          />
        )}
        {filters.tags!.map((selectedTag) =>
          <Chip
            key={selectedTag}
            label={availableTags.find((tag) => tag.key === selectedTag)!.text}
            onDelete={() => this.onClearTag(selectedTag)}
            deleteIcon={<CloseIcon />}
          />
        )}
        {filters.eventTypes!.map((selectedEventType) =>
          <Chip
            key={selectedEventType}
            label={availableEventTypes.find((eventType) => eventType.key === selectedEventType)!.text}
            onDelete={() => this.onClearEventType(selectedEventType)}
            deleteIcon={<CloseIcon />}
          />
        )}
      </React.Fragment>
    );
  }

  private getAvailableTags = (): SelectOption[] => {
    if (!this.props.tenantSettings)
      return [];

    let availableTags: SelectOption[] = [];
    this.props.availableTopics.map((tagGroup) => {
      if (!!tagGroup.tags.length && !tagGroup.deleted && !tagGroup.disabled) {
        availableTags.push({ key: tagGroup.id, text: tagGroup.name, type: "header" });
        tagGroup.tags.map((tag) => {
          if (!tag.deleted && !tag.disabled)
            availableTags.push({ key: tag.id, text: tag.name, type: "item" });
          return tag;
        });
      }
      return tagGroup;
    });
    return availableTags;
  }


  private onChangeFilters = (value: Partial<EventFilterValues>) => {
    this.props.onChangeFilters({ ...this.props.filters, lcidToSearch: this.state.lcidToSearch, textToSearch: this.state.textToSearch, ...value });
    this.setCanSearch();
  }

  private onClearFilters = () => {
    this.clearCanSearch();
    this.props.onClearFilters();
  }


  private onChangeEventTypes = (currentEventType: string) => {
    const hasSelectedEventType: boolean = !!this.props.filters.eventTypes!.find((selectedEventType) => selectedEventType === currentEventType);
    if (hasSelectedEventType)
      this.onChangeFilters({ eventTypes: this.props.filters.eventTypes!.filter((selectedEventType) => selectedEventType !== currentEventType) });
    else
      this.onChangeFilters({ eventTypes: this.props.filters.eventTypes!.concat([currentEventType]) });
  }

  private onChangeEventStates = (currentEventState: ContentState) => {
    const hasSelectedEventState: boolean = this.props.filters.eventStates?.includes(currentEventState) ?? false;

    if (hasSelectedEventState)
      this.onChangeFilters({ eventStates: this.props.filters.eventStates?.filter((selectedEventState) => selectedEventState !== currentEventState) });
    else
      this.onChangeFilters({ eventStates: this.props.filters.eventStates?.concat([currentEventState]) });
  }

  private onChangeLcidToSearch = (lcidToSearch: string) => {
    this.onChangeFilters({ lcidToSearch });
  }

  private onChangeTags = (currentTag: string) => {
    const hasSelectedTag: boolean = !!this.props.filters.tags!.find((selectedTag) => selectedTag === currentTag);
    if (hasSelectedTag)
      this.onChangeFilters({ tags: this.props.filters.tags!.filter((selectedTag) => selectedTag !== currentTag) });
    else
      this.onChangeFilters({ tags: this.props.filters.tags!.concat([currentTag]) });
  }

  private onChangeTextToSearch = () => {
    this.onChangeFilters({ textToSearch: this.state.textToSearch });
  }

  private onClearEventType = (eventType: string) => {
    this.onChangeFilters({ eventTypes: this.props.filters.eventTypes!.filter((selectedEventType) => selectedEventType !== eventType) });
  }

  private onClearEventState = (eventState: ContentState) => {
    this.onChangeFilters({ eventStates: this.props.filters.eventStates!.filter((selectedEventState) => selectedEventState !== eventState) });
  }

  private onClearTag = (tag: string) => {
    this.onChangeFilters({ tags: this.props.filters.tags!.filter((selectedTag) => selectedTag !== tag) });
  }

  private onClearPublishedTime = () => {
    this.onChangeFilters({ newerThan: "", olderThan: "" });
  }

  private onClearTextToSearch = () => {
    this.onChangeFilters({ textToSearch: "" });
  }

  private onUpdateLcidToSearch = (event) => {
    this.setState({ lcidToSearch: event.target.value });
    if (!!this.props.filters.textToSearch)
      this.onChangeLcidToSearch(event.target.value);
  }

  private onUpdateTextToSearch = (event) => {
    this.setState({ textToSearch: event.target.value });
    if (event.target.value && event.target.value !== "") {
      this.setCanSearch();
    }
    else if (this.hasNoCurrentFilters()) {
      this.clearCanSearch();
    }
  }

  private hasNoCurrentFilters = () => {
    let filters = this.props.filters;

    return (!filters.tags || filters.tags.length === 0)
      && filters.textToSearch === ""
      && (!filters.eventTypes || filters.eventTypes.length === 0)
      && !filters.newerThan && !filters.olderThan
      && filters.eventStates!.length === 0;
  }

  private clearCanSearch = () => {
    this.setState({ canSearch: false });
  }

  private setCanSearch = () => {
    this.setState({ canSearch: true });
  }

  private checkGroupSelected = (tagGroup: TenantSettingsTagGroup) => 
    tagGroup.tags
      .every(tag => this.props.filters.tags!.includes(tag.id))

  private handleTagGroupClick = (tagGroup: TenantSettingsTagGroup) => {
    let isCurrentlyChecked = this.checkGroupSelected(tagGroup);

    if (isCurrentlyChecked) {
      this.deselectTagGroup(tagGroup);
    }
    else {
      this.selectTagGroup(tagGroup);
    }
  }

  private selectTagGroup = (tagGroup: TenantSettingsTagGroup) => {
    let currentTags = this.props.filters.tags ?? [];

    tagGroup.tags.forEach(tag => {
      if (!currentTags.includes(tag.id)) {
        currentTags.push(tag.id);
      }
    })

    let newFilters = this.props.filters;
    newFilters.tags = currentTags;
    this.onChangeFilters(newFilters);
  }

  private deselectTagGroup = (tagGroup: TenantSettingsTagGroup) => {
    let currentTags = this.props.filters.tags ?? [];
    let tagsToRemove = tagGroup.tags.map(t => t.id);

    currentTags = currentTags.filter(currentTag => !tagsToRemove.includes(currentTag));

    let newFilters = this.props.filters;
    newFilters.tags = currentTags;
    this.onChangeFilters(newFilters);
  }

  private onHandleAllTags = (tagSelected: boolean) => {
    let newFilters = this.props.filters;

    if (tagSelected) {
      newFilters.tags = [];
      this.onChangeFilters(newFilters);
    }
    else {
      let currentTags = this.props.filters.tags ?? [];
      let tagGroups = this.props.availableTopics;

      tagGroups.forEach(tagGroup => {
        tagGroup.tags.forEach(tag => {
          if (!currentTags.includes(tag.id)) {
            currentTags.push(tag.id);
          }
        });
      });
      newFilters.tags = currentTags;
    }

    this.onChangeFilters(newFilters);
  }
}


interface ComponentProps {
  filters: Partial<EventFilterValues>;
  onChangeFilters: (filters: Partial<EventFilterValues>) => void;
  onClearFilters: () => void;
  pageId: string;
  availableTopics: TenantSettingsTagGroup[];
  onlyOlder?: boolean;
  onlyNewer?: boolean;
}

interface ComponentState {
  canSearch: boolean;
  lcidToSearch: string;
  textToSearch: string;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenantSettings: state.settings.tenantSettings
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EventFilters);
