import React from "react";

import { ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { PromptTypeEnum } from "../models";
import MagicIcon from "../common/magicIcon";

interface ISmartContentDropDownProps {
    smartContentButtonDisabled?: boolean;
    promptType: PromptTypeEnum;
    label: string;
    onSmartContentGenerate: (promptTypeForGeneration: PromptTypeEnum, promptTypeForInsert: PromptTypeEnum) => void;
}

const SmartContentDropDown: React.FunctionComponent<ISmartContentDropDownProps> = ({
    smartContentButtonDisabled,
    promptType,
    label,
    onSmartContentGenerate,
}) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    const handleListKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === "Escape") {
            setOpen(false);
        }
    }

    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const onSmartContentSelection = (event: React.MouseEvent, selectedPromptType: PromptTypeEnum) => {
        onSmartContentGenerate(promptType, selectedPromptType);
        handleClose(event);
    };

    const menuItems = [
        {
            label: label.includes("Summary") ? 'Generate alternate summaries' : 'Generate summaries',
            type: PromptTypeEnum.Summarize,
            isAlternate: label.includes("Summary"),
        },
        {
            label: label.includes("Title") ? 'Generate alternate titles' : 'Generate titles',
            type: PromptTypeEnum.Title,
            isAlternate: label.includes("Title"),
        },
        {
            label: 'Generate post body',
            type: PromptTypeEnum.Body,
            isAlternate: false,
        },
    ];

    //Ensure alternate is at the top.
    const sortedMenuItems = menuItems.sort((a, b) => Number(b.isAlternate) - Number(a.isAlternate));

    return (
        <div>
            <IconButton
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                disabled={smartContentButtonDisabled}
                sx={{ color: "#3b78ab" }}
            >
                <MagicIcon fontSize={"16"} />
            </IconButton>
            <Popper
                style={{ zIndex: 1000 }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom-start" ? "left top" : "right top",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {sortedMenuItems.map((item) => (
                                        <MenuItem key={item.type} onClick={(e) => onSmartContentSelection(e, item.type)}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export { SmartContentDropDown };
