import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "@mui/material";
import HoverText from "modules/documents/components/action-buttons/hoverText";
import EditAudiences from "modules/newsletters/common/editAudiences";
import DateAndTime from "modules/common/components/forms/inputs/dateAndTime";
import { Address, SimpleTag } from "modules/newsletters";
import { GlobalApplicationState } from "globalApplicationState";
import { IEmail } from "modules/emails/models";
import ChipList from "modules/common/components/chipList";
import { emailsApi } from "api/instances";
import { emailsSlice } from "modules/emails/reducer";
import RecipientCountDownload from "./recipientCountDownload";
import { useDownloadRecipients } from "modules/common/hooks/useDownloadRecipients";
import DrawerWithBookmarkButton from "modules/common/components/drawers/drawerWithBookmarkButton";
import SendTestEmailInput from "./sendTestEmailInput";
import EmailValidationTextField from "modules/common/components/emailValidationTextField";
import AddressSelect from "modules/common/components/addressSelect";

import "../../styles/emailSettingsPanel.sass";

interface IEmailSettingsPanelProps {
    open: boolean;
    toggleOpen: () => void;
    fromEmailAddresses: Address[];
    updateEmail: (updatedEmailFields: Partial<IEmail>, changedSinceLastSaved?: boolean) => void;
    onSuccessMessage: (message: string) => void;
    onErrorMessage: (message: string) => void;
    onSaveEmail: () => void;
    defaultAudienceCount: number;
    isFetchingDefaultAudienceCount: boolean;
}

const EmailSettingsPanel: React.FC<IEmailSettingsPanelProps> = ({
    open,
    toggleOpen,
    fromEmailAddresses,
    updateEmail,
    onSuccessMessage,
    onErrorMessage,
    onSaveEmail,
    defaultAudienceCount,
    isFetchingDefaultAudienceCount
}) => {
    const { 
        email, 
        shouldDownloadRecipientsList, 
        testEmails, 
        shouldSendTestEmails, 
        changedSinceLastSaved 
    } = useSelector((state: GlobalApplicationState) => state.emails.editor);
    const audiences = useSelector((state: GlobalApplicationState) => state.audiences.audiences);
    const { downloadRecipients } = useDownloadRecipients({onSuccessMessage, onErrorMessage, emailId: email.id});
    const [isSendingTestEmail, setIsSendingTestEmail] = useState(false);
    const dispatch = useDispatch();

    const setEmailAddressesForTest = (testEmails: string[]) => 
        dispatch({ type: emailsSlice.actions.SET_TEST_EMAILS, payload: { testEmails } });

    const setShouldDownloadRecipientsList = (shouldDownload: boolean) => 
        dispatch({ type: emailsSlice.actions.SET_SHOULD_DOWNLOAD_RECIPIENTS_LIST, payload: { shouldDownload } });

    const setShouldSendTestEmails = (shouldSend: boolean) => 
        dispatch({ type: emailsSlice.actions.SET_SHOULD_SEND_TEST_EMAILS, payload: { shouldSend } });

    useEffect(() => {
        if (email.id && !changedSinceLastSaved) {
            if (shouldDownloadRecipientsList) {
                setShouldDownloadRecipientsList(false);
                downloadRecipients();
            }
            else if (shouldSendTestEmails) {
                setShouldSendTestEmails(false);
                sendTestEmail();
            }
        }
    }, [email.id, shouldDownloadRecipientsList, shouldSendTestEmails, changedSinceLastSaved]);

    const sendTestEmail = async () => {
        setIsSendingTestEmail(true);

        try {
            await emailsApi.testEmail(email.id, testEmails);
            onSuccessMessage("Successfully sent your test email.");
        }
        catch {
            onErrorMessage("There was an error. Please try again.");
        }
        finally {
            setIsSendingTestEmail(false);
        }
    }

    const onClickSendTestEmails = async () => {
        // same thing here as for downloading the reports for emails that 
        // don't have an id (are new) or have changes not yet saved.
        if (!email.id || changedSinceLastSaved) {
            onSaveEmail();
            setShouldSendTestEmails(true);
        }
        else
            await sendTestEmail();
    }

    const onChangeAudience = (audiences: SimpleTag[], isClear?: boolean, sendToAllUsers?: boolean) => {
        if (isClear)
            // if its an update via 'Clear All', then clear the 'sendToAllUsers' as well
            updateEmail({ audiences, sendToAllUsers: false });
        else if (sendToAllUsers !== undefined)
            updateEmail({ audiences: [], sendToAllUsers });
        else if (!email.sendToAllUsers)
            // only update audiences if we don't have 'sendToAllUsers' as true
            updateEmail({ audiences });
    }

    return <DrawerWithBookmarkButton
        open={open}
        onBookmarkClick={toggleOpen}
        PaperProps={{ id: "email-settings-drawer" }}
    >
        <div className="email-editor-settings-container">
            <div className="email-from-container">
                <span className="email-from-label">From</span>
                <AddressSelect
                    formControlClassName="email-from-form-control"
                    addresses={fromEmailAddresses}
                    selectedEmailAddress={email.fromSender.email}
                    onChangeAddress={address => updateEmail({
                        fromSender: {
                            email: address.fromEmailAddress,
                            name: address.fromEmailName
                        }
                    })}
                />
            </div>
            <div className="reply-email-container">
                <HoverText label="Reply email" labelContainerClassName="reply-email-label">
                    Replies will be sent to this email address. If left blank, replies will be sent to the address provided in the 'From' field. 
                </HoverText>
                <EmailValidationTextField
                    defaultValue={email.replyAddress}
                    onChangeEmail={replyAddress => replyAddress !== email.replyAddress && updateEmail({ replyAddress })}
                    className="reply-email-text-field"
                />
            </div>
            <div className="to-container">
                <span className="to-label">To</span>
                <div className="recipient-field-container">
                    <RecipientCountDownload
                        onSaveEmail={onSaveEmail}
                        onSuccessMessage={onSuccessMessage}
                        onErrorMessage={onErrorMessage}
                        defaultAudienceCount={defaultAudienceCount}
                        isFetchingDefaultAudienceCount={isFetchingDefaultAudienceCount}
                    />
                    <EditAudiences
                        buttonProps={{sx: { paddingLeft: 0 }}}
                        buttonLabel="Add/Edit Audiences" 
                        audiences={email.audiences ?? []} 
                        availableAudiences={audiences} 
                        onChange={onChangeAudience}
                        disableAllSelections={email.sendToAllUsers}
                        sendToAllUsers={email.sendToAllUsers}
                    />
                    <div>
                        <ChipList 
                            items={email.audiences ?? []} 
                            emptyLabel={email.sendToAllUsers ? "All users" : "No recipients selected"}
                            showEmptyChip
                            onClose={a => updateEmail({ audiences: email.audiences?.filter(audience => audience.id !== a.id) })}
                            truncateAtIndex={4}
                            closable
                        />
                    </div>
                </div>
            </div>
            <Divider className="authoring-divider"/>
            <div className="send-on-container">
                <HoverText label="Send on" labelContainerClassName="send-on-label-container">
                    Schedule when this email should be sent. If left blank, it will be sent immediately after you click 'Send' in One Last Check.
                </HoverText>
                <DateAndTime
                    clearable
                    onChangeDate={dateString => updateEmail({ sentTime: dateString })}
                    date={email.sentTime}
                    dateStyle={{ width: 250 }}
                    showIcons={true}
                    minDate={moment()}
                />
            </div>
            <Divider className="authoring-divider"/>
            <SendTestEmailInput
                testEmails={testEmails}
                isSendingTestEmail={isSendingTestEmail}
                onClickSendTestEmails={onClickSendTestEmails}
                setEmailAddressesForTest={setEmailAddressesForTest}
            />
        </div>
    </DrawerWithBookmarkButton>;
};

export default EmailSettingsPanel;
