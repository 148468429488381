import * as React from "react";

import Button from "@mui/material/Button";

const AuthoringSearch: React.FunctionComponent<ComponentProps> = props => {
  return (
    <React.Fragment>
      <Button color="primary" onClick={props.onClearFilters} disabled={!props.canSearch}>Clear All</Button>
      <Button variant="contained" color="primary" disableElevation disabled={!props.canSearch} onClick={props.onApplyFilters}>Search</Button>
    </React.Fragment>
  );
}
  

interface ComponentProps {
  canSearch: boolean;
  onApplyFilters: () => void;
  onClearFilters: () => void;
}

export default AuthoringSearch;