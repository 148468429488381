import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import moment from "moment";
import Typography from "@mui/material/Typography";

import * as actions from "modules/events/actionCreator";
import { DialogContentView } from "modules/common/components/dialogs/dialogContentView";
import { FeedButtons } from "modules/common/components/feed/feedButtons";
import Loading from "modules/common/components/loading";

import Filters from "./components/filters/Filters";
import Views from "./components/views/Views";

import { EventFeedFilters } from "../../models";
import EventView from "../event-views/eventView";
import VisualCard from "./components/layouts/visualCard/VisualCard";
import ViewOptions from "./consts/viewOptions";

import "../../styles/eventFeed.sass";
import "../../styles/attendance.sass";


class EventFeed extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
        super(props);

        this.state = {
            selectedEventId: props.selectedEventId || "",
        };
    }

    public componentWillMount() {
        if (this.props.shouldFetch) {
            this.fetchFeed(ViewOptions[this.props.selectedView].filters, false, ViewOptions[this.props.selectedView].sortAscending, 1);
        }
    }

    public componentDidMount() {
        moment.locale("en");
    }

    public componentDidUpdate(prevProps: PropsWithRedux) {
        if (this.props.shouldFetch && (this.props.shouldFetch !== prevProps.shouldFetch)) {
            this.fetchFeed(ViewOptions[this.props.selectedView].filters, false, ViewOptions[this.props.selectedView].sortAscending, 1);
        }
    }

    public render() {
        const { eventsFeed } = this.props;

        if (this.props.shouldFetch || !this.props.currentUser.userId)
            return <Loading />;

        return (
            <React.Fragment>
                <Filters
                    onChangeFilters={this.onChangeFilters}
                    onClearFilters={this.onClearFilters}
                />
                <div>
                    <Views onChangeView={this.changeView} />
                    {(!eventsFeed.length && this.props.fetching)
                        ? (
                            <Loading />
                        )
                        : (
                            <React.Fragment>
                                {this.props.filtersApplied &&
                                    <Typography variant="h2" className="feed-results">Results</Typography>
                                }
                                {eventsFeed.length === 0
                                    ? <div className="no-feed">No events were found</div>
                                    : this.getLayout()
                                }
                                <FeedButtons
                                    loadMore={{
                                        isFetching: this.props.fetching,
                                        canLoadMore: this.props.canLoadMore,
                                        onFetchMore: this.continueFetching
                                    }}
                                />
                            </React.Fragment>
                        )
                    }
                    <DialogContentView id="view-event-dialog" open={!!this.state.selectedEventId} classes={{ paper: "dialog-content" }} onClose={this.unselectEvent}>
                        <EventView eventId={this.state.selectedEventId} onClose={this.unselectEvent} />
                    </DialogContentView>
                </div>
            </React.Fragment>
        );
    }


    private onChangeFilters = (filters: Partial<EventFeedFilters>) => {
        const updatedFilters = { ...this.props.filters, ...filters };
        this.props.clearEventFeed();
        this.props.setEventFeedFilters(updatedFilters);
        this.fetchFeed({ ...ViewOptions[this.props.selectedView].filters, ...updatedFilters }, true, ViewOptions[this.props.selectedView].sortAscending, 1);
    }

    private onClearFilters = () => {
        this.props.clearEventFeedFilters();
        this.props.clearEventFeed();
    }

    private changeView = (view: string) => {
        if (view !== this.props.selectedView && !this.props.fetching) {
            this.props.setEventFeedView(view);
            this.props.clearEventFeed();
            this.fetchFeed({ ...ViewOptions[view].filters, ...this.props.filters }, this.props.filtersApplied, ViewOptions[view].sortAscending, 1);
        }
    }


    private continueFetching = (newPage: number) => {
        const { filters } = this.props;

        const sortAscending: boolean = ViewOptions[this.props.selectedView].sortAscending;

        const updatedFilters = {
            ...filters,
            ...ViewOptions[this.props.selectedView].filters,
        };
        this.fetchFeed(updatedFilters, this.props.filtersApplied, sortAscending, newPage);
    }


    private fetchFeed = (filters: Partial<EventFeedFilters>, filtersApplied: boolean, sortAscending: boolean, pageNumber: number) => {
        this.props.getEventFeed(filters, filtersApplied, sortAscending, pageNumber);
    }


    private getLayout(): JSX.Element {
        return <VisualCard onEventSelected={this.selectEvent} cardCount={3}/>;
    }

    private selectEvent = (eventId: string) => {
        this.setState({ selectedEventId: eventId });
    }

    private unselectEvent = () => {
        this.setState({ selectedEventId: "" });
    }
}


interface ComponentProps {
    selectedEventId?: string;
}

interface ComponentState {
    selectedEventId: string;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        eventsFeed: state.events.eventsFeed.eventsFeed,
        canLoadMore: state.events.eventsFeed.canLoadMore,
        fetching: state.events.eventsFeed.fetching,
        filters: state.events.eventsFeed.filters,
        filtersApplied: state.events.eventsFeed.filtersApplied,
        selectedView: state.events.eventsFeed.selectedView,
        shouldFetch: state.events.eventsFeed.shouldFetch,
        currentUser: state.settings.currentUser
    }),
    {
        clearEventFeed: actions.clearEventFeed,
        clearEventFeedFilters: actions.clearEventFeedFilters,
        getEventFeed: actions.getEventFeed,
        setEventFeedFilters: actions.setEventFeedFilters,
        setEventFeedView: actions.setEventFeedView,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(EventFeed);
