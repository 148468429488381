import React, { useMemo } from "react";
import { Chip } from "@mui/material";
import moment from "moment";

import { IContentBandLayoutProps } from "./contentBandLayoutPreview";
import { useSelector } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { DEFAULT_CONFIG } from "../../reducer";
import { useDefaultLocale } from "modules/common/hooks/useDefaultLocale";

interface IContentBandWindowProps extends IContentBandLayoutProps {
    rightHeroBadge?: JSX.Element; // component to render top right corner of hero
}

export const ContentBandWindow: React.FunctionComponent<IContentBandWindowProps> = ({
    bannerColor = "#2196f3",
    heroUrl,
    title,
    leftInfo,
    rightInfo,
    leftHeroBadge,
    rightHeroBadge,
    size = "sm",
    dateBlock,
}) => {
    const { config } = useSelector((state: GlobalApplicationState) => state.contentBands);
    const usableConfig = config || DEFAULT_CONFIG;

    useDefaultLocale();

    const usableTitle = useMemo(() => (title && title.length > 55 ? title.substring(0, 52).trim() + "..." : title), [title]);

    const dateToUse = dateBlock?.isAllDayEvent ? moment.utc(dateBlock.eventStartTime).startOf("day") : moment(dateBlock?.eventStartTime);
    const getChipLabel = (): string =>
        dateBlock
            ? `STARTS ${dateToUse.format(`MMM D, ${dateBlock.isAllDayEvent ? "" : "h:mmA"}`)}${
                  dateBlock.isAllDayEvent ? "ALL DAY" : ""
              }`.toUpperCase()
            : "";

    const getBackgroundStylesForSize = (): React.CSSProperties | undefined => {
        let result: React.CSSProperties | undefined = undefined;

        if (size === "lg") {
            result = {
                backgroundSize: "cover" /* Image will cover the entire div */,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            };
        }

        return result;
    };

    const linearGradient = `linear-gradient(to bottom, rgba(0,0,0,0), ` +
        `rgb(${usableConfig.contentBandsGradientColor.rgb.r}, ` +
        `${usableConfig.contentBandsGradientColor.rgb.g}, ` +
        `${usableConfig.contentBandsGradientColor.rgb.b}))`;

    return (
        <div
            className={`content-band-window ${size}`}
            style={{
                backgroundColor: bannerColor,
                backgroundImage: `url(${heroUrl})`,
                ...getBackgroundStylesForSize(),
            }}
        >
            <div className="hero-badges">
                {dateBlock && <Chip size="small" label={getChipLabel()} className="date-chip" />}
                {leftHeroBadge}
                <div
                    style={{
                        marginRight: 5,
                        marginLeft: "auto",
                    }}
                >
                    {rightHeroBadge}
                </div>
            </div>
            <div className="content-band-card-content">
                <span className="title">{usableTitle}</span>
                <div className="info">
                    {leftInfo}
                    {rightInfo && <div style={leftInfo ? {} : { marginLeft: "auto" }}>{rightInfo}</div>}
                </div>
            </div>
            <div className="gradient" style={{ background: linearGradient }} />
        </div>
    );
};
