import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";


import Description from "./common/description";
import Label from "./common/label";
import PluginWrapper from "./common/pluginWrapper";

import { ITinaProps } from "../models/common";
import MultiselectAndChipsWithSearch from "modules/common/components/forms/inputs/multiSelectAndChipsWithSearch";
import { actions, UserCategoryTag } from "modules/categorytags";

const SelectCategoryTags: React.FunctionComponent<PropsWithRedux> = (props: PropsWithRedux) => {
    const {
        shouldFetch,
        categoryTags,
        isInitialLoad,
        getUserCategoryTags
    } = props;

    React.useEffect(() => {
        if (shouldFetch || isInitialLoad) getUserCategoryTags();
    }, [shouldFetch, isInitialLoad, getUserCategoryTags]);

    const onUpdateCategoryTags = (ct: UserCategoryTag[]) => props.input.onChange(ct);

    return (
        <PluginWrapper>
            <Label>
                {props.field.label}
                {props.field.description &&
                    <Description>{props.field.description}</Description>
                }
            </Label>
            <MultiselectAndChipsWithSearch
                defaultOptions={categoryTags}
                selectedOptions={props.input.value}
                buttonText="Add category tags"
                buttonClassName="regular-case-button"
                onChange={onUpdateCategoryTags}
                truncateAtIndex={categoryTags.length}
                nestingAllowed={false}
            />
        </PluginWrapper>
    );
};

interface ISelectCategoryTags { }

const connector = connect(
    (state: GlobalApplicationState, ownProps: ISelectCategoryTags) => ({
        ...ownProps,
        categoryTags: state.categoryTags.userCategoryTags,
        shouldFetch: state.categoryTags.shouldFetch,
        isInitialLoad: state.categoryTags.isInitialLoad
    }),
    {
        getUserCategoryTags: actions.getUserCategoryTags
    }
);

type PropsWithRedux = ITinaProps<UserCategoryTag[]> & ConnectedProps<typeof connector>;

export default connector(SelectCategoryTags);