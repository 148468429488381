import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import * as settingsActions from "modules/settings/actionCreator";

import { Tag } from "modules/common/components/authoring/models";

import Description from "./common/description";
import Label from "./common/label";
import PluginWrapper from "./common/pluginWrapper";

import { ITinaProps } from "../models/common";
import MultiselectAndChipsWithSearch from "modules/common/components/forms/inputs/multiSelectAndChipsWithSearch";
import { MultiSelectTopic, MultiSelectTopicGroup } from "modules/settings";

const SelectTopics: React.FunctionComponent<PropsWithRedux> = (props: PropsWithRedux) => {
    const {
        shouldFetch,
        tagGroups,
    } = props.tagSettings;

    const { getUserTagSettings } = props;

    React.useEffect(() => {
        if (shouldFetch || tagGroups.length === 0) getUserTagSettings();
    }, [shouldFetch, tagGroups, getUserTagSettings])

    const getProcessedTopicList = (): MultiSelectTopicGroup[] => {
        return tagGroups
            .filter(tg => !tg.tags.every(topic => topic.disabled))
            .map(tg => ({
                id: tg.id,
                name: tg.name,
                tags: tg.tags.map(t => ({ id: t.id, name: t.name, restricted: t.restricted ?? false }))
            }));
    };

    const onUpdateTopics = (topics: MultiSelectTopic[]) => props.input.onChange(topics);

    return (
        <PluginWrapper>
            <Label>
                {props.field.label}
                {props.field.description &&
                    <Description>{props.field.description}</Description>
                }
            </Label>
            <MultiselectAndChipsWithSearch
                defaultOptions={getProcessedTopicList().map(t => ({ id: t.id, name: t.name, children: t.tags }))}
                selectedOptions={props.input.value.map(t => ({ id: t.id, name: t.name, restricted: t.restricted ?? false }))}
                buttonText="Add Topics"
                buttonClassName="regular-case-button"
                onChange={onUpdateTopics}
            />
        </PluginWrapper>
    )
}

interface ISelectTopics { }

const connector = connect(
    (state: GlobalApplicationState, ownProps: ISelectTopics) => ({
        ...ownProps,
        tagSettings: state.settings.tagSettings
    }),
    {
        getUserTagSettings: settingsActions.getUserTagSettings
    }
);

type PropsWithRedux = ITinaProps<Tag[]> & ConnectedProps<typeof connector>;

export default connector(SelectTopics);