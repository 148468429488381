import React from "react";

import { Button } from "@mui/material";
import { ILoadMoreButton, LoadMoreButton } from "./loadMoreButton";

import "./styles/feedButtons.sass";

interface IActionButton {
    action: () => void;
}

interface IFeedButtons {
    viewAll?: IActionButton;
    loadMore?: ILoadMoreButton;
    inCard?: boolean;
}

export const FeedButtons: React.FunctionComponent<IFeedButtons> = (props) => {
    return <div className={`feed-buttons ${props.inCard ? "card-feed-buttons" : ""}`}>
        {
            props.viewAll &&
            <Button variant="text" color="primary" className="feed-buttons" onClick={props.viewAll?.action}>
                VIEW ALL
            </Button>
        }
        {
            props.loadMore &&
            <LoadMoreButton
                canLoadMore={props.loadMore?.canLoadMore}
                isFetching={props.loadMore?.isFetching}
                onFetchMore={(pageNumber: number) => props.loadMore?.onFetchMore(pageNumber)}
            />
        }
    </div>;
};