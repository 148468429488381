import { Button, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { KeyboardEvent, useState } from "react";
import NewsletterSender from "./newsletterSender";
import { Address, NewsletterConfigDigest, SaveNewsletterConfigModelStateErrors } from "modules/newsletters/models";
import _ from "lodash";

import "../../styles/editEmailSender.sass";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { actions } from "modules/newsletters";
import ConfirmDialog from "modules/common/components/dialogs/confirmDialog";

interface IEditEmailSenderProps {
    modelErrors?: SaveNewsletterConfigModelStateErrors;
    config: NewsletterConfigDigest;
    onErrorMessage: (errorMessage: string) => void;
    onSaveAddresses: (addresses: Address[]) => void;
    onCheckAssociatedNewsletters: (address: Address) => void;
    listSectionStyles?: React.CSSProperties;
    emailSearchStyles?: React.CSSProperties;
}

const EditEmailSender: React.FC<PropsWithRedux> = ({
    modelErrors,
    config,
    onErrorMessage,
    onSaveAddresses,
    onCheckAssociatedNewsletters,
    listSectionStyles,
    emailSearchStyles
}) => {
    const [textToSearch, setTextToSearch] = useState("");
    const [addresses, setAddresses] = useState<Address[]>(config.addresses.sort((a, b) => a.fromEmailName.localeCompare(b.fromEmailName)));
    const [filteredAddresses, setFilteredAddresses] = useState<Address[]>(config.addresses.sort((a, b) => a.fromEmailName.localeCompare(b.fromEmailName)));
    const [newSenderName, setNewSenderName] = useState("");
    const [newSenderEmailPrefix, setNewSenderEmailPrefix] = useState("");
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [addressToDelete, setAddressToDelete] = useState<Address | undefined>();

    let timeout: NodeJS.Timeout;

    const addressFilterFunc = (a: Address): boolean => a.fromEmailName.toLocaleLowerCase().includes(textToSearch) || 
        a.fromEmailAddress.toLocaleLowerCase().includes(textToSearch);

    const onKeyUp = (event: KeyboardEvent<HTMLDivElement> | undefined) => {
        if (event !== undefined && event?.code === 'Enter') {
            let filteredAddrs = addresses.filter(addressFilterFunc);

            setFilteredAddresses(filteredAddrs);
        }
        else{
            clearInterval(timeout);
            timeout = setTimeout(() => {
                let newAddresses = addresses.filter(addressFilterFunc);

                setFilteredAddresses(newAddresses);
            }, 500);
        }
    }

    const onAddNewSender = () => {
        let domain = _.last(config.fromEmailAddress.split("@")) ?? "";
        let newSender = {
            fromEmailAddress: (newSenderEmailPrefix + "@" + domain).trim(),
            fromEmailName: newSenderName.trim(),
            currentlyInUse: false
        }

        let result = validateSenderDetails(newSender);
        if(!result) return;
        
        updateAddressesWithNewAddress(newSender);
    }

    const validateSenderDetails = (newSender: Address) => {
        let domain = _.last(config.fromEmailAddress.split("@")) ?? "";
        let endCharacter = newSenderEmailPrefix.trim().charAt(newSenderEmailPrefix.length-1);
        let advancedCheck = /^[A-Za-z0-9]+([\.\_\-][A-Za-z0-9]+)*$/ //eslint-disable-line

        if(domain === "") {
            onErrorMessage("Cannot process a sender without a domain.");
            return false;
        }

        if(newSenderEmailPrefix.trim() === "" || newSenderName.trim() === "") {
            onErrorMessage("Cannot process a sender with an empty field.");
            return false;
        }

        if( endCharacter === "." || endCharacter === "_" || endCharacter === "-") {
            onErrorMessage("Cannot process a sender that ends in a special character.");
            return false;
        }

        if (!advancedCheck.test(newSenderEmailPrefix))  {
            onErrorMessage("Formatting error. Make sure no special characters are beside each other.");
            return false;
        }

        if(addresses.filter(a => a.fromEmailAddress === newSender.fromEmailAddress && a.fromEmailName === newSender.fromEmailName).length > 0) {
            onErrorMessage("Sender already exists.");
            return false;
        }

        return true;
    }

    const updateAddressesWithNewAddress = (newSender: Address) => {
        let newAddresses = addresses;
        newAddresses = newAddresses.concat(newSender).sort((a, b) => a.fromEmailName.localeCompare(b.fromEmailName));
        let newConfig = config;
        
        if(!!newConfig) newConfig.addresses = newAddresses;

        setAddresses(newAddresses);
        setFilteredAddresses(newAddresses);
        setTextToSearch("");

        onSaveAddresses(newAddresses);
    }

    const onDiscard = () => {
        setNewSenderName("");
        setNewSenderEmailPrefix("");
    }

    const onCloseDeleteDialog = () => {
        setShowDeleteDialog(false);
    }

    const onDeleteSender = () => {
        onCloseDeleteDialog();

        if (
            addressToDelete !== undefined &&
            !!addresses.find(a => a.fromEmailAddress === addressToDelete.fromEmailAddress && a.fromEmailName === addressToDelete.fromEmailName)
        ) {
            const addressesWithoutDeleted = addresses.filter(a => !(a.fromEmailAddress === addressToDelete.fromEmailAddress && a.fromEmailName === addressToDelete.fromEmailName));
            let newConfig = config;
    
            if(!!newConfig) newConfig.addresses = addressesWithoutDeleted;

            setAddresses(addressesWithoutDeleted);
            setFilteredAddresses(addressesWithoutDeleted);
            setTextToSearch("");
            
            onSaveAddresses(addressesWithoutDeleted);
        }
        else {
            onErrorMessage("Cannot delete a sender that doesn't exist.");
        }
    }

    const setAndPrepareForDelete = (addressToDelete: Address) => {
        setAddressToDelete(addressToDelete);
        setShowDeleteDialog(true);
    }

    return <div className="email-sender-container">
        <div className="sender-edit-section">
            <div className="edit-sender">
                <TextField
                    variant="outlined"
                    size="small"
                    value={newSenderName}
                    error={_.some(modelErrors?.FromEmailName)}
                    helperText={_.first(modelErrors?.FromEmailName)}
                    fullWidth
                    autoComplete="off"
                    onChange={(event) => setNewSenderName(event.target.value)}
                    placeholder="Enter 'From' name (required)"
                />
                <div className="sender-email">
                    <TextField
                        variant="outlined"
                        size="small"
                        value={newSenderEmailPrefix}
                        error={_.some(modelErrors?.FromEmailAddress)}
                        helperText={_.first(modelErrors?.FromEmailAddress)}
                        fullWidth
                        autoComplete="off"
                        onChange={(event) => setNewSenderEmailPrefix(event.target.value)}
                        placeholder="Enter email prefix (required)"
                        className="email-prefix"
                    />
                    <TextField
                        variant="outlined"
                        size="small"
                        value={"@"+_.last(config.fromEmailAddress.split("@")) || ""}
                        disabled
                        className="email-suffix"
                    />
                </div>
                <div className="sender-email-buttons-container">
                    <Button onClick={onAddNewSender} color="primary" className="add-button" disabled={!newSenderEmailPrefix || !newSenderName}>Add</Button>
                    <Button className="discard-button" onClick={onDiscard} disabled={!newSenderEmailPrefix && !newSenderName}>Discard</Button>
                </div>
            </div>
        </div>
        <div className="email-sender-list-section" style={{...listSectionStyles}}>
            <TextField
                variant="outlined"
                size="small"
                value={textToSearch}
                placeholder="Search name or email"
                InputProps={{
                    startAdornment: <SearchIcon style={{fill: "#666666", marginRight: "5px"}} />
                }}
                onChange={(event) => setTextToSearch(event.target.value.toLocaleLowerCase())}
                onKeyUp={onKeyUp}
                className="email-sender-search-bar"
                style={{...emailSearchStyles}}
            />
            <div className="sender-list">
                {
                    filteredAddresses.map((address, index) =>
                        <div key={index}>
                            <NewsletterSender 
                                onlyItem={filteredAddresses.length === 1} 
                                addressToDelete={address} 
                                onDelete={() => setAndPrepareForDelete(address)} 
                                onLocked={onCheckAssociatedNewsletters}
                            />
                        </div>
                    )
                }
            </div>
        </div>
        <ConfirmDialog
            title="Delete sender email"
            denyLabel="Cancel"
            confirmLabel="Delete"
            denyButtonProps={{ sx:{ color: "#7F7F7F" }}}
            confirmButtonProps={{sx: {backgroundColor: '#dc3838', color: 'white'}}}
            onClose={onCloseDeleteDialog}
            onConfirm={onDeleteSender}
            onDeny={onCloseDeleteDialog}
            dialogMaxWidth="sm"
            open={showDeleteDialog}
        >
            Are you sure you want to delete this sender email?
        </ConfirmDialog>
    </div>;
};
  
const connector = connect(
    (state: GlobalApplicationState, ownProps: IEditEmailSenderProps) => 
    ({ 
        ...ownProps,
        tenantId: state.tenant.id,
    }),
    {
        getAssociatedNewsletters: actions.getAssociatedNewsletters
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
  
export default connector(EditEmailSender);
