import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";
import { useMediaQuery, useTheme } from "@mui/material";

import {
    Post,
    PostAverages,
    TranslatedContent as ITranslatedContent
} from "../../models";
import * as actions from "../../actionCreator";
import * as userActions from "modules/profile/actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import EditorWrapperLayout from "modules/common/components/authoring/editorLayouts/editorWrapperLayout";
import MainEditorLayout from "modules/common/components/authoring/editorLayouts/mainEditorLayout";
import { POST_SETTINGS_EDITOR_DRAWER_WIDTH, POST_SETTINGS_TAB_VALUES } from "./postCreation";
import { ContentAnalysis, DEFAULT_TRANSLATABLE_CONTENT, TranslatableContent } from "modules/common/components/authoring/models";
import useIsMounted from "modules/common/hooks/useIsMounted";
import { getTranslatedBody, getTranslatedDescription, getTranslatedTitle } from "utils/getTranslatedContent";
import PostSettingsPanel from "./components/postSettingsPanel";
import useLanguages from "modules/common/hooks/data/useLanguages";
import PostMainPanel from "./components/postMainPanel";
import { loggedEventsApi, postsApi } from "api/instances";
import { FEATURE_ACTION_CODES } from "modules/common/models";
import usePrevious from "modules/common/hooks/usePrevious";
import { LANGUAGE_CODES } from "modules/localization/models";
import { SmartContentPaperFab } from "modules/smartContent/buttons/smartContentPaperFab";
import useIsFirstRender from "modules/common/hooks/useIsFirstRender";
import cookie from "utils/cookie";
import { PostSmartContentPanel } from "./smartContent/postSmartContentPanel";
import { ICustomCssModelv1 } from "api/files";
import { PromptTypeEnum } from "modules/smartContent/models";

const PostEditor: React.FunctionComponent<PropsWithRedux> = ({
    settingsDrawerOpen,
    selectedTab,
    fullscreenEditor,
    customCss,
    analysisIndicator,
    onChangeAnalysisIndicator,
    onChangeFullscreen,
    onToggleSettingsDrawer,
    getContentAnalysis,
    isContributor,
    ...props
}) => {
    const { currentUser, post, tenant } = props;
    const {
        availableLanguages,
        selectedLanguages,
        activeLcid,
        defaultLcid,
        lcidMappings,
        onRemoveLanguage,
        onSelectLanguage,
    } = useLanguages(post.translatedContent);

    const setTenantAveragesSetting = (toSet: boolean) => {
        setIsUsingTenant(toSet);
        cookie.setTenantAveragesSetting(toSet);

        if (!!currentAnalyses)
            updateAnalysisAverageScores(currentAnalyses, toSet);
    }

    const theme = useTheme();
    const isMounted = useIsMounted();
    const isSmallAndSmaller = useMediaQuery(theme.breakpoints.down('md'));
    const isFirstRender = useIsFirstRender();
    const contentAnalysesTabVisited = useRef<boolean>(false); // keep track if user has selected content analyses tab yet
    const bodyChangedSinceLastAnalyze = useRef<boolean>(false);
    const previousTranslatedContent = usePrevious<ITranslatedContent | undefined>(post.translatedContent); // keep track of the previous renders translated content
    const prevLcid = usePrevious<string>(activeLcid); // keep track of previous renders active lcid

    const notificationSettingsEnabled = !!post.notifications && props.isTenantNotifsEnabled;
    const reminderSettingsEnabled = !!post.notifications && !!post.notifications.reminders && props.isTenantNotifsEnabled;

    const [currentAnalyses, setCurrentAnalyses] = useState<ContentAnalysis | undefined>();
    const [fetchingAnalyses, setFetchingAnlyses] = useState<boolean>(false);
    const [initAnalyze, setInitAnalyze] = useState<boolean>(false); // this is used to allow other components to send an analyze request through this component
    const [highlightsOn, setHighlightsOn] = useState<boolean>(true);
    const [smartContentOpen, setSmartContentOpen] = useState<boolean>(false);
    const [smartContentCalloutOpen, setSmartContentCalloutOpen] = useState<boolean>(false);
    const [smartContentIndicator, setSmartContentIndicator] = useState<boolean>(false);
    const [tenantPostAverages, setTenantPostAverages] = useState<PostAverages>();
    const [globalPostAverages, setGlobalPostAverages] = useState<PostAverages>();
    const [isUsingTenant, setIsUsingTenant] = useState(cookie.getTenantAveragesSetting());

    const smartContentHasBeenOpenOnce = useRef<boolean>(false);
    const settingsOpenBeforeSmartContent = useRef<boolean | null>(null);
    const contentChangesMade = useRef<boolean>(false); // keep track of any changes made to title, summary, body in order to show smart content callout after 20s
    const defaultContent = useRef<ITranslatedContent | undefined>(post.translatedContent); // the content when post is first loaded

    const [highlightedTextInTinyEditor, setHighlightedTextInTinyEditor] = useState<string>();
    const [promptType, setPromptType] = useState<PromptTypeEnum>();
    const [usedTinyMceDropDown, setUsedTinyMceDropDown] = useState<boolean>();
    const [smartContentKeywords, setsmartContentKeywords] = useState<string | undefined>();
    const [smartContentSuggestion, setSmartContentSuggestion] = useState<string>("");

    const { translatedContent } = post;
    const title = useMemo<string>(() => getTranslatedTitle(translatedContent, activeLcid), [activeLcid, translatedContent]);
    const body = useMemo<string>(() => getTranslatedBody(translatedContent, activeLcid), [activeLcid, translatedContent]);
    const summary = useMemo<string>(() => getTranslatedDescription(translatedContent, activeLcid), [activeLcid, translatedContent]);

    const fetchTenantPostAverages = useCallback(() => {
        postsApi.GetTenantPostAverages()
            .then(result => {
                setTenantPostAverages(result);
            })
            .catch();
    }, []);

    /**
     * Get title, summary, body etc for current language
     */
    const getTranslation = useCallback((lcid: string, translation: ITranslatedContent | undefined): TranslatableContent => {
        return translation
            ? translation[lcid]
            : DEFAULT_TRANSLATABLE_CONTENT;
    }, []);

    const translation = useMemo<TranslatableContent>(() => getTranslation(activeLcid, translatedContent), [activeLcid, translatedContent, getTranslation]);

    const fetchGlobalPostAverages = useCallback(() => {
        postsApi.GetGlobalPostAverages()
            .then(result => {
                setGlobalPostAverages(result);
            })
            .catch();
    }, []);

    useEffect(() => {
        moment.locale(LANGUAGE_CODES.en);
        fetchTenantPostAverages();
        fetchGlobalPostAverages();
    }, [fetchGlobalPostAverages, fetchTenantPostAverages]);

    // show fab callout logic
    useEffect(() => {
        // after 20 seconds if no changes to title, body or summary made show the smart content callout
        const smartContentCalloutTimeout = setTimeout(() => {
            // no changes, smart content modal not open, not on small screen and no content
            if (!contentChangesMade.current && !smartContentOpen && !isSmallAndSmaller && !title && !body && !summary) {
                setSmartContentCalloutOpen(true);
            }
        }, 20000);

        return () => {
            clearTimeout(smartContentCalloutTimeout);
        }
    }, [smartContentOpen, isSmallAndSmaller, body, title, summary]);

    // show fab indicator logic
    useEffect(() => {
        if (contentChangesMade.current) {
            // if at least one field has content and at least one field does not have content
            // but not all fields have content and not all fields dont have content
            if (!smartContentHasBeenOpenOnce.current && (title || summary || body) && (!title || !summary || !body) && !(title && summary && body) && !(!title && !summary && !body))
                setSmartContentIndicator(true);
            else
                setSmartContentIndicator(false);
        }
    }, [title, body, summary]);

    /**
     * Keep track if changes have been made
     * We use this to show/not show the smart content callout and red dot indicator
     */
    useEffect(() => {
        if (contentChangesMade.current === false) {
            const defaultTranslation = getTranslation(activeLcid, defaultContent.current);

            contentChangesMade.current = defaultTranslation?.body !== body ||
                defaultTranslation?.title !== title ||
                defaultTranslation?.description !== summary;
        }
    }, [title, body, summary, getTranslation, activeLcid]);

    // handle effects of closing smart content modal
    // - remove red indicator
    // - restore settings panel open state
    useEffect(() => {
        if (!smartContentOpen) {
            if (settingsOpenBeforeSmartContent.current) {
                onToggleSettingsDrawer(settingsOpenBeforeSmartContent.current);
            }
        }
    }, [smartContentOpen, onToggleSettingsDrawer]);

    // keep track of if smart content modal has been open once
    useEffect(() => {
        if (smartContentOpen) smartContentHasBeenOpenOnce.current = true;
        else if (!isFirstRender) setSmartContentIndicator(false);
    }, [smartContentOpen, isFirstRender]);

    const updateAnalysisAverageScores = useCallback((contentAnalyses: ContentAnalysis, isUsingTenant: boolean) => {
        let analysisToModify = contentAnalyses;
        analysisToModify.readabilityIndicator.score = Math.max(analysisToModify.readabilityIndicator.score, 1);

        if (!tenantPostAverages || !globalPostAverages || !analysisToModify) {
            return;
        }

        if (isUsingTenant) {
            analysisToModify.personalismIndicator.recommendedScore = tenantPostAverages.averagePersonalism;
            analysisToModify.toneIndicator.recommendedScore = tenantPostAverages.averageTone;
            analysisToModify.readabilityIndicator.recommendedScore = Math.max(tenantPostAverages.averageReadabilityScore, 1);
            analysisToModify.sentimentIndicator.recommendedScore = tenantPostAverages.averageSentiment;
        } else {
            analysisToModify.personalismIndicator.recommendedScore = globalPostAverages.averagePersonalism;
            analysisToModify.toneIndicator.recommendedScore = globalPostAverages.averageTone;
            analysisToModify.readabilityIndicator.recommendedScore = Math.max(globalPostAverages.averageReadabilityScore, 1);
            analysisToModify.sentimentIndicator.recommendedScore = globalPostAverages.averageSentiment;
        }

        setCurrentAnalyses(analysisToModify);
    }, [tenantPostAverages, globalPostAverages]);

    const analyze = useCallback(async () => {
        bodyChangedSinceLastAnalyze.current = false;
        setFetchingAnlyses(true);

        try {
            if (!body) return;

            // only analyze if active lcid is an english language
            if (lcidMappings[activeLcid].translateAs !== LANGUAGE_CODES.en)
                return;

            let analyses = await getContentAnalysis(post.id || "00000000-0000-7368-6172-65706f696e74", body, true);
            if (isMounted()) {
                // add the red dot if we are not on the analyses tab
                onChangeAnalysisIndicator(selectedTab !== POST_SETTINGS_TAB_VALUES.CONTENT_ANALYSES);
                updateAnalysisAverageScores(analyses, isUsingTenant);
                setHighlightsOn(true);
            }
        } catch (err) { }
        finally {
            setInitAnalyze(false);
            setFetchingAnlyses(false);
        }
    }, [isUsingTenant, updateAnalysisAverageScores, selectedTab, activeLcid, isMounted, post.id, getContentAnalysis, lcidMappings, body]);

    // keep track of body changes since last analyze request
    useEffect(() => {
        const prevBody = getTranslatedBody(previousTranslatedContent, activeLcid);

        const bodyChanged = body !== prevBody || prevLcid !== activeLcid;

        // only set to true here, false should only ever be set after analyze
        // also, show red dot indicator
        if (bodyChanged) {
            bodyChangedSinceLastAnalyze.current = bodyChanged;

            if (!isFirstRender)
                onChangeAnalysisIndicator(true);
        }
    }, [body, activeLcid, previousTranslatedContent, isFirstRender, prevLcid]);

    /**
     * analyze content tab click side effect
     * analyze when:
     * 1. change to content anlyses tab
     */
    useEffect(() => {
        if (selectedTab === POST_SETTINGS_TAB_VALUES.CONTENT_ANALYSES) {
            let shouldFetch = (bodyChangedSinceLastAnalyze.current && !fetchingAnalyses) || !contentAnalysesTabVisited.current;

            if (shouldFetch)
                analyze();

            contentAnalysesTabVisited.current = true;
        }
    }, [selectedTab, activeLcid, fetchingAnalyses, analyze]);

    useEffect(() => {
        if (initAnalyze) {
            analyze();

            // record event
            loggedEventsApi.sendFeatureUsageEvent(FEATURE_ACTION_CODES.ANALYZE_AGAIN, currentUser.userId, tenant);
        }
    }, [initAnalyze, analyze, currentUser.userId, tenant]);

    const onChangePost = (value: Partial<Post>) => {
        props.updatePost(value);
    }

    /**
     * Grab body change and pass to parent change handler
     */
    const onChangeBody = (newBody: string) => {
        onChangeTranslation({ ...translation, body: newBody });
    }

    /**
     * Grab title change and pass to parent change handler
     */
    const onChangeTitle = (newTitle: string) => {
        onChangeTranslation({ ...translation, title: newTitle });
    }

    /**
     * Grab summary change and pass to parent change handler
     */
    const onChangeSummary = (newSummary: string) => {
        onChangeTranslation({ ...translation, description: newSummary });
    }

    /**
     * Handle changes to title, summary, body for current langauge
     */
    const onChangeTranslation = (newValue: TranslatableContent) => {
        let newTranslations = { ...translatedContent, [activeLcid]: { ...newValue } };
        onChangeTranslatedContent(newTranslations);
    }

    const onChangeTranslatedContent = (translatedContent: ITranslatedContent) => {
        onChangePost({ translatedContent });
    }

    const getMainEditorClassName = (): string => {
        let result: string = "";
        // on sm screens we will just overlap the two panels
        if (isSmallAndSmaller) {
            result = "main-editor-paper";
        } else {
            result = settingsDrawerOpen || smartContentOpen
                ? "main-editor-paper main-editor-paper-open"
                : "main-editor-paper main-editor-paper-closed"
        }

        return result;
    }

    const onChangeTab = (event: React.ChangeEvent, newValue: number) => {
        if (newValue === POST_SETTINGS_TAB_VALUES.CONTENT_ANALYSES) {
            onChangeAnalysisIndicator(false);

            // record event if not already on analyses tab
            if (selectedTab !== POST_SETTINGS_TAB_VALUES.CONTENT_ANALYSES)
                loggedEventsApi.sendFeatureUsageEvent(FEATURE_ACTION_CODES.SELECT_CONTENT_ANLYSIS_TAB, currentUser.userId, props.tenant);
        } else {
            setHighlightsOn(false);
        }

        props.onChangeTab(event, newValue);
    }

    /**
     * Handle focus to tiny editor
     * - turn highlights off
     */
    const onBodyFocus = () => {
        setHighlightsOn(false);
    }

    const onBookmarkClick = () => {
        // close smart content if we are opening settings
        if (!settingsDrawerOpen) {
            setSmartContentOpen(false);
        }

        onToggleSettingsDrawer();
    }

    /**
     * Show smart content callout, set highlighted text and prompt type.
     * @param highlightedText 
     * @param promptType 
     */
    const setShowSmartContentInsertOpen = (highlightedText: string, promptType: PromptTypeEnum) => {
        setSmartContentOpen(true);
        onToggleSettingsDrawer(false);
        setHighlightedTextInTinyEditor(highlightedText);
        setUsedTinyMceDropDown(true);
        setPromptType(promptType);
    }

    /**
     * Used to apply smart content suggestion to the body when using the expanded drop down and override the highlighted selection.
     * @param suggestion 
     */
    const applySmartContentSuggestion = (suggestion: string) => {
        setSmartContentSuggestion(suggestion);
    }

    /**
     * Clear smart content suggestions
     */
    const clearSmartContentSuggestion = () => {
        setSmartContentSuggestion("");
    }

    /*
     * On smart content generation, set the smart content keyword to the translated content
     */
    const generateAndOpenSmartContent = (promptTypeForGeneration: PromptTypeEnum, promptTypeForInsert: PromptTypeEnum, tinyBody?: string) => {
        if (post.translatedContent) {
            switch (promptTypeForGeneration) {
                case PromptTypeEnum.Title:
                    setsmartContentKeywords(post.translatedContent[activeLcid].title);
                    break;
                case PromptTypeEnum.Summarize:
                    setsmartContentKeywords(post.translatedContent[activeLcid].description);
                    break;
                case PromptTypeEnum.Body:
                    setsmartContentKeywords(tinyBody);
                    break;
            }
            setPromptType(promptTypeForInsert);
            setSmartContentOpen(true);
            onToggleSettingsDrawer(false);
        }
    };

    /**
     * On close smart content callout, save user settings if neccessary
     * @param skipCallouts
     */
    const onCloseSmartContentCallout = async (skipCallouts: boolean): Promise<void> => {
        // testing equality here because the language on these properties is reversed i.e. skipCallouts is dont show smart content help callouts
        // so if they are equal we need to save the new skip value
        if (skipCallouts === currentUser.showSmartContentHelpCallouts) {
            await props.saveUserSettings({ ...currentUser, showSmartContentHelpCallouts: skipCallouts });
        }

        setSmartContentCalloutOpen(false);
    }

    const onClosePostSmartContentPanel = () => {
        setSmartContentOpen(false);
        setHighlightedTextInTinyEditor("");
        setUsedTinyMceDropDown(false);
        setPromptType(undefined);
        setsmartContentKeywords(undefined);
    }

    return (
        <EditorWrapperLayout>
            <MainEditorLayout
                paperProps={{
                    className: getMainEditorClassName(),
                    elevation: fullscreenEditor ? 0 : 8 // remove shadow when fullscreen
                }}
            >
                <PostMainPanel
                    customCss={customCss}
                    fetchingAnalyses={fetchingAnalyses}
                    postId={post.id}
                    turnHighlightsOff={() => setHighlightsOn(false)}
                    fullscreenEditor={fullscreenEditor}
                    onFullscreen={onChangeFullscreen}
                    onBodyFocus={onBodyFocus}
                    translations={post.translatedContent}
                    availableLanguages={availableLanguages}
                    selectedLanguages={selectedLanguages}
                    activeLcid={activeLcid}
                    defaultLcid={defaultLcid}
                    lcidMappings={lcidMappings}
                    smartContentEnabledForTenant={props.tenantSettings.postSmartContentEnabledByTenant}
                    onRemoveLanguage={onRemoveLanguage}
                    onSelectLanguage={onSelectLanguage}
                    getDraftTranslation={props.getDraftTranslation}
                    onChangeTranslatedContent={onChangeTranslatedContent}
                    highlightedText={highlightsOn ? currentAnalyses?.highlightAnalysis?.processedHighlightText : undefined}
                    onChangeBody={onChangeBody}
                    onChangeSummary={onChangeSummary}
                    onChangeTitle={onChangeTitle}
                    translation={translation}
                    onShowSmartContentInsert={setShowSmartContentInsertOpen}
                    smartContentSuggestion={smartContentSuggestion}
                    clearSmartContentSuggestion={clearSmartContentSuggestion}
                    generateAndOpenSmartContent={generateAndOpenSmartContent}
                />
            </MainEditorLayout>
            {props.tenantSettings.showFeatures.postSmartContentEnabledForTenant && props.tenantSettings.postSmartContentEnabledByTenant &&
                <>
                    <SmartContentPaperFab
                        setCalloutOpen={setSmartContentCalloutOpen}
                        onClick={() => {
                            // store the open state of settings panel before smart content open so we can return to that state when smart content closed
                            settingsOpenBeforeSmartContent.current = settingsDrawerOpen;

                            // close settings panel and open smart content
                            setSmartContentCalloutOpen(false);
                            onToggleSettingsDrawer(false);
                            setSmartContentOpen(true);
                        }}
                        fabStyle={{
                            display: smartContentOpen ? "none" : "inline-flex", // only display when smart content is not open
                            right: settingsDrawerOpen
                                ? POST_SETTINGS_EDITOR_DRAWER_WIDTH + 27 // 27 is the space between fab and post settings panel when its open - from prototype
                                : 58,
                        }}
                        fabClassName={settingsDrawerOpen ? "open" : "closed"}
                        calloutOpen={smartContentCalloutOpen}
                        onClose={onCloseSmartContentCallout}
                        callout={currentUser.showSmartContentHelpCallouts}
                        indicator={!smartContentOpen && smartContentIndicator}
                    />
                    <PostSmartContentPanel
                        open={smartContentOpen}
                        activeTranslation={translation}
                        onClose={() => onClosePostSmartContentPanel()}
                        indicatorOn={smartContentIndicator}
                        onChangeBody={onChangeBody}
                        onChangeSummary={onChangeSummary}
                        onChangeTitle={onChangeTitle}
                        typeOfPrompt={promptType}
                        usedTinyMceDropDown={usedTinyMceDropDown}
                        highlightedText={highlightedTextInTinyEditor}
                        applySmartContentSuggestion={applySmartContentSuggestion}
                        keywords={smartContentKeywords}
                        setKeywords={(keyword: string | undefined) => setsmartContentKeywords(keyword)}
                    />
                </>}
            <PostSettingsPanel
                toggleHighlights={() => { setHighlightsOn(prev => !prev); }}
                highlightsOn={highlightsOn}
                post={post}
                fetchingAnalyses={fetchingAnalyses}
                contentAnalyses={currentAnalyses}
                tenantAverages={tenantPostAverages}
                globalAverages={globalPostAverages}
                isUsingTenantAverages={isUsingTenant}
                setIsUsingTenantAverages={setTenantAveragesSetting}
                currentUser={currentUser}
                selectedTab={selectedTab}
                onChangeTab={onChangeTab}
                analyze={() => { setInitAnalyze(true); }}
                activeLcid={activeLcid}
                analysisIndicator={analysisIndicator}
                lcidMappings={lcidMappings}
                notificationsEnabled={notificationSettingsEnabled}
                reminderNotificationsEnabled={reminderSettingsEnabled}
                hasPublished={props.hasPublished || false}
                commentsEnabled={props.tenantSettings.commentsEnabled}
                reactionsEnabled={props.tenantSettings.reactionsEnabled}
                canContributorsToggleComments={props.tenantSettings.canContributorsToggleComments}
                canContributorsToggleReactions={props.tenantSettings.canContributorsToggleReactions}
                tenantNotificationSettings={props.notificationSettings}
                onChangePost={onChangePost}
                onBookmarkClick={onBookmarkClick}
                saveUserSettings={props.saveUserSettings}
                validationChecks={props.validationChecks}
                drawerProps={{
                    open: settingsDrawerOpen,
                    PaperProps: {
                        id: "post-settings-drawer",
                    }
                }}
                isContributor={isContributor}
            />
        </EditorWrapperLayout>
    );
}

interface ComponentProps {
    settingsDrawerOpen: boolean;
    selectedTab: number;
    fullscreenEditor?: boolean;
    customCss?: ICustomCssModelv1;
    onChangeFullscreen?: () => void;
    onChangeTab: (event: React.ChangeEvent, newTab: number) => void;
    isContributor: boolean;
    onToggleSettingsDrawer: (open?: boolean) => void;
    analysisIndicator: boolean;
    onChangeAnalysisIndicator: (value: boolean) => void;
    isTenantNotifsEnabled: boolean;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        lcidMappings: state.resources.lcidMappings,
        post: state.posts.editor.post,
        currentUser: state.settings.currentUser,
        hasPublished:
            !!state.posts.editor.post &&
            state.posts.editor.post.revisions &&
            state.posts.editor.post.revisions.findIndex((r) => !!r.publishedTime) >= 0,
        notificationSettings: state.settings.notificationSettings,
        tenantSettings: state.settings.tenantSettings,
        tenant: state.tenant.id,
        validationChecks: state.posts.editor.validationChecks
    }),
    {
        getContentAnalysis: actions.getContentAnalysis,
        getDraftTranslation: actions.getDraftTranslation,
        updatePost: actions.updatePost,
        saveUserSettings: userActions.saveUserSettings,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PostEditor);
