import { createSlice } from "@reduxjs/toolkit";
import { ISnackbarsState } from "./models";

const DEFAULT_SNACKBARS_STATE: ISnackbarsState = {
    successMessage: "",
    errorMessage: "",
    infoMessage: ""
}

interface ISetMessageAction {
    payload: {
        message: string;
    }
}

export const snackbarsSlice = createSlice({
    name: "snackbars",
    initialState: DEFAULT_SNACKBARS_STATE,
    reducers: {
        SET_SUCCESS_MESSAGE: (state: ISnackbarsState, action: ISetMessageAction) => {
            state.successMessage = action.payload.message;
        },
        SET_ERROR_MESSAGE: (state: ISnackbarsState, action: ISetMessageAction) => {
            state.errorMessage = action.payload.message;
        },
        SET_INFO_MESSAGE: (state: ISnackbarsState, action: ISetMessageAction) => {
            state.infoMessage = action.payload.message;
        }
    }
});