import React from "react";

import "./styles/emptyFeed.sass";

export const EmptyFeed: React.FunctionComponent = () => {
    return <div className="empty-feed">
        <img src="/images/thumbs-up.svg" alt="thumbs up"/>

        <br />
        You're all caught up here!
    </div>;
};