import { Button, Checkbox, Chip, List, ListItem, TextField } from '@mui/material';

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import MoreOptionsButton from 'modules/common/components/buttons/moreOptionsButton';
import MoreOptionsItem from 'modules/common/components/moreOptionsItem';
import moment, { Moment } from 'moment';
import * as React from 'react';
import "./insightFilters.sass";
import { dateOptions } from 'utils/dateFormatting';
import { MultiSelectTopic, TenantSettings, TenantSettingsTagGroup } from 'modules/settings';
import Loading from 'modules/common/components/loading';

import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import EventIcon from "@mui/icons-material/Event";
import { ALL_TIME, AnalyticsReportFilters, CUSTOM_RANGE, LAST_30_DAYS, LAST_3_MONTHS, LAST_6_MONTHS, LAST_MONTH, LAST_WEEK, THIS_MONTH } from '../models';
import { connect, ConnectedProps } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';
import { Audience } from 'modules/audiences';
import FilterMoreOptionsButton from 'modules/common/components/buttons/filterMoreOptionsButton';
import LoadingCircle from 'modules/common/components/loadingCircle';
import FilterContainer from 'modules/common/components/filters/filterContainer';
import { SelectOption } from 'modules/common/models';
import { PickerLocalization } from 'modules/common/components/pickerLocalization';
import MultiSelectCalloutWithSearch from 'modules/common/components/forms/inputs/multiSelectCalloutWithSearch';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

//Update when more options are added.
enum filterOptions {
    "Last 30 days" = 0,
    "This month" = 1,
    "Last week" = 2,
    "Last month" = 3,
    "Last 3 months" = 4,
    "Last 6 months" = 5,
    "All time" = 6,
    "Custom range" = 7,
}

const FILTER_BY_AUDIENCES = "Audiences";
const FILTER_BY_TOPICS = "Topics";
const FILTER_BY_CUSTOM_AUDIENCES = "Custom Audiences";
const FILTER_BY_CUSTOM_TOPICS = "Custom Topics";

class InsightFilters extends React.Component<PropsWithRedux, ComponentState> {

    constructor(props: PropsWithRedux) {
        super(props);

        this.state = {
            selectedValue: 0,
            customStart: moment(new Date()).subtract(30, "days"),
            customEnd: moment(new Date()),
            fromText: moment().subtract(30, "days").format(dateOptions.basicFormatting),
            toText: moment().format(dateOptions.basicFormatting),
            filterBy: "",
            audienceSearchText: "",
            topicSearchText: "",
            audiences: [],
            tagGroups: [],
            audienceDropdownText: FILTER_BY_AUDIENCES,
            topicsDropdownText: FILTER_BY_TOPICS,
            selectedTopics: [],
        };
    }

    public render() {
        return (
            <FilterContainer
                filters={this.getFilters()
                }
                filterCommands={
                    <React.Fragment>
                    </React.Fragment>
                }
                filterSelection={this.getFilterSelection()}
            >
            </FilterContainer>
        )
    }

    private onChangeFilters = (filters: Partial<AnalyticsReportFilters>) => {

        this.props.onChangeFilters({ ...filters });
        this.setAudienceFilterText(this.props.filters.audiences);
        this.setTopicsFilterText(this.props.filters.topics);
    }

    private fetchLoadingIcon = () => {
        return <Loading size={16} />
    }

    private onClearFilters = () => {
        const startDateToSet = moment(new Date()).startOf("day").subtract(30, "days");
        const endDatetoSet = moment(new Date()).endOf("day");
        const startDate = startDateToSet.format(dateOptions.basicWithHours);
        const endDate = endDatetoSet.format(dateOptions.basicWithHours);

        this.setState({ filterBy: "" });
        this.onFromDateChange(startDateToSet, startDateToSet.format(dateOptions.basicFormatting), false);
        this.onToDateChange(endDatetoSet, endDatetoSet.format(dateOptions.basicFormatting), false);
        this.setState({ selectedValue: 0 });
        this.setState({ selectedTopics: []});

        this.onChangeFilters({
            startDate, endDate, rangeString: LAST_30_DAYS, topics: [], audiences: []
        });
    }

    private onAudienceTopicFilterChange = (filter) => {
        this.onChangeFilters({ topics: [], audiences: [] });

        if (filter === FILTER_BY_AUDIENCES) {
            this.setState({ audiences: this.props.audiences });
        } else if (filter === FILTER_BY_TOPICS) {
            this.setState({ tagGroups: this.props.tenantSettings.tagGroups })
        }

        this.setState({ filterBy: filter, audienceSearchText: '', topicSearchText: '' })
    }
    private setFilterToCustom = () => {
        if (this.state.selectedValue !== filterOptions["Custom range"]) {
            this.setState({ selectedValue: filterOptions["Custom range"] });
        }
    }

    private onFromDateChange = (date, value, setToCustom) => {
        this.setState({ fromText: value });
        this.setState({ customStart: date });
        let startDate = moment();

        //If date is null go back extremely far.
        if (date) {
            startDate = moment(date).startOf("day");
        }
        else {
            startDate = moment(new Date()).set("year", 2017).set("month", 0).startOf("month");
        }

        let endDate = this.state.customEnd ? moment(this.state.customEnd).endOf("day") : moment().endOf("day");

        if (setToCustom) {
            this.setFilterToCustom();
        }
        this.onChangeFilters({ startDate: startDate.format(dateOptions.basicWithHours), endDate: endDate.format(dateOptions.basicWithHours), rangeString: filterOptions[7] });
    }

    private onToDateChange = (date, value, setToCustom) => {
        this.setState({ toText: value });
        this.setState({ customEnd: date });
        let endDate = moment();

        //If date is null go back extremely far.
        if (date) {
            endDate = moment(date).endOf("day");
        }
        else {
            endDate = moment(new Date()).endOf("day");
        }

        let startDate = this.state.customStart ? moment(this.state.customStart).startOf("day") : moment(new Date()).set("year", 2017).set("month", 0).startOf("month");

        if (setToCustom) {
            this.setFilterToCustom();
        }
        this.onChangeFilters({ startDate: startDate.format(dateOptions.basicWithHours), endDate: endDate.format(dateOptions.basicWithHours), rangeString: filterOptions[7] });
    }

    private selectValue = (valueToSet: filterOptions) => {
        this.setState({ selectedValue: valueToSet });
        let startDate = "";
        let endDate = "";
        let startDateToSet = moment();
        let endDatetoSet = moment();

        switch (valueToSet) {
            case (filterOptions[LAST_30_DAYS]):
                startDateToSet = moment(new Date()).subtract(30, "days").startOf("day");
                endDatetoSet = moment(new Date()).endOf("day");

                startDate = startDateToSet.format(dateOptions.basicWithHours);
                endDate = endDatetoSet.format(dateOptions.basicWithHours);
                break;

            case (filterOptions[THIS_MONTH]):
                startDateToSet = moment(new Date()).startOf('month');
                endDatetoSet = moment(new Date()).endOf("day");

                startDate = startDateToSet.startOf('month').format(dateOptions.basicWithHours);
                endDate = endDatetoSet.format(dateOptions.basicWithHours)
                break;

            case (filterOptions[LAST_WEEK]):
                startDateToSet = moment(new Date()).startOf('week').subtract(1, "week").startOf("day");
                endDatetoSet = moment(new Date()).startOf('week').subtract(1, "week").add(6, "days").endOf("day");

                startDate = startDateToSet.format(dateOptions.basicWithHours);
                endDate = endDatetoSet.format(dateOptions.basicWithHours);
                break;

            case (filterOptions[LAST_MONTH]):
                startDateToSet = moment(new Date()).subtract(1, "month").startOf("month");
                endDatetoSet = moment(new Date()).subtract(1, "month").endOf("month");

                startDate = startDateToSet.format(dateOptions.basicWithHours);
                endDate = endDatetoSet.format(dateOptions.basicWithHours);
                break;

            case (filterOptions[LAST_3_MONTHS]):
                startDateToSet = moment(new Date()).subtract(3, "month").startOf("month");
                endDatetoSet = moment(new Date()).subtract(1, "month").endOf("month");

                startDate = startDateToSet.format(dateOptions.basicWithHours);
                endDate = endDatetoSet.format(dateOptions.basicWithHours);
                break;

            case (filterOptions[LAST_6_MONTHS]):
                startDateToSet = moment(new Date()).subtract(6, "month").startOf("month");
                endDatetoSet = moment(new Date()).subtract(1, "month").endOf("month");

                startDate = startDateToSet.format(dateOptions.basicWithHours);
                endDate = endDatetoSet.format(dateOptions.basicWithHours);
                break;

            case (filterOptions[ALL_TIME]):
                startDateToSet = moment(new Date()).set("year", 2017).set("month", 0).startOf("month");
                endDatetoSet = moment(new Date()).endOf("day");

                startDate = startDateToSet.format(dateOptions.basicWithHours);
                endDate = endDatetoSet.format(dateOptions.basicWithHours);

                this.onFromDateChange(null, null, false);
                this.onToDateChange(endDatetoSet, endDatetoSet.format(dateOptions.basicFormatting), false);
                this.onChangeFilters({ startDate, endDate, rangeString: filterOptions[valueToSet] });
                return;

            case (filterOptions[CUSTOM_RANGE]):
                startDateToSet = moment(new Date()).set("year", 2017).set("month", 0).startOf("month");
                endDatetoSet = moment(new Date());

                startDate = startDateToSet.format(dateOptions.basicWithHours);
                endDate = endDatetoSet.format(dateOptions.basicWithHours);

                //Empty filters but don't query.
                this.setState({ fromText: "" });
                this.setState({ customStart: null });
                this.setState({ toText: "" });
                this.setState({ customEnd: null });
                return;
        }

        this.onFromDateChange(startDateToSet, startDateToSet.format(dateOptions.basicFormatting), false);
        this.onToDateChange(endDatetoSet, endDatetoSet.format(dateOptions.basicFormatting), false);
        this.onChangeFilters({ startDate, endDate, rangeString: filterOptions[valueToSet] });
    }

    private getLoader = () => {
        return <LoadingCircle size={16} style={{ margin: "0px 10px" }} />
    }

    private getAudienceSearchBox = () => {
        return <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={this.state.audienceSearchText}
            placeholder="Search audience"
            InputProps={{
                startAdornment: <SearchIcon className="search-icon" />
            }}
            onChange={this.onUpdateAudienceTextToSearch}
            className="text-to-search" />
    }

    private getAudienceFilterFooter = () => {
        const audienceSelected = (this.props.filters.audiences?.length ?? [].length) > 0;
        return <Button color="primary" style={{ float: "right" }} onClick={() => this.onHandleAllAudience(audienceSelected)}>
            {audienceSelected ? "Unselect" : "Select"} All
        </Button>
    }

    private cleanupDate = (toConvert: Date | Moment | null) => {
        let sanitizedDate: Date | null = null;

        if (moment.isMoment(toConvert)) {
            sanitizedDate = toConvert?.toDate();
        }
        else {
            sanitizedDate = toConvert;
        }

        return sanitizedDate;
    }

    private getDatePickerFilter = () => {
        let sanitizedStartDate = this.cleanupDate(this.state.customStart);
        let sanitizedEndDate = this.cleanupDate(this.state.customEnd);

        return <PickerLocalization>
            <div className="calendar-section">
                <div className="calendar-element">
                    <DatePicker
                        format={dateOptions.datePickerFormatting}
                        disableFuture
                        maxDate={sanitizedEndDate ?? null}
                        value={sanitizedStartDate ?? null}
                        slotProps={{
                            textField: { size: "small", placeholder: "Select start date", inputProps: { readOnly: true, style: { color: "black", backgroundColor: "white" } } },
                            openPickerIcon: { color: "primary" }
                        }}
                        slots={{
                            openPickerIcon: this.props.isLoading ? this.fetchLoadingIcon : EventIcon
                        }}
                        disabled={this.props.isLoading}
                        onChange={(date, value) => this.onFromDateChange(date, value, true)}
                        className="date-picker"
                    />
                </div>
                <div className="calendar-text">
                    to
                </div>
                <div className="calendar-element">
                    <DatePicker
                        format={dateOptions.datePickerFormatting}
                        disableFuture
                        minDate={sanitizedStartDate}
                        value={sanitizedEndDate}
                        slotProps={{
                            textField: { size: "small", placeholder: "Select end date", inputProps: { readOnly: true, style: { color: "black", backgroundColor: "white" } } },
                            openPickerIcon: { color: "primary" }
                        }}
                        slots={{
                            openPickerIcon: this.props.isLoading ? this.fetchLoadingIcon : EventIcon
                        }}
                        disabled={this.props.isLoading}
                        onChange={(date, value) => this.onToDateChange(date, value, true)}

                        className="date-picker"
                    />
                </div>
            </div>
        </PickerLocalization>
    }

    private getDateRangeSelectFilter = () => {
        return <MoreOptionsButton text={filterOptions[this.state.selectedValue]} className="moreOptionsButton">
            <List disablePadding>
                <MoreOptionsItem
                    text={LAST_30_DAYS}
                    onClick={() => this.selectValue(filterOptions[LAST_30_DAYS])} />

                <MoreOptionsItem
                    text={THIS_MONTH}
                    onClick={() => this.selectValue(filterOptions[THIS_MONTH])} />

                <MoreOptionsItem
                    text={LAST_WEEK}
                    onClick={() => this.selectValue(filterOptions[LAST_WEEK])} />

                <MoreOptionsItem
                    text={LAST_MONTH}
                    onClick={() => this.selectValue(filterOptions[LAST_MONTH])} />

                <MoreOptionsItem
                    text={LAST_3_MONTHS}
                    onClick={() => this.selectValue(filterOptions[LAST_3_MONTHS])} />

                <MoreOptionsItem
                    text={LAST_6_MONTHS}
                    onClick={() => this.selectValue(filterOptions[LAST_6_MONTHS])} />

                <MoreOptionsItem
                    text={ALL_TIME}
                    onClick={() => this.selectValue(filterOptions[ALL_TIME])} />

                <MoreOptionsItem
                    text={CUSTOM_RANGE}
                    onClick={() => this.selectValue(filterOptions[CUSTOM_RANGE])} />
            </List>
        </MoreOptionsButton>
    }

    private GetFilterBySelectOption = () => {
        return <MoreOptionsButton text="FILTER BY" className="moreOptionsButton">
            <List disablePadding>
                <MoreOptionsItem
                    text="All audiences & topics"
                    onClick={() => this.onAudienceTopicFilterChange("")} />

                <MoreOptionsItem
                    text="Audiences"
                    onClick={() => this.onAudienceTopicFilterChange(FILTER_BY_AUDIENCES)} />

                <MoreOptionsItem
                    text="Topics"
                    onClick={() => this.onAudienceTopicFilterChange(FILTER_BY_TOPICS)} />
            </List>
        </MoreOptionsButton>
    }

    private getTopicsFilter = () => {
        return <MultiSelectCalloutWithSearch
            defaultOptions={this.props.tenantSettings.tagGroups.map(opt => ({id: opt.id, name: opt.name, children: opt.tags}))}
            selectedOptions={this.state.selectedTopics}
            onChange={this.onChangeSelectedTopics}
            buttonStyle={{
                buttonClassName:"moreOptionsButton moreOptionsButton-ml",
                buttonText: this.state.topicsDropdownText,
                buttonVariant:"outlined",
                endIcon:<ArrowDropDownIcon/>
            }}
            nestingAllowed
        />;
    }

    private onChangeSelectedTopics = (topics: MultiSelectTopic[]) => {
        let topicIds: string[] = topics.map((topic) => topic.id);
        this.props.onChangeFilters({ topics: topicIds });
        this.setTopicsFilterText(topicIds);
        this.setState({ selectedTopics: topics });
    }

    private getAudienceFilter = () => {
        return <FilterMoreOptionsButton text={this.state.audienceDropdownText} header={this.getAudienceSearchBox()} footer={this.getAudienceFilterFooter()}
            className="moreOptionsButton moreOptionsButton-ml">
            <div style={{ height: "254px", width: "350px" }}>
                <List disablePadding>
                    {this.state.audiences.map(audience => {
                        return (
                            <React.Fragment key={audience.id}>
                                <ListItem button onClick={() => this.onChangeAudience(audience.id)} className="select-popover-group-header-clickable">
                                    <Checkbox
                                        edge="start"
                                        tabIndex={-1}
                                        disableRipple
                                        size="small"
                                        color="primary"
                                        checked={!!this.props.filters.audiences!.find((selectedAudience) => selectedAudience === audience.id)}
                                    />
                                    {audience.displayName}
                                </ListItem>
                            </React.Fragment>
                        )
                    })}
                </List>
            </div>
        </FilterMoreOptionsButton>
    }

    private onHandleAllAudience = (audienceSelected: boolean) => {
        let newFilters = { ...this.props.filters };

        if (audienceSelected) {
            newFilters.audiences = [];
        }
        else {
            let currentAudiences = this.props.filters.audiences ?? [];
            let audiences = this.props.audiences;
            audiences.forEach(audience => {
                if (!currentAudiences.includes(audience.id)) {
                    currentAudiences.push(audience.id);
                }
            });
            newFilters.audiences = currentAudiences;
        }
        this.props.onChangeFilters(newFilters);

        this.setAudienceFilterText(newFilters.audiences);
    }

    private onChangeAudience = (currentAudience: string) => {
        const hasSelectedTag: boolean = !!this.props.filters.audiences!.find((selecteAudience) => selecteAudience === currentAudience);
        let audiences: string[];

        if (hasSelectedTag)
            audiences = this.props.filters.audiences!.filter((selecteAudience) => selecteAudience !== currentAudience);
        else
            audiences = this.props.filters.audiences!.concat([currentAudience]);

        this.props.onChangeFilters({ audiences: audiences });
        this.setAudienceFilterText(audiences);
    }

    private onUpdateAudienceTextToSearch = (ev: any) => {
        this.setState({audienceSearchText: ev.target.value});
        this.setState({audiences: this.props.audiences.filter(c => c.displayName.toLowerCase().includes(ev.target.value.toLowerCase()))});
    }

    private getFilters() {
        return <div className="filters-display">
            <div>
                {this.getDateRangeSelectFilter()}
            </div>
            <div style={{ height: "20px" }}>
                {this.getDatePickerFilter()}
            </div>
            <div>
                {this.GetFilterBySelectOption()}
            </div>
            {this.state.filterBy === "" &&
                <div>
                    <MoreOptionsButton text="All Audiences & Topics"
                        className="moreOptionsButton moreOptionsButton-ml" disabled>
                    </MoreOptionsButton>
                </div>}
            {this.state.filterBy === FILTER_BY_AUDIENCES &&
                <div>
                    {this.getAudienceFilter()}
                </div>}
            {this.state.filterBy === FILTER_BY_TOPICS &&
                <div>
                    {this.getTopicsFilter()}
                </div>}

            <div style={{ marginLeft: "auto" }}>
                {this.props.filters.audiences!.length > 0 &&
                    <span className="totalSubscribers">
                        Users in selected audiences: {this.props.isLoading ? this.getLoader() : <b>{this.props.totalSubscribers}</b>}
                    </span>}
                {this.props.filters.topics!.length > 0 &&
                    <span className="totalSubscribers">
                        Subscribers in selected topics: {this.props.isLoading ? this.getLoader() : <b>{this.props.totalSubscribers}</b>}
                    </span>}
                <Button
                    color="primary"
                    onClick={() => this.onClearFilters()}
                    style={{ marginLeft: "10px" }}
                    disabled={this.state.selectedValue === filterOptions[LAST_30_DAYS] && this.props.filters.topics!.length === 0 && this.props.filters.audiences!.length === 0}
                >Reset</Button>
            </div>
        </div>;
    }

    private getFilterSelection = (): JSX.Element | undefined => {
        const { filters } = this.props;

        if (!filters.topics?.length && !filters.audiences?.length)
            return undefined;

        const availableTopics: SelectOption[] = this.getAvailableTags();

        return (
            <React.Fragment>
                {filters.topics!.map((selectedTopic) =>
                    <Chip
                        key={selectedTopic}
                        label={availableTopics.find((topic) => topic.key === selectedTopic)?.text}
                        onDelete={() => this.onClearTopic(selectedTopic)}
                        deleteIcon={<CloseIcon />}
                    />
                )}
                {filters.audiences!.map((selectedAudience) =>
                    <Chip
                        key={selectedAudience}
                        label={this.state.audiences.find((audience) => audience.id === selectedAudience)!.displayName}
                        onDelete={() => this.onClearAudience(selectedAudience)}
                        deleteIcon={<CloseIcon />}
                    />
                )}
            </React.Fragment>
        );
    }

    private onClearTopic = (topic: string) => {
        const topics = this.props.filters.topics!.filter((selectedTopic) => selectedTopic !== topic);
        const selectedTopicsToSet = this.state.selectedTopics.filter(t => topics.includes(t.id));
        this.onChangeFilters({ topics: topics });
        this.setTopicsFilterText(topics);
        this.setState({selectedTopics: selectedTopicsToSet});
    }

    private onClearAudience = (audience: string) => {
        const audiences = this.props.filters.audiences!.filter((selectedAudience) => selectedAudience !== audience);
        this.props.onChangeFilters({ audiences: audiences });
        this.setAudienceFilterText(audiences);
    }


    private getAvailableTags = (): SelectOption[] => {
        if (!this.props.tenantSettings)
            return [];

        let availableTags: SelectOption[] = [];
        this.props.tenantSettings.tagGroups.map((tagGroup) => {
            if (!!tagGroup.tags.length && !tagGroup.deleted && !tagGroup.disabled) {
                availableTags.push({ key: tagGroup.id, text: tagGroup.name, type: "header" });
                tagGroup.tags.map((tag) => {
                    if (!tag.deleted && !tag.disabled)
                        availableTags.push({ key: tag.id, text: tag.name, type: "item" });
                    return tag;
                });
            }
            return tagGroup;
        });
        return availableTags;
    }

    private setAudienceFilterText(audiences?: string[]) {
        if ((audiences != null && audiences.length > 0)) {
            this.setState({ audienceDropdownText: FILTER_BY_CUSTOM_AUDIENCES });
        } else {
            this.setState({ audienceDropdownText: FILTER_BY_AUDIENCES });
        }
    }

    private setTopicsFilterText(topics?: string[]) {
        if ((topics != null && topics.length > 0)) {
            this.setState({ topicsDropdownText: FILTER_BY_CUSTOM_TOPICS });
        } else {
            this.setState({ topicsDropdownText: FILTER_BY_TOPICS });
        }
    }
}

interface ComponentState {
    selectedValue: filterOptions;
    customStart: Moment | null;
    customEnd: Moment | null;
    fromText: string;
    toText: string;
    filterBy: string;
    audienceSearchText: string;
    topicSearchText: string;
    audiences: Audience[];
    tagGroups: TenantSettingsTagGroup[];
    audienceDropdownText: string;
    topicsDropdownText: string;
    selectedTopics: MultiSelectTopic[];
}


interface ComponentProps {
    filters: Partial<AnalyticsReportFilters>;
    onChangeFilters: (filters: Partial<AnalyticsReportFilters>) => void;
    isLoading: boolean;
    tenantSettings: TenantSettings;
    audiences: Audience[];
    totalSubscribers: number;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        lcidMappings: state.resources.lcidMappings,
        tenantSettings: state.settings.tenantSettings,
        tenant: state.tenant.id
    })
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(InsightFilters);
