import * as React from "react";

import { EventFeedItem } from "../../../../../models";

import { Tile } from "./Tile";
import "../../../../../../common/components/feed/styles/windowFeed.sass";
import "./../../../styles/sectionWindow.sass";

export const Window: React.FunctionComponent<IWindowProps> = props => {
    return (
        <div className="window">
            {
                props.eventFeed.map(event =>
                    <Tile
                        key={event.id}
                        event={event}
                        onClick={props.onClickEvent}
                        cardCount={props.cardCount}
                        hideSummary={props.hideSummary}
                    />
                )
            }
        </div>
    );
};

interface IWindowProps {
    eventFeed: EventFeedItem[];
    onClickEvent: (eventId: string) => void;
    cardCount: number;
    hideSummary: boolean;
}