import * as React from "react";
import MsalAuthModule from "./msalAuthModule";
import useQueryParams, { QUERY_PARAM_KEYS } from "modules/common/hooks/useQueryParams";
import { linkApi } from "api/instances";
import useIsMounted from "modules/common/hooks/useIsMounted";
import { InteractionStatus } from "@azure/msal-browser";
import LoginLayout from "routes/_layout/login/loginLayout";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect, useMemo, useState } from "react";
import Loading from "modules/common/components/loading";
import { Button, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from "react-router-dom";
import { ExpiryLink } from "api/links";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontWeight: "bold",
        },
        success: {
            fontWeight: 500,
            color: "#376632",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center"
        },
        checkIcon: {
            marginRight: "1rem"
        },
        action: {
            alignSelf: "center",
            color: "rgb(59, 120, 171)",
            marginTop: "1rem",
            "&:hover": {
                color: "#23527c",
                background: "transparent"
            }
        },
        underline: {
            textDecoration: "underline"
        },
        container: {
            display: "flex",
            flexDirection: "column"
        },
    })
);

interface IErrorMessage {
    title: string;
    body: string;
    action: JSX.Element;
}

const Invitation: React.FunctionComponent = (props) => {
    const classes = useStyles();
    const { inProgress } = useMsal();
    const query = useQueryParams();
    const isAuthenticated = useIsAuthenticated();
    const isMounted = useIsMounted();

    const [token] = useState<string | null>(query.get(QUERY_PARAM_KEYS.INVITE_TOKEN));
    const [tenantName] = useState<string | null>(query.get(QUERY_PARAM_KEYS.TENANT_NAME));
    const [tenantIdQueryParam] = useState<string | null>(query.get(QUERY_PARAM_KEYS.TENANT_ID));
    const [isResetPasswordQueryParam] = useState<boolean | null>(query.get(QUERY_PARAM_KEYS.IS_RESET_PASSWORD_LINK) === "true");

    const [loading, setLoading] = useState<boolean>(true);
    const [expiryLink, setExpiryLink] = useState<ExpiryLink>();
    const [isResending, setIsResending] = useState<boolean>();
    const [resentSuccess, setResentSuccess] = useState<boolean>();
    const [resentError, setResentError] = useState<boolean>();

    const loadingComponent = useMemo(() => <Loading style={{ color: "#ADB7C1" }} />, []);
    const GENERIC_ERROR_MESSAGE = useMemo<IErrorMessage>(() => ({
        title: "Something went wrong",
        body: "Please try again later.",
        action: <Link className={`${classes.action} ${classes.underline}`} to={"/"}>Login with existing account</Link>
    }), [classes.action, classes.underline]);

    const INVITE_USED_ERROR_MESSAGE = useMemo<IErrorMessage>(() => ({
        title: "Something went wrong",
        body: `This ${expiryLink?.isPasswordResetLink ? "link" : "invite   "} has already been used. Please contact your administrators, internal communications team, HR or IT department for more details.`,
        action: <Link className={`${classes.action} ${classes.underline}`} to={"/"}>Login with existing account</Link>
    }), [classes.action, classes.underline, expiryLink]);

    const INVITE_DOESNT_EXIST_ERROR_MESSAGE = useMemo<IErrorMessage>(() => ({
        title: "Something went wrong",
        body: `Oops, looks like this ${expiryLink?.isPasswordResetLink ? "link" : "invite   "} doesn't exist. Please contact your administrators, internal communications team, HR or IT department for more details.`,
        action: <Link className={`${classes.action} ${classes.underline}`} to={"/"}>Login with existing account</Link>
    }), [classes.action, classes.underline, expiryLink]);

    const INVITE_EXPIRED_ERROR_MESSAGE = useMemo<IErrorMessage>(() => {
        /**
         * Send a new reset password link
         */
        const sendNewResetPasswordLink = async (): Promise<boolean> => {
            if (!token) return false;

            await linkApi.resendSparrowAccountPasswordResetLink(token);

            return true;
        };

        /**
         * Send a new social invite link
         */
        const sendNewSocialInviteLink = async (): Promise<boolean> => {
            if (!token || !tenantIdQueryParam) return false;

            await linkApi.resendSocialInvite(tenantIdQueryParam, token);

            return true;
        };

        /**
        * Resend a new invite or reset password link
        */
        const onRequestNew = async () => {
            if (!expiryLink) return;
            let success = false;
            setIsResending(true);

            try {
                const { isPasswordResetLink } = expiryLink;
                if (isPasswordResetLink)
                    success = await sendNewResetPasswordLink();
                else
                    success = await sendNewSocialInviteLink();
            } catch (error) {
                success = false;
            } finally {
                setIsResending(false);
                if (success)
                    setResentSuccess(true);
                else
                    setResentError(true);
            }
        };

        return {
            title: `Oops, looks like this ${expiryLink?.isPasswordResetLink ? "password reset link" : "invite"} has expired`,
            body: "You can request a new one by clicking the button below or contact your administrator or communications team.",
            action: <>{isResending
                ? loadingComponent
                : <Button disableRipple className={classes.action} onClick={async () => { await onRequestNew(); }}>
                    REQUEST NEW {expiryLink?.isPasswordResetLink ? "LINK" : "INVITE"}
                </Button>}
            </>
        }
    }, [classes.action, expiryLink, isResending, loadingComponent, tenantIdQueryParam, token]);

    useEffect(() => {
        const validateToken = async () => {
            if (isAuthenticated) return;

            if (token) {
                try {
                    const expiryLink = await linkApi.getExpiryLink(token, tenantIdQueryParam, isResetPasswordQueryParam);
                    if (isMounted()) {
                        setExpiryLink(expiryLink);

                        if (!expiryLink.isExists || !expiryLink.isEnabled)
                            setLoading(false);
                    }
                } catch (error) {
                    if (isMounted()) {
                        setLoading(false);
                    }
                }
            } else {
                setLoading(false);
            }
        };

        validateToken();
    }, [token, tenantName, tenantIdQueryParam, isMounted, isAuthenticated, isResetPasswordQueryParam]);

    // see if we should redirect to invite journey
    useEffect(() => {
        const inviteAsync = async () => {
            if (token && inProgress === InteractionStatus.None) {
                localStorage.clear();

                if (isResetPasswordQueryParam)
                    await MsalAuthModule.getInstance().resetSparrowPassword(token);
                else
                    await MsalAuthModule.getInstance().invite(token, tenantName || "");
            }
        }

        if (expiryLink) {
            const { isEnabled, isExists, isExpired, isUsed } = expiryLink;
            if (isEnabled && isExists && !isExpired && !isUsed)
                inviteAsync();
            else
                setLoading(false);
        }
    }, [expiryLink, inProgress, token, tenantName, isAuthenticated, isResetPasswordQueryParam]);

    // set title once done loading
    useEffect(() => {
        if (loading === false) document.title = "Sparrow Connected";
    }, [loading]);

    /**
     * Calculates if there was an error depending on state
     * @returns IErrorMessage or null
     */
    const getErrorMessage = (): IErrorMessage => {
        let errorMessage;
        if (resentSuccess) return errorMessage;

        if (expiryLink) {
            const { isUsed, isExpired, isExists, isEnabled } = expiryLink;
            if (isUsed)
                errorMessage = INVITE_USED_ERROR_MESSAGE;
            else if (isExpired)
                errorMessage = INVITE_EXPIRED_ERROR_MESSAGE;
            else if (!isExists || !isEnabled) {
                errorMessage = INVITE_DOESNT_EXIST_ERROR_MESSAGE;
            }
        } else if (!loading || resentError) {
            errorMessage = GENERIC_ERROR_MESSAGE;
        }

        return errorMessage;
    };

    const errorMessage = getErrorMessage();

    return (
        <LoginLayout helpLinks>
            {(loading || isAuthenticated) && loadingComponent}
            {errorMessage &&
                <div className={classes.container}>
                    <p className={classes.title}>{errorMessage.title}</p>
                    <p>{errorMessage.body}</p>
                    <>{errorMessage.action}</>
                </div>
            }
            {resentSuccess && expiryLink && <div className={classes.container}>
                <p className={`${classes.success}`}>
                    <CheckCircleIcon className={classes.checkIcon} />
                    {expiryLink?.isPasswordResetLink ? "Password reset link" : "Invite"} sent!
                </p>
                <p>Check your email. {expiryLink.maskedEmail && <>We have sent a new {expiryLink.isPasswordResetLink ? "link" : "invite"} to <b>{expiryLink.maskedEmail}.</b></>}</p>
                <Link className={`${classes.action} ${classes.underline}`} to={"/"}>Back to login</Link>
            </div>}
        </LoginLayout>
    );
};
export default Invitation;
