import { useDispatch } from "react-redux";
import { snackbarsSlice } from "../components/snackbars/reducer";

interface IUseSetSnackbarMessagesReturnType {
    setSuccessMessage: (message: string) => void;
    setErrorMessage: (message: string) => void;
    setInfoMessage: (message: string) => void;
}

export const UseSetSnackbarMessages = (): IUseSetSnackbarMessagesReturnType => {
    const dispatch = useDispatch();
    const setSuccessMessage = (message: string) => dispatch({ type: snackbarsSlice.actions.SET_SUCCESS_MESSAGE, payload: { message }});
    const setErrorMessage = (message: string) => dispatch({ type: snackbarsSlice.actions.SET_ERROR_MESSAGE, payload: { message }});
    const setInfoMessage = (message: string) => dispatch({ type: snackbarsSlice.actions.SET_INFO_MESSAGE, payload: { message }});

    return {
        setSuccessMessage,
        setErrorMessage,
        setInfoMessage
    };
};
