import * as React from "react"

import DownloadReports from "./action-buttons/downloadReports";
import NewPost from "./action-buttons/newPost";
import PostListing from "./post-listing/postListing";

import BasePage from "pages/common/basePage";
import Header from "pages/common/header";
import MainContent from "pages/common/mainContent";
import { PostFilterValues, TAB_IDS, defaultDraftPostFilterValues, defaultPublishedPostFilterValues, defaultSubmissionPostFilterValues } from "../models";

class PostManagementPage extends React.Component<ComponentProps, ComponentState> {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: TAB_IDS.DRAFTS,
      filters: Array(TAB_IDS.__LENGTH)
        .fill(defaultDraftPostFilterValues, 0, 1)
        .fill(defaultSubmissionPostFilterValues, 1, 2)
        .fill(defaultPublishedPostFilterValues, 2, TAB_IDS.__LENGTH) // published posts default filter sort by published desc
    };
  }

  public render() {
    return (
      <BasePage fullWidth>
        <Header
          title={"Manage Posts"}
          rightComponent={
            <React.Fragment>
              {
                !this.props.isContributor &&
                // only support reports downloads for published posts because of accompanying post analytics data and stats
                this.state.selectedTab === TAB_IDS.PUBLISHED &&
                <DownloadReports filter={this.state.filters[this.state.selectedTab]}/>
              }
              <NewPost />
            </React.Fragment>
          }
        />
        <MainContent>
          <div className="authoring-page">
            <PostListing 
              filters={this.state.filters} 
              onUpdateFilters={this.onUpdateFilters} 
              onReplaceFilters={this.onReplaceFilters} 
              selectedTab={this.state.selectedTab} 
              onSelectTab={this.onSelectTab}
            />
          </div>
        </MainContent>
      </BasePage>
    )
  }

  private onUpdateFilters = (newFilters: Partial<PostFilterValues>, callback?: (() => void | undefined)) => {
    let filters = this.state.filters;
    filters[this.state.selectedTab] = newFilters;

    this.setState({ ...this.state, filters }, callback);
  }

  private onReplaceFilters = (newFilters: Partial<PostFilterValues>[], callback?: (() => void | undefined)) => {
    this.setState({ ...this.state, filters: newFilters }, callback);
  }

  private onSelectTab = (tabIndex: number) => {
    this.setState({ ...this.state, selectedTab: tabIndex });
  }
}

interface ComponentProps {
  isContributor: boolean;
}

interface ComponentState {
  filters: Partial<PostFilterValues>[];
  selectedTab: TAB_IDS;
}

export default PostManagementPage;