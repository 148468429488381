import * as React from "react";

import Description from "./common/description";
import Label from "./common/label";
import PluginWrapper from "./common/pluginWrapper";

import { ITinaProps } from "../models/common";
import { IdNamePair } from "modules/common/models";
import MultiselectAndChipsWithSearch from "modules/common/components/forms/inputs/multiSelectAndChipsWithSearch";

export const SelectItems: React.FunctionComponent<ITinaProps<string[]>> = (props: ITinaProps<string[]>) => {

    const items = props.input.value
        ? props.input.value.map(value => ({ id: value, name: props.field.options?.find(option => option.value === value)?.label ?? "" }))
        : [];
    const [selectedItems, setSelectedItems] = React.useState<IdNamePair<string, string>[]>(items);

    const onToggleItemsList = (items: IdNamePair<string, string>[]) => {
        props.input.onChange(items.map(i => i.id));
        setSelectedItems(items);
    };

    return <PluginWrapper>
        <Label>
            {props.field.label}
            {props.field.description &&
                <Description>{props.field.description}</Description>
            }
        </Label>
        <MultiselectAndChipsWithSearch
            defaultOptions={props.field.options?.map(t => ({ id: t.value, name: t.label })) ?? []}
            selectedOptions={selectedItems}
            buttonText={props.field.buttonLabel}
            buttonClassName="add-text-button"
            onChange={onToggleItemsList}
            nestingAllowed={false}
        />
    </PluginWrapper>;
};
