import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";

import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloseIcon from "@mui/icons-material/Close";

import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { eventsApi } from 'api/instances';
import EventTypeBanner from "modules/common/components/banners/eventTypeBanner";
import Loading from "modules/common/components/loading";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import ShareDialog from "modules/common/components/dialogs/shareDialog";
import { Answers, AttendanceType, EventView as IEventView, RSVPType } from "../../models";
import Attendance from "../attendance/attendance";
import DateBlock from "../date-block/dateBlock";
import AddToCalendar from "./components/addToCalendar";
import Author from "./components/author";
import Body from "./components/body";
import CutoffTime from "./components/cutoffTime";
import EventTimes from "./components/eventTimes";
import FileAttachments from "./components/fileAttachments";
import Location from "./components/location";
import SignUp from "./components/signUp";
import SignUpForm from "./components/signUpForm";
import Tags from "./components/tags";
import Title from "./components/title";
import SnackbarWrapper from "modules/common/components/snackbars/snackbarWrapper";
import { ImageScale } from "modules/posts/models";
import { parseAnchors } from "utils/htmlUtils";
import HeroBanner from "modules/common/components/heroBanner";
import { CustomCss } from "modules/common/components/customCss";

import '../../../../styles/views.sass'
import { useDefaultLocale } from "modules/common/hooks/useDefaultLocale";

const EventView: React.FunctionComponent<PropsWithRedux> = ({
    eventId,
    tinyMceCustomCssEnabled,
    getEvent: proppedGetEvent,
    ...props
}) => {
    const [activeLcid, setActiveLcid] = useState<string>("en-us");
    const [event, setEvent] = useState<IEventView>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showSignUpOptions, setShowSignUpOptions] = useState<boolean>(false);

    const { currentUserResponse, respondingEnabled } = event || {};
    useDefaultLocale();

    // do not show download ics button if person has responded not attending
    const showAddToCalendar = !(respondingEnabled && currentUserResponse?.attendanceType === "NotAttending");

    useEffect(() => {
        const getEvent = async (eventId: string, initialLoad?: boolean) => {
            const event = await proppedGetEvent(eventId, ImageScale.Modal);

            if (!!event) {
                const newLcid: string = !!event.translatedContent![event.preferredLCID]
                    ? event.preferredLCID
                    : "en-us";

                setActiveLcid(newLcid)
                setEvent(event);

                if (initialLoad)
                    eventsApi.sendEventOpenedEvent(eventId, activeLcid);
            }

            setIsLoading(false);
        }

        if (eventId) {
            getEvent(eventId, true);
        }
    }, [eventId, proppedGetEvent, activeLcid]);

    useEffect(() => {
        return () => {
            if (event)
                eventsApi.sendEventClosedEvent(event.id, event.preferredLCID);
        }
    }, [event]);

    const getAvailableLanguages = (): JSX.Element => {
        if (!event || Object.keys(event.translatedContent).length < 2 || !props.lcidMappings)
            return <></>;

        const { lcidMappings } = props;

        const availableLcids: string[] = Object.keys(event.translatedContent);
        const availableLanguages: Language[] = availableLcids.map((lcid) => ({ language: lcidMappings[lcid] ? lcidMappings[lcid].languageShort : lcid, lcid }));

        return (
            <div className="available-languages">
                <span className="emphasis">Available In: </span>
                {availableLanguages
                    .map<React.ReactNode>((language) =>
                        <span key={language.lcid} onClick={() => onSelectLanguage(language)} className={language.lcid === activeLcid
                            ? ""
                            : "inactive-language"}>{language.language}</span>
                    )
                    .reduce((prev, curr) => [prev, ", ", curr])
                }
            </div>
        );
    }

    const onHideSignUpOptions = () => {
        setShowSignUpOptions(false);
    }

    const onSelectLanguage = (language: Language) => {
        setActiveLcid(language.lcid);
    }

    const onShowSignUpOptions = () => {
        setShowSignUpOptions(true);
    }

    const onSignUp = async (attendanceType: AttendanceType, answers: Answers): Promise<IEventView> => {
        let rsvpType: RSVPType = "initial";

        if (event?.currentUserResponse && !event.isCurrentUserNotAttending && !event.isCurrentUserOnWaitlist) {
            if (attendanceType === "InPerson" && !event.isCurrentUserAttendingInPerson)
                rsvpType = "changeStatus";
            else if (attendanceType === "Online" && !event.isCurrentUserAttendingOnline)
                rsvpType = "changeStatus";
            else if (attendanceType === "Waitlist" || attendanceType === "NotAttending")
                rsvpType = "initial";
            else
                rsvpType = "editAnswers";
        }

        const newEvent = await props.rsvp(eventId, attendanceType, answers, rsvpType);

        if (newEvent) {
            setEvent(newEvent);

            props.updateEventFeed(
                props.eventsFeed.map(event => event.id === eventId
                    ? {
                        ...event,
                        currentUserResponse: {
                            answers,
                            attendanceType
                        },
                        isCurrentUserAttending: attendanceType === "InPerson" || attendanceType === "Online",
                        isCurrentUserAttendingInPerson: attendanceType === "InPerson",
                        isCurrentUserAttendingOnline: attendanceType === "Online",
                        isCurrentUserNotAttending: attendanceType === "NotAttending",
                        isCurrentUserOnWaitlist: attendanceType === "Waitlist"
                    }
                    : event
                )
            );
        }

        return newEvent;
    }

    const getTitle = (): string => {
        let result = "";

        if (event && event.translatedContent)
            result = event.translatedContent[activeLcid].title;

        return result;

    }
    const title = getTitle();

    return (
        <>
            <CustomCss enabled={tinyMceCustomCssEnabled} />
            {isLoading
                ? <Loading />
                : (event
                    ? <>
                        <div className="event-views event-view">
                            {!!props.onClose &&
                                <IconButton
                                    onClick={props.onClose}
                                    className="close-view-event-dialog"
                                    size="large">
                                    <CloseIcon color="inherit" />
                                </IconButton>
                            }
                            <div>
                                <div className="view-page">
                                    <HeroBanner bannerColor={event.bannerColor} imageUrl={event.imageUrl} attachedContent={event.attachedContent}>
                                        <EventTypeBanner eventType={event.eventType} isRestricted={event.isRestricted} full lcid={activeLcid} />
                                        <DateBlock eventStartTime={event.eventStartTime} eventEndTime={event.eventEndTime} eventTimes={event.eventTimes} isAllDayEvent={event.isAllDayEvent} />
                                    </HeroBanner>
                                    <div className="page-content">
                                        <div className="content-heading">
                                            <Title title={title} />
                                        </div>
                                        <Location location={event.location} locationLink={event.locationLink} locationLinkLabel={event.locationLinkLabel} />
                                        <EventTimes activeLcid={activeLcid} {...event} showAddToCalendar={showAddToCalendar} />
                                        <CutoffTime cutoffTime={event.cutoffTime} eventType={event.eventType} />
                                        <Tags tags={event.tags} />
                                        <Author author={event.author.name || ""} authorEmail={event.author.email} avatarColor={event.author.avatarColor} />
                                        {getAvailableLanguages()}
                                        <Body body={parseAnchors(event.translatedContent![activeLcid].body)} />
                                        <FileAttachments fileAttachments={event.fileAttachments} />
                                    </div>
                                </div>
                            </div>
                            <div className="comments">
                                <div>Comments are not available for events yet.</div>
                            </div>
                        </div>
                        <Paper elevation={6} className="sign-up">
                            <Collapse in={!showSignUpOptions}>
                                <div className="sign-up-info">
                                    <div>
                                        {showAddToCalendar && <AddToCalendar activeLcid={activeLcid} {...event} />}
                                        {(props.shareUrlsEnabled.toggleCompanyPortalUrl || props.shareUrlsEnabled.toggleSpUrl || props.shareUrlsEnabled.toggleTeamsUrl)
                                            &&
                                            <ShareDialog
                                                articleId={event.id}
                                                articleTitle={title}
                                                articleType="event"
                                            />
                                        }
                                        <Attendance {...event} />
                                    </div>
                                    <SignUp {...event} onClick={onShowSignUpOptions} />
                                </div>
                            </Collapse>
                            <Collapse in={showSignUpOptions}>
                                <SignUpForm {...event} title={title} onSignUp={onSignUp} onClose={onHideSignUpOptions} />
                            </Collapse>
                        </Paper>
                        {/* success snackbar */}
                        <SnackbarWrapper
                            open={!!props.infoMessage.message}
                            message={props.infoMessage.message}
                            severity={props.infoMessage.severity}
                            onClose={props.clearSuccessMessage}
                            icon={
                                props.infoMessage.icon === "cancel" ? (
                                    <CancelOutlinedIcon fontSize="inherit" />
                                ) : undefined
                            }
                        />
                        {/* error snack bar */}
                        <SnackbarWrapper open={!!props.errorMessage} onClose={props.clearErrorMessage} message={props.errorMessage} severity="error" />
                        <LoadingOverlay show={props.isSaving} styles={{ paddingTop: 150 }} />
                    </>
                    : <div>
                        <div className="no-event-data">Unfortunately, this event is no longer available.</div >
                    </div>

                )}
        </>
    );
}

interface Language {
    language: string;
    lcid: string;
}

interface ComponentProps {
    eventId: string;
    onClose?: () => void;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        errorMessage: state.events.errorMessage,
        eventsFeed: state.events.eventsFeed.eventsFeed,
        isFetching: state.events.isFetching,
        isSaving: state.events.isSaving,
        lcidMappings: state.resources.lcidMappings,
        infoMessage: state.events.infoMessage,
        shareUrlsEnabled: state.settings.tenantSettings.shareUrlsConfig,
        tinyMceCustomCssEnabled: state.settings.tenantSettings.showFeatures.tinyMceCustomCssEnabled
    }),
    {
        clearErrorMessage: actions.clearErrorMessage,
        clearSuccessMessage: actions.clearSuccessMessage,
        getEvent: actions.getEvent,
        rsvp: actions.rsvp,
        updateEventFeed: actions.updateEventFeed
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EventView);
