import { IdNamePair, ITranslatedText, ISimpleUser } from "modules/common/models";

export interface IEmailListingPage extends IEmailListing {
    id: EmailStatus;
    isFetching: boolean;
}

export interface IEmailListing {
    currentPage: number;
    emails: IEmailListItem[];
    totalPages: number;
    totalEmails: number;
}

export interface IEmailFilters {
    textToSearch: string;
    status: EmailStatus;
    createdByMeOnly: boolean;
    sortBy: EmailSortStyle;
}

export enum EmailSortStyle {
    SubjectAsc = "subjectAsc",
    LastModifiedTimeAsc = "lastModifiedTimeAsc",
    LastModifiedByAsc = "lastModifiedByAsc",
    FromSenderAsc = "fromSenderAsc",
    SentTimeAsc = "sentTimeAsc",
    SentByAsc = "sentByAsc",
    SubjectDesc = "subjectDesc",
    LastModifiedTimeDesc = "lastModifiedTimeDesc",
    LastModifiedByDesc = "lastModifiedByDesc",
    FromSenderDesc = "fromSenderDesc",
    SentTimeDesc = "sentTimeDesc",
    SentByDesc = "sentByDesc",
}

const DEFAULT_EMAIL_FILTERS = {
    textToSearch: "",
    createdByMeOnly: false,
    sortBy: EmailSortStyle.LastModifiedTimeDesc
}

export enum EmailStatus {
    Draft = "draft",
    Scheduled = "scheduled",
    Sent = "sent"
}

export const DEFAULT_DRAFT_EMAIL_FILTERS: IEmailFilters = {
    ...DEFAULT_EMAIL_FILTERS,
    status: EmailStatus.Draft,
}

export const DEFAULT_SCHEDULED_EMAIL_FILTERS: IEmailFilters = {
    ...DEFAULT_EMAIL_FILTERS,
    sortBy: EmailSortStyle.SentTimeDesc,
    status: EmailStatus.Scheduled,
}

export const DEFAULT_SENT_EMAIL_FILTERS: IEmailFilters = {
    ...DEFAULT_EMAIL_FILTERS,
    sortBy: EmailSortStyle.SentTimeDesc,
    status: EmailStatus.Sent,
}

export interface IModification {
    modifiedTime: Date;
    modifiedBy: IdNamePair<string, string>;
}

export interface IEmailListItem {
    id: string;
    tenantId: string;
    createdBy: IdNamePair<string, string>;
    createdTime: Date;
    sentBy?: IdNamePair<string, string>;
    sentTime?: Date;
    lastModification: IModification;
    subject: ITranslatedText[];
    status: EmailStatus;
    fromSender: ISimpleUser;
}

export interface IEmail {
    id: string;
    tenantId: string;
    sentBy?: IdNamePair<string, string>;
    sentTime?: string;
    subject: ITranslatedText[];
    body: ITranslatedText[];
    status: EmailStatus;
    audiences: IdNamePair<string, string>[];
    sendToAllUsers: boolean;
    fromSender: ISimpleUser;
    replyAddress?: string;
}

export interface IEmailInput {
    subject: ITranslatedText[];
    body: ITranslatedText[];
    audiences: string[];
    sendToAllUsers: boolean;
    fromSender: ISimpleUser;
    replyAddress?: string
    sentTime?: string;
}

export interface IEmailSentConfirmation {
    shouldShowDialog: boolean,
    expectedSentTime: string,
    emailId: string,
}

export interface IEmailsState {
    draftEmailsList: IEmailListingPage;
    scheduledEmailsList: IEmailListingPage;
    sentEmailsList: IEmailListingPage;
    editor: {
        email: IEmail;
        changedSinceLastSaved: boolean;
        isFetching: boolean;
        isSaving: boolean;
        shouldDownloadRecipientsList: boolean;
        shouldSendTestEmails: boolean;
        testEmails: string[];
        shouldOpenOneLastCheck: boolean;
    }
    successMessage: string,
    errorMessage: string,
    sentConfirmation: IEmailSentConfirmation;
    redirectUrl?: string;
}

export interface IPersonalizationToken {
    title: string;
    description: string;
    value: PersonalizationTokenValue;
}

export enum PersonalizationTokenValue {
    UserFirstName = "FirstName",
    UserLastName = "LastName"
}

export const MERGE_TAG_PREFIX = "{{";

export const MERGE_TAG_SUFFIX = "}}";

export const EMAIL_PERSONALIZATION_TOKENS: IPersonalizationToken[] = [
    { title: "First Name", description: "This will insert the employee's first name", value: PersonalizationTokenValue.UserFirstName }, 
    { title: "Last Name",  description: "This will insert the employee's last name", value: PersonalizationTokenValue.UserLastName }
];