import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { UserRoleStrings } from "modules/authorization/models";

import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";

import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import {
    DashboardOutlined,
    PostAddOutlined,
    LocalOfferOutlined,
    SendOutlined,
    MenuBookOutlined,
    AssessmentOutlined,
    HistoryOutlined,
    PhotoLibraryOutlined,
    AccountCircleOutlined,
    SettingsOutlined,
    OpenInNewOutlined,
    AdminPanelSettingsOutlined,
    MenuBook,
    CalendarTodayOutlined,
    Description,
} from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import UpdateOptionsDialog from "modules/updateOptions/updateOptionsDialog";
import useQueryParams, { QUERY_PARAM_KEYS } from "modules/common/hooks/useQueryParams";
import { useHistory, useLocation } from "react-router";
import { getHighestRole } from "utils/userRoleUtils";
import { MenuUtils } from "routes/_layout/common/menuUtils";
import { IMenuItem, MenuItemType, MenuItem } from "routes/_layout/common/components/MenuItem";

import "../../common/styles/menuItem.sass"
import { UserRoleWrapper } from "routes/_layout/common/components/wrappers/UserRoleWrapper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            position: "static",
            overflow: "hidden auto"
        },
        poweredBy: {
            color: "#aaaaaa",
            fontSize: 10,
            marginBottom: 10,
            marginLeft: 23,
            marginTop: 10,
            opacity: 0,
            "&.expanded": {
                opacity: 1,
                transition: "opacity linear 0.1s 0.2s"
            }
        },
        updateOptions: {
            textTransform: "none",
            fontSize: 10,
            padding: 0,
            textDecoration: "underline",
            fontWeight: "normal",
            "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline"
            },
            color: "rgb(170, 170, 170)"
        },
        versionContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center"
        },
        pipe: {
            margin: "0 8px"
        }
    })
);

/**
 * If both portal pages and home screen enabled, nest in Channel Layouts,
 * otherwise show either/neither separately
 */
const processPortalPagesAndHomeScreens = (homeScreenConfigEnabled: boolean, portalPagesEnabled: boolean, items: IMenuItem[]): IMenuItem[] => {
    const portalPages: IMenuItem = {
        label: "Portal Pages",
        type: MenuItemType.Link,
        icon: <MenuBookOutlined />,
        url: "~/admin/portalPages",
        userRole: UserRoleStrings.OWNER,
        hide: !portalPagesEnabled
    };

    const homeScreens: IMenuItem = {
        label: "Home Screens",
        type: MenuItemType.Link,
        icon: <MenuBook />,
        url: "~/admin/homeScreens",
        userRole: UserRoleStrings.OWNER,
        hide: !homeScreenConfigEnabled
    };

    if (portalPagesEnabled && homeScreenConfigEnabled) {
        items = [...items,
        {
            label: "Channel Layouts",
            type: MenuItemType.Link,
            icon: <MenuBook />,
            userRole: UserRoleStrings.OWNER,
            children: [
                portalPages,
                homeScreens
            ]
        }
        ];
    } else {
        items.push(portalPages);
        items.push(homeScreens);
    }

    return items;
}

const contributorOnlyMenu = (eventsEnabled: boolean) : IMenuItem[] => [
    {
        label: "Manage My Content",
        type: MenuItemType.Header,
        userRole: UserRoleStrings.CONTRIBUTOR,
        strictRole: true
    },
    {
        label: "Posts",
        icon: <Description />,
        type: MenuItemType.Link,
        url: "~/admin/posts",
        userRole: UserRoleStrings.CONTRIBUTOR,
        strictRole: true
    },
    {
        label: "Events",
        icon: <CalendarTodayOutlined />,
        type: MenuItemType.Link,
        url: "~/admin/events",
        userRole: UserRoleStrings.CONTRIBUTOR,
        strictRole: true,
        hide: !eventsEnabled,
    }
]

const menuItems = ({
    portalPagesEnabled,
    eventsEnabled,
    documentsEnabled,
    audiencesEnabled,
    currentUser,
    categoryTagsEnabled,
    homeScreenConfigEnabled,
    emailsManagementEnabled
}: PropsWithRedux): IMenuItem[] => {
    let items: IMenuItem[] = [
        {
            label: "Dashboard",
            type: MenuItemType.Link,
            url: "~/admin/dashboard",
            icon: <DashboardOutlined />,
            userRole: UserRoleStrings.AUTHOR
        },
        { label: "", type: MenuItemType.Divider },
        {
            label: "Content Management",
            type: MenuItemType.Link,
            icon: <PostAddOutlined />,
            userRole: UserRoleStrings.AUTHOR,
            children: [
                {
                    label: "Posts",
                    type: MenuItemType.Link,
                    url: "~/admin/posts",
                    userRole: UserRoleStrings.AUTHOR,
                },
                {
                    label: "Events",
                    type: MenuItemType.Link,
                    url: "~/admin/events",
                    userRole: UserRoleStrings.AUTHOR,
                    hide: !eventsEnabled,
                },
                {
                    label: "Surveys",
                    type: MenuItemType.Link,
                    url: "~/admin/surveys",
                    userRole: UserRoleStrings.AUTHOR,
                },
                {
                    label: "Documents",
                    type: MenuItemType.Link,
                    url: "~/admin/documents",
                    userRole: UserRoleStrings.AUTHOR,
                    hide: !documentsEnabled,
                },
                {
                    label: "Quick Links",
                    type: MenuItemType.Link,
                    url: "~/admin/quickLinks",
                    userRole: UserRoleStrings.AUTHOR,
                    hide: true,
                },
            ],
        },
        {
            label: "Targeting Tools",
            type: MenuItemType.Link,
            icon: <LocalOfferOutlined />,
            userRole: UserRoleStrings.OWNER,
            children: [
                {
                    label: "Audiences",
                    type: MenuItemType.Link,
                    url: "~/admin/audiences",
                    userRole: UserRoleStrings.OWNER,
                    hide:
                        !audiencesEnabled ||
                        !currentUser.roles.includes(UserRoleStrings.OWNER),
                },
                {
                    label: "Topics (Posts & Events)",
                    type: MenuItemType.Link,
                    url: "~/admin/topics",
                    userRole: UserRoleStrings.OWNER,
                    hide: !currentUser.roles.includes(UserRoleStrings.OWNER),
                },
                {
                    label: "Category Tags (Others)",
                    type: MenuItemType.Link,
                    url: "~/admin/categoryTags",
                    userRole: UserRoleStrings.OWNER,
                    hide:
                        !categoryTagsEnabled ||
                        !currentUser.roles.includes(UserRoleStrings.OWNER),
                },
            ],
        },
        {
            label: "Messaging Center",
            type: MenuItemType.Link,
            icon: <SendOutlined />,
            userRole: UserRoleStrings.OWNER,
            children: [
                {
                    label: "Direct Messaging",
                    type: MenuItemType.Link,
                    url: "~/admin/messaging",
                    userRole: UserRoleStrings.OWNER,
                },
                {
                    label: "Emails",
                    type: MenuItemType.Link,
                    url: "~/admin/emails",
                    userRole: UserRoleStrings.OWNER,
                    hide: !emailsManagementEnabled
                },
                {
                    label: "Newsletters",
                    type: MenuItemType.Link,
                    url: "~/admin/newsletters",
                    userRole: UserRoleStrings.OWNER,
                },
            ],
        },
        { label: "", type: MenuItemType.Divider }
    ];

    // handle channel layouts menu
    items = processPortalPagesAndHomeScreens(homeScreenConfigEnabled, portalPagesEnabled, items);

    items = [...items,
        {
            label: "Media Gallery",
            type: MenuItemType.Link,
            icon: <PhotoLibraryOutlined />,
            url: "~/admin/gallery",
            userRole: UserRoleStrings.AUTHOR,
        },
        {
            label: "Activity Tools",
            type: MenuItemType.Link,
            icon: <HistoryOutlined />,
            url: "~/admin/activity",
            userRole: UserRoleStrings.OWNER,
        },
        {
            label: "Platform Analytics",
            type: MenuItemType.Link,
            icon: <AssessmentOutlined />,
            url: "~/admin/insights",
            userRole: UserRoleStrings.AUTHOR,
        },
        {
            label: "Users & Permissions",
            type: MenuItemType.Link,
            icon: <AccountCircleOutlined />,
            url: "~/admin/users",
            userRole: UserRoleStrings.OWNER,
        },
        {
            label: "Tenant Configurations",
            type: MenuItemType.Link,
            icon: <SettingsOutlined />,
            url: "~/admin/settings",
            userRole: UserRoleStrings.OWNER,
        },
        { label: "", type: MenuItemType.Divider },
        {
            label: "Tenant Management",
            type: MenuItemType.Link,
            icon: <AdminPanelSettingsOutlined />,
            url: "~/admin/tenantManagement",
            userRole: UserRoleStrings.SPARROW_SUPER_ADMIN,
        },
        ...contributorOnlyMenu(eventsEnabled),
        {
            label: "",
            type: MenuItemType.Divider,
            userRole: UserRoleStrings.CONTRIBUTOR
        },
        {
            label: "Go to Company Portal",
            icon: <OpenInNewOutlined />,
            type: MenuItemType.Link,
            url: "~/home",
            pushToBrowserHistory: true,
        }
    ];

    return items;
};


const Menu: React.FunctionComponent<PropsWithRedux> = props => {
    const [expandedMenuItem, setExpandedMenuItem] = useState("");
    const [updateDialogOpen, setUpdateDialogOpen] = useState<boolean>(false);
    const classes = useStyles();
    const query = useQueryParams();
    const location = useLocation();
    const history = useHistory();

    const isContributor = getHighestRole(props.currentUser) === UserRoleStrings.CONTRIBUTOR;

    // toggle state value of updateDialogOpen
    const toggleUpdateDialogOpen = () => {
        setUpdateDialogOpen((prev) => !prev);
    };

    // handle default update options modal behaviour
    useEffect(() => {
        const showUpdateOptions = query.get(QUERY_PARAM_KEYS.OPEN_UPDATE_OPTIONS);
        if (showUpdateOptions) {
            // remove the query param for next time component renders
            const queryParams = new URLSearchParams(location.search);
            queryParams.delete(QUERY_PARAM_KEYS.OPEN_UPDATE_OPTIONS);
            history.replace({ search: queryParams.toString() });

            setUpdateDialogOpen(showUpdateOptions === "true");
        }
    }, [query, history, location.search]);

    const getDivider = (userRole: UserRoleStrings | undefined, index: number): JSX.Element | undefined=> {
        if (!isContributor || (isContributor && userRole === UserRoleStrings.CONTRIBUTOR))
            return <Divider key={index} light/>;
    }

    return (
        <>
            <Drawer
                open={props.expand}
                variant="permanent"
                PaperProps={{
                    elevation: 5
                }}
                classes={{
                    paper: props.drawerPaperClassName
                }}
            >
                <List className={classes.list}>
                    {menuItems(props).map((menuItem, index) => {
                        switch (menuItem.type) {
                            case MenuItemType.Divider:
                                return getDivider(menuItem.userRole, index);
                            
                            case MenuItemType.Header:
                                if (props.expand)
                                    return <UserRoleWrapper key={index} userRole={menuItem.userRole} strictRole={menuItem.strictRole}>
                                        <Typography className="list-item-header">
                                            <b>Manage My Content</b>
                                        </Typography>
                                    </UserRoleWrapper>
                                return <></>;

                            case MenuItemType.Link:
                                const selected = new MenuUtils(props.tenant, props.locationPathName)
                                    .isLinkItemSelected(menuItem);
                                return (
                                    <MenuItem
                                        key={index}
                                        expand={props.expand}
                                        expandedMenuItem={expandedMenuItem}
                                        menuItem={menuItem}
                                        selected={selected}
                                        setExpand={props.setExpand}
                                        setExpandedMenuItem={setExpandedMenuItem}
                                    />
                                );
                            default:
                                return <></>;
                        }
                    })}
                </List>
                
                <div className={`${classes.poweredBy} ${props.expand ? "expanded" : ""}`}>
                    <div>Powered by Sparrow Connected</div>
                    <div className={classes.versionContainer}>
                        <span>{process.env.REACT_APP_ADMINPORTAL_VERSION}</span>
                        {
                            !isContributor &&
                            <>
                                <span className={classes.pipe}>|</span>
                                <Button
                                    variant="text"
                                    disableRipple
                                    className={`${classes.updateOptions}`}
                                    onClick={() => { toggleUpdateDialogOpen(); }}>
                                    Update Options
                                </Button>
                            </>
                        }
                    </div>
                </div>
            </Drawer>
            <UpdateOptionsDialog open={updateDialogOpen} onClose={toggleUpdateDialogOpen} />
        </>
    );
}

interface ComponentProps {
    expand?: boolean;
    locationPathName: string;
    setExpand: (expand: boolean) => void;
    tenant: string;
    drawerPaperClassName: string;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        audiencesEnabled: state.settings.tenantSettings.showFeatures.audiences,
        documentsEnabled: state.settings.tenantSettings.showFeatures.documentsEnabled && state.settings.tenantSettings.documentsEnabled,
        eventsEnabled: state.settings.tenantSettings.mobileEventsEnabled,
        categoryTagsEnabled: state.settings.tenantSettings.showFeatures.categoryTagsEnabled,
        portalPagesEnabled: state.settings.tenantSettings.showFeatures.portalPagesEnabled && state.settings.tenantSettings.portalPagesEnabled,
        homeScreenConfigEnabled: state.settings.tenantSettings.showFeatures.homeScreenConfigEnabled,
        currentUser: state.settings.currentUser,
        emailsManagementEnabled: state.settings.tenantSettings.showFeatures.emailManagementEnabled
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(Menu);
