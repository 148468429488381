import * as React from "react";
import Box from "@mui/material/Box";

import DateBlock from "modules/events/components/date-block/dateBlock";
import { EventFeedItem } from "modules/events";
import { EventInfo } from "../eventInfo";
import { IOptionalEventContent } from "modules/portalPages/tinacms/models/feed";
import { FeedLayout } from "modules/portalPages/tinacms/models/common";

export const Tile: React.FunctionComponent<IEventTile> = ({
    event,
    onClick,
    optionalEventContent
}) => {
    if (!event) return (null);

    const selectPost = () => onClick(event.id);

    return (
        <div onClick={selectPost} className="event">
            <Box position={"relative"}>
                <DateBlock eventStartTime={event.eventStartTime} eventEndTime={event.eventEndTime} isAllDayEvent={event.isAllDayEvent} />
            </Box>
            <EventInfo
                event={event}
                layout={FeedLayout.List}
                hideSummary={optionalEventContent.hideSummary}
                hideTopics={optionalEventContent.hideTopics}
                hideStartDate
                />
        </div>
    );
};

interface IEventTile {
    event: EventFeedItem;
    onClick: (eventId: string) => void;
    optionalEventContent: IOptionalEventContent;
}
