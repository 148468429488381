import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import * as actions from "modules/posts/actionCreator";
import * as settingsActions from "modules/settings/actionCreator";

import Loading from "modules/common/components/loading";
import { PostFeedFilters, PostFeedItem } from "../../models";
import { DialogPostView } from "../post-view/dialogPostView";
import Filters from "./components/filters/Filters";
import Card from "./components/layouts/card/Card";

import Typography from "@mui/material/Typography";

import { FeedButtons } from "../../../common/components/feed/feedButtons";
import "./styles/NewsFeed.sass";


class MyFeed extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
        super(props);

        this.state = {
            selectedPostId: props.selectedPostId || "",
        };
    }

    public componentDidMount() {
        this.props.getTenantConfig();
        if (this.props.shouldFetch) {
            this.fetchFeed({}, false, 1);
        }
    }

    public componentDidUpdate(prevProps: PropsWithRedux) {
        if (this.props.shouldFetch && (this.props.shouldFetch !== prevProps.shouldFetch)) {
            this.fetchFeed({}, false, 1);
        }
    }

    public render() {
        const { postsFeed } = this.props;

        if (this.props.shouldFetch || !this.props.currentUser.layout)
            return <Loading />;

        return (
            <div id="news-feed">
                <Filters
                    onChangeFilters={this.onChangeFilters}
                    onClearFilters={this.onClearFilters}
                />
                <div>
                    {(!postsFeed.length && this.props.fetching)
                        ? <Loading />
                        : <React.Fragment>
                            {this.props.filtersApplied &&
                                <Typography variant="h2" className="feed-results">Results</Typography>
                            }
                            {postsFeed.length === 0
                                ? <div className="no-feed">No posts were found</div>
                                : this.getLayout(this.props)
                            }
                            <FeedButtons
                                loadMore={{
                                    isFetching: this.props.fetching,
                                    canLoadMore: this.props.canLoadMore,
                                    onFetchMore: this.continueFetching
                                }}
                            />
                        </React.Fragment>
                    }
                    <DialogPostView
                        isPublic
                        onClose={this.unselectPost}
                        postId={this.state.selectedPostId}
                        open={!!this.state.selectedPostId}
                    />
                </div>
            </div>
        );
    }


    private onChangeFilters = (filters: Partial<PostFeedFilters>) => {
        const updatedFilters = { ...this.props.filters, ...filters };
        this.props.clearPostFeed();
        this.props.setPostFeedFilters(updatedFilters);
        this.fetchFeed(updatedFilters, true, 1);
    }

    private onClearFilters = () => {
        this.props.clearPostFeedFilters();
        this.props.clearPostFeed();
    }

    private continueFetching = (pageNumber: number) => {
        const updatedFilters = {
            ...this.props.filters,
        };
        this.fetchFeed(updatedFilters, this.props.filtersApplied, pageNumber);
    }


    private fetchFeed = (filters: Partial<PostFeedFilters>, filtersApplied: boolean, pageNumber: number) => {
        this.props.fetchPostsFeed(filters, filtersApplied, pageNumber);
    }


    /** FUTURE FUNCTIONALITY
     * For now, the user portal will all use the 3-card layout.
     */
    private getLayout(props: PropsWithRedux): JSX.Element {
        // switch (this.props.currentUser.layout) {
        //   case "card":
        //     return <Card cardCount={3} postsFeed={props.postsFeed} onPostSelected={this.selectPost} />;
        //   case "column":
        //     return <Column cardCount={3} postsFeed={props.postsFeed} preferredLCID={props.currentUser.preferredLCID} onPostSelected={this.selectPost} />;
        //   case "window":
        //     return <Window cardCount={3} postsFeed={props.postsFeed} preferredLCID={props.currentUser.preferredLCID} onPostSelected={this.selectPost} />;
        //   default:
        //     return <Card cardCount={3} postsFeed={props.postsFeed} preferredLCID={props.currentUser.preferredLCID} onPostSelected={this.selectPost} />;
        // }
        return <Card cardCount={3} postsFeed={props.postsFeed} preferredLCID={props.currentUser.preferredLCID} onPostSelected={this.selectPost} />;
    }

    private selectPost = (post: PostFeedItem) => {
        this.setState({ selectedPostId: post.id });
        if (!post.lastReadTime)
            this.props.updatePostsFeed(
                this.props.postsFeed.map(article => article.id === post.id && !post.lastReadTime
                    ? { ...article, lastReadTime: new Date().toISOString() }
                    : article
                )
            );
    }

    private unselectPost = () => {
        this.setState({ selectedPostId: "" });
    }
}

interface ComponentProps {
    selectedPostId?: string;
}

interface ComponentState {
    selectedPostId: string;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        postsFeed: state.posts.postsFeed.postsFeed,
        canLoadMore: state.posts.postsFeed.canLoadMore,
        fetching: state.posts.postsFeed.fetching,
        filters: state.posts.postsFeed.filters,
        filtersApplied: state.posts.postsFeed.filtersApplied,
        shouldFetch: state.posts.postsFeed.shouldFetch,
        currentUser: state.settings.currentUser
    }),
    {
        clearPostFeed: actions.clearPostFeed,
        clearPostFeedFilters: actions.clearPostFeedFilters,
        fetchPostsFeed: actions.fetchPostsFeed,
        setPostFeedFilters: actions.setPostFeedFilters,
        updatePostsFeed: actions.updatePostsFeed,
        getTenantConfig: settingsActions.getTenantSettings
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(MyFeed);
