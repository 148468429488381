import * as React from "react";

import { IOptionalEventContent } from "modules/portalPages/tinacms/models/feed";
import { Tile } from "./Tile";
import { EventFeedItem } from "../../../../../models";

import "../../../styles/sectionList.sass";

const List: React.FunctionComponent<IEventList> = (props: IEventList) => {
    return (
        <div className="list">
            {
                props.eventFeed.map(event =>
                    <Tile
                        key={event.id}
                        event={event}
                        onClick={props.onClickEvent}
                        optionalEventContent={props.optionalContent}
                    />
                )
            }
        </div>
    );
};

interface IEventList {
    eventFeed: EventFeedItem[];
    onClickEvent: (eventId: string) => void;
    optionalContent: IOptionalEventContent
}

export default List;