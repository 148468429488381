import { documentsApi } from "api/instances";
import { VIEWABLE_FILE_TYPES } from "modules/documents/constants";
import { DocumentFeedItem } from "modules/documents/models";
import { getMimeType } from "modules/documents/utilities/getMimeType";
import { FileDownloader } from "utils/fileDownloader";

export class DocumentActions {
    private updateAction?: () => void;

    constructor(updateAction?: () => void) {
        this.updateAction = updateAction;
    }

    private update = () => {
        if (this.updateAction) this.updateAction();
    }

    public download = async (selectedDocument: DocumentFeedItem) => {
        const file = {
            name: `${selectedDocument.fileName}.${selectedDocument.fileType}`
        };

        try {
            const data = await documentsApi.GetDocumentFile(selectedDocument.id);

            new FileDownloader(file).downloadDoc(data);
            this.update();
        }
        catch (_) { }
    };

    public openInNew = async (selectedDocument: DocumentFeedItem) => {
        try {
            const file = await documentsApi.GetDocumentFile(selectedDocument.id);
            
            const blob = new Blob([file], { type: getMimeType(selectedDocument.fileType) });
            const fileURL = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = fileURL;
            window.open(fileURL);

            this.update();
        }
        catch (_) {}
    };

    public select = async (selectedDocument: DocumentFeedItem) => {
        if (VIEWABLE_FILE_TYPES.includes(selectedDocument.fileType.toLowerCase())) {
            await this.openInNew(selectedDocument);
        }
        else {
            await this.download(selectedDocument);
        }
    };
}