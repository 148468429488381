import React from "react";

import SaveAltIcon from "@mui/icons-material/SaveAlt";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";

import { DocumentFeedItem } from "modules/documents/models";
import DocumentShareButton from "./documentShareButton";

interface IDocumentActionButtons {
    document: DocumentFeedItem;
    onDownloadDocument: (document: DocumentFeedItem) => Promise<void>;
    onInspectDocument: (document: DocumentFeedItem) => void;
}

export const DocumentActionButtons: React.FunctionComponent<IDocumentActionButtons> = ({
    document,
    onDownloadDocument,
    onInspectDocument
}) => {
    return (<React.Fragment>
        <IconButton title="Download" size="small" onClick={async () => await onDownloadDocument(document)}>
            <SaveAltIcon fontSize="small" />
        </IconButton>
        <IconButton title="More Info" size="small" onClick={() => onInspectDocument(document)}>
            <InfoOutlinedIcon fontSize="small" />
        </IconButton>
        <DocumentShareButton documentId={document.id}></DocumentShareButton>
    </React.Fragment>);
};
