import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { EventFeedFilters } from "../../../../models";

import AuthoringSearch from "modules/common/components/filters/authoringSearch";
import FilterContainer from "modules/common/components/filters/filterContainer";
import FilterDropdown from "modules/common/components/filters/filterDropdown";

import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import TextField from "@mui/material/TextField";

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { SelectOption } from "modules/common/models";
import { tagsApi } from "api/instances";
import { getAvailableTags } from "utils/filterTagsUtils";


const availableEventTypes: SelectOption[] = [
  { key: "standard", text: "Standard", type: "item" },
  { key: "mandatory", text: "Mandatory", type: "item" },
  { key: "informational", text: "Informational", type: "item" }
];

class Filters extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      canSearch: !!props.filters.eventTypes!.length || !!props.filters.tags!.length || !!props.filters.textToSearch,
      lcidToSearch: props.filters.lcidToSearch || "",
      textToSearch: props.filters.textToSearch || "",
      tagEventCounts: undefined, // populate after tags are retrieved / component is mounted
    };
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.filters.lcidToSearch !== prevProps.filters.lcidToSearch)
      this.setState({ lcidToSearch: this.props.filters.lcidToSearch || "" });

    if (this.props.filters.textToSearch !== prevProps.filters.textToSearch)
      this.setState({ textToSearch: this.props.filters.textToSearch || "" });
  }

  public async componentDidMount() {
    this.setState({ tagEventCounts: await tagsApi.getTagEventCounts() });
  }

  public render() {
    const { filters } = this.props;

    const availableTags: SelectOption[] = getAvailableTags(this.props.tagSettings, this.state.tagEventCounts);

    return (
        <FilterContainer
          filters={
            <React.Fragment>
              <TextField
                variant="outlined"
                size="small"
                value={this.state.textToSearch}
                placeholder="Search events"
                InputProps={{
                  startAdornment: (
                    <SearchIcon className="search-icon" />
                  ),
                }}
                onChange={this.onUpdateTextToSearch}
                onKeyUp={this.onKeyUp}
                className="text-to-search"
              />
              <FilterDropdown text="Topics">
                  <List disablePadding>
                    {availableTags.map((availableTag) => {
                      if (availableTag.type === "header")
                        return <ListSubheader key={availableTag.key} disableSticky className="select-popover-group-header">{availableTag.text}</ListSubheader>;
                      return (
                        <ListItem key={availableTag.key} dense button onClick={() => this.onChangeTags(availableTag.key)}>
                          <ListItemIcon className="callout-checkbox">
                            <Checkbox
                              edge="start"
                              tabIndex={-1}
                              disableRipple
                              size="small"
                              color="primary"
                              checked={!!filters.tags!.find((selectedTag) => selectedTag === availableTag.key)}
                            />
                          </ListItemIcon>
                          <ListItemText primary={availableTag.text} />
                        </ListItem>
                      );
                    })}
                </List>
              </FilterDropdown>
              <FilterDropdown text="Types">
                <List disablePadding>
                  {availableEventTypes.map((availableEventType) => (
                    <ListItem key={availableEventType.key} dense button onClick={() => this.onChangeEventTypes(availableEventType.key)}>
                      <ListItemIcon className="callout-checkbox">
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                          size="small"
                          color="primary"
                          checked={!!filters.eventTypes!.find((selectedEventType) => selectedEventType === availableEventType.key)}
                        />
                      </ListItemIcon>
                      <ListItemText primary={availableEventType.text} />
                    </ListItem>
                  ))}
                </List>
              </FilterDropdown>
            </React.Fragment>
          }
          filterCommands={
            <AuthoringSearch
              canSearch={this.state.canSearch}
              onApplyFilters={this.onChangeTextToSearch}
              onClearFilters={this.onClearFilters}
            />
          }
          filterSelection={this.getFilterSelection()}
        />
    );
  }

  private getFilterSelection = (): JSX.Element | undefined => {
    const { filters } = this.props;

    if (!filters.textToSearch && !filters.tags!.length && !filters.eventTypes!.length)
      return undefined;

    const availableTags: SelectOption[] = getAvailableTags(this.props.tagSettings, this.state.tagEventCounts);

    return (
      <React.Fragment>
        {!!filters.textToSearch &&
          <Chip
            key="search-text"
            label={`"${filters.textToSearch}"`}
            onDelete={this.onClearTextToSearch}
            deleteIcon={<CloseIcon />}
          />
        }
        {filters.tags!.map((selectedTag) =>
          <Chip
            key={selectedTag}
            label={availableTags.find((tag) => tag.key === selectedTag)!.text}
            onDelete={() => this.onClearTag(selectedTag)}
            deleteIcon={<CloseIcon />}
          />
        )}
        {filters.eventTypes!.map((selectedEventType) =>
          <Chip
            key={selectedEventType}
            label={availableEventTypes.find((eventType) => eventType.key === selectedEventType)!.text}
            onDelete={() => this.onClearEventType(selectedEventType)}
            deleteIcon={<CloseIcon />}
          />
        )}
      </React.Fragment>
    );
  }

  
  private onChangeFilters = (value: Partial<EventFeedFilters>) => {
    this.props.onChangeFilters({ ...this.props.filters, lcidToSearch: this.state.lcidToSearch, textToSearch: this.state.textToSearch, ...value, ignoreSubscriptions: true });

    let currentFilters = {...this.props.filters, ...value};
    if ( currentFilters.eventTypes?.length === 0 && currentFilters.eventStates?.length === 0 && currentFilters.tags?.length === 0 && this.state.textToSearch === "") {
      this.clearCanSearch();
    }
    else {
      this.setCanSearch();
    }
  }

  private onChangeEventTypes = (currentEventType: string) => {
    const hasSelectedEventType: boolean = !!this.props.filters.eventTypes!.find((selectedEventType) => selectedEventType === currentEventType);
    if (hasSelectedEventType)
      this.onChangeFilters({ eventTypes: this.props.filters.eventTypes!.filter((selectedEventType) => selectedEventType !== currentEventType) });
    else
      this.onChangeFilters({ eventTypes: this.props.filters.eventTypes!.concat([currentEventType]) });
  }

  private onChangeLcidToSearch = (lcidToSearch: string) => {
    this.onChangeFilters({ lcidToSearch });
  }

  private onChangeTags = (currentTag: string) => {
    const hasSelectedTag: boolean = !!this.props.filters.tags!.find((selectedTag) => selectedTag === currentTag);
    if (hasSelectedTag)
      this.onChangeFilters({ tags: this.props.filters.tags!.filter((selectedTag) => selectedTag !== currentTag) });
    else
      this.onChangeFilters({ tags: this.props.filters.tags!.concat([currentTag]) });
  }

  private onChangeTextToSearch = () => {
    this.onChangeFilters({ textToSearch: this.state.textToSearch });
  }
  
  private onClearEventType = (eventType: string) => {
    this.onChangeFilters({ eventTypes: this.props.filters.eventTypes!.filter((selectedEventType) => selectedEventType !== eventType) });
  }

  private onClearFilters = () => {
    this.clearCanSearch();
    this.props.onClearFilters();
  }

  private onClearTag = (tag: string) => {
    this.onChangeFilters({ tags: this.props.filters.tags!.filter((selectedTag) => selectedTag !== tag) });
  }

  private onClearTextToSearch = () => {
    this.onChangeFilters({ textToSearch: "" });

    if(this.hasNoFilters()) {
      this.clearCanSearch();
    }
  }

  private onKeyUp = (key) => {
    if (key.keyCode === 13)
      this.onChangeTextToSearch();
  }

  private onUpdateTextToSearch = (event) => {
    this.setState({ textToSearch: event.target.value });

    if(event.target.value !== "" && !this.state.canSearch) {
      this.setState({canSearch: true});
    }
    else if(event.target.value === "" && this.hasNoFilters()) {
      this.setState({canSearch: false});
    }
  }

  private hasNoFilters = () => {
    return (this.props.filters.eventTypes?.length === 0 
      && this.props.filters.eventStates?.length === 0 
      && this.props.filters.tags?.length === 0);
  }
  
  private clearCanSearch = () => {
    this.setState({ canSearch: false });
  }

  private setCanSearch = () => {
    this.setState({ canSearch: true });
  }
}

interface ComponentProps {
  onChangeFilters: (filters: Partial<EventFeedFilters>) => void;
  onClearFilters: () => void;
}

interface ComponentState {
  canSearch: boolean;
  lcidToSearch: string;
  textToSearch: string;
  tagEventCounts: { [key: string]: number; } | undefined;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    filters: state.events.eventsFeed.filters,
    clientSettings: state.settings.clientSettings,
    lcidMappings: state.resources.lcidMappings,
    tagSettings: state.settings.tagSettings
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(Filters);