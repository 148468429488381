import * as React from "react";
import { connect, ConnectedProps } from "react-redux"
import { GlobalApplicationState } from "globalApplicationState";
import { actions } from "../";
import { ThemeSettings, SpecialtyPostColors, MobileThemeColors } from "../models";

import { ChromePicker } from 'react-color';
import SpecialtyBanner from 'modules/common/components/specialtyBanner';
import { hexCodeFromText } from "utils/hexFunctions";


const DEFAULT_THEME_COLOR = "#174e87"
const DEFAULT_BRAND_LIGHT_COLOR = "#ffffff";
const DEFAULT_ACCENT_COLOR = "#ffffff";
const DEFAULT_SPECIALTY_BANNER_COLORS = { mandatory: "#004e79", compliance: "#faaf18", complianceChecked: "#05b34b", breaking: "#fe0004", featured: "#e6911a", restricted: "#b72026", public: "#fcfcf9"}

class ThemeSettingsForm extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props) {
        super(props);
        this.state = { 
            isPendingBrandColorValid: true, 
            isPendingSpecialtyOptionsValid: { mandatory: true, compliance: true, complianceChecked: true, breaking: true, featured: true, restricted: true, public: true},
            isPendingAccentColorValid: true,
            isPendingBrandLightColorValid: true,

            pendingBrandColor: props.brandColor, 
            pendingAccentColor: props.accentColor,
            pendingBrandLightColor: props.brandLightColor,

            pendingSpecialtyOptionColors: props.specialtyColors,
            pendingMobileOptionColors: props.mobileThemeColors,

            showingBrandColorPicker: false,
            showingAccentColorPicker: false,
            showingBrandLightColorPicker: false,
            showingSpecialtyOptionColorPicker: { mandatory: false, compliance: false, complianceChecked: false, breaking: false, featured: false, restricted: false, public: false },
        };
    }

    public shouldComponentUpdate(nextProps, nextState) {
        return (
            
            this.props.brandColor !== nextProps.brandColor
            || this.props.accentColor !== nextProps.accentColor
            || this.props.brandLightColor !== nextProps.brandLightColor
            || this.props.tenantDisplayName !== nextProps.tenantDisplayName
            || this.props.specialtyColors !== nextProps.specialtyColors
            || this.props.translatedContent !== nextProps.translatedContent


            || this.state.isPendingBrandColorValid !== nextState.isPendingBrandColorValid
            || this.state.isPendingAccentColorValid !== nextState.isPendingAccentColorValid
            || this.state.isPendingBrandLightColorValid !== nextState.isPendingBrandLightColorValid
            || this.state.isPendingSpecialtyOptionsValid !== nextState.isPendingSpecialtyOptionsValid

            || this.state.pendingBrandColor !== nextState.pendingBrandColor
            || this.state.pendingAccentColor !== nextState.pendingAccentColor
            || this.state.pendingBrandLightColor !== nextState.pendingBrandLightColor
            || this.state.pendingSpecialtyOptionColors !== nextState.pendingSpecialtyOptionColors

            || this.state.showingBrandColorPicker !== nextState.showingBrandColorPicker
            || this.state.showingAccentColorPicker !== nextState.showingAccentColorPicker
            || this.state.showingBrandLightColorPicker !== nextState.showingBrandLightColorPicker
            || this.state.showingSpecialtyOptionColorPicker !== nextState.showingSpecialtyOptionColorPicker
             );
    }

    public componentWillReceiveProps(nextProps: PropsWithRedux) {
        this.setState({ 
            ...this.state,
            isPendingBrandColorValid: true, 
            isPendingAccentColorValid: true,
            isPendingBrandLightColorValid: true,
            isPendingSpecialtyOptionsValid: { mandatory: true, compliance: true, complianceChecked: true, breaking: true, featured: true, restricted: true, public: true},
            pendingBrandColor: nextProps.brandColor!,
            pendingAccentColor: nextProps.accentColor!,
            pendingBrandLightColor: nextProps.brandLightColor!,
            pendingSpecialtyOptionColors: nextProps.specialtyColors,
        });
    }

    public render() {
        let working = this.props.saving || this.props.fetching;

        let brandColorPicker = (
        <div style={{marginTop: this.state.pendingBrandColor === DEFAULT_THEME_COLOR ? "45px" : "5px"}}>
            <div onClick={(e) => { e.stopPropagation(); }}>
                <ChromePicker                
                    disableAlpha={true}
                    color={this.state.pendingBrandColor}
                    onChangeComplete={this.handleBrandColorChangedFromPicker} />
            </div>
        </div>);

        return <div>
            <div className="form-section">
                <h4>Theme</h4>
                <div className="row">
                    <div className="col-300">
                        <div className="form-group color">
                            <label>Organization name</label>
                            <div className="input-group">
                                <input className="form-control" type="text"
                                    value={this.props.tenantDisplayName}
                                    readOnly={working}
                                    onChange={this.handleNameChange} />
                            </div>
                        </div>
                        <div className="form-group color">
                            <label className={this.state.isPendingBrandColorValid ? "" : " has-error"}>
                                Brand Color {this.state.isPendingBrandColorValid ? "" : " - invalid color code"}
                            </label>
                            <div className="input-group">
                                <input
                                    className={"form-control" + (this.state.isPendingBrandColorValid ? "" : " has-error")}
                                    onBlur={(e) => this.validatePrimaryColor(e, "brand")}
                                    onClick={() => this.colorBlockClicked("brand")}
                                    onChange={(e) => this.handlePrimaryColorChanged(e, "brand")}
                                    value={this.state.pendingBrandColor}
                                    style={{
                                        borderRightWidth: "35px",
                                        borderRightColor: this.state.pendingBrandColor
                                    }}
                                />
                            </div>
                            <button className="reset-btn" onClick={() => this.resetColor(DEFAULT_THEME_COLOR, "brand")} hidden={this.state.pendingBrandColor === DEFAULT_THEME_COLOR}>Reset Default</button>
                            <div className="close-btn" onClick={() => this.colorBlockClicked("brand")} hidden={!this.state.showingBrandColorPicker}>
                                <i className="material-icons">clear</i>
                            </div>
                            {this.state.showingBrandColorPicker ? brandColorPicker : null}
                        </div>
                    </div>
                </div>
            </div>

           <div className="form-section">
                <h4>Specialty Post Banner Colors</h4>
                <div className="row">
                    <div className="col-300">
                        {Object.keys(this.props.specialtyColors || {}).map((key, i) => {
                            return <div className="form-group color" key={i+key}>
                                <label className={this.state.isPendingSpecialtyOptionsValid[key] ? "" : " has-error"}>
                                    {this.getSpecialtyTitles(key)} {this.state.isPendingSpecialtyOptionsValid[key] ? "" : " - invalid color code"}
                                </label>
                                <div className="input-group" style={{paddingTop: "8px"}}>
                                    <input
                                        className={"form-control" + (this.state.isPendingSpecialtyOptionsValid[key] ? "" : " has-error")}
                                        onBlur={(e) => this.validatePrimaryColor(e, "specialtyBanner", key)}
                                        onClick={() => this.colorBlockClicked("specialtyBanner", key)}
                                        onChange={(e) => this.handlePrimaryColorChanged(e, "specialtyBanner", key)}
                                        value={this.state.pendingSpecialtyOptionColors[key]}
                                        style={{
                                            borderRightWidth: "35px",
                                            borderRightColor: this.state.pendingSpecialtyOptionColors[key]
                                        }}
                                    />
                                </div>
                                <div className="banner">
                                    <SpecialtyBanner 
                                        bannerType={key} 
                                        title={this.getSpecialtyTitles(key)} 
                                        color={this.state.pendingSpecialtyOptionColors[key]} 
                                        height={"40"} />
                                </div>
                                <button className="reset-btn" onClick={() => this.resetColor(DEFAULT_SPECIALTY_BANNER_COLORS[key], "specialtyBanner", key)} hidden={this.state.pendingSpecialtyOptionColors[key] === DEFAULT_SPECIALTY_BANNER_COLORS[key]}>Reset Default</button>
                                <div className="close-btn" onClick={() => this.colorBlockClicked("specialtyBanner", key)} hidden={!this.state.showingSpecialtyOptionColorPicker[key]}>
                                    <i className="material-icons">clear</i>
                                </div>
                                {this.state.showingSpecialtyOptionColorPicker[key] ? this.renderSpecialtyColorPicker(key) : null} 
                            </div>
                        })}
                    </div>
                </div>
           </div>
        </div>
    }

    private handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        let input = e.target as HTMLInputElement;
        this.props.setTenantDisplayName(input.value);
    }

    private validatePrimaryColor = (e: React.FormEvent<HTMLInputElement>, type: string, option?: number | string) => {
        let input = e.target as HTMLInputElement;
        let hexcode = hexCodeFromText(input.value);
        let isHexCodeValid = hexcode !== null;

            if (type === "brand") {
                if (isHexCodeValid) {
                    let themeSettings: ThemeSettings = { ...this.props.themeSettings!, color: hexcode };
                    this.settingTheme(themeSettings);
                    return;
                }
                this.setState({ 
                    ...this.state,
                    isPendingBrandColorValid: false, 
                    pendingBrandColor: input.value 
                });
            }
            else if (type === "accent") {
                if (isHexCodeValid) {
                    let themeSettings: ThemeSettings = { ...this.props.themeSettings!, accent: hexcode };
                    this.settingTheme(themeSettings);
                    return;
                }
                this.setState({ 
                    ...this.state,
                    isPendingAccentColorValid: false, 
                    pendingAccentColor: input.value 
                });
            }            
            else if (type === "brandLight") {
                if (isHexCodeValid) {
                    let themeSettings: ThemeSettings = { ...this.props.themeSettings!, brandLight: hexcode };
                    this.settingTheme(themeSettings);
                    return;
                }
                this.setState({ 
                    ...this.state,
                    isPendingBrandLightColorValid: false, 
                    pendingBrandLightColor: input.value 
                });
            }
            else {
                if (isHexCodeValid) {
                   this.props.setSpecialtyColorOption(option!, hexcode!)
                   return;
                }

                let newIsPendingColorOptionValid = {...this.state.isPendingSpecialtyOptionsValid};
                newIsPendingColorOptionValid[option!] = false;

                let newPendingOptionColors = {...this.state.pendingSpecialtyOptionColors};
                newPendingOptionColors[option!] = input.value;

                this.setState({ 
                    ...this.state,
                    isPendingSpecialtyOptionsValid: newIsPendingColorOptionValid, 
                    pendingSpecialtyOptionColors: newPendingOptionColors
                });
            }     
    }

    private handlePrimaryColorChanged = (e: React.FormEvent<HTMLInputElement>, type: string, option?: number | string) => {
        let input = e.target as HTMLInputElement;
        let hexcode = hexCodeFromText(input.value);
        let isHexCodeValid = hexcode !== null;

            if (type === "brand") {
                if (isHexCodeValid) {
                    let themeSettings: ThemeSettings = { ...this.props.themeSettings!, color: input.value };
                    this.settingTheme(themeSettings);
                    return;
                }

                this.setState({ 
                    ...this.state,
                    pendingBrandColor: input.value });
            } else if (type === "accent") {
                if (isHexCodeValid) {
                    let themeSettings: ThemeSettings = { ...this.props.themeSettings!, accent: input.value };
                    this.settingTheme(themeSettings);
                    return;
                }

                this.setState({ 
                    ...this.state,
                    pendingAccentColor: input.value });
            } else if (type === "brandLight") {
                if (isHexCodeValid) {
                    let themeSettings: ThemeSettings = { ...this.props.themeSettings!, brandLight: input.value };
                    this.settingTheme(themeSettings);
                    return;
                }

                this.setState({ 
                    ...this.state,
                    pendingBrandLightColor: input.value });
            } else {
                if (isHexCodeValid) {
                    this.props.setSpecialtyColorOption(option!, hexcode!)
                    return;
                }

                let newPendingOptionColors = {...this.state.pendingSpecialtyOptionColors};
                newPendingOptionColors[option!] = input.value;

                this.setState({ 
                    ...this.state,
                    pendingSpecialtyOptionColors: newPendingOptionColors
                });
            }
    }

    private handleBrandColorChangedFromPicker = (color) => {
        let themeSettings: ThemeSettings = {  ...this.props.themeSettings!, color: color.hex  };
        this.settingTheme(themeSettings);

        this.setState({
            ...this.state,
            isPendingBrandColorValid: true,
            pendingBrandColor: color.hex
        });
    }

    private handleAccentColorChangedFromPicker = (color) => {
        let themeSettings: ThemeSettings = {  ...this.props.themeSettings!, accent: color.hex  };
        this.settingTheme(themeSettings);

        this.setState({
            ...this.state,
            isPendingAccentColorValid: true,
            pendingAccentColor: color.hex
        });
    }

    private handleBrandLightColorChangedFromPicker = (color) => {
        let themeSettings: ThemeSettings = {  ...this.props.themeSettings!, brandLight: color.hex  };
        this.settingTheme(themeSettings);

        this.setState({
            ...this.state,
            isPendingBrandLightColorValid: true,
            pendingBrandLightColor: color.hex
        });
    }

    private handleSpecialtyColorChangedFromPicker = (color, option) => {
        this.props.setSpecialtyColorOption(option, color.hex);

        let newIsPendingOptionsValid = {...this.state.isPendingSpecialtyOptionsValid }
        newIsPendingOptionsValid[option] = true;

        let newPendingOptionColors = { ...this.state.pendingSpecialtyOptionColors }
        newPendingOptionColors[option] = color.hex;

        this.setState({
            ...this.state,
            isPendingSpecialtyOptionsValid: newIsPendingOptionsValid,
            pendingSpecialtyOptionColors: newPendingOptionColors
        });
    }

    private handleRestricted = (color) => {this.handleSpecialtyColorChangedFromPicker(color, "restricted")}
    private handlePublic = (color) => {this.handleSpecialtyColorChangedFromPicker(color, "public")}
    private handleMandatory = (color) => { this.handleSpecialtyColorChangedFromPicker(color, "mandatory") }
    private handleCompliance = (color) => { this.handleSpecialtyColorChangedFromPicker(color, "compliance") }
    private handleComplianceChecked = (color) => { this.handleSpecialtyColorChangedFromPicker(color, "complianceChecked") }
    private handleBreaking = (color) => { this.handleSpecialtyColorChangedFromPicker(color, "breaking") }
    private handleFeatured = (color) => { this.handleSpecialtyColorChangedFromPicker(color, "featured") }

    private colorBlockClicked = (type: string, option?: number | string) => {
        var newShowingSpecialtyColorPicker;

        if (type === "specialtyBanner") {
            newShowingSpecialtyColorPicker = {...this.state.showingSpecialtyOptionColorPicker}
            newShowingSpecialtyColorPicker[option!] = !this.state.showingSpecialtyOptionColorPicker[option!];
        }

        this.setState({
            ...this.state,
            showingBrandColorPicker: type === "brand"? !this.state.showingBrandColorPicker : this.state.showingBrandColorPicker,
            showingAccentColorPicker: type === "accent"? !this.state.showingAccentColorPicker : this.state.showingAccentColorPicker,
            showingBrandLightColorPicker: type === "brandLight"? !this.state.showingBrandLightColorPicker : this.state.showingBrandLightColorPicker,
            showingSpecialtyOptionColorPicker: type === "specialtyBanner" ? newShowingSpecialtyColorPicker : this.state.showingSpecialtyOptionColorPicker
        })
    }

    private resetColor = (color: string, type: string, option?: number | string ) => {
        if (type === "brand") {
            let themeSettings: ThemeSettings = {...this.props.themeSettings!, color: color};
            this.settingTheme(themeSettings);

            this.setState({
                ...this.state,
                isPendingBrandColorValid: true,
                pendingBrandColor: color
            });
        } else if (type === "accent") {
            let themeSettings: ThemeSettings = {...this.props.themeSettings!, accent: color};
            this.settingTheme(themeSettings);

            this.setState({
                ...this.state,
                isPendingAccentColorValid: true,
                pendingAccentColor: color
            });
        } else if (type === "brandLight") {
            let themeSettings: ThemeSettings = {...this.props.themeSettings!, brandLight: color};
            this.settingTheme(themeSettings);

            this.setState({
                ...this.state,
                isPendingBrandLightColorValid: true,
                pendingBrandLightColor: color
            });
        } else {
            if (option) {
                this.props.setSpecialtyColorOption(option, color);

                let newIsPendingOptionsValid = {...this.state.isPendingSpecialtyOptionsValid }
                newIsPendingOptionsValid[option] = color;
        
                let newPendingOptionColors = { ...this.state.pendingSpecialtyOptionColors }
                newPendingOptionColors[option] = color;
        
                this.setState({
                    ...this.state,
                    isPendingSpecialtyOptionsValid: newIsPendingOptionsValid,
                    pendingSpecialtyOptionColors: newPendingOptionColors
                });
            }
        }
    }

    private renderSpecialtyColorPicker = (key: string): JSX.Element => {
        const handleColorChange = { mandatory: this.handleMandatory, compliance: this.handleCompliance, complianceChecked: this.handleComplianceChecked, breaking: this.handleBreaking, featured: this.handleFeatured, restricted: this.handleRestricted, public: this.handlePublic};

        return <div style={{marginTop: this.state.pendingSpecialtyOptionColors[key] === DEFAULT_SPECIALTY_BANNER_COLORS[key] ? "45px" : "5px"}}>
            <div onClick={(e) => { e.stopPropagation(); }}>
                <ChromePicker                
                    disableAlpha={true}
                    color={this.state.pendingSpecialtyOptionColors[key]}
                    onChangeComplete={handleColorChange[key]} />
            </div>
        </div>
    }

    private getMobileTitles = (key: string) => {
        let mobileTitles = { accent: "Accent Color", brandLight: "Brand Color (Light)" }
        return mobileTitles[key];
    }

    private getSpecialtyTitles = (key: string) => {
        let specialtyTitles = { mandatory: "mandatoryPost" , compliance: "compliancePost", complianceChecked: "compliedPost", breaking: "breakingPost", featured: "featuredPost", restricted: "restrictedContent", public: "publicPost" }
        let title = specialtyTitles[key];
        return this.props.translatedContent[this.props.defaultLCID][title];
    }

    private settingTheme = (themeSettings?: ThemeSettings) => {
        let skinningEnabled = !!themeSettings && (themeSettings.color !== DEFAULT_THEME_COLOR || themeSettings.accent !== DEFAULT_ACCENT_COLOR || themeSettings.brandLight !== DEFAULT_BRAND_LIGHT_COLOR) ;
        this.props.setThemeSettings(skinningEnabled, themeSettings)
    }
}

interface ComponentState {
    isPendingBrandColorValid: boolean,
    isPendingBrandLightColorValid: boolean,
    isPendingAccentColorValid: boolean;
    pendingBrandColor: string,
    pendingBrandLightColor: string,
    pendingAccentColor: string,
    isPendingSpecialtyOptionsValid: { mandatory: boolean, compliance: boolean, complianceChecked: boolean, breaking: boolean, featured: boolean, restricted: boolean, public: boolean, },
    pendingSpecialtyOptionColors: SpecialtyPostColors,
    pendingMobileOptionColors: MobileThemeColors,
    showingBrandColorPicker: boolean,
    showingAccentColorPicker: boolean,
    showingBrandLightColorPicker: boolean,
    showingSpecialtyOptionColorPicker:{ mandatory: boolean, compliance: boolean, complianceChecked: boolean, breaking: boolean, featured: boolean, restricted: boolean, public: boolean },

}

interface ComponentProps {}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => {
        let tenantSettings = state.settings.tenantSettings;
        let themeSettings = tenantSettings.themeSettings;
        let brandColor = themeSettings && tenantSettings.themeEnabled ? themeSettings.color : DEFAULT_THEME_COLOR;
        let accentColor = themeSettings && tenantSettings.themeEnabled ? themeSettings.accent : DEFAULT_ACCENT_COLOR;
        let brandLightColor = themeSettings && tenantSettings.themeEnabled ? themeSettings.brandLight : DEFAULT_BRAND_LIGHT_COLOR;
        let specialtyColors = tenantSettings.specialtyPostColors ? tenantSettings.specialtyPostColors : DEFAULT_SPECIALTY_BANNER_COLORS;
        return {
            ...ownProps,
            fetching: state.settings.fetching,
            saving: state.settings.saving,
            tenantDisplayName: tenantSettings.displayName,
            specialtyColors: specialtyColors,
            brandColor: brandColor,
            accentColor: accentColor,
            brandLightColor: brandLightColor,
            translatedContent: tenantSettings.translatedContent,
            defaultLCID: tenantSettings.defaultLCID,
            themeSettings: themeSettings,
        }
    },
    {
        setThemeSettings: actions.setThemeSettings,
        setTenantDisplayName: actions.setDisplayName,
        setBannerColorOption: actions.setBannerColorOption,
        setSpecialtyColorOption: actions.setSpecialtyColorOption
    }
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(ThemeSettingsForm)
