import React from "react";

import PluginWrapper from "../../plugins/common/pluginWrapper";

import PostFeed from "modules/posts/components/post-feed/postFeed";
import { INewsFeedConfig } from "../../models/feed";
import { FeedLayout } from "../../models/common";
import { contentHeaderTemplate, ignoreSubscriptionsTemplate, lockedToTopicsTemplate, optionalDataTemplate, stylingTemplate } from "./templates";

const NewsFeed: React.FunctionComponent<{ data: INewsFeedConfig, columnCount: number; }> = props => {
    const cardCount = props.data.layout === FeedLayout.Window || props.data.layout === FeedLayout.StackedCard
        ? -props.columnCount + 4 // calculates card count based on the type of column
        : 0;

    return (
        <PostFeed
            cardCount={cardCount}
            hidePublishTime={props.data.hide_publish_time}
            hideStats={props.data.hide_stats}
            hideSummary={props.data.hide_summary}
            hideTopics={props.data.hide_topics}
            hideLoadMore={props.data.hide_load_more}
            hideViewAll={props.data.hide_view_all}
            ignoreSubscriptions={props.data.ignore_subscriptions}
            lockSubscriptions={props.data.lockSubscriptions}
            layout={props.data.layout}
            lockedToTopics={props.data.locked_to_topics}
            maxResults={Number.parseInt(props.data.max_results)}
            postTypes={props.data.post_types}
        />
    );
};

export const NewsFeedBlock: React.FunctionComponent<{ data: INewsFeedConfig, columnCount: number; }> = props => {
    return (
        <NewsFeed {...props} />
    );
};

const postTypeTemplate = {
    name: "post_types",
    label: "Lock to specific news types",
    description: "The component will only load posts with news types selected. If none are selected, all types are loaded.",
    buttonLabel: "Add news types",
    component: "select-items",
    options: [
        { value: "standard", label: "Standard" },
        { value: "mandatory", label: "Mandatory" },
        { value: "compliance", label: "Compliance" },
        { value: "public", label: "Public" }
    ]
};

export const NEWS_FEED_TEMPLATE = {
    key: "news-feed-block",
    label: "News Feed",
    defaultItem: {
        hide_topics: true,
        layout: FeedLayout.HorizontalBand,
        locked_to_topics: [],
        max_results: 6,
        post_types: []
    },
    fields: [
        contentHeaderTemplate,
        ...ignoreSubscriptionsTemplate,
        lockedToTopicsTemplate("post"),
        postTypeTemplate,
        optionalDataTemplate.header,
        ...optionalDataTemplate.postData,
        ...optionalDataTemplate.buttons,
        {
            name: "optional_data_spacing",
            component: () => <PluginWrapper />
        },
        {
            name: "content_hr",
            component: "hr"
        },
        ...Object.values(stylingTemplate("news"))
    ]
};