import React from "react";

import { TinaCMS, TinaProvider } from "tinacms";

import Checkbox from "./plugins/checkbox";
import CommandBar from "./plugins/commandBar";
import EditNavigationItems from "./plugins/editNavigationItems";
import Image from "./plugins/image";
import Label from "./plugins/label";
import MuiToggle from "./plugins/muiToggle";
import PageDetails from "./plugins/pageDetails";
import PercentageSlider from "./plugins/percentageSlider";
import SectionLabel from "./plugins/sectionLabel";
import { SelectItems } from "./plugins/selectItems";
import SelectTopics from "./plugins/selectTopics";
import SelectCategoryTags from "./plugins/selectCategoryTags";
import TinyMCE from "./plugins/tinyMCE";

import "../styles/page.sass";


const Setup: React.FunctionComponent<{}> = props => {
  const cms = new TinaCMS({
    enabled: true,
    sidebar: {
      position: "displace"
    }
  });

  cms.plugins.remove({
    __type: "screen",
    name: "Media Manager"
  });

  cms.fields.add({ name: "checkbox", Component: Checkbox });
  cms.fields.add({ name: "command-bar", Component: CommandBar });
  cms.fields.add({ name: "edit-navigation-items", Component: EditNavigationItems });
  cms.fields.add({ name: "hr", Component: () => <hr /> });
  cms.fields.add({ name: "image", Component: Image });
  cms.fields.add({ name: "label", Component: Label });
  cms.fields.add({ name: "mui-toggle", Component: MuiToggle });
  cms.fields.add({ name: "page-details", Component: PageDetails });
  cms.fields.add({ name: "percentage-slider", Component: PercentageSlider });
  cms.fields.add({ name: "section-label", Component: SectionLabel });
  cms.fields.add({ name: "select-items", Component: SelectItems });
  cms.fields.add({ name: "select-topics", Component: SelectTopics });
  cms.fields.add({ name: "select-category-tags", Component: SelectCategoryTags });
  cms.fields.add({ name: "tinymce", Component: TinyMCE });

  if (!cms.sidebar!.isOpen)
    cms.sidebar!.isOpen = true;

  return (
    <TinaProvider cms={cms}>
      {props.children}
    </TinaProvider >
  );
}

export default Setup;