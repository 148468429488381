import { Button, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import LoadingSpinner from "modules/common/components/loadingSpinner";
import React from "react";
import EmailContentView from "./emailContentView";
import { useSelector } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import moment from "moment";
import RecipientCountDownload from "./editor/recipientCountDownload";
import ChipList from "modules/common/components/chipList";

import "../styles/emailOneLastCheck.sass";

interface IEmailOneLastCheckProps {
    show: boolean;
    onClose: () => void;
    sending: boolean;
    sendEmail: () => void;
    onSaveEmail: () => void;
    onSuccessMessage: (message: string) => void;
    onErrorMessage: (message: string) => void;
    activeLcid: string;
    defaultAudienceCount: number;
    isFetchingDefaultAudienceCount: boolean;
}

const EmailOneLastCheck: React.FC<IEmailOneLastCheckProps> = ({
    show,
    onClose,
    sending,
    sendEmail,
    onSaveEmail,
    onSuccessMessage,
    onErrorMessage,
    activeLcid,
    defaultAudienceCount,
    isFetchingDefaultAudienceCount
}) => {
    const email = useSelector((state: GlobalApplicationState) => state.emails.editor.email);
    const labelGridSize = 2;
    const dataGridSize = 10;

    if (!email) return <></>;

    return <Dialog
        open={show}
        className="emails-one-last-check-dialog"
        fullWidth
        maxWidth={"md"}
        PaperProps={{
            style: {
                maxWidth: 880
            }
        }}
        scroll="body"
        onClose={() => !sending && onClose()}
    >
        <DialogTitle className="emails-check-dialog-title">
            <span>One last check!</span>
            <div>
                <Button variant="text" color="primary" onClick={onClose} disabled={sending}>
                    KEEP EDITING
                </Button>
                {
                    sending 
                        ? <div style={{ float: 'right' }}><LoadingSpinner size={"14px"} /></div>
                        : <Button variant="contained" color="primary" onClick={sendEmail}>SEND</Button>
                }
            </div>
        </DialogTitle>
        <DialogContent className="emails-check-dialog-content">
            <Grid className="data-container" container spacing={2}>
                <Grid item xs={labelGridSize} className="data-container-label">
                    Send on
                </Grid>
                <Grid item xs={dataGridSize}>
                    {email.sentTime && new Date(email.sentTime) >= new Date() ? moment(email.sentTime).format("dddd, MMMM D, YYYY h:mm A") : "Now"}
                </Grid>
                <Grid item xs={labelGridSize} className="send-to-label data-container-label">
                    Send to
                </Grid>
                <Grid item xs={dataGridSize}>
                    <div className="recipients-container">
                        <RecipientCountDownload
                            onSaveEmail={onSaveEmail}
                            onSuccessMessage={onSuccessMessage}
                            onErrorMessage={onErrorMessage}
                            defaultAudienceCount={defaultAudienceCount}
                            isFetchingDefaultAudienceCount={isFetchingDefaultAudienceCount}
                        />
                        <ChipList 
                            items={email.audiences ?? []} 
                            emptyLabel={email.sendToAllUsers ? "All users" : "No recipients selected"}
                            showEmptyChip
                            truncateAtIndex={4}
                        />
                    </div>
                </Grid>
                <Grid item xs={labelGridSize} className="data-container-label">
                    Reply email
                </Grid>
                <Grid item xs={dataGridSize}>
                    {email.replyAddress ?? email.fromSender?.email ?? ""}
                </Grid>
            </Grid>
            <EmailContentView email={email} activeLcid={activeLcid}/>
            <p className="preview-help">Preview varies based on device and/or platform.</p>
        </DialogContent>
    </Dialog>;
};

export default EmailOneLastCheck;
