import React from "react";
import { AuthoringAppBarAction, AuthoringAppBarActions, IAuthoringAppBarAction } from "modules/common/components/authoring/authoringAppBarActions";
import { useContentGuidelines } from "modules/common/hooks/data/useContentGuidelines";

interface IEmailAuthoringEditorActionsProps {
    onSend: () => void;
    isSendDisabled: boolean;
    onSave: () => void;
    isSaveDisabled: boolean;
    onPreview: () => void;
    onToggleSettingsPanel: () => void;
    isToggleSettingsPanelDisabled: boolean;
    onDiscard: () => void;
    isDiscardDisabled: boolean;
    onBack: () => void;
    openGuidelines: () => void;
}

const EmailAuthoringEditorActions: React.FC<IEmailAuthoringEditorActionsProps> = ({
    onSend,
    isSendDisabled,
    onSave,
    isSaveDisabled,
    onPreview,
    onToggleSettingsPanel,
    isToggleSettingsPanelDisabled,
    onDiscard,
    isDiscardDisabled,
    onBack,
    openGuidelines
}) => {
    const { isEmpty: isGuidelineEmpty } = useContentGuidelines();

    const getActions = (): Record<AuthoringAppBarAction, IAuthoringAppBarAction | undefined> => ({
        [AuthoringAppBarAction.Publish]: {
            onClick: onSend,
            disabled: isSendDisabled,
            label: "SEND"
        },
        [AuthoringAppBarAction.Save]: {
            disabled: isSaveDisabled,
            onClick: onSave,
        },
        [AuthoringAppBarAction.Preview]: {
            disabled: false,
            onClick: onPreview,
        },
        [AuthoringAppBarAction.ToggleSettingsPanel]: {
            disabled: isToggleSettingsPanelDisabled,
            onClick: onToggleSettingsPanel
        },
        [AuthoringAppBarAction.Discard]: {
            disabled: isDiscardDisabled,
            onClick: onDiscard,
        },
        [AuthoringAppBarAction.Back]: {
            disabled: false,
            onClick: onBack,
        },
        [AuthoringAppBarAction.OpenGuidelines]: {
            disabled: isGuidelineEmpty,
            onClick: openGuidelines
        },
        [AuthoringAppBarAction.Reject]: undefined,
    });

    return <AuthoringAppBarActions actions={getActions()} />;
};

export default EmailAuthoringEditorActions;
