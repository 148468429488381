import { GlobalApplicationState } from "globalApplicationState";
import Bodyv2 from "modules/common/components/authoring/bodyv2";
import FilledInput from "modules/common/components/forms/inputs/filledInput";
import { EMAIL_PERSONALIZATION_TOKENS, IEmail, IPersonalizationToken, MERGE_TAG_PREFIX, MERGE_TAG_SUFFIX } from "modules/emails/models";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import PersonalizationTokenButton from "./personalizationTokenButton";
import { ITranslatedText } from "modules/common/models";

interface IEmailEditorProps {
    updateEmail: (updatedEmailFields: Partial<IEmail>, changedSinceLastSaved?: boolean) => void;
    activeLcid: string;
}

const EmailEditor: React.FC<IEmailEditorProps> = ({
    updateEmail,
    activeLcid
 }) => {
    const { email, changedSinceLastSaved } = useSelector((state: GlobalApplicationState) => state.emails.editor);
    const bodyValue = email.body.find(b => b.lcid === activeLcid)?.text;
    const [innerSubjectValue, setInnerSubjectValue] = useState(email.subject);
    const [oldSubjectText, setOldSubjectText] = useState("");
    const textFieldRef = useRef<HTMLInputElement>(null);

    const updateTranslatedTextField = (lcid: string, newText: string, translatedText: ITranslatedText[]): ITranslatedText[] => 
        [...translatedText.filter(t => t.lcid !== lcid), { lcid, text: newText }];
    
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Backspace" || event.key === "Delete") {
            // if cursor is inside a merge tag while deleting, prevent default deletion and delete the full tag
            const startEndDelimiters = getMergeTagStartAndEndDelimiter();
            if (startEndDelimiters) {
                event.preventDefault();
                const currentInnerSubjectValue = innerSubjectValue.find(s => s.lcid === activeLcid);

                if (currentInnerSubjectValue) {
                    const newSubjectText = currentInnerSubjectValue.text.slice(0, startEndDelimiters.startDelimiter) + 
                        currentInnerSubjectValue.text.slice(startEndDelimiters.endDelimiter + 1);
                    setInnerSubjectValueText(newSubjectText);
                }
            }
        }
    };

    const handleClick = () => {
        // if the user tries to put their cursor into our merge tags, move it outside.
        const startEndDelimiters = getMergeTagStartAndEndDelimiter();
        if (startEndDelimiters && textFieldRef.current) {
            textFieldRef.current.setSelectionRange(startEndDelimiters.endDelimiter + 1, startEndDelimiters.endDelimiter + 1);
        }
    }

    /**
     * A method for finding the start and end delimiters of a merge tag / personalization token block
     * Returns undefined if there is none found
     */
    const getMergeTagStartAndEndDelimiter = (): { startDelimiter: number; endDelimiter: number } | undefined => {
        if (textFieldRef.current) {
            const cursorStartPos = textFieldRef.current.selectionStart ?? 0;
            const cursorEndPos = textFieldRef.current.selectionEnd ?? 0;
            const text = textFieldRef.current.value;

            // find the boundaries of the merge tags
            const startDelimiter = text.lastIndexOf(MERGE_TAG_PREFIX, cursorStartPos - 1);
            const endDelimiter = text.indexOf(MERGE_TAG_SUFFIX, cursorEndPos - MERGE_TAG_PREFIX.length);

            // see if the cursor is between merge tags
            return startDelimiter !== -1 && 
                endDelimiter !== -1 && 
                startDelimiter < cursorStartPos && 
                cursorEndPos <= endDelimiter + MERGE_TAG_PREFIX.length &&
                // check that the value within the block is one of our tokens
                EMAIL_PERSONALIZATION_TOKENS.find(token => token.value === text.substring(startDelimiter + MERGE_TAG_PREFIX.length, endDelimiter))
                    ? { startDelimiter, endDelimiter: endDelimiter - 1 + MERGE_TAG_PREFIX.length }
                    : undefined;
        }
    }

    const onBlurSubject = (event: React.FocusEvent<HTMLInputElement>) => {
        let newSubject = event.target.value as string;

        if (newSubject !== oldSubjectText) {
            setOldSubjectText(newSubject);
            onChangeSubjectText(newSubject);
        }
    }

    const onFocusSubject = (event: React.FocusEvent<HTMLInputElement>) => setOldSubjectText(event.target.value as string);

    const onChangeSubjectText = (newSubjectText: string) => onChangeSubject(updateTranslatedTextField(activeLcid, newSubjectText, innerSubjectValue));

    const onChangeSubject = (newSubject: ITranslatedText[]) => {
        updateEmail({ subject: newSubject });
    }

    const onChangeBodyText = (newBodyText: string) => onChangeBody(updateTranslatedTextField(activeLcid, newBodyText, email.body));

    const onChangeBody = (newBody: ITranslatedText[]) => updateEmail({ body: newBody });

    const setInnerSubjectValueText = (newSubjectText: string) => {
        const newSubject = updateTranslatedTextField(activeLcid, newSubjectText, innerSubjectValue);
        setInnerSubjectValue(newSubject);
        onChangeSubject(newSubject);
    }

    const onChangeInnerSubjectValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newSubject = e.target.value as string;
        setInnerSubjectValueText(newSubject);
    }

    const onTokenClick = (token: IPersonalizationToken) => {
        const input = textFieldRef.current;
        if (input) {
            const textToInsert = `${MERGE_TAG_PREFIX}${token.value}${MERGE_TAG_SUFFIX}`;
            const start = input.selectionStart || 0;
            const end = input.selectionEnd || 0;

            // insert the text at the current cursor position
            input.setRangeText(textToInsert, start, end, "end");

            // put the cursor at the end of the inserted text
            input.selectionStart = input.selectionEnd = start + textToInsert.length;

            // trigger onchange
            const event = new Event("change", { bubbles: true });
            input.dispatchEvent(event);
        }
    }
    
    return <>
        <FilledInput
            maxCount={900}
            label={"Subject"}
            onUndo={(newValue: string | undefined) => {
                const subjectVal = newValue || "";
                setInnerSubjectValueText(subjectVal);
            }}
            required
            value={innerSubjectValue.find(s => s.lcid === activeLcid)?.text ?? ""}
            inputProps={{
                placeholder: "Enter subject",
                onBlur: onBlurSubject,
                onChange: onChangeInnerSubjectValue,
                onFocus: onFocusSubject,
                onKeyDown: handleKeyDown,
                onClick: handleClick
            }}
            onlyShowIconsWhenFocused={false}
            extraIconButton={<PersonalizationTokenButton personalizationTokens={EMAIL_PERSONALIZATION_TOKENS} onClick={onTokenClick}/>}
            inputLabelContainerStyle={{ alignItems: "center" }}
            controlled
            inputRef={textFieldRef}
        />
        <Bodyv2
            value={bodyValue}
            editorOptions={{ min_height: 640 }}
            onChange={newBody => newBody !== bodyValue && onChangeBodyText(newBody)}
            required={false}
            personalizationTokens={EMAIL_PERSONALIZATION_TOKENS}
            maxWidth={800}
            changedSinceSaved={changedSinceLastSaved}
        />
    </>;
};

export default EmailEditor;
