import React from "react";
import moment from "moment";
import numeral from "numeral";

import InfoHover from "modules/common/components/hovers/infoHover";
import WarningHover from "modules/common/components/hovers/warningHover";

import { AccountType, UserDetails, UserEdit, UserRole } from "../../models";

import Divider from "@mui/material/Divider";

import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import { Button } from "@mui/material";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { useDefaultLocale } from "modules/common/hooks/useDefaultLocale";


const Account: React.FunctionComponent<ComponentProps> = props => {
  useDefaultLocale();

  const getAccountType = (accountType: AccountType) => {
    switch (accountType) {
      case "adfs":
        return "ADFS";
      case "apple":
        return "Apple";
      case "default":
        return "Pending";
      case "facebook":
        return "Facebook";
      case "google":
        return "Google";
      case "linkedIn":
        return "LinkedIn";
      case "microsoft":
        return "Microsoft Personal";
      case "sparrow":
        return "Sparrow account";
      case "work":
        return "Work account";
      default:
        return accountType;
    }
  }

  const getRoleName = (role: UserRole) => {
    switch(role) {
      case 1:
        return "Author";
      case 2:
        return "Owner";
      case 3: 
        return "User";
      default:
        return "Default";
    } 
  }

  const { userDetails } = props;
  const userRole = getRoleName(userDetails.permissionDetails.role);
  
  return (
    <div className="account-info">
      <div>
        {!props.isEnabled &&
            <div style={{float: "right"}}>
              <WarningHover labelText="Deactivated">Deactivating an account revokes a user's access to your organization's Sparrow app, portals and/or intranet.</WarningHover>
              {/* <span style={{paddingLeft: "5px", position: "relative", top: "-6px"}}>Deactivated</span> */}
            </div>
        }
        {!!userDetails.name &&
          <div className="avatar">
            <div style={{ backgroundColor: userDetails.avatar }} className="avatar-icon-container">
              <div className="avatar-letter">{userDetails.initials}</div>
            </div>
          </div>
        }
        <div className="user-info">
          <div>
            <div className="full-name">{userDetails.name || "[Name Not Provided]"}</div>
          </div>
        </div>
        <div className="email">{userDetails.email}</div>
        <div style={{display: "flex"}}>
          <div className="email" style={{alignSelf: "end"}}>{userRole}</div>
          <Button style={{marginLeft: "auto"}} color="primary" endIcon={<OpenInNew/>} onClick={props.onOpenDrawer}>View Permissions</Button>
        </div>
      </div>
      <div>
        <Divider light />
        <div className="account-data">
          {userDetails.created !== "0001-01-01T00:00:00+00:00" &&
            <div>
              <div>Member since</div>
              <div>{moment(userDetails.created).format("MMM D, YYYY")}</div>
            </div>
          }
          <div className="account-type">
            <div>Account type</div>
            <div>{getAccountType(userDetails.accountType)}</div>
          </div>
          <div>
            <div>
              Views
              <InfoHover>Number of times this user has viewed all posts and events, respectively.</InfoHover>
            </div>
            <div className="interaction-stats">
              <div>
                <DescriptionIcon fontSize="small" />
                {numeral(userDetails.dataDetails.postViews).format("0,0")}
              </div>
              <div>
                <CalendarTodayOutlinedIcon fontSize="small" />
                {numeral(userDetails.dataDetails.eventViews).format("0,0")}
              </div>
            </div>
          </div>
          <div>
            <div>
              Reads
              <InfoHover>Number of times this user has completely read all posts and events, respectively.</InfoHover>
            </div>
            <div className="interaction-stats">
              <div>
                <DescriptionIcon fontSize="small" />
                {numeral(userDetails.dataDetails.postReads).format("0,0")}
              </div>
              <div>
                <CalendarTodayOutlinedIcon fontSize="small" />
                {numeral(userDetails.dataDetails.eventReads).format("0,0")}
              </div>
            </div>
          </div>
          <div>
            <div>Reactions</div>
            <div className="interaction-stats">
              <div>
                <DescriptionIcon fontSize="small" />
                {numeral(userDetails.dataDetails.reactions).format("0,0")}
              </div>
            </div>
          </div>
          <div>
            <div>Comments</div>
            <div className="interaction-stats">
              <div>
                <DescriptionIcon fontSize="small" />
                {numeral(userDetails.dataDetails.comments).format("0,0")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface ComponentProps {
  userDetails: UserDetails;
  isEnabled: boolean;
  onOpenDrawer: () => void;
  onChangeUser: (value: Partial<UserEdit>) => void;
}

export default Account;