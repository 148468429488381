import React from "react";

import { DocumentFeedItem } from "../../../../../models";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { DocumentActionButtons } from "../../documentActionButtons";
import { useDefaultLocale } from "modules/common/hooks/useDefaultLocale";
import { IDocumentFeedOptionalFlags } from "modules/portalPages/tinacms/models/feed";

const MAX_DOC_TITLE_LENGTH = 100;

const Document: React.FunctionComponent<IFeedDocumentProps> = props => {
    useDefaultLocale();

    const { document } = props;

    const getNoveltyBanner = (): JSX.Element => {
        if (document.isNew) return <div className="new inline-banner">New</div>;

        if (document.isUpdated) return <div className="updated inline-banner">Updated</div>;

        return <></>;
    };

    const getDocumentTitle = (): JSX.Element => {
        const title = document.title || `${document.fileName}.${document.fileType}`;

        const processedTitle = title.length > MAX_DOC_TITLE_LENGTH
            ? `${title.substring(0, MAX_DOC_TITLE_LENGTH - 3)}...`
            : title;

        return (
            <React.Fragment>
                {getNoveltyBanner()}
                <b>{processedTitle}</b>
            </React.Fragment>
        );
    };

    const getCategoryTags = (): JSX.Element => {
        if (document.tags.length <= 0) return <></>;

        const categoryTags = document.tags
            .map(tag => tag.name)
            .join(", ");

        return (<div className="one-line-ellipsis">
            Category tags: {categoryTags}
        </div>);
    };

    return (
        <TableRow
            key={document.id}
            className="document"
            onClick={async () => await props.onDocumentSelected(document)}
            hover
        >
            {
                !props.optionalSettings.hideFileIcon &&
                <TableCell align="center" padding="none" className="with-divider file-icon">
                    <img src={`/images/icons/documents/${document.fileType}-list.png`} alt={document.fileType} className="file-type" />
                </TableCell>
            }

            <TableCell component="th" scope="row" className="with-divider">
                {getDocumentTitle()}
                {
                    !props.optionalSettings.hideCategoryTags
                    && <div> {getCategoryTags()} </div>
                }
            </TableCell>

            <TableCell align="center" onClick={(ev) => ev.stopPropagation()}
                className="with-divider last-cell">
                {document.isUnread && <div className="unread">Unread</div>}
                <div className="action-buttons">
                    {
                        !props.optionalSettings.hideActionIcons &&
                        <DocumentActionButtons
                            document={document}
                            onDownloadDocument={props.onDownloadDocument}
                            onInspectDocument={props.onInspectDocument}
                        />
                    }
                </div>
            </TableCell>
        </TableRow>
    );
};

interface IFeedDocumentProps {
    document: DocumentFeedItem;
    onDocumentSelected: (document: DocumentFeedItem) => Promise<void>;
    onDownloadDocument: (document: DocumentFeedItem) => Promise<void>;
    onInspectDocument: (document: DocumentFeedItem) => void;
    optionalSettings: IDocumentFeedOptionalFlags;
}

export default Document;