import { TextField } from "@mui/material";
import React, { useState } from "react";
import isValidEmail from "utils/isValidEmail";

interface IEmailValidationTextFieldProps {
    className?: string;
    defaultValue?: string;
    onChangeEmail: (emailValue?: string) => void;
}

const EmailValidationTextField: React.FC<IEmailValidationTextFieldProps> = ({
    className,
    defaultValue,
    onChangeEmail
}) => {
    const [replyEmailInnerValue, setReplyEmailInnerValue] = useState(defaultValue);
    const [isReplyEmailFieldFocussed, setIsReplyEmailFieldFocussed] = useState(false);

    const isReplyEmailFieldInvalid = !!replyEmailInnerValue && !isValidEmail(replyEmailInnerValue);

    return <TextField
        size="small" 
        className={className}
        fullWidth 
        value={replyEmailInnerValue}
        onChange={e => setReplyEmailInnerValue(e.target.value)}
        error={!isReplyEmailFieldFocussed && isReplyEmailFieldInvalid}
        helperText={!isReplyEmailFieldFocussed && isReplyEmailFieldInvalid ? "Enter valid email" : undefined}
        FormHelperTextProps={{sx: { marginLeft: 0 }}}
        onBlur={_ => {
            onChangeEmail(isReplyEmailFieldInvalid ? "" : replyEmailInnerValue);
            setIsReplyEmailFieldFocussed(false);
        }}
        onFocus={_ => setIsReplyEmailFieldFocussed(true)}
    />;
};

export default EmailValidationTextField;
