import React, { useState } from "react";
import Callout from "modules/common/components/callout";
import { Audience } from "modules/audiences/models";
import { SimpleTag } from "modules/newsletters/models";
import Button, { ButtonProps } from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import { FormControlLabel, ListItemButton } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";
import { DebounceState, useDebounceValue } from "utils/debounceHook";

const EditAudiences: React.FunctionComponent<ComponentProps> = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const audiencesDeb: DebounceState<SimpleTag[]> = useDebounceValue<SimpleTag[]>(
    props.audiences,
    async () => props.onChange(audiencesDeb.debVal)
  );
  const [calloutOpen, setCalloutOpen] = useState(false);

  return (
    <React.Fragment>
      <Button
        variant="text"
        color="primary"
        startIcon={<AddIcon />}
        onClick={(event: any) => {
          setAnchorEl(event.currentTarget);
          setCalloutOpen(true);
        }}
        {...props.buttonProps}
      >
        {props.buttonLabel ?? "Edit audiences"}
      </Button>
      <Callout
        anchorEl={anchorEl}
        open={calloutOpen}
        setOpen={(toSet) => {
          props.onChange(audiencesDeb.debVal);
          setCalloutOpen(toSet)
        }}
        footer={
          <div>
            {
                props.sendToAllUsers !== undefined &&
                <FormControlLabel
                    control={
                        <Checkbox 
                            checked={props.sendToAllUsers} 
                            size="small" 
                            onChange={(_, checked) => {
                                audiencesDeb.setDebVal([]);
                                props.onChange([], false, checked);
                            }}
                        />
                    }
                    label="Send to all employees" 
                />
            }
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                audiencesDeb.setDebVal([]);
                props.onChange([], true);
                setCalloutOpen(false);
              }}
            >
              Clear all
            </Button>
          </div>
        }
      >
        <List disablePadding className="audiences">
          {props.availableAudiences.map((audience) => {
              const hasSelectedAudience: boolean = audiencesDeb.debVal.map(a => a.id).includes(audience.id);

              return <ListItemButton 
                key={audience.id} 
                dense 
                onClick={() => {
                  if (props.disableAllSelections) 
                      return;

                  const newAudiences = hasSelectedAudience
                      ? audiencesDeb.debVal.filter((selectedAudience) => selectedAudience.id !== audience.id)
                      : audiencesDeb.debVal.concat([{ id: audience.id, name: audience.displayName }]);

                  audiencesDeb.setDebVal(newAudiences);
                }}
              >
                <ListItemIcon className="callout-checkbox">
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      disableRipple
                      size="small"
                      color="primary"
                      checked={!props.disableAllSelections && hasSelectedAudience}
                      disabled={props.disableAllSelections}
                    />
                </ListItemIcon>
                <ListItemText primary={`${audience.displayName || ""}${audience.enabled ? "" : " (disabled)"}`} />
              </ListItemButton>
          })}
        </List>
      </Callout>
    </React.Fragment>
  );
}

interface ComponentProps {
  audiences: SimpleTag[];
  availableAudiences: Audience[];
  onChange: (audiences: SimpleTag[], clickedClear?: boolean, sendToAllUsers?: boolean) => void;
  buttonLabel?: string;
  buttonProps?: ButtonProps;
  disableAllSelections?: boolean;
  sendToAllUsers?: boolean;
}

export default EditAudiences;