import React from "react";

import { DocumentFeedItem } from "../../../../../models";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import moment from "moment";
import { dateOptions } from "utils/dateFormatting";

import { DocumentActionButtons } from "../../documentActionButtons";
import { useDefaultLocale } from "modules/common/hooks/useDefaultLocale";


const Document: React.FunctionComponent<ComponentProps> = props => {
  useDefaultLocale();

  const { document } = props;

  return (
    <TableRow
      hover
      onClick={async () => await props.onDocumentSelected(document)}
      key={document.id}
      className="document"
    >
      <TableCell align="center">
        <img src={`/images/icons/documents/${document.fileType}-list.png`} alt={document.fileType} className="file-type" />
      </TableCell>
      <TableCell component="th" scope="row">{document.title || `${document.fileName}.${document.fileType}`}</TableCell>
      <TableCell align="center">
        {document.isNew
          ? <div className="new">New</div>
          : document.isUpdated &&
            <div className="updated">Updated</div>
        }
      </TableCell>
      <TableCell align="center">{moment(document.lastUpdatedDate).format(dateOptions.basicFormatting)}</TableCell>
      <TableCell align="center">
        {!!document.tags.length &&
          <div>{document.tags.slice(0, 3).map(tag => tag.name).join(", ")}{document.tags.length > 3 ? `, ... +${document.tags.length - 3}` : ""}</div>
        }
      </TableCell>
      <TableCell align="center">
        {document.isUnread
          ? <div className="unread">Unread</div>
          : <div className="read">Read {moment(document.lastReadDate).fromNow()}</div>
        }
      </TableCell>
      <TableCell align="center" onClick={(ev) => ev.stopPropagation()}>
        <DocumentActionButtons
          document={document}
          onDownloadDocument={props.onDownloadDocument}
          onInspectDocument={props.onInspectDocument}
        />
      </TableCell>
    </TableRow>
  );
}

interface ComponentProps {
  document: DocumentFeedItem;
  onDocumentSelected: (document: DocumentFeedItem) => Promise<void>;
  onDownloadDocument: (document: DocumentFeedItem) => Promise<void>;
  onInspectDocument: (document: DocumentFeedItem) => void;
}

export default Document;