import * as React from "react";

import moment from "moment";
import { useDefaultLocale } from "modules/common/hooks/useDefaultLocale";


const PublishTime: React.FunctionComponent<ComponentProps> = props => {
  useDefaultLocale();
  
  return (
    <div className="publish-time">
      {moment(props.publishTime).format("MMMM D, YYYY")}
    </div>
  );
}

interface ComponentProps {
  publishTime: string;
}

export default PublishTime;