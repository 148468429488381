import * as React from "react";

import { PostFeedItem } from "../../../../../models";
import BreakingFeaturedBanner from "../../../../banners/breakingFeaturedBanner";
import PublishTime from "../../dates/PublishTime";
import ReadingTime from "../../dates/ReadingTime";
import Stats from "../../stats/Stats";
import Topics from "../../topics/Topics";
import PostTypeIcons from "./PostTypeIcons";

const Tile: React.FunctionComponent<ComponentProps> = props => {
    const { cardCount, post, preferredLCID, onPostSelected } = props;
  
    if (!post) return (null);

    const selectPost = () => onPostSelected ? onPostSelected(post) : null;
    const excerptToDisplay = post.excerpt.length > 87 ? post.excerpt.substring(0, 87) + "..." : post.excerpt;

    return (
      <div onClick={selectPost} className={`tile tile-${cardCount}`}>
        <div className="content-container">
          <div className="content">
            <div className="date-bar">
              {!props.hidePublishTime &&
                <PublishTime publishTime={post.publishTime} />
              }
            </div>

            <div style={{display: "flex", alignItems: "baseline"}}>
                  <PostTypeIcons {...props} {...post} lcid={preferredLCID} />
                <div className="title">{post.title}</div>
            </div>

            {!props.hideStats &&
              <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <ReadingTime readingTime={post.readingTime} simple/>
                <div style={{marginLeft: "auto"}}>
                  <Stats {...post}/>
                </div>
              </div>
            }
            <div className="summary">
              <BreakingFeaturedBanner {...props} {...post} activeLcid={preferredLCID} />
              {!props.hideSummary &&
                excerptToDisplay
              }
            </div>
            {!props.hideTopics &&
              <Topics topics={post.tags} />
            }
          </div>
        </div>
      </div>
    );
  }

interface ComponentProps {
    cardCount: number;
    hidePublishTime?: boolean;
    hideReadDate?: boolean;
    hideStats?: boolean;
    hideSummary?: boolean;
    hideTopics?: boolean;
    post: PostFeedItem;
    preferredLCID: string;
    onPostSelected: (post: PostFeedItem) => void;
}

export default Tile;