import { SparrowApi } from "./network/adapters/SparrowApi";
import { ApiRequest } from './network';
import { Blast, TenantNotificationSettings } from "modules/messaging";

export class MessagingApi {
    constructor(private _sparrowApi: SparrowApi) {
        
    }

    public SendSMSBlast = (smsBlast: Blast): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post('/{tenant}/api/v1/notifications/sms/blast', smsBlast)
        )
    }

    public MassSendConsent = (smsBlast: Blast): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post('/{tenant}/api/v1/notifications/sms/consent', smsBlast)
        )
    }

    public UpdateNotificationSettings = (newSettings: TenantNotificationSettings): ApiRequest =>  {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().put('/{tenant}/adminapi/v1/notifications', newSettings)
        )
    }

    public ApplyBlackoutPeriods = (events: string[]): ApiRequest =>  {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().put('/{tenant}/adminapi/v1/notifications/blackoutperiods', events)
        )
    }

    public GetSMSStats = (): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('/{tenant}/adminapi/v1/users/notification/smsstats')
        )
    }

    public GetTeamsStats = (): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('/{tenant}/adminapi/v1/users/notification/teamsstats')
        )
    }

    public GetMobileStats = (): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('/{tenant}/adminapi/v1/users/notification/mobilestats')
        )
    }

    public GetEmailUserCsv = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('/{tenant}/adminapi/v1/notifications/useremailcsv')
        )
    }

    public GetSMSUserCsv = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('/{tenant}/adminapi/v1/notifications/usersmscsv')
        )
    }

    public GetTeamsUserCsv = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('/{tenant}/adminapi/v1/notifications/userteamscsv')
        )
    }

    public GetMobileUserCsv = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('/{tenant}/adminapi/v1/notifications/usermobilecsv')
        )
    }

    public GetOutbox = (outboxType: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get(`/{tenant}/adminapi/v1/notifications/dispatchcsv/${outboxType}`)
        )
    }
}