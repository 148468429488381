import React from "react";
import HoverText from "modules/documents/components/action-buttons/hoverText";
import LoadingCircle from "modules/common/components/loadingCircle";
import { Button } from "@mui/material";
import ChipListInput from "modules/common/components/chipListInput";
import isValidEmail from "utils/isValidEmail";

export const MAX_EMAILS_FOR_TEST = 10;

interface ISendTestEmailInputProps {
    testEmails: string[];
    isSendingTestEmail: boolean;
    onClickSendTestEmails: () => void;
    setEmailAddressesForTest: (emails: string[]) => void;
    
}

const SendTestEmailInput: React.FC<ISendTestEmailInputProps> = ({
    testEmails,
    isSendingTestEmail,
    onClickSendTestEmails,
    setEmailAddressesForTest
}) => {
    return <div className="send-test-email-container">
        <div className="send-test-email-label-button-container">
            <HoverText label="Send test email">
                Send test emails to preview how your message will appear to recipients.
            </HoverText>
            <div className="send-test-email-loading-button-container">
                { isSendingTestEmail && <LoadingCircle size="16px" style={{ marginRight: 4 }}/> }
                <Button 
                    variant="outlined" 
                    disabled={testEmails.length === 0 || isSendingTestEmail}
                    onClick={onClickSendTestEmails}
                >
                    Send test email
                </Button>
            </div>
        </div>
        <ChipListInput
            values={testEmails}
            onRemoveChip={email => setEmailAddressesForTest(testEmails.filter(addr => addr !== email))}
            onAddValidatedChips={validEmails => setEmailAddressesForTest([...testEmails, ...validEmails].slice(0, MAX_EMAILS_FOR_TEST))}
            errorProps={{
                isValueValid: isValidEmail,
                errorHelperText: "Enter valid email"
            }}
            helperText={<i>Separate email addresses with commas</i>}
            textFieldProps={{
                className: "test-email-address-text-field",
                size: "small",
                placeholder: `Enter up to ${MAX_EMAILS_FOR_TEST} mail addresses`,
                FormHelperTextProps: { sx: { marginLeft: 0 }},
                disabled: testEmails.length >= MAX_EMAILS_FOR_TEST
            }}
            chipClassName="test-email-chip"
            containerSx={{ overflow: "auto" }}
        />
    </div>;
};

export default SendTestEmailInput;
