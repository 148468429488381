import React, { useState, useEffect, useMemo } from "react";
import { Add, Lock } from "@mui/icons-material";

import ChipList from "modules/common/components/chipList";
import { IdNamePair } from "modules/common/models";
import { TenantSettingsTagGroup, TenantSettingsTag, MultiSelectTopic } from "modules/settings";
import { IContentBandSettingsInputProps } from "../editor/contentBandSettingsForm";
import MultiSelectCallout from "modules/common/components/forms/inputs/multiSelectCalloutWithSearch";

export interface ITopicInputProps extends IContentBandSettingsInputProps<string[]> {
    topicGroups: TenantSettingsTagGroup[];
}

export const TopicInput: React.FunctionComponent<ITopicInputProps> = ({ idx, value, topicGroups, onChange }) => {
    // keep an internal value so we only update parent when callout closed
    const [internalValue, setInternalValue] = useState<string[]>(value);

    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    const filtered = useMemo(() => {
        return topicGroups
            .map((group: TenantSettingsTagGroup) => ({
                ...group,
                tags: group.tags.filter((tag: TenantSettingsTag) => !tag.disabled),
            }))
            .filter((group: TenantSettingsTagGroup) => group.tags.length > 0);
    }, [topicGroups]);

    const selected = useMemo(() => {
        let allTopics = topicGroups.flatMap((group: TenantSettingsTagGroup) => group.tags);

        return allTopics
            .filter((topic: TenantSettingsTag) => internalValue.includes(topic.id))
            .map((topic: TenantSettingsTag) => ({
                ...topic,
                icon: topic.restricted
            }));
    }, [topicGroups, internalValue]);

    const onChangeProcesser = (topics: MultiSelectTopic[]) => {
        let ids = topics.map((topic: MultiSelectTopic) => topic.id);
        setInternalValue(ids);
    };

    const onRemoveProcessor = (tagToRemove: IdNamePair<string, string>) => {
        let newValues = internalValue.filter((id: string) => id !== tagToRemove.id);

        onChange(newValues, idx);
    };

    const onCalloutClose = () => {
        onChange(internalValue, idx);
    };

    return (
        <>
            <div className="form-group">
                <label>Lock to topics</label>
                <div className="input grow">
                    {filtered.length !== 0 && (
                        <MultiSelectCallout
                            defaultOptions={filtered}
                            selectedOptions={selected}
                            onChange={onChangeProcesser}
                            buttonStyle={{
                                startIcon:<Add />
                            }}
                            onClose={onCalloutClose}
                            nestingAllowed
                        />
                    )}
                </div>
            </div>
            <div className="form-group offset">
                <div className="chip-list">
                    <ChipList
                        closable
                        showEmptyChip
                        emptyStyle={{
                            fontSize: 14,
                        }}
                        emptyLabel="All topics"
                        onClose={onRemoveProcessor}
                        truncateAtIndex={4}
                        items={selected.map((opt: MultiSelectTopic) => ({
                            id: opt.id,
                            name: opt.name,
                            icon: opt.restricted ? <Lock style={{ fontSize: "16px", color: "#B72026", position: "relative", top: "2px", marginLeft: "4px" }} /> : undefined
                        }))}
                    />
                </div>
            </div>
        </>
    );
};
