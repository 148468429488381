import * as React from "react";
import { SaveNewsletterModel, SaveNewsletterModelStateErrors } from "modules/newsletters/models"
import TextField from "@mui/material/TextField";
import { Grid, Typography } from "@mui/material";
import InfoHover from "modules/common/components/hovers/infoHover";
import _ from "lodash";

class SubjectAndPreHeader extends React.Component<ComponentProps, {}> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
        <div style={{marginBottom: 30}}>
        <p className="tx-strong">Compose the email</p>
        <div>
          <Grid container spacing={2} style={{padding: "6px 0px"}}>
            <Grid item xs={3}>
              <Typography style={{lineHeight:'42px'}}>Subject<span className="required-coloring">*</span></Typography>
            </Grid>
            <Grid item xs={9}>
            <TextField
              variant="outlined"
              size="small"
              value={this.props.newsletter.translatedContent[this.props.currentLcid].subject || ""}
              autoComplete="off"
              placeholder="Ex. Here's is what you missed!"
              error={_.some(this.props.modelErrors?.EmailSubject) || (this.props.highlight && this.props.newsletter.translatedContent[this.props.currentLcid].subject === "")}
              helperText={_.first(this.props.modelErrors?.EmailSubject)}
              fullWidth
              inputProps={{
                maxLength: 78
              }}
              onChange={this.onSubjectChange}
            />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{padding: "6px 0px"}}>
            <Grid item xs={3}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography style={{ lineHeight:'42px' }}>Preheader</Typography>
                <InfoHover>The Preheader is the text following the subject line when an email is previewed.</InfoHover>
              </div>
            </Grid>
            <Grid item xs={9}>
            <TextField
              variant="outlined"
              size="small"
              value={this.props.newsletter.translatedContent[this.props.currentLcid].preheader || ""}
              autoComplete="off"
              error={_.some(this.props.modelErrors?.EmailPreheader)}
              helperText={_.first(this.props.modelErrors?.EmailPreheader)}
              placeholder="Here's is what you missed in Flightward!"
              fullWidth
              inputProps={{
                maxLength: 250
              }}
              onChange={this.OnPreheaderChange}
            />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  private OnPreheaderChange = (event) => {
    let currentContent = this.props.newsletter.translatedContent;
    currentContent[this.props.currentLcid].preheader = event.target.value;
    this.props.onNewsletterChange({translatedContent: currentContent});
  }

  private onSubjectChange = (event) => {
    let currentContent = this.props.newsletter.translatedContent;
    currentContent[this.props.currentLcid].subject = event.target.value;
    this.props.onNewsletterChange({translatedContent: currentContent});
  }
}

interface ComponentProps {
  newsletter: SaveNewsletterModel;
  modelErrors: SaveNewsletterModelStateErrors | null;
  highlight: boolean;
  onNewsletterChange:  (value: Partial<SaveNewsletterModel>) => void;
  currentLcid: string;
}

export default SubjectAndPreHeader;