import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import HoverText from "modules/documents/components/action-buttons/hoverText";
import { useTranslatableContent } from "modules/common/hooks/useTranslatableContent";

import "./contentTypeIcons.sass";
const iconRestricted: string = "/images/icons/restricted_ico.svg";

export interface IBanner extends IDefaultBanner{
    icon: string;
}

export interface IDefaultBanner {
    color: string;
    text: string;
    description?: string;
    urlDescription?: string;
}

interface IContentTypeIcons {
    isRestricted?: boolean;
}

const DEFAULT_RESTRICTED_BANNER : IDefaultBanner = {
    color: "#b72026",
    text: "Restricted",
    description: "This content is exclusive to specific audiences."
}

const ContentTypeIcons: React.FunctionComponent<PropsWithRedux> = ({
    isRestricted,
    restrictedBannerEnabled,
    restrictedBannerColor
}) => {
    const { getTranslation } = useTranslatableContent();

    const restrictedBanner : IBanner  = {
        icon: iconRestricted,
        color: restrictedBannerColor || DEFAULT_RESTRICTED_BANNER.color,
        text: getTranslation("restrictedContent") || DEFAULT_RESTRICTED_BANNER.text,
        description: getTranslation("restrictedBannerDescription") || DEFAULT_RESTRICTED_BANNER.description 
    };

    return (
        <React.Fragment>
            {isRestricted && restrictedBannerEnabled &&
                <HoverText
                    label={
                        <div className="contentIcon" style={{ backgroundColor: restrictedBanner.color }}>
                            <img src={iconRestricted} alt="Restricted content icon" className="iconImage"></img>
                        </div>
                    }
                >
                    {restrictedBanner.description}
                </HoverText>
            }
        </React.Fragment>
    );
};


const connector = connect(
    (state: GlobalApplicationState, ownProps: IContentTypeIcons) => ({
        ...ownProps,
        restrictedBannerColor: state.settings.clientSettings.specialtyPostColors?.restricted,
        restrictedBannerEnabled: state.settings.tenantSettings.bannerVisibility.showRestrictedBanner,
    }),
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(ContentTypeIcons);