import React from "react";

import { postsApi } from "api/instances";
import DownloadButton from "modules/common/components/buttons/muiDownloadButton";
import { FileDownloader } from "utils/fileDownloader";
import { PostFilterValues } from "modules/posts/models";

const DownloadReports: React.FunctionComponent<ComponentProps> = props => {
    const onClick = async (): Promise<any> => {
        const result = await postsApi.GetPostsCSV(props.filter);

        return result;
    };

    return (
        <DownloadButton
            fileType={FileDownloader.DEFAULT_FILE_TYPE}
            text="Download Reports"
            onClick={onClick}
            downloadName={`sparrow-posts-${new Date().toLocaleString().replaceAll(/, | /g, "/")}.csv`}
        />
    );
}

interface ComponentProps {
    filter: Partial<PostFilterValues>;
}

export default DownloadReports;
