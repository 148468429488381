import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { ShareUtil } from "modules/common/components/utils/shareUtils";

import IconButton from "@mui/material/IconButton";
import LinkIcon from '@mui/icons-material/Link';
import CloseIcon from "@mui/icons-material/Close";

import copy from "copy-to-clipboard";
import { Alert, AlertTitle, Button, Snackbar } from "@mui/material";

export const DocumentShareButton: React.FunctionComponent<PropsWithRedux> = (props) => {
    const [isCopying, setIsCopying] = React.useState<boolean>(false);

    const handleShare = () => {
        const shareUtil = new ShareUtil(props.tenantId);
        const url = shareUtil.getPortalUrl("", "document", props.documentId ?? "");
        setIsCopying(true);
        copy(url);
    };

    const onCloseSnackbar = () => setIsCopying(false);

    return (
        <div>
            {
                props.children
                    ? <Button title="Share" size="small" onClick={handleShare} startIcon={<LinkIcon fontSize="small" />}>
                        {props.children}
                    </Button>
                    : <IconButton title="Share" size="small" onClick={handleShare}>
                        <LinkIcon fontSize="small" />
                    </IconButton>
            }
            <Snackbar
                open={isCopying}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                style={{ position: "fixed" }}
                autoHideDuration={6000}
                onClose={onCloseSnackbar}
            >
                <Alert  variant="filled" icon={false}
                style={{backgroundColor: "#303030", justifyContent: "center"}}
                action={
                    <IconButton onClick={onCloseSnackbar} size="medium" color="inherit">
                        <CloseIcon />
                    </IconButton>
                }
                >
                    <AlertTitle>Link copied.</AlertTitle>
                </Alert>
            </Snackbar>
        </div>
    );
};

interface ComponentProps {
    documentId: string;
    children?: string;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        tenantId: state.tenant.id,
    }),
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(DocumentShareButton);