import { BANNER_TEMPLATE } from "../components/banner";
import { CUSTOM_WIDGET_TEMPLATE } from "../components/customWidget";
import { DOCUMENT_FEED_TEMPLATE } from "../components/feed/docsFeed";
import { EVENT_FEED_TEMPLATE } from "../components/feed/eventsFeed";
import { NEWS_FEED_TEMPLATE } from "../components/feed/newsFeed";
import { TEXT_TEMPLATE } from "../components/text";

export enum SectionType {
    Banner = "banner",
    NewsFeed = "news-feed",
    Text = "text",
    EventsFeed = "events-feed",
    DocumentFeed = "document-feed",
    CustomWidget = "custom-widget"
}

export const COMPONENT_TEMPLATES = {
    [SectionType.Banner]: BANNER_TEMPLATE,
    [SectionType.NewsFeed]: NEWS_FEED_TEMPLATE,
    [SectionType.Text]: TEXT_TEMPLATE,
    [SectionType.EventsFeed]: EVENT_FEED_TEMPLATE,
    [SectionType.DocumentFeed]: DOCUMENT_FEED_TEMPLATE,
    [SectionType.CustomWidget]: CUSTOM_WIDGET_TEMPLATE
}

export interface ISectionComponent {
    _template: SectionType
}
