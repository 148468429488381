import React from "react";

import Table from '@mui/material/Table';
import TableBody from "@mui/material/TableBody";

import Document from "./document";
import { DocumentFeedItem } from "modules/documents/models";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { actions } from "modules/documents";
import { defaultDocumentFeedFilters } from "modules/documents/reducer";
import { UserCategoryTag } from "modules/categorytags";
import Loading from "modules/common/components/loading";
import { FeedButtons } from "modules/common/components/feed/feedButtons";

import { DocumentActions } from "../../../documentActions";
import DocumentView from "modules/documents/components/document-views/documentView";
import { push } from "react-router-redux";
import { EmptyFeed } from "modules/common/components/feed/emptyFeed";
import { IDocumentFeedOptionalFlags } from "modules/portalPages/tinacms/models/feed";

import "../../../../../styles/layouts/list.sass";
import "../../../../../styles/layouts/feed.sass";


interface IDocumentFeedSection {
    lockedToCategoryTags: UserCategoryTag[];
    maxResults: number;
    optionalContent: IDocumentFeedOptionalFlags;
    refresh: boolean;
    resetRefresh: () => void;
}

const DocumentFeedSection: React.FunctionComponent<IDocumentFeedSection> = (props: PropsWithRedux) => {
    const [documents, setDocuments] = React.useState<DocumentFeedItem[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [canLoadMore, setCanLoadMore] = React.useState<boolean>(true);
    const [selectedDocument, setSelectedDocument] = React.useState<DocumentFeedItem>();

    const {
        fetchDocumentFeed,
        resetRefresh,
        maxResults,
        refresh
    } = props;

    const currentFilters = {
        ...defaultDocumentFeedFilters,
        tags: props.lockedToCategoryTags.map(ct => ct.id)
    };

    const onFetchMore = React.useCallback( async (pageNumber: number) => {
        try {
            setIsLoading(true);
            const newDocs = await fetchDocumentFeed(pageNumber, currentFilters, maxResults);

            setDocuments(pageNumber === 1 ? newDocs : [...documents, ...newDocs]);
            setCanLoadMore(newDocs.length === maxResults);
        }
        catch {
            setDocuments([]);
            setCanLoadMore(false);
        }
        finally {
            resetRefresh();
            setIsLoading(false);
        }
    }, [currentFilters, maxResults, documents, fetchDocumentFeed, resetRefresh]);

    React.useEffect(() => {
        const fetchDocuments = async () => await onFetchMore(1);

        if (!isLoading && refresh) fetchDocuments();
    }, [isLoading, onFetchMore, refresh]);

    const onSelectDocument = async (document: DocumentFeedItem) => {
        const action = new DocumentActions();
        await action.select(document);
    };

    const onDownloadDocument = async (document: DocumentFeedItem) => {
        setIsLoading(true);
        const action = new DocumentActions();
        await action.download(document);
        setIsLoading(false);
    };

    const onViewDocument = async (selectedDocument: DocumentFeedItem) => {
        setIsLoading(true);
        const action = new DocumentActions();
        await action.openInNew(selectedDocument);
        setIsLoading(false);
    };

    const onInspectDocument = (document: DocumentFeedItem) => {
        setSelectedDocument(document);
    };

    const getDocumentFeed = () => <TableBody className="list">
        {documents.map(document => (
            <Document
                key={document.id}
                document={document}
                onDocumentSelected={onSelectDocument}
                onDownloadDocument={onDownloadDocument}
                onInspectDocument={onInspectDocument}
                optionalSettings={props.optionalContent}
            />
        ))}
    </TableBody>;

    const onViewAll = () => {
        props.setDocumentFeedFilters(currentFilters);
        props.redirectTo(`/${props.tenantId}/documents`);
    };

    if (documents.length === 0) {
        return isLoading
            ? <Loading />
            : <EmptyFeed />;
    }

    const loadMore = props.optionalContent.hideLoadMore
        ? undefined
        : { isFetching: isLoading, canLoadMore: canLoadMore, onFetchMore: onFetchMore}

    return (
        <React.Fragment>
            <Table id="document-feed" className="portal-page-doc-feed">
                {getDocumentFeed()}
            </Table>
            <FeedButtons
                viewAll={props.optionalContent.hideViewAll ? undefined : { action: onViewAll }}
                loadMore={loadMore}
            />
            
            <DocumentView
                show={!!selectedDocument}
                view={selectedDocument}
                onDownloadDocument={onDownloadDocument}
                onViewDocument={onViewDocument}
                onClose={() => setSelectedDocument(undefined)}
            />
        </React.Fragment>
    );
};

const connector = connect(
    (state: GlobalApplicationState, ownProps: IDocumentFeedSection) => ({
        ...ownProps,
        tenantId: state.tenant.id
    }),
    {
        setDocumentFeedFilters: actions.setDocumentFeedFilters,
        fetchDocumentFeed: actions.getDocumentFeedLocal,
        redirectTo: push
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(DocumentFeedSection);
