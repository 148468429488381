import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { Button, Dialog, DialogContent, DialogTitle, Divider, FormControl, IconButton, Switch, TextField, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ArrowIcon from '@mui/icons-material/ArrowForwardIos';
import OpenNewIcon from '@mui/icons-material/OpenInNew';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { actions, InvitedUser, InvitePlatform, UserRole } from 'modules/users';
import { usersApi } from 'api/instances';

import Disclaimer from 'modules/common/components/disclaimer';
import InfoHover from 'modules/common/components/hovers/infoHover';
import HoverText from 'modules/documents/components/action-buttons/hoverText';

import { emailRegex } from "utils/regexUserValidation";
import { USER_ROLE_DESCRIPTIONS, UserRoleStrings } from "modules/authorization/models";
import TopicPermissionSection from './dialogs/topicPermissionSection';
import { MultiSelectTopic } from 'modules/settings';
import { RoleSelector } from "modules/common/components/forms/inputs/roleSelector";
import { getHierarchyRoles } from "utils/getRole";

interface ComponentProps {
    isOpen: boolean;
    closeDialog: () => void;
    swapDialogs: () => void;
    closeReport: () => void;
    bulkClicked: () => void;
    setLoading: (loading: boolean) => void;
    setUploadFailed: (status: boolean) => void;
    updateSparrowUser: (name: string, email: string) => void;
}

const specialCharacterRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/; //eslint-disable-line

const LocalUserDialog: React.FC<PropsWithRedux> = (props: PropsWithRedux) => {
    const [optionalFieldsOpen, setOptionalFieldsOpen] = React.useState(false);
    const [permissionsOpen, setPermissionsOpen] = React.useState(false);

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");

    const [errorFirstName, setErrorFirstName] = React.useState(false);
    const [errorLastName, setErrorLastName] = React.useState(false);
    const [errorEmail, setErrorEmail] = React.useState(false);
    const [errorEmailMessage, setErrorEmailMessage] = React.useState("");

    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [title, setTitle] = React.useState("");
    const [department, setDepartment] = React.useState("");
    const [reportsTo, setReportsTo] = React.useState("");
    const [country, setCountry] = React.useState("");
    const [region, setRegion] = React.useState("");
    const [nickname, setNickname] = React.useState("");

    const [errorPhoneNumber, setErrorPhoneNumber] = React.useState(false);

    const [roleSelected, setRoleSelected] = React.useState(UserRole.USER);
    const [isSubManager, setIsSubManager] = React.useState(false);

    const [selectedTopics, setSelectedTopics] = React.useState<MultiSelectTopic[]>([]);

    const errorPermissions = roleSelected === UserRole.CONTRIBUTOR && selectedTopics.length === 0;
    const isUserValid = firstName !== "" && lastName !== "" && email !== "" &&
        !errorFirstName && !errorLastName && !errorEmail && errorEmailMessage === "" && !errorPermissions;

    //Reset error values when the component is re-opened
    useEffect(() => {
        if (props.isOpen) {
            setErrorEmail(false);
            setErrorFirstName(false);
            setErrorLastName(false);
            setErrorPhoneNumber(false);
            setRoleSelected(UserRole.USER);
            setOptionalFieldsOpen(false);
            setPermissionsOpen(false);
        }
    }, [props.isOpen]);

    //Only check the inputted email after they stop typing for .5 seconds.
    useEffect(() => {
        const interval = setTimeout(() => {
            if (!email.match(emailRegex) || !validateEmail(email)) {
                setErrorEmail(true);
                setErrorEmailMessage("Enter valid email address.");
            } else {
                usersApi.CheckIfEmailAvailable(email).then((response) => {
                    if (response) {
                        setErrorEmail(false);
                        setErrorEmailMessage("");
                    } else {
                        setErrorEmail(true);
                        setErrorEmailMessage("Email address is already taken.");
                    }
                });
            }
        }, 500);

        return () => clearInterval(interval);
    }, [email]);

    const validateEmail = (email: string) => {
        if (specialCharacterRegex.test(email.charAt(0)) || specialCharacterRegex.test(email.charAt(email.length - 1))) {
            return false;
        }
        return true;
    };

    let updateNumber = (e) => {
        if (e.target.value.match(/^[0-9\+\-\(\)]*$/))
            //eslint-disable-line
            setPhoneNumber(e.target.value);
    };

    const updateEmail = (newValue: string) => {
        setEmail(newValue);
    };

    const verifyAndSubmitUser = async () => {
        var validToUpload = true;

        if (firstName === "") {
            setErrorFirstName(true);
            validToUpload = false;
        }

        if (lastName === "") {
            setErrorLastName(true);
            validToUpload = false;
        }

        //Email validation handled elsewhere.

        let submissionManager = false;
        let userRoles: string[] = getHierarchyRoles(roleSelected);

        if (userRoles.includes(UserRoleStrings.AUTHOR) && isSubManager)
            submissionManager = true;

        let number: string = (phoneNumber || "").replace(/[^0-9]/g, "");

        if (number.length < 10 && phoneNumber !== "") {
            setErrorPhoneNumber(true);
            validToUpload = false;
        }

        const countryCode: string = `+${!!number.slice(0, -10).length ? number.slice(0, -10) : "1"} `;
        let formattedNumber = countryCode + "(" + number.slice(-10, -7) + ") " + number.slice(-7, -4) + "-" + number.slice(-4);

        if (validToUpload) {
            let toSend: InvitedUser = {
                inviteType: InvitePlatform.Sparrow,
                enabled: true,
                email: email.toLowerCase(),
                smsPhone: phoneNumber === "" ? "" : formattedNumber,
                userDetails: {
                    firstName: firstName,
                    lastName: lastName,
                    title: title,
                    department: department,
                    reportsTo: reportsTo,
                    country: country,
                    region: region,
                    preferredName: nickname,
                    roles: userRoles,
                    canManageSubmissions: submissionManager,
                    permissionSetTopics: roleSelected === UserRole.CONTRIBUTOR ? selectedTopics.map(t => t.id) : []
                },
            };

            props.swapDialogs();
            props.setUploadFailed(false); //Reset value to false before making request.
            let result = await props.inviteSparrowUser([toSend]);

            //Check if call failed
            if (result && result.toString() !== "200") {
                props.setUploadFailed(true);
            }
            else {
                props.updateSparrowUser(firstName + " " + lastName, email);
            }
            clearUserData();
            props.setLoading(false);
        }
    };

    const clearUserData = () => {
        setLastName("");
        setFirstName("");
        setEmail("");
        setPhoneNumber("");
        setTitle("");
        setDepartment("");
        setReportsTo("");
        setNickname("");
        setCountry("");
        setRoleSelected(UserRole.USER);
    };

    const onRoleChange = (newRole: UserRole) => {
        setRoleSelected(newRole);
    };

    const finishButton = () => <Button
        onClick={verifyAndSubmitUser}
        style={{ float: "right", marginLeft: "6px" }}
        color="primary"
        variant="contained"
        disabled={!isUserValid}
    >
        Finish
    </Button>;

    return (
        <React.Fragment>
            <Dialog open={props.isOpen} onClose={props.closeDialog} maxWidth={"md"} fullWidth={true}>
                <DialogTitle className="external-user-dialog-formatting">
                    <div className="external-user-dialog-header-title">
                        <Typography variant="h2">Add New Sparrow User</Typography>
                        <div style={{ position: "relative", top: "4px", marginRight: "auto" }}>
                            <InfoHover>
                                Sparrow Users are accounts directly provided, managed and created by our system. These users do not require
                                external providers to login.
                            </InfoHover>
                        </div>
                        <IconButton onClick={props.closeDialog} size="large">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className="external-user-dialog-content">
                    <div style={{ maxHeight: "600px", minHeight: "600px", overflowY: "auto", paddingLeft: "10px", paddingRight: "10px" }}>
                        <div className="local-fields-formatting">
                            <div>
                                <span style={{ float: "left" }}>
                                    First name<span style={{ color: "#B72026", top: "0px" }}>*</span>
                                </span>
                                <TextField
                                    error={errorFirstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    onClick={() => setErrorFirstName(false)}
                                    placeholder="Enter first name"
                                    className="textfield-formatting"
                                    variant="outlined"
                                    size="small"
                                />
                            </div>

                            <div>
                                <span style={{ float: "left" }}>
                                    Last name<span style={{ color: "#B72026", top: "0px" }}>*</span>
                                </span>
                                <TextField
                                    error={errorLastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    onClick={() => setErrorLastName(false)}
                                    placeholder="Enter last name"
                                    className="textfield-formatting"
                                    variant="outlined"
                                    size="small"
                                />
                            </div>

                            <div>
                                <span style={{ float: "left" }}>
                                    Email address<span style={{ color: "#B72026", top: "0px" }}>*</span>
                                </span>
                                <div style={{ display: "flex", flexDirection: "column" }} className="textfield-formatting">
                                    <TextField
                                        onChange={(e) => updateEmail(e.target.value)}
                                        onClick={() => setErrorEmail(false)}
                                        error={errorEmail}
                                        placeholder="Ex. alan.smith@example.com"
                                        variant="outlined"
                                        helperText={errorEmailMessage}
                                        size="small"
                                    />
                                </div>
                            </div>
                        </div>

                        <Divider />

                        <div
                            style={{
                                cursor: "pointer",
                                height: "70px",
                                display: "flex",
                                alignItems: "center",
                                minWidth: "500px",
                                maxWidth: "880px",
                            }}
                            onClick={() => setOptionalFieldsOpen(!optionalFieldsOpen)}
                        >
                            <b style={{ fontWeight: 500 }}>Standard profile fields (optional) </b>
                            {!optionalFieldsOpen && errorPhoneNumber ? <b> - Errors inside</b> : null}
                            {optionalFieldsOpen ? (
                                <ArrowIcon style={{ transform: "rotate(90deg)", marginLeft: "auto", color: "#707070" }} />
                            ) : (
                                <ArrowIcon style={{ transform: "rotate(270deg)", marginLeft: "auto", color: "#707070" }} />
                            )}
                        </div>

                        <Divider />
                        {optionalFieldsOpen && (
                            <div className="local-fields-formatting">
                                <div>
                                    <span style={{ float: "left" }}>Mobile no.</span>
                                    <TextField
                                        onClick={(e) => setErrorPhoneNumber(false)}
                                        onChange={updateNumber}
                                        error={errorPhoneNumber}
                                        placeholder="Ex. +1-123-867-5309"
                                        className="textfield-formatting"
                                        variant="outlined"
                                        size="small"
                                        value={phoneNumber}
                                    />
                                </div>

                                <div>
                                    <span style={{ float: "left" }}>Title</span>
                                    <TextField
                                        onChange={(e) => setTitle(e.target.value)}
                                        placeholder="Enter role or title"
                                        className="textfield-formatting"
                                        variant="outlined"
                                        size="small"
                                        value={title}
                                    />
                                </div>

                                <div>
                                    <span style={{ float: "left" }}>Department</span>
                                    <TextField
                                        onChange={(e) => setDepartment(e.target.value)}
                                        placeholder="Enter department"
                                        className="textfield-formatting"
                                        variant="outlined"
                                        size="small"
                                        value={department}
                                    />
                                </div>

                                <div>
                                    <span style={{ float: "left" }}>Reports to</span>
                                    <TextField
                                        onChange={(e) => setReportsTo(e.target.value)}
                                        placeholder="Enter name"
                                        className="textfield-formatting"
                                        variant="outlined"
                                        size="small"
                                        value={reportsTo}
                                    />
                                </div>

                                <div>
                                    <span style={{ float: "left" }}>Country</span>
                                    <TextField
                                        onChange={(e) => setCountry(e.target.value)}
                                        placeholder="Enter country"
                                        className="textfield-formatting"
                                        variant="outlined"
                                        size="small"
                                        value={country}
                                    />
                                </div>

                                <div>
                                    <span style={{ float: "left" }}>Region</span>
                                    <TextField
                                        onChange={(e) => setRegion(e.target.value)}
                                        placeholder="Enter region, city, or province"
                                        className="textfield-formatting"
                                        variant="outlined"
                                        size="small"
                                        value={region}
                                    />
                                </div>

                                <div>
                                    <span style={{ float: "left" }}>Preferred Name</span>
                                    <TextField
                                        onChange={(e) => setNickname(e.target.value)}
                                        placeholder="Enter preferred name"
                                        className="textfield-formatting"
                                        variant="outlined"
                                        size="small"
                                        value={nickname}
                                    />
                                </div>

                                <Divider />
                            </div>
                        )}

                        <div
                            style={{
                                cursor: "pointer",
                                height: "70px",
                                display: "flex",
                                alignItems: "center",
                                minWidth: "500px",
                                maxWidth: "880px",
                            }}
                            onClick={() => setPermissionsOpen(!permissionsOpen)}
                        >
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <b style={{ fontWeight: 500 }}>Roles</b>
                                <span>Set more roles for this user</span>
                            </div>
                            {permissionsOpen ? (
                                <ArrowIcon style={{ transform: "rotate(90deg)", marginLeft: "auto", color: "#707070" }} />
                            ) : (
                                <ArrowIcon style={{ transform: "rotate(270deg)", marginLeft: "auto", color: "#707070" }} />
                            )}
                        </div>

                        <Divider />

                        {permissionsOpen && (
                            <div style={{ paddingBottom: "20px" }}>
                                <div style={{ display: "flex", flexDirection: "row", paddingBottom: "15px" }}>
                                    <span style={{ width: "230px", position: "relative", top: "14px" }}>Give permissions of a </span>
                                    <RoleSelector
                                        formControlStyle={{ paddingTop: 6 }}
                                        selectedRole={roleSelected}
                                        onSelectRole={onRoleChange}
                                    />
                                </div>

                                <div>

                                <Disclaimer
                                    icon={<InfoOutlinedIcon />}
                                    text={
                                    <React.Fragment>
                                        <span>{USER_ROLE_DESCRIPTIONS[roleSelected]}</span>
                                    </React.Fragment>
                                    }
                                />
                                </div>

                                {(roleSelected === UserRole.AUTHOR || roleSelected === UserRole.OWNER) &&
                                    <div style={{ paddingTop: "15px" }}>
                                        <div>
                                            <span style={{ paddingTop: "6px" }}>Assign as a Submission Manager</span>
                                            <FormControl style={{ float: "right", position: "relative", top: "-7px" }}>
                                                <Switch size="medium" onChange={(e) => setIsSubManager(e.target.checked)} />
                                            </FormControl>
                                        </div>
                                        <span className="description-text">
                                            Notify this {UserRole[roleSelected].toString().toLocaleLowerCase()} when Content is submitted by a Contributor.
                                        </span>
                                    </div>
                                }

                                {
                                    roleSelected === UserRole.CONTRIBUTOR &&
                                    <TopicPermissionSection
                                        topics={props.topics}
                                        selectedTopics={selectedTopics}
                                        setSelectedTopics={setSelectedTopics}
                                        role={UserRole.CONTRIBUTOR} />
                                }
                            </div>
                        )}
                    </div>
                </DialogContent>
                <Divider style={{ marginBottom: "10px" }} />
                <div style={{ paddingBottom: "10px", paddingRight: "5px", paddingLeft: "5px" }}>
                    <Button style={{ float: "left" }} onClick={props.bulkClicked} color="primary" endIcon={<OpenNewIcon />}>
                        Bulk Import Sparrow Users
                    </Button>
                    {
                        !isUserValid
                            ? <HoverText label={finishButton()}>
                                Complete required fields
                            </HoverText>
                            : finishButton()
                    }
                    <Button onClick={props.closeDialog} style={{ float: "right" }}>
                        Cancel
                    </Button>
                </div>
            </Dialog>
        </React.Fragment>
    );
};

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        topics: state.settings.tenantSettings.tagGroups
    }),
    {
        inviteSparrowUser: actions.inviteSparrowUsers,
    }
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(LocalUserDialog);
