import React from "react";

import { EventFeedItem, EventType, Tag } from "modules/events/models";
import { FeedLayout } from "modules/portalPages/tinacms/models/common";
import { connect, ConnectedProps } from "react-redux";
import { push } from "react-router-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { actions } from "modules/events";
import { defaultEventsFeedFilter } from "modules/events/reducer";
import VisualCard from "./components/layouts/visualCard/VisualCard";
import ViewOptions from "./consts/viewOptions";
import Loading from "modules/common/components/loading";
import { DialogContentView } from "modules/common/components/dialogs/dialogContentView";
import { EmptyFeed } from "modules/common/components/feed/emptyFeed";
import EventView from "../event-views/eventView";
import List from "./components/layouts/list/List";
import { Window } from "./components/layouts/window/Window";
import { IFeedOptionalFlags } from "modules/portalPages/tinacms/models/feed";
import { FeedButtons } from "modules/common/components/feed/feedButtons";

import "./styles/sectionCard.sass";
import { CardType } from "./models";

interface IEventFeedOptionalFlags extends IFeedOptionalFlags {
    ignoreSubscriptions: boolean;
    hideSummary: boolean;
    hideTopics: boolean;
}

interface IEventFeedSection {
    layout: FeedLayout;
    cardCount: number;
    lockedToTopics: Tag[];
    eventTypes: EventType[];
    maxResults: number;
    optionalContent: IEventFeedOptionalFlags;
    refresh: boolean;
    resetRefresh: () => void;
}

const EventFeedSection: React.FunctionComponent<PropsWithRedux> = (props: PropsWithRedux) => {
    const [events, setEvents] = React.useState<EventFeedItem[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [canLoadMore, setCanLoadMore] = React.useState<boolean>(true);
    const [selectedEventId, setSelectedEventId] = React.useState<string>("");

    const currentFilters = {
        ...defaultEventsFeedFilter,
        ...ViewOptions["upcoming"].filters,
        ignoreSubscriptions: props.optionalContent.ignoreSubscriptions,
        tags: props.lockedToTopics.map(t => t.id),
        eventTypes: props.eventTypes ?? []
    };

    const {
        fetchEventFeed,
        resetRefresh,
        maxResults,
        refresh,
        optionalContent
    } = props;

    const onFetchMore = React.useCallback(async (pageNumber: number) => {
        try {
            setIsLoading(true);
            const newEvents = await fetchEventFeed(currentFilters, pageNumber, maxResults);

            setEvents(pageNumber === 1 ? newEvents : [...events, ...newEvents]);
            setCanLoadMore(newEvents.length === +maxResults);
        }
        catch {
            setEvents([]);
            setCanLoadMore(false);
        }
        finally {
            resetRefresh();
            setIsLoading(false);
        }
    }, [currentFilters, maxResults, events, fetchEventFeed, resetRefresh])

    React.useEffect(() => {
        const fetchEvents = async () => await onFetchMore(1);

        if (!isLoading && refresh) fetchEvents();
    }, [isLoading, onFetchMore, refresh]);

    const onViewAll = () => {
        props.setEventFeedFilters(currentFilters);
        props.redirectTo(`/${props.tenantId}/events`);
    };

    const selectEvent = (eventId: string) => setSelectedEventId(eventId);

    const unselectEvent = () => setSelectedEventId("");

    if (events.length === 0) {
        return isLoading
            ? <Loading />
            : <EmptyFeed />;
    }

    const loadMore = optionalContent.hideLoadMore
        ? undefined
        : { isFetching: isLoading, canLoadMore: canLoadMore, onFetchMore: onFetchMore}
    
    const optionalEventContent = {
        hideSummary: optionalContent.hideSummary,
        hideTopics: optionalContent.hideTopics
    }

    const horizontalBandLayout = () => <VisualCard
        events={events}
        onEventSelected={selectEvent}
        cardType={CardType.Horizontal}
        viewAll={optionalContent.hideViewAll ? undefined : onViewAll}
        loadMore={loadMore}
        hideSummary={optionalContent.hideSummary}
        hideTopics={optionalContent.hideTopics}
        showReadingStatus
    />;

    const getEventSection = (): JSX.Element => {
        switch (props.layout) {
            case FeedLayout.HorizontalBand:
                return horizontalBandLayout();

            case FeedLayout.StackedCard:
                return <VisualCard
                    events={events}
                    onEventSelected={selectEvent}
                    cardType={CardType.Stacked}
                    cardCount={props.cardCount}
                    hideSummary={optionalContent.hideSummary}
                    hideTopics={optionalContent.hideTopics}
                    showReadingStatus
                />;

            case FeedLayout.List:
                return <List
                    eventFeed={events}
                    onClickEvent={selectEvent}
                    optionalContent={optionalEventContent}
                />;

            case FeedLayout.Window:
                return <Window
                    eventFeed={events}
                    onClickEvent={selectEvent}
                    cardCount={props.cardCount}
                    hideSummary={optionalEventContent.hideSummary}
                />;

            default:
                return horizontalBandLayout();
        }
    };

    return (
        <div id="event-feed" className="news-feed-section">
            {getEventSection()}

            {
                props.layout !== FeedLayout.HorizontalBand &&
                <FeedButtons
                    viewAll={props.optionalContent.hideViewAll ? undefined : { action: onViewAll }}
                    loadMore={loadMore}
                />
            }
            <DialogContentView id="view-event-dialog" open={!!selectedEventId} classes={{ paper: "dialog-content" }} onClose={unselectEvent}>
                <EventView eventId={selectedEventId} onClose={unselectEvent} />
            </DialogContentView>
        </div>
    );
};

const connector = connect(
    (state: GlobalApplicationState, ownProps: IEventFeedSection) => ({
        ...ownProps,
        tenantId: state.tenant.id
    }),
    {
        setEventFeedFilters: actions.setEventFeedFilters,
        fetchEventFeed: actions.fetchEventFeedLocal,
        redirectTo: push
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(EventFeedSection);
