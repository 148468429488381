import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { SaveNewsletterModel } from "modules/newsletters/models";
import { Divider, Grid, Link } from "@mui/material";
import { NewsletterTemplate } from "modules/newsletters";
import { Audience } from "modules/audiences/models";
import moment from "moment";
import _ from "lodash";
import ChipLabels from "modules/common/components/chips/chipLabels";
import Tabs from "pages/common/tabs";
import Disclaimer from "modules/common/components/disclaimer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Loading from "modules/common/components/loading";

class NewsletterPreview extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      selectedTab: 0,
      isLoading: false,
      uniqueUsers: 0
    };
  }

  async componentDidMount() {
    await this.updateUniqueRecipients()
  }

  public async updateUniqueRecipients() {
    var ids = this.props.newsletter.audiences?.map(a => a.id) || [];
    var grabAll = ids.length === 0;
    this.setState({isLoading: true});
    var result = await this.props.getUniqueUsers(ids, grabAll);
    this.setState({uniqueUsers: result, isLoading: false});
  }

  public render() {
    const { newsletter } = this.props;
    const audiences = _.orderBy(_.map(newsletter.audiences,c => c.name),n=>n).join(', ');

    const tabs = [
      {label: "Email details", value: 0},
      {label: "More settings", value: 1}
    ]

    var topicsUsed = Object.create({});
    if(newsletter.topicOption === 'All')
    {
      topicsUsed = [{id: 0, name: "All topics"}]
    }
    else if(newsletter.topicOption === 'Employee')
    {
      topicsUsed = [{id: 0, name:"Employee topics"}]
    }
    else if(newsletter.topicOption === 'Custom')
    {
      topicsUsed = newsletter.customTopics;
    }
    else
    {
      topicsUsed = [{id: 0, name: "Invalid value"}]
    }

    return (
      <Dialog open={true} maxWidth={false} scroll="paper" onClose={this.props.onClose} style={{minHeight: "800px"}} classes={{ paper: "newsletter-dialog" }}>
        <DialogTitle className="newsletter-dialog-title">
          <div>One last check!</div>
          <div>
            <Button variant="text" color="primary" onClick={this.props.onClose}>Keep Editing</Button>
            <Button variant="contained" color="primary" onClick={this.props.publishNewsletter}>Publish</Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="newsletter-preview">
            <div style={{marginBottom: '15px', marginTop: '15px'}}>
              <Grid container spacing={3} className="element-padding">
                <Grid item xs={4}>
                  <span>Newsletter Title</span>
                </Grid>
                <Grid item xs={8}>
                  {newsletter.title}
                </Grid>
              </Grid>
              <Grid container spacing={3} className="element-padding">
                <Grid item xs={4}>
                  <span>Description</span>
                </Grid>
                <Grid item xs={8}>
                  {newsletter.description === "" ? <i>No description is included</i> : newsletter.description}
                </Grid>
              </Grid>
              <Grid container spacing={3} className="element-padding">
                <Grid item xs={4}>
                  <span>From</span>
                </Grid>
                <Grid item xs={8}>
                  <div style={{wordBreak: "break-word"}}>
                  {newsletter.fromEmailName}, {newsletter.fromEmailAddress}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3} className="element-padding">
                <Grid item xs={4}>
                  <span>To</span>
                </Grid>
                <Grid item xs={8}>
                  <ChipLabels chips={newsletter.audiences || []} emptyText="All users in directory" />
                </Grid>
              </Grid>
              <div style={{paddingTop: "20px"}}>
                <Tabs
                  tabs={tabs}
                  selectedTab={this.state.selectedTab}
                  onSelectTab={(e) => this.setState({selectedTab: e})}
                />
              </div>
            </div>

            {this.state.selectedTab === 0 && 
              <div style={{minHeight: "605px"}}>
                <div style={{marginBottom: '15px', marginTop: '15px'}}>
                  <Grid container spacing={3} className="element-padding">
                    <Grid item xs={4}>
                      <span>Subject</span>
                    </Grid>
                    <Grid item xs={8}>
                      {newsletter.translatedContent[this.props.defaultLcid].subject}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="element-padding">
                    <Grid item xs={4}>
                      <span>Preheader</span>
                    </Grid>
                    <Grid item xs={8}>
                      {newsletter.translatedContent[this.props.defaultLcid].preheader === "" 
                        ? <i>No preheader is included</i> 
                        : newsletter.translatedContent[this.props.defaultLcid].preheader}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="element-padding">
                    <Grid item xs={4}>
                      <span>Theme</span>
                    </Grid>
                    <Grid item xs={8}>
                    {this.props.template.isCustom ? 'Custom': 'Standard'}
                    </Grid>
                  </Grid>
                </div>
                <Divider />
                <div style={{marginBottom: '15px', marginTop: '15px'}}>
                { !this.props.template.isCustom && 
                  <div>
                  <Grid container spacing={3} className="element-padding">
                    <Grid item xs={4}>
                      <span>Header Image</span>
                    </Grid>
                    <Grid item xs={8}>
                      { this.props.headerImage 
                        ? <img src={URL.createObjectURL(this.props.headerImage)} alt="header"></img>
                        : <img src={"/images/newsletterPreviews/secondHeader.png"} alt="header"></img>
                      }
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="element-padding">
                    <Grid item xs={4}>
                      <span>Header text</span>
                    </Grid>
                    <Grid item xs={8}>
                    {newsletter.translatedContent[this.props.defaultLcid].header === "" 
                      ? <i>No header text is included</i> 
                      : <div dangerouslySetInnerHTML={{ __html: newsletter.translatedContent[this.props.defaultLcid].header }} className="body"></div>
                    }
                    </Grid>
                  </Grid>
                  </div>
                }
                { !this.props.template.isCustom && 
                  <div>
                  <Grid container spacing={3} className="element-padding">
                    <Grid item xs={4}>
                      <span>Body</span>
                    </Grid>
                    <Grid item xs={8}>
                    {newsletter.translatedContent[this.props.defaultLcid].body === "" 
                      ? <i>No body text is included</i> 
                      : <div dangerouslySetInnerHTML={{ __html: newsletter.translatedContent[this.props.defaultLcid].body}} className="body"></div>
                    }
                    </Grid>
                  </Grid>
                  </div>
                }
                {!this.props.template.isCustom && 
                <div>
                <Grid container spacing={3} className="element-padding">
                  <Grid item xs={4}>
                    <span>Footer Image</span>
                  </Grid>
                  <Grid item xs={8}>
                    { this.props.footerImage
                        ? <img src={URL.createObjectURL(this.props.footerImage)} alt="footer"></img>
                        : <img src={"/images/newsletterPreviews/secondFooter.png"} alt="footer"></img>
                    }
                  </Grid>
                </Grid>
                <Grid container spacing={3} className="element-padding">
                  <Grid item xs={4}>
                    <span>Footer text</span>
                  </Grid>
                  <Grid item xs={8}>
                    {newsletter.translatedContent[this.props.defaultLcid].footer === "" 
                      ? <i>No footer text is included</i> 
                      : <div dangerouslySetInnerHTML={{ __html: newsletter.translatedContent[this.props.defaultLcid].footer }} className="body"></div>
                    }
                  </Grid>
                </Grid>
                </div>
                }
                {this.props.template.isCustom &&
                  <div>
                    <Disclaimer
                      icon={<InfoOutlinedIcon className="successfully-scanned-icon" />}
                      text={
                        <React.Fragment>
                          <span style={{color: "black"}}>
                            Header and Footer images cannot be modified through the Newsletter Editor when using custom templates.
                            Add or modify custom templates with the help of our Support team. <Link onClick={() => window.open("https://support.sparrowconnected.com/en/how-do-i-create-a-custom-template-for-newsletters")} underline="always">Learn more</Link>.
                          </span>
                        </React.Fragment>
                      }
                    />
                  </div>
                }
              </div>
            </div>
            }

            {this.state.selectedTab === 1 &&
              <div style={{minHeight: "605px"}}>
                {this.state.isLoading &&
                  <div style={{paddingBottom: "20px"}}>
                    <Loading size={28}/>
                  </div>
                }
                {!this.state.isLoading && 
                <div style={{marginBottom: '15px', marginTop: '15px'}}>
                  <Grid container spacing={3} className="element-padding">
                    <Grid item xs={4}>
                      <span>Total unique recipients?</span>
                    </Grid>
                    <Grid item xs={8}>
                      {this.state.uniqueUsers}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="element-padding">
                    <Grid item xs={4}>
                      <span>Send to</span>
                    </Grid>
                    <Grid item xs={8}>
                      {audiences || 'All users'}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="element-padding">
                    <Grid item xs={4}>
                      <span>Next queued for</span>
                    </Grid>
                    <Grid item xs={8}>
                      {moment(newsletter.firstIssueDateTime).format("dddd, MMM D, YYYY, h:mmA")}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="element-padding">
                    <Grid item xs={4}>
                      <span>Frequency</span>
                    </Grid>
                    <Grid item xs={8}>
                      {newsletter.recurrenceType === 'Adhoc' ? <div>Adhoc</div> :
                      <div>Every {newsletter.recurrenceType === "Monthly" ? `${newsletter.monthlyRecurrenceWeek} ${newsletter.monthlyRecurrenceDay}` : (newsletter.recurrenceType === 'Biweekly' ? `other ${moment(newsletter.firstIssueDateTime).format('dddd')}` : newsletter.dailyRecurrenceOn.join(", "))}</div>}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="element-padding">
                    <Grid item xs={4}>
                      <span>Topics used</span>
                    </Grid>
                    <Grid item xs={8}>
                      <ChipLabels chips={topicsUsed} emptyText="All topics" />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="element-padding">
                    <Grid item xs={4}>
                      <span>Include unread posts</span>
                    </Grid>
                    <Grid item xs={8}>
                      {newsletter.maxPostsToInclude > 0 ? 'Yes': 'No'}
                    </Grid>
                  </Grid>
                  {newsletter.maxPostsToInclude > 0 &&
                      <div>
                        <Grid container spacing={3} className="element-padding">
                          <Grid item xs={4}></Grid>
                          <Grid item xs={8}>
                            Include up to {newsletter.maxPostsToInclude} unread posts in the last {newsletter.postsToIncludeDaysWindow} days
                          </Grid>
                        </Grid>
                      </div>
                    }
                    <Grid container spacing={3} className="element-padding">
                      <Grid item xs={4}>
                        <span>Include upcoming events</span>
                      </Grid>
                      <Grid item xs={8}>
                        {newsletter.maxEventsToInclude > 0 ? 'Yes': 'No'}
                      </Grid>
                    </Grid>
                    {newsletter.maxEventsToInclude > 0 &&
                      <div>
                        <Grid container spacing={3} className="element-padding">
                          <Grid item xs={4}></Grid>
                          <Grid item xs={8}>
                            Include up to {newsletter.maxEventsToInclude} upcoming events in the next {newsletter.eventsToIncludeDaysWindow} days
                          </Grid>
                        </Grid>
                      </div>
                    }
                </div>
              } 
              </div>
            }
          </div>
        </DialogContent>
        
      </Dialog>
    );
  }
}

interface ComponentProps {
  newsletter: SaveNewsletterModel;
  template: NewsletterTemplate;
  audiences: Audience[];
  headerImage?: File | null;
  footerImage?: File | null;
  defaultLcid: string;
  publishNewsletter: () => any;
  onClose:() => any;
  getUniqueUsers: (ids: string[], grabAll: boolean) => Promise<number>;
}

interface ComponentState {
  selectedTab: number;
  isLoading: boolean;
  uniqueUsers: number;
}

export default NewsletterPreview;