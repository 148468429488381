import { Action, createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { IEmailFilters, IEmailsState, IEmailListing, IEmail, IEmailSentConfirmation, EmailStatus } from "./models";
import { emailsApi } from "api/instances";
import { GlobalApplicationState } from "globalApplicationState";

const DEFAULT_EMAILS_LIST_STATE = {
    currentPage: 0,
    totalEmails: 0,
    totalPages: 0,
    emails: [],
    isFetching: false
}

export const DEFAULT_EMAIL: IEmail = {
    id: "",
    tenantId: "",
    sentBy: undefined,
    sentTime: undefined,
    subject: [
        {
            lcid: "en-us",
            text: ""
        }
    ],
    body: [
        {
            lcid: "en-us",
            text: ""
        }
    ],
    status: EmailStatus.Draft,
    audiences: [],
    sendToAllUsers: false,
    fromSender: {
        name: "",
        email: ""
    },
    replyAddress: "",
}

const DEFAULT_EMAILS_STATE: IEmailsState = {
    draftEmailsList: { id: EmailStatus.Draft, ...DEFAULT_EMAILS_LIST_STATE },
    scheduledEmailsList: { id: EmailStatus.Scheduled, ...DEFAULT_EMAILS_LIST_STATE },
    sentEmailsList: { id: EmailStatus.Sent, ...DEFAULT_EMAILS_LIST_STATE },
    editor: {
        email: DEFAULT_EMAIL,
        changedSinceLastSaved: false,
        isFetching: false,
        isSaving: false,
        shouldDownloadRecipientsList: false,
        shouldSendTestEmails: false,
        testEmails: [],
        shouldOpenOneLastCheck: false,
    },
    successMessage: "",
    errorMessage: "",
    sentConfirmation: {
        shouldShowDialog: false,
        expectedSentTime: "",
        emailId: ""
    }
}

export interface ISetEmailListAction extends Action {
    payload: IEmailListing;
}

export interface IGetEmailListThunkPayload {
    pageAmount: number;
    pageNumber: number;
    filters: IEmailFilters;
}

export const getDraftEmailList = createAsyncThunk<void, IGetEmailListThunkPayload, { state: GlobalApplicationState }>(
    "GET_DRAFT_EMAIL_POST_LIST",
    async (payload: IGetEmailListThunkPayload, { dispatch }) => {
        try {
            const { data } = 
                await emailsApi.getEmailList(payload.pageAmount, payload.pageNumber, {...payload.filters, status: EmailStatus.Draft});

            dispatch({ type: emailsSlice.actions.SET_DRAFT_EMAIL_LIST, payload: data});
        } catch (err) {
            throw err;
        }
    }
);

export const getScheduledEmailList = createAsyncThunk<void, IGetEmailListThunkPayload, { state: GlobalApplicationState }>(
    "GET_SCHEDULED_EMAIL_POST_LIST",
    async (payload: IGetEmailListThunkPayload, { dispatch }) => {
        try {
            const { data } = 
                await emailsApi.getEmailList(payload.pageAmount, payload.pageNumber, {...payload.filters, status: EmailStatus.Scheduled});

            dispatch({ type: emailsSlice.actions.SET_SCHEDULED_EMAIL_LIST, payload: data});
        } catch (err) {
            throw err;
        }
    }
);

export const getSentEmailList = createAsyncThunk<void, IGetEmailListThunkPayload, { state: GlobalApplicationState }>(
    "GET_SENT_EMAIL_POST_LIST",
    async (payload: IGetEmailListThunkPayload, { dispatch }) => {
        try {
            const { data } = 
                await emailsApi.getEmailList(payload.pageAmount, payload.pageNumber, {...payload.filters, status: EmailStatus.Sent});

            dispatch({ type: emailsSlice.actions.SET_SENT_EMAIL_LIST, payload: data});
        } catch (err) {
            throw err;
        }
    }
);

export interface IGetEmailToEditThunkPayload {
    emailId: string;
}

export interface ISetMessageThunkPayload {
    message: string;
    messageType: "success" | "error";
}

export interface ICreateNewEmailThunkPayload {
    redirectUrl?: string;
}

export interface IUpdateEmailEditorAction extends Action {
    payload: {
        email: Partial<IEmail>;
        changedSinceLastSaved?: boolean;
    };
}

export interface ISetEmailEditorAction extends Action {
    payload: {
        email: IEmail;
    };
}

export interface IChangedSinceLastSavedEditorAction extends Action {
    payload: {
        changedSinceLastSaved: boolean;
    };
}

export interface ISetEmailMessageAction extends Action {
    payload: {
        message: string;
    };
}

export interface ISetShouldDownloadAction extends Action {
    payload: {
        shouldDownload: boolean;
    };
}

export interface ISetShouldSendAction extends Action {
    payload: {
        shouldSend: boolean;
    };
}

export interface ISetShouldOpenAction extends Action {
    payload: {
        shouldOpen: boolean;
    };
}

export interface ISetShouldShowSentConfirmAction extends Action {
    payload: {
        sentConfirmation: IEmailSentConfirmation;
    };
}

export interface ISetRedirectUrlAction extends Action {
    payload: {
        redirectUrl?: string;
    };
}

export interface ISetTestEmailsAction extends Action {
    payload: {
        testEmails: string[];
    };
}

export const getEmailToEdit = createAsyncThunk<void, IGetEmailToEditThunkPayload, { state: GlobalApplicationState }>(
    "GET_EMAIL_TO_EDIT",
    async (payload: IGetEmailToEditThunkPayload, { dispatch }) => {
        try {
            const { data } = await emailsApi.getEmail(payload.emailId);

            dispatch({ type: emailsSlice.actions.SET_EMAIL_EDITOR, payload: { email: data }});
        } catch (err) {
            throw err;
        }
    }
);

export const createNewEmail = createAsyncThunk<void, ICreateNewEmailThunkPayload, { state: GlobalApplicationState }>(
    "CREATE_NEW_EMAIL",
    async (payload: ICreateNewEmailThunkPayload, { dispatch, getState }) => {
        try {
            const { data } = await emailsApi.createNewEmail(getState().emails.editor.email);

            dispatch({ type: emailsSlice.actions.SET_REDIRECT_URL, payload: { redirectUrl: payload.redirectUrl } });
            dispatch({ type: emailsSlice.actions.SET_EMAIL_EDITOR, payload: { email: data }});
        } catch (err) {
            throw err;
        }
    }
);

export const saveExistingEmail = createAsyncThunk<void, ICreateNewEmailThunkPayload, { state: GlobalApplicationState }>(
    "SAVE_EXISTING_EMAIL",
    async (payload: ICreateNewEmailThunkPayload, { dispatch, getState }) => {
        try {
            await emailsApi.saveExistingEmail(getState().emails.editor.email);

            dispatch({ type: emailsSlice.actions.SET_REDIRECT_URL, payload: { redirectUrl: payload.redirectUrl } });
            dispatch({ type: emailsSlice.actions.SET_CHANGED_SINCE_LAST_SAVED, payload: { changedSinceLastSaved: false } });
        } catch (err) {
            throw err;
        }
    }
);

export const emailsSlice = createSlice({
    name: "emails",
    initialState: DEFAULT_EMAILS_STATE,
    reducers: {
        SET_DRAFT_EMAIL_LIST: (state: IEmailsState, action: ISetEmailListAction) => {
            state.draftEmailsList = {
                ...action.payload,
                id: EmailStatus.Draft,
                isFetching: false
            }
        },
        SET_SCHEDULED_EMAIL_LIST: (state: IEmailsState, action: ISetEmailListAction) => {
            state.scheduledEmailsList = {
                ...action.payload,
                id: EmailStatus.Scheduled,
                isFetching: false
            }
        },
        SET_SENT_EMAIL_LIST: (state: IEmailsState, action: ISetEmailListAction) => {
            state.sentEmailsList = {
                ...action.payload,
                id: EmailStatus.Sent,
                isFetching: false
            }
        },
        UPDATE_EMAIL_EDITOR: (state: IEmailsState, action: IUpdateEmailEditorAction) => {
            state.editor.email = { ...state.editor.email, ...action.payload.email };
            state.editor.changedSinceLastSaved = action.payload.changedSinceLastSaved ?? true;
        },
        SET_EMAIL_EDITOR: (state: IEmailsState, action: ISetEmailEditorAction) => {
            state.editor.email = action.payload.email;
            state.editor.changedSinceLastSaved = false;
        },
        SET_SHOULD_DOWNLOAD_RECIPIENTS_LIST: (state: IEmailsState, action: ISetShouldDownloadAction) => {
            state.editor.shouldDownloadRecipientsList = action.payload.shouldDownload;
        },
        SET_SHOULD_SEND_TEST_EMAILS: (state: IEmailsState, action: ISetShouldSendAction) => {
            state.editor.shouldSendTestEmails = action.payload.shouldSend;
        },
        SET_TEST_EMAILS: (state: IEmailsState, action: ISetTestEmailsAction) => {
            state.editor.testEmails = action.payload.testEmails;
        },
        SET_SHOULD_OPEN_ONE_LAST_CHECK: (state: IEmailsState, action: ISetShouldOpenAction) => {
            state.editor.shouldOpenOneLastCheck = action.payload.shouldOpen;
        },
        SET_SHOW_SENT_CONFIRMATION: (state: IEmailsState, action: ISetShouldShowSentConfirmAction) => {
            state.sentConfirmation = action.payload.sentConfirmation;
        },
        SET_REDIRECT_URL: (state: IEmailsState, action: ISetRedirectUrlAction) => {
            state.redirectUrl = action.payload.redirectUrl;
        },
        SET_CHANGED_SINCE_LAST_SAVED: (state: IEmailsState, action: IChangedSinceLastSavedEditorAction) => {
            state.editor.changedSinceLastSaved = action.payload.changedSinceLastSaved;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDraftEmailList.pending, (state: IEmailsState) => {
                state.draftEmailsList.isFetching = true;
            })
            .addCase(getDraftEmailList.fulfilled, (state: IEmailsState) => {
                state.draftEmailsList.isFetching = false;
            })
            .addCase(getDraftEmailList.rejected, (state: IEmailsState) => {
                state.draftEmailsList.isFetching = false;
            })
            .addCase(getScheduledEmailList.pending, (state: IEmailsState) => {
                state.scheduledEmailsList.isFetching = true;
            })
            .addCase(getScheduledEmailList.fulfilled, (state: IEmailsState) => {
                state.scheduledEmailsList.isFetching = false;
            })
            .addCase(getScheduledEmailList.rejected, (state: IEmailsState) => {
                state.scheduledEmailsList.isFetching = false;
            })
            .addCase(getSentEmailList.pending, (state: IEmailsState) => {
                state.sentEmailsList.isFetching = true;
            })
            .addCase(getSentEmailList.fulfilled, (state: IEmailsState) => {
                state.sentEmailsList.isFetching = false;
            })
            .addCase(getSentEmailList.rejected, (state: IEmailsState) => {
                state.sentEmailsList.isFetching = false;
            })
            .addCase(getEmailToEdit.pending, (state: IEmailsState) => {
                state.editor.isFetching = true;
            })
            .addCase(getEmailToEdit.fulfilled, (state: IEmailsState) => {
                state.editor.isFetching = false;
            })
            .addCase(getEmailToEdit.rejected, (state: IEmailsState) => {
                state.editor.isFetching = false;
            })
            .addMatcher(isAnyOf(createNewEmail.pending, saveExistingEmail.pending), (state: IEmailsState) => {
                state.editor.isSaving = true;
            })
            .addMatcher(isAnyOf(createNewEmail.fulfilled, saveExistingEmail.fulfilled), (state: IEmailsState) => {
                state.editor.isSaving = false;
            })
            .addMatcher(isAnyOf(createNewEmail.rejected, saveExistingEmail.rejected), (state: IEmailsState) => {
                state.editor.isSaving = false;
            });
    }
});