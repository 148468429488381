import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { postsApi } from "api/instances";
import Loading from "modules/common/components/loading";
import { PostFeedFilters, PostFeedItem } from "../../models";
import Card from "./components/layouts/card/Card";
import { DialogPostView } from "../post-view/dialogPostView";

import "./styles/NewsFeed.sass";
import { FeedButtons } from "../../../common/components/feed/feedButtons";

class PublicFeed extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
        super(props);

        this.state = {
            canLoadMore: false,
            isFetching: true,
            postsFeed: [],
            selectedPostId: props.selectedPostId || ""
        };
    }

    public componentDidMount() {
        this.fetchFeed({}, 1);
    }

    public render() {
        const { postsFeed } = this.state;

        if (this.state.postsFeed.length === 0 && this.state.isFetching)
            return <Loading />;

        return (
            <div id="news-feed">
                {postsFeed.length === 0
                    ? <div className="no-feed">No posts were found</div>
                    : this.getLayout({ ...this.props, ...this.state })
                }
                <FeedButtons
                    loadMore={{
                        isFetching: this.state.isFetching,
                        canLoadMore: this.state.canLoadMore,
                        onFetchMore: this.continueFetching
                    }}
                />
                <DialogPostView
                    isPublic
                    open={!!this.state.selectedPostId}
                    tenantId={this.props.tenantId}
                    onClose={this.unselectPost}
                    postId={this.state.selectedPostId}
                />
            </div>
        );
    }

    private continueFetching = (pageNumber: number) => {
        this.fetchFeed({}, pageNumber);
    }


    private fetchFeed = (filters: Partial<PostFeedFilters>, pageNumber: number) => {
        this.setState({ isFetching: true });

        postsApi.GetPublicFeed(this.props.tenantId, filters, pageNumber)
            .then((postsFeed) => {
                this.setState({ canLoadMore: postsFeed.length === 12, isFetching: false, postsFeed: this.state.postsFeed.concat(postsFeed) });
            })
            .catch(_ => {
                this.setState({ canLoadMore: false, isFetching: false, postsFeed: [] });
            });
    }


    private getLayout(props: ComponentProps & ComponentState): JSX.Element {
        return <Card {...props} cardCount={3} hideReadDate preferredLCID="en-us" onPostSelected={this.selectPost} />;
    }

    private selectPost = (post: PostFeedItem) => {
        this.setState({ selectedPostId: post.id });
        if (!post.lastReadTime)
            this.setState({
                postsFeed: this.state.postsFeed.map(article => article.id === post.id && !post.lastReadTime
                    ? { ...article, lastReadTime: new Date().toISOString() }
                    : article
                )
            });
    }

    private unselectPost = () => {
        this.setState({ selectedPostId: "" });
    }
}

interface ComponentProps {
    selectedPostId?: string;
    tenantId: string;
}

interface ComponentState {
    canLoadMore: boolean;
    isFetching: boolean;
    postsFeed: PostFeedItem[];
    selectedPostId: string;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(PublicFeed);
